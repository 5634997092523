import { ErrorContext, OperationResult } from '../../../types/apiTypes';
import { StopPredictionDto } from '../../../types/busHistoryTypes';
import { Dictionary } from '../../../types/view-models-interfaces';
import { BphDirectionModel, BphRouteModel } from './BphTypes';
import { StopPredictionEntry } from './stopPredictionEntry';

export interface BphSliderState {
    maxValue: number;
    sliderValue: number;
}

export type BphDirectionsState = {
    directions: BphDirectionModel[] | null,
    selectedDirection: BphDirectionModel | null,
};


export type BphRoutesState = {
    routes: BphRouteModel[] | null;
    selectedRoute: BphRouteModel | null;
    initialRoutesList: BphRouteModel[];
};

export type DateTimeState = {
    selectedDate: string;
    selectedTime: string;
    selectedDateTime: string;
};


export class StopPredictionsState {
    public readonly error?: ErrorContext;
    public readonly plist?: StopPredictionEntry[];

    constructor(predictionResult: OperationResult<StopPredictionDto[]>) {
        if (!predictionResult.isSuccess) {
            this.error = predictionResult.error;
            return;
        }

        this.plist = [];

        predictionResult.data?.sort((a, b) => {
            let r = a.tripId.localeCompare(a.tripId);
            if (r === 0)
                r = a.vehicleId.localeCompare(b.vehicleId);
            if (r === 0)
                r = -a.predictionTime.localeCompare(b.predictionTime);
            return r;
        });

        const created: Dictionary<StopPredictionEntry> = {};
        for (const e of predictionResult.data) {
            const n = `${e.tripId}-${e.vehicleId}`;
            let entry = created[n];
            if (entry)
                entry.append(e);
            else {
                created[n] = (entry = new StopPredictionEntry(e));
                this.plist.push(entry);
            }
        }
    }

    /**
     * Finds active entry on specified date and time.
     * @param time date and time string in format "yyyy-MM-ddTHH:mm:ss"
     */
    public onTime(time: string): StopPredictionEntry[] {
        const active: StopPredictionEntry[] = [];
        if (this.plist) {
            for (const sp of this.plist) {
                if (sp.updateOnTime(time))
                    active.push(sp);
            }
            active.sort((a, b) => {
                const ta = a.active?.predictedTime || '';
                const tb = b.active?.predictedTime || '';
                return ta.localeCompare(tb);
            });
        }
        return active;
    }

}