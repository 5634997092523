import { ChartOptions, TooltipItem } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineDatePoint } from '../../../types/chartTypes';
import { VehiclesCountChartData } from '../../../types/vehicleStatisticsTypes';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'With Trips',
            lineTension: 0.1,
            backgroundColor: 'rgba(130, 225, 128, 0.8)',
            borderColor: 'rgba(130, 225, 128, 0.8)',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(130, 225, 128, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(130, 225, 128, 0.8)',
            pointHoverBorderColor: 'rgba(130, 225, 128, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
        {
            label: 'Without Trips',
            lineTension: 0.1,
            backgroundColor: 'rgba(255, 131, 104, 0.8)',
            borderColor: 'rgba(255, 131, 104, 0.8)',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(255, 131, 104, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(255, 131, 104, 0.8)',
            pointHoverBorderColor: 'rgba(255, 131, 104, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'day',
            },
            ticks: {
                stepSize: 1,
                autoSkip: false,
            },
            title: {
                display: false,
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: false,
            },
            stacked: true,
        },
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    elements: {
        line: {
            tension: 0.4,
        },
    },
    plugins: {
        title: {
            display: false,
            position: 'top',
            text: 'Vehicles count',
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                boxWidth: 20,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                title: function (tooltipItems: TooltipItem<'line'>[]) {
                    return moment(tooltipItems[0].parsed.x).format('YYYY-MM-DD');
                },
                label: function (tooltipItem: TooltipItem<'line'>) {
                    const lineType = tooltipItem.datasetIndex === 0 ? 'With Trips' : 'Without Trips';
                    return `${lineType}: ${tooltipItem.parsed.y}`;
                },
                labelColor: function (tooltipItem: TooltipItem<'line'>) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            borderColor: 'rgba(130, 225, 128, 0.8)',
                            backgroundColor: 'rgba(130, 225, 128, 0.8)',
                        };
                    } else {
                        return {
                            borderColor: 'rgba(255, 131, 104, 0.8)',
                            backgroundColor: 'rgba(255, 131, 104, 0.8)',
                        };
                    }
                },
            },
        },
        filler: {
            propagate: true,
        },
    },
    spanGaps: false,
};

interface Props {
    chartData: VehiclesCountChartData[],
}

const VehiclesCountChart: React.FC<Props> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        const orderedChartData = chartData.sort((a, b) => new Date(a.serviceDate).getTime() - new Date(b.serviceDate).getTime());
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: orderedChartData.map(d => {
                    return {
                        x: d.serviceDate,
                        y: d.withTrips,
                    };
                }),
            },
            {
                ...prevState.datasets[1],
                data: orderedChartData.map(d => {
                    return {
                        x: d.serviceDate,
                        y: d.withoutTrips,
                    };
                }),
            }],
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState}
            height={120}
            options={options}
        />
    );
};

export default VehiclesCountChart;