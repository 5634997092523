import { ChartOptions, TooltipItem } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { DeviationForPeriod } from '../../../../types/delayStatisticsTypes';
import Utils from '../../../../utilities/utils';

interface ChartData {
    labels: Date[];
    datasets: {
        label: string;
        data: number[];
        fill: boolean;
        backgroundColor: string;
        borderColor: string;
    }[];
}

const initState = {
    labels: [],
    datasets: [
        {
            label: 'Average Deviation (min)!',
            data: [],
            fill: true,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(66, 220, 198, 0.1)',
            pointBackgroundColor: '#42DCC6',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(66, 220, 198, 0.1)',
            pointHoverBorderColor: 'rgba(66, 220, 198, 0.1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
        },
    ],
};

const options: ChartOptions<'line'> = {
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                boxWidth: 10,
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'x',
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                title: (context: TooltipItem<'line'>[]) => {
                    const tooltipItem = context[0];
                    return moment(tooltipItem.label).format('YYYY-MM-DD');
                },
                label: function (context: TooltipItem<'line'>) {
                    const value = context.parsed.y;
                    return `Avg Deviation: ${Utils.convertSecondsToMinutes(value, 'm', 's')}`;
                },
            },
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                parser: 'hh:mm a',
                unit: 'day',
            },
            ticks: {
                autoSkip: true,
                source: 'auto',
            },
            grid: { drawOnChartArea: false },
        },
        y: {
            ticks: {
                callback: function (value: number | string): string | null {
                    if (typeof value === 'number') {
                        return `${Utils.roundNumber(Number(value) / 60, 1)} min`;
                    }
                    return null;
                },
                stepSize: 30,
            },
            grid: { drawOnChartArea: false },
        },
    },
};

const DeviationHistoryChart: React.FC<{ chartData: DeviationForPeriod[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState<ChartData>(initState);
    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => new Date(d.period)),
            datasets: prevState.datasets.map(dataset => ({
                ...dataset,
                data: chartData.map(d => d.deviation),
            })),
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState}
            height={200}
            options={options}
        />
    );
};

export default DeviationHistoryChart;
