import { BoxAndWiskers, BoxPlotController, ViolinController } from '@sgratzl/chartjs-chart-boxplot';
import { Chart as ChartJS, ChartOptions, registerables, TooltipItem } from 'chart.js';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import Utils from '../../../utilities/utils';
import { AggregatedTripsItem, AnalysisByPercentilesTripStats } from './AnalysisByPercentilesForm';

ChartJS.register(
    ...registerables,
    BoxPlotController,
    BoxAndWiskers,
    ViolinController
);

const heightPerRow = 30;
const LeftBoundary = 'leftBoundary';
const RightBoundary = 'rightBoundary';
const MedianBoundary = 'medianBoundary';

const getIndexByPercentile = (percentile: number, elementsCount: number, roundFunc: (n: number) => number): number | null => {
    const percentileValue = roundFunc(percentile * elementsCount / 100);
    const arrayIndex = percentileValue === 0 ? null : percentileValue - 1;
    return arrayIndex;
};

const getBoundaryByPercentile = (percentile: number, delaysArr: number[],
    boundary: typeof LeftBoundary | typeof RightBoundary | typeof MedianBoundary): number | null => {
    if (delaysArr.length === 0)
        return null;
    const roundFunc: (n: number) => number = boundary === RightBoundary ? n => Math.ceil(n) : n => Math.floor(n);
    const arrIndex = getIndexByPercentile(percentile, delaysArr.length, roundFunc);
    if (arrIndex === null)
        return delaysArr[0];
    const nextIndex = arrIndex + 1;
    switch (boundary) {
        case RightBoundary:
            return delaysArr[arrIndex];
        case LeftBoundary:
            if (nextIndex >= delaysArr.length)
                return null;
            return delaysArr[nextIndex];
        case MedianBoundary:
            if (delaysArr.length % 2 === 0) {
                if (nextIndex >= delaysArr.length)
                    return delaysArr[arrIndex];
                return (delaysArr[arrIndex] + delaysArr[nextIndex]) / 2;
            } else {
                return delaysArr[arrIndex];
            }
        default:
            return null;
    }
};

const processChartData = (data: AggregatedTripsItem[]) => {
    const percentileMin = 10;
    const percentileMax = 90;
    const percentileMedian = 50;

    const chartData = data.map(d => {
        const leftBoundary = getBoundaryByPercentile(percentileMin, d.delaysMinutes, LeftBoundary);
        const rightBoundary = getBoundaryByPercentile(percentileMax, d.delaysMinutes, RightBoundary);
        const medianBoundary = getBoundaryByPercentile(percentileMedian, d.delaysMinutes, MedianBoundary);
        const min = Math.min(0, d.delaysMinutes[0]);
        const max = Math.max(d.scheduledDelayToNextTrip, d.delaysMinutes[d.delaysMinutes.length - 1]);

        return { ...d, leftBoundary, rightBoundary, medianBoundary, min, max };
    }).filter(d => d.leftBoundary !== null && d.rightBoundary !== null && d.medianBoundary !== null);

    const labels = chartData.map(p => p.scheduledStartTime);
    const datasets = [{
        label: 'Delays Data',
        backgroundColor: 'rgba(255,0,0,0.5)',
        borderColor: 'red',
        borderWidth: 1,
        outlierColor: 'lightgreen',
        outlierLineWidth: 2,
        padding: 20,
        outlierBorderColor: 'grey',
        lowerColor: 'lightblue',
        medianColor: 'green',
        itemRadius: 10,
        itemStyle: 'circle' as const,
        outlierRadius: 5,
        lowerBackgroundColor: 'lightblue',
        outlierBackgroundColor: 'rgba(65,255,125,0.5)',

        data: chartData.map(p => ({
            min: p.min,
            max: p.max,
            whiskerMin: p.delaysMinutes[0],
            q1: p.leftBoundary as number,
            median: p.medianBoundary as number,
            q3: p.rightBoundary as number,
            whiskerMax: p.delaysMinutes[p.delaysMinutes.length - 1],
            outliers: [0, p.scheduledDelayToNextTrip],
            tripInfo: p,
            percentileMin: percentileMin,
            percentileMax: percentileMax,
        })),
    }];

    return { labels, datasets };
};

const options: ChartOptions<'boxplot'> = {
    responsive: true,
    indexAxis: 'y',
    scales: {
        x: {
            position: 'top',
            ticks: {
                color: '#4183c4',
            },
            title: {
                display: true,
                text: 'Minutes',
            },
        },
        y: {
            ticks: {
                color: '#4183c4',
            },
        },
    },
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (context: TooltipItem<'boxplot'>) {
                    const dataItem = context.raw as {
                        tripInfo: AggregatedTripsItem;
                        min: number;
                        max: number;
                        whiskerMin: number;
                        whiskerMax: number;
                        q1: number;
                        median: number;
                        q3: number;
                    };
                    const tripInfo = dataItem.tripInfo;
                    const stats: AnalysisByPercentilesTripStats = {
                        tripInfo: tripInfo,
                        percentileMin: 25,
                        percentileMax: 75,
                        q1: dataItem.q1,
                        percentileMedian: dataItem.median,
                        q3: dataItem.q3,
                    };
                    const tripId = tripInfo.tripId;
                    const numberOfTrips = tripInfo.delaysMinutes.length;
                    const nextTripDelay = tripInfo.scheduledDelayToNextTrip;
                    const scheduledRunTime = tripInfo.scheduledRunTime;
                    const percentileMin = stats.percentileMin;
                    const percentileMax = stats.percentileMax;

                    return [
                        `Trip ${tripId}`,
                        `${numberOfTrips} trips`,
                        `Scheduled Run-Time, min: ${scheduledRunTime}`,
                        `Next trip start, min: ${nextTripDelay}`,
                        `${percentileMin}th percentile: ${stats.q1 > 0 ? '+' : ''}${Utils.roundNumber(stats.q1, 0)} min`,
                        `${percentileMax}th percentile: ${stats.q3 > 0 ? '+' : ''}${Utils.roundNumber(stats.q3, 0)} min`,
                    ].join('; ');
                },
            },
        },
    },
};

const AnalysisByPercentilesChart: React.FC<{
    analysisByPercentilesData: AggregatedTripsItem[];
    percentileMin: number;
    percentileMax: number;
    percentileMedian?: number;
    handleChartTimeAxisClick: (timeLabel: string) => void;
}> = ({ analysisByPercentilesData, percentileMin, percentileMax, percentileMedian = 50, handleChartTimeAxisClick }) => {

    const initialChartData = processChartData(analysisByPercentilesData);
    const [dataState, setDataState] = useState(initialChartData);

    const [optionsState, setOptionsState] = useState(options);
    const [chartStyleState, setChartStyleState] = useState({
        height: analysisByPercentilesData.length * heightPerRow +'px',
    });

    const chartRef = useRef(null);

    useEffect(() => {
        const chartData = analysisByPercentilesData.map(d => {
            const leftBoundary: number | null = getBoundaryByPercentile(percentileMin, d.delaysMinutes, LeftBoundary);
            const rightBoundary: number | null = getBoundaryByPercentile(percentileMax, d.delaysMinutes, RightBoundary);
            const medianBoundary: number | null = getBoundaryByPercentile(percentileMedian, d.delaysMinutes, MedianBoundary);
            const min: number = Math.min(0, d.delaysMinutes[0]);
            const max: number = Math.max(d.scheduledDelayToNextTrip, d.delaysMinutes[d.delaysMinutes.length - 1]);
            return { ...d, leftBoundary, rightBoundary, medianBoundary, min, max };
        }).filter(d => d.leftBoundary !== null && d.rightBoundary !== null && d.medianBoundary !== null);

        const chartHeight: number = chartData.length * heightPerRow;
        setChartStyleState({ height: chartHeight + 'px' });

        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(p => p.scheduledStartTime),
            datasets: [{
                ...prevState.datasets[0], data: chartData.map(p => {
                    return {
                        min: p.min,
                        max: p.max,
                        whiskerMin: p.delaysMinutes[0],
                        q1: p.leftBoundary as number,
                        median: p.medianBoundary as number,
                        q3: p.rightBoundary as number,
                        whiskerMax: p.delaysMinutes[p.delaysMinutes.length - 1],
                        outliers: [0, p.scheduledDelayToNextTrip],
                        tripInfo: p,
                        percentileMin: percentileMin,
                        percentileMax: percentileMax,
                    };
                }),
            }],
        }));

        const relativePosition = (event: MouseEvent, chart: ChartJS<'boxplot', AnalysisByPercentilesTripStats[]>): { y: number } => {
            const { top } = chart.canvas.getBoundingClientRect();
            return {
                y: event.clientY - top,
            };
        };

        const handleClick = (e: MouseEvent) => {
            if (chartRef == null || chartRef.current == null) return;
            const chartInstance = chartRef.current;
            const { y } = relativePosition(e, chartInstance);  // Исправлено типизация
            const { scales } = chartInstance as any;
            const yAxis = scales.y;
            if (!yAxis)
                return;
            const index = yAxis.getValueForPixel(y);
            const timeLabel = yAxis.getLabelForValue(index as number);
            if (timeLabel) {
                handleChartTimeAxisClick(timeLabel);
            }
        };

        setOptionsState(prevState => ({
            ...prevState,
            onClick: (e) => handleClick(e.native as MouseEvent),
        }));
    }, [analysisByPercentilesData, percentileMin, percentileMax, percentileMedian]);

    return (
        <div style={chartStyleState}>
            <Chart ref={chartRef} type="boxplot" data={dataState} options={optionsState} />
        </div>
    );
};

export default AnalysisByPercentilesChart;
