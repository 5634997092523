import { List, Menu } from 'semantic-ui-react';
import { BphDirection } from './types/BphTypes';

const styles = {
    menu: {
        marginBottom: '20px',
        marginTop: '30px',
    },
    listItem: {
    },
    list: {
    },
};

interface Props {
    directions: BphDirection[]
}

export default function DirectionInfo({ directions }: Props) {
    return (<>{
        directions.map(direction => {
            return (
                <Menu key={direction.id} style={styles.menu}>
                    <List divided size="large" className="celledMenuList" style={styles.list}>
                        <List.Item style={styles.listItem} >
                            <List.Content>
                                <List.Header>{direction.route}</List.Header>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                {direction.stop}
                            </List.Content>
                        </List.Item>
                    </List>
                </Menu>
            );
        })
    }</>);
}