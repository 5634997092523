import BlockUi from '@availity/block-ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getTwelveHoursStatus } from '../../../actions/otpActions';
import { AppState } from '../../../reducers/index';
import { getSelectedOrDefaultAgency } from '../../../selectors/index';
import { TwelveHoursStatusItem } from '../../../types/otpTypes';
import HistoricalDataStatusChart from './HistoricalDataStatusChart';

interface Props {
    agency: AgencyStateType | undefined;
}

const TwelveHoursStatusForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [twelveHoursStatusState, setTwelveHoursState] = useState<TwelveHoursStatusItem[]>([]);


    const retrieveReportData = async () => {
        if (!agency || !agency.id)
            return;
        setFormBlockingState(true);
        try {
            const twelveHoursStatus = await getTwelveHoursStatus(agency.id);
            setTwelveHoursState(twelveHoursStatus);
        } catch {
            setTwelveHoursState([]);
        } finally {
            setFormBlockingState(false);
        }
    };
    const styles = {
        chartDescription: {
            marginBottom: '30px',
        } as React.CSSProperties,
    };
    useEffect(() => {
        if (agency === undefined) return;
        const fetchData = async () => {
            await retrieveReportData();
        };
        fetchData();
    }, [agency?.id]);

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    12 Hour Reporting Status
                </Header>
                <div style={styles.chartDescription}>
                Not all vehicles have serviceable TAIP/GTFS-Realtime devices installed. The absence of device, their malfunctions and not assigning vehicles to trips by drivers lead to the fact that not all vehicles are displayed on the map and not all arrivals are predicted. The plot shows the distribution of trips performed by vehicles with and without reporting devices.
                </div>
                <Form.Group>
                    <Form.Field width={16}>
                        <HistoricalDataStatusChart statusItems={twelveHoursStatusState} />
                    </Form.Field>
                </Form.Group>
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(TwelveHoursStatusForm);