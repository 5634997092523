import moment from 'moment/moment';
import busStopSelectedIcon from '../../../static/bus-stop-20-hover.png';
import busStopIcon from '../../../static/bus-stop-20.png';
import { VehiclePositionState } from '../../../types/busHistoryTypes';
import { DirectionVariant } from '../../../types/gtfsTypes';
import { Dictionary } from '../../../types/view-models-interfaces';
import { getVehicleInfoboxHtml } from '../VehicleInfobox';
import { AgencyModel } from './agencyModel';
import { BphDirectionsState, BphRoutesState, BphSliderState, DateTimeState, StopPredictionsState } from './bphStates';
import { trackColorOrdinary, trackColorSelected } from './Constants';
import { DateTime } from './dateTime';
import { PublicInstanceHelper } from './InternalInstanceHelper';
import { Optional } from './Optional';
import { SliderInfoProps as BphSliderInfoProps } from './SliderInfoProps';

export interface NamedEntity {
    displayName: string
}

export interface SecondsIntervalOptions {
    /** default: '-' */
    negativeSign?: string,
    /** default: '+' */
    positiveSign?: string,
    /** default: 'before' */
    position?: 'before' | 'after'
}

export interface BusPositionHistoryStateType {
    helper: PublicInstanceHelper;

    mapReady: boolean;

    agency: AgencyModel | null;
    dateTime: DateTimeState;

    routesLoading: boolean;
    routesState: BphRoutesState;

    directionsLoading: boolean;
    directionsState: BphDirectionsState;
    directionsInfo: BphDirection[];

    stopsLoading: boolean;
    stopsState: BphStopsStateType;
    stopsSearchState: StopsSearchStateType;
    stopsNoResultMessageState?: string;
    stopsPredictions: Nullable<StopPredictionsState>;

    historyLoading: boolean;
    busPositionsState: VehiclePositionState[];

    scheduleDates: Nullable<DateTime[]>;
    sliderOptions: Nullable<BphSliderState>;
    sliderInfo: Nullable<BphSliderInfoProps>;
}

export interface BphDirection {
    id: string;
    route: string;
    stop: string;
}

export interface BusPositionHistoryContextType extends BusPositionHistoryStateType {
    onMapReady: (div: HTMLDivElement) => void;
    setAgency: (agency: AgencyModel | null) => void;
    updateDateTime: (date: Optional<string>, time: Optional<string>) => boolean;
    selectRoute: (value?: Nullable<BphRouteModel>) => boolean;
    selectDirection: (value?: Optional<BphDirectionModel>) => boolean;

    selectStop(value?: Nullable<BphStopModel>): boolean;

    setStopsSearchString: (value?: Nullable<string>) => void;
    onSliderTimeChanged: (sliderValue: number) => void;
    onDisplayDirectionStopsChanged: (value: boolean) => void;
}

export type StopsSearchStateType = {
    searchQuery?: string | null;
};

export class BphRouteModel implements NamedEntity {
    constructor(
        public readonly routeId: string,
        public readonly displayName: string) {
    }
}

export class BphStopModel implements NamedEntity {
    public readonly pushpin: Microsoft.Maps.Pushpin;
    public readonly infobox: Microsoft.Maps.Infobox;

    constructor(
        public readonly displayName: string,
        public readonly stopId: string,
        lat: number,
        lng: number,
        selected = true,
    ) {
        this.pushpin = new Microsoft.Maps.Pushpin(
            new Microsoft.Maps.Location(lat, lng),
            { icon: selected ? busStopSelectedIcon : busStopIcon },
        );
        this.infobox = new Microsoft.Maps.Infobox(
            new Microsoft.Maps.Location(lat, lng),
            {
                description: `${displayName}`,
                visible: false,
            },
        );
    }

    public addPushpinHandler(pushpin: Microsoft.Maps.Pushpin, infoboxes: Microsoft.Maps.Infobox[]) {
        const {
            latitude,
            longitude,
        } = pushpin.getLocation();
        const selectedInfobox = infoboxes.find(i => {
            const infoboxLocation = i.getLocation();
            return infoboxLocation.latitude === latitude && infoboxLocation.longitude === longitude;
        });
        Microsoft.Maps.Events.addHandler(pushpin, 'mouseover', (_event: Microsoft.Maps.IMouseEventArgs) => {
            infoboxes.forEach(i => i.setOptions({ visible: false }));
            if (selectedInfobox) {
                this.infobox.setOptions({ visible: true });
            }
        });
    }
}

export class BphVehicleModel {
    private static _zero?: Microsoft.Maps.Location;

    public readonly id: string;
    public readonly infobox: Microsoft.Maps.Infobox;

    constructor(position: VehiclePositionState) {
        this.id = position.vehicleId;
        if (!BphVehicleModel._zero) {
            BphVehicleModel._zero = new Microsoft.Maps.Location(0, 0);
        }
        this.infobox = new Microsoft.Maps.Infobox(BphVehicleModel._zero);
        this.update(position);
    }

    private _description = '';
    private _highlighted = false;

    public get highlighted(): boolean {
        return this._highlighted;
    }

    public set highlighted(value: boolean) {
        this._highlighted = !!value;
        this._updateInfobox();
    }

    private _updateInfobox(): void {
        const c = this._highlighted ? '#c40000' : undefined;
        this.infobox.setOptions({
            htmlContent: getVehicleInfoboxHtml(this.id, this._description, c),
        });
    }

    public update(position: VehiclePositionState) {
        if (this.id !== position.vehicleId) {
            console.error(`BPH: invalid update [${this.id}] <> [${position.vehicleId}] `);
        }
        const location = new Microsoft.Maps.Location(position.latitude, position.longitude);
        this.infobox.setLocation(location);
        this._description = `at ${moment.parseZone(position.reportTimeAtz).format('h:mm:ss A')}`;
        this._updateInfobox();
    }
}

export class BphDirectionModel implements NamedEntity {
    public readonly directionVariantId: string;
    public readonly directionVariantInternalId: string;
    public readonly routeName: string;
    public readonly cardinalDirection: string;
    public readonly lastStop: string;
    public readonly polyline: Microsoft.Maps.Polyline;
    public readonly bounds: Microsoft.Maps.LocationRect;

    private readonly _stopIds?: Dictionary<BphStopModel>;

    constructor(
        public readonly displayName: string,
        source: DirectionVariant,
    ) {
        this.directionVariantId = source.directionVariantId;
        this.directionVariantInternalId = source.directionVariantInternalId;
        this.routeName = source.routeName;
        this.cardinalDirection = source.cardinalDirection;
        this.lastStop = source.lastStop;
        if (source.stops) {
            this._stopIds = {};
            for (const s of source.stops) {
                this._stopIds[s.stopId] = new BphStopModel(s.stopName, s.stopId, Number(s.lat), Number(s.lon), false);
            }
            const stops = Object.values(this._stopIds);
            stops.forEach(s => s.addPushpinHandler(s.pushpin, stops.map(si => si.infobox)));
        }
        const locations = source.pointCoords.map(e => new Microsoft.Maps.Location(e[0] as Latitude, e[1] as Longitude));
        this.bounds = Microsoft.Maps.LocationRect.fromLocations(locations);
        this.polyline = new Microsoft.Maps.Polyline(
            locations,
            {
                strokeColor: trackColorOrdinary,
                strokeThickness: 3,
            },
        );
    }

    public get hasStops(): boolean {
        return !!this._stopIds;
    }

    public hasStop(stopId: string): boolean {
        return !!this._stopIds && (stopId in this._stopIds);
    }

    public select(selected: boolean) {
        this.polyline.setOptions({
            strokeColor: selected
                ? trackColorSelected
                : trackColorOrdinary,
        });
    }

    public getStops(): BphStopModel[] {
        return this._stopIds ? Object.values(this._stopIds) : [];
    }
}

export type BphStopsStateType = {
    stops: BphStopModel[] | null;
    selectedStop: BphStopModel | null;
    loadingSchedule: boolean;
};

export interface PreditionData {
    predictionTime: string;
    predictedTime: string;
    actualTime: string;
    scheduledTime: string;
}