import { ChartOptions, TimeUnit, TooltipItem } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { crosshairOption } from '../../../types/chartjs-plugin-crosshair/CrosshairOption';
import { LineDatePoint } from '../../../types/chartTypes';
import { ModelType, modelTypeColors, OverallDataPoint } from './_data';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Adaptive',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.HISTORY].fill,
            borderColor: modelTypeColors[ModelType.HISTORY].base,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: modelTypeColors[ModelType.HISTORY].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.HISTORY].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.HISTORY].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
        {
            label: 'Linear',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.LINEAR].fill,
            borderColor: modelTypeColors[ModelType.LINEAR].base,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: modelTypeColors[ModelType.LINEAR].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.LINEAR].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.LINEAR].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
        {
            label: 'Legacy',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.SIRI].fill,
            borderColor: modelTypeColors[ModelType.SIRI].base,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: modelTypeColors[ModelType.SIRI].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.SIRI].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.SIRI].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
            },
            ticks: {
                stepSize: 1,
                autoSkip: false,
            },
            title: {
                display: true,
                text: 'Service Date',
            },
        },
        y: {
            type: 'linear' as const,
            title: {
                display: true,
                text: 'Accuracy, %',
            },
            beginAtZero: true,
        },
    },
    plugins: {
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            callbacks: {
                title: function () {
                    return '';
                },
                labelColor: (tooltipItem: TooltipItem<'line'>) => {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            borderColor: 'rgba(65,105,225,1)',
                            backgroundColor: 'rgba(65,105,225,1)',
                        };
                    } else if (tooltipItem.datasetIndex === 1) {
                        return {
                            borderColor: 'rgb(255, 165, 0)',
                            backgroundColor: 'rgb(255, 165, 0)',
                        };
                    } else {
                        return {
                            borderColor: 'rgb(60, 179, 113)',
                            backgroundColor: 'rgb(60, 179, 113)',
                        };
                    }
                },
            },
        },
    },
    interaction: {
        mode: 'x' as const,
        intersect: false,
    },
};

function mapPoints(data: OverallDataPoint[], model: ModelType): LineDatePoint[] {
    return data.map(p => {
        return {
            x: moment(p.date1).format('YYYY-MM-DD'),
            y: p[model] || null,
        };
    });
}

type AccuracyLineChartProps = {
    mode: string | undefined;
    data: OverallDataPoint[]
};

type TypeUnitsMap = { [mode: string]: { unit: string; unitStepSize: number } };

const TimeUnits: TypeUnitsMap = {
    Day: { unit: 'day', unitStepSize: 1 },
    Week: { unit: 'day', unitStepSize: 7 },
    Month: { unit: 'month', unitStepSize: 1 },
};

const AccuracyLineChart: React.FC<AccuracyLineChartProps> = (props) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const unit = TimeUnits[props.mode || 'Day']?.unit || TimeUnits.Day.unit;
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                x: {
                    ...prevState.scales?.x,
                    time: {
                        minUnit: unit as TimeUnit,
                        unit: unit as TimeUnit,
                    },
                    ticks: {
                        ...prevState.scales?.x?.ticks,
                        autoSkip: false,
                        stepSize: TimeUnits[props.mode || 'Day']?.unitStepSize || TimeUnits.Day.unitStepSize,
                    },
                },
            },
        }));
    }, [props.mode]);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [
                { ...prevState.datasets[0], data: mapPoints(props.data, ModelType.HISTORY) },
                { ...prevState.datasets[1], data: mapPoints(props.data, ModelType.LINEAR) },
                { ...prevState.datasets[2], data: mapPoints(props.data, ModelType.SIRI) },
            ],
        }));
    }, [props.data]);

    return (
        <div className="chart-wrapper">
            <Line
                data={dataState}
                options={optionsState}
                height={80}
                plugins={[ { id: 'crosshair', ...crosshairOption } ]}
            />
        </div>
    );
};

export default AccuracyLineChart;
