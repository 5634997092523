import { ChartOptions, TooltipItem } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineDatePoint } from '../../../types/chartTypes';
import { RunningTimesComparisonChartType } from '../../../types/runningTimeAnalyticsTypes';
import Utils from '../../../utilities/utils';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: '',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#2185d0',
            borderColor: '#2185d0',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: '#2185d0',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#2185d0',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineDatePoint[],
        },
        {
            label: '',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#21ba45',
            borderColor: '#21ba45',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: '#21ba45',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#21ba45',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineDatePoint[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'linear' as const,
            ticks: {
                stepSize: 5,
                autoSkip: false,
            },
            title: {
                display: true,
                text: 'Average Run-Times (minutes)',
            },
        },
        y: {
            ticks: {
                stepSize: 1,
                autoSkip: false,
            },
            title: {
                display: true,
                text: 'Distance (miles)',
            },
        },
    },
    plugins: {
        title: {
            display: false,
            position: 'top' as const,
        },
        tooltip: {
            mode: 'index' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 10,
            callbacks: {
                labelColor: (tooltipItem: TooltipItem<'line'>) => {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            borderColor: '#2185d0',
                            backgroundColor: '#2185d0',
                        };
                    } else {
                        return {
                            borderColor: '#21ba45',
                            backgroundColor: '#21ba45',
                        };
                    }
                },
                title: function () {
                    return '';
                },
            },
        },
    },
    interaction: {
        mode: 'x' as const,
        intersect: false,
    },
};

const RunningTimesComparisonChart: React.FC<RunningTimesComparisonChartType> = ({
        runningTimesComparisonData,
        line1Label,
        line2Label,
    }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);
    useEffect(() => {
        const tripStops1 = runningTimesComparisonData && runningTimesComparisonData.tripsData1
            ? runningTimesComparisonData.tripsData1.sort((a, b) => a.stopSequence - b.stopSequence)
            : [];
        const tripStops2 = runningTimesComparisonData && runningTimesComparisonData.tripsData2
            ? runningTimesComparisonData.tripsData2.sort((a, b) => a.stopSequence - b.stopSequence)
            : [];
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                label: line1Label,
                data: tripStops1.map(s => {
                    return {
                        x: Utils.roundNumber(s.travelSec / 60, 0),
                        y: Utils.kmToMiles(s.traveledKm),
                    };
                }),
            }, {
                ...prevState.datasets[1],
                label: line2Label,
                data: tripStops2.map(s => {
                    return {
                        x: Utils.roundNumber(s.travelSec / 60, 0),
                        y: Utils.kmToMiles(s.traveledKm),
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        label: function (tooltipItem: TooltipItem<'line'>) {
                            const stopsData = tooltipItem.datasetIndex === 0 ? tripStops1 : tripStops2;
                            const stop = stopsData[tooltipItem.dataIndex];
                            return stop ? `Run-Time: ${Utils.convertSecondsToMinutes(stop.travelSec, 'm', 's')} @ ${tooltipItem.parsed.y} mil; Stop: ${stop.stopName} (${stop.stopId}); Sequence: ${stop.stopSequence}` : `Run-Time: ${Utils.convertSecondsToMinutes(tooltipItem.parsed.y * 60, 'm', 's')} @ ${tooltipItem.parsed.y.toFixed(2)} mil`;
                        },
                    },
                },
            },
        }));
    }, [runningTimesComparisonData]);

    return (
        <Line
            data={dataState}
            height={150}
            options={optionsState}
        />
    );
};

export default RunningTimesComparisonChart;