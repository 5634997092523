import { StopPredictionDto } from '../../../types/busHistoryTypes';
import { PreditionData } from './BphTypes';

export class StopPredictionEntry {
    public readonly tripId: string;
    public readonly vehicleId: string;
    public readonly predictions: PreditionData[];
    public active: PreditionData | null;

    constructor(source: StopPredictionDto) {
        this.tripId = source.tripId;
        this.vehicleId = source.vehicleId;
        this.active = null;
        this.predictions = [];
        this.append(source);
    }

    public append(source: StopPredictionDto) {
        if (this.tripId !== source.tripId || this.vehicleId !== source.vehicleId) {
            throw new Error('Invalid argument: tripId or vehicleId not match.');
        }
        this.predictions.push({
            predictionTime: source.predictionTime,
            predictedTime: source.predictedTime,
            actualTime: source.actualTime,
            scheduledTime: source.scheduledTime,
        });
    }

    /**
     * Finds active entry on specified date and time.
     * @param time date and time string in format "yyyy-MM-ddTHH:mm:ss"
     */
    public updateOnTime(time: string): boolean {
        this.active = null;

        for (let i = this.predictions.length; i > 0;) {
            const pe = this.predictions[--i];
            if (pe.predictionTime >= time) break;
            this.active = pe;
        }
        if (this.active?.actualTime && this.active.actualTime < time)
            this.active = null;
        return this.active !== null;
    }

}