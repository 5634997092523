import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BunchesByKey } from '../../../../types/busBunchingTypes';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Bunches Count',
            data: [] as number[],
            barPercentage: 0.5,
            backgroundColor: '#42DCC6',
        },
    ],
};

const options: ChartOptions<'bar'> = {
    scales: {
        x: {
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {},
            grid: {
                drawOnChartArea: false,
            },
            title: {
                display: true,
                text: 'Number of buched vehicles',
            },
        },
    },
    responsive: true,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (tooltipItem) {
                    return `Bunches: ${tooltipItem.parsed.y}`;
                },
            },
        },
    },
};

const BunchesByWeekDayChart: React.FC<{ chartData: BunchesByKey[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        const labels = chartData.map(d => d.key);
        const values = chartData.map(d => d.bunchesCount);

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0],
                data: values,
            }],
        }));
    }, [chartData]);

    return (
        <Bar
            data={dataState}
            height={150}
            options={options}
        />
    );
};

export default BunchesByWeekDayChart;