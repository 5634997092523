import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { crosshairOption } from '../../../types/chartjs-plugin-crosshair/CrosshairOption';
import { PredictionsAnalysisData } from './PredictionCoverageForm';

type DataType = { x: string; y: number }[];

const defaultDataset = {
    fill: false,
    lineTension: 0.1,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBorderWidth: 1,
    pointHoverRadius: 3,
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 10,
    data: [] as DataType,
};

const chartSettings = {
    labels: [] as string[][],
    datasets: [
        ['00-03', '#31A6DC'],
        ['03-06', '#FFC061'],
        ['06-12', '#82E180'],
        ['12-30', '#FF8368'],
    ].map(([label, color]) => ({
        ...defaultDataset,
        label,
        backgroundColor: color,
        borderColor: color,
        pointBorderColor: color,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
    })),
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            display: false,
            labels: [],
            ticks: {
                autoSkip: false,
                maxRotation: 0,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        x2: {
            grid: {
                display: false,
                drawOnChartArea: false,
            },
            ticks: {
                autoSkip: false,
                maxRotation: 0,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                autoSkip: false,
                stepSize: 1,
                maxTicksLimit: 10,
            },
            grid: {
                drawOnChartArea: false,
            },
            title: {
                display: true,
                text: '%',
            },
        },
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                boxWidth: 10,
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            callbacks: {
                title: function () {
                    return '';
                },
                labelColor: function (tooltipItem) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            backgroundColor: '#31A6DC',
                            borderColor: '#31A6DC',
                        };
                    } else if (tooltipItem.datasetIndex === 1) {
                        return {
                            backgroundColor: '#FFC061',
                            borderColor: '#FFC061',
                        };
                    } else if (tooltipItem.datasetIndex === 2) {
                        return {
                            backgroundColor: '#82E180',
                            borderColor: '#82E180',
                        };
                    } else {
                        return {
                            backgroundColor: '#FF8368',
                            borderColor: '#FF8368',
                        };
                    }
                },
            },
        },
    },
};

interface Props {
    chartData: PredictionsAnalysisData[];
    showTooltipXLabel?: boolean;
    scaleXLabel?: string;
    autoScale?: boolean;
}

const PredictionsPercentageForPeriodChart: React.FC<Props> = ({
      chartData,
      showTooltipXLabel,
      scaleXLabel,
      autoScale,
    }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.indicator.split(/(?=-)/g)),
            datasets: [{
                ...prevState.datasets[0],
                data: chartData.map(d => {
                    return {
                        x: d.indicator,
                        y: d.short,
                    };
                }),
            },
                {
                    ...prevState.datasets[1],
                    data: chartData.map(d => {
                        return {
                            x: d.indicator,
                            y: d.mid1,
                        };
                    }),
                },
                {
                    ...prevState.datasets[2],
                    data: chartData.map(d => {
                        return {
                            x: d.indicator,
                            y: d.mid2,
                        };
                    }),
                },
                {
                    ...prevState.datasets[3],
                    data: chartData.map(d => {
                        return {
                            x: d.indicator,
                            y: d.long,
                        };
                    }),
                }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                x: {
                    ...prevState.scales?.x,
                    title: {
                        display: scaleXLabel !== undefined,
                        text: scaleXLabel,
                    },
                    ticks: {
                        ...prevState.scales?.y?.ticks,
                        autoSkip: autoScale,
                    },
                },
                x2: {
                    ...prevState.scales?.x2,
                    title: {
                        display: scaleXLabel !== undefined,
                        text: scaleXLabel,
                    },
                    ticks: {
                        ...prevState.scales?.y?.ticks,
                        autoSkip: autoScale,
                    },
                },
                y: {
                    ...prevState.scales?.y,
                    ticks: {
                        ...prevState.scales?.y?.ticks,
                        callback: function (tickValue: string | number) {
                            return tickValue;
                        },
                    },
                },
            },
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        label: function (tooltipItem) {
                            let lineType = '00-03';
                            let value = chartData.map(v => v.short)[tooltipItem.dataIndex];
                            if (tooltipItem.datasetIndex === 1) {
                                lineType = '03-06';
                                value = chartData.map(v => v.mid1)[tooltipItem.dataIndex];
                            } else if (tooltipItem.datasetIndex === 2) {
                                lineType = '06-12';
                                value = chartData.map(v => v.mid2)[tooltipItem.dataIndex];
                            } else if (tooltipItem.datasetIndex === 3) {
                                lineType = '12-30';
                                value = chartData.map(v => v.long)[tooltipItem.dataIndex];
                            }
                            let tooltipLabel = `${lineType}: ${value} %`;
                            if (showTooltipXLabel)
                                tooltipLabel = `${tooltipItem.parsed.x}: ` + tooltipLabel;
                            return tooltipLabel;
                        },
                    },
                },
            },
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState}
            height={170}
            options={optionsState}
            plugins={[ { id: 'crosshair', ...crosshairOption } ]}
        />
    );
};

export default PredictionsPercentageForPeriodChart;
