import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getShapeData } from '../../../../actions/gtfsStaticActions';
import { getRtVehiclePositionData, getTripStopsData } from '../../../../actions/vehiclesHistoryActions';
import Api from '../../../../api/api';
import busStopIcon from '../../../../static/bus-stop-20.png';
import { PolylineProps, PushpinProps } from '../../../../types/BingMapProps';
import { RtVehiclePosition, TripStopData } from '../../../../types/busHistoryTypes';
import { DropDownStateType } from '../../../../types/types';
import BingMapQuerier from '../../../../utilities/BingMapQuerier';
import { getVehicleInfoboxHtml } from '../../../bushistory/VehicleInfobox';
import BingMap from '../../../shared/BingMap';
import StopsScheduleTable from './StopsScheduleTable';
import { VehicleHistorySlider } from './VehicleHistorySlider';

const initialDropDownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};

type Styles = {
    busy: React.CSSProperties,
    block1: React.CSSProperties,
    box1: React.CSSProperties,
    box2: React.CSSProperties,
    sliderSegment: React.CSSProperties,
};

const styles: Styles = {
    busy: {
        height: 'auto',
    },
    block1: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 14,
    },
    box1: {
        flexGrow: 1,
        minWidth: '50%',
        height: 500,
    },
    box2: {
        minWidth: '25%',
        maxHeight: 500,
        justifyContent: 'space-around',
    },
    sliderSegment: {
        paddingLeft: 24,
        paddingRight: 24,
    },
};

interface VehicleHistoryPaneProps {
    agencyId: string,
    serviceDate: string,
    vehicleId: string,
    tripId: string,
    shapeId: string,
}

export const VehicleHistoryPane: React.FC<VehicleHistoryPaneProps> = (props) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [center, setCenter] = useState<CoordinatePair>();
    const [mapBoundsState, setMapBoundsState] = useState<Microsoft.Maps.LocationRect | undefined>();
    const [polylineState, setPolylineState] = useState<PolylineProps>();
    const [pushpinsState, setPushpinsState] = useState<PushpinProps[]>([]);
    const [tripsState] = useState(initialDropDownState);
    const [vehiclePositionsState, setVehiclePositionsState] = useState<RtVehiclePosition[]>([]);
    const [currentPositionState, setCurrentPositionState] = useState<RtVehiclePosition>();
    const [sliderValueState, setSliderValueState] = useState<number>(-1);
    const [tripStopsState, setTripStopsState] = useState<TripStopData[]>([]);
    const [selectedStopState, setSelectedStopState] = useState<TripStopData>();
    const mapRef = React.createRef<BingMapQuerier>();

    useEffect(() => {
        clearStates();
        (async (agencyId: string) => {
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
            if (props.vehicleId) {
                await retrieveTripsData(props.vehicleId, props.serviceDate);
            }
        })(props.agencyId);
    }, [props.agencyId, props.serviceDate, props.vehicleId, props.tripId]);

    useEffect(() => {
        const infobox = updateBusPosition(sliderValueState, vehiclePositionsState);
        return () => {
            if (infobox) {
                infobox.setMap(null as unknown as Microsoft.Maps.Map);
            }
        };
    }, [sliderValueState]);


    useEffect(() => {
        if (selectedStopState) {
            const { latitude, longitude } = selectedStopState;
            setPushpinsState([{
                location: [latitude, longitude],
                options: {
                    title: selectedStopState.stopName,
                    subTitle: `${selectedStopState.stopId}, ${moment.parseZone(selectedStopState.scheduledTime).format('h:mm A')}`,
                    icon: busStopIcon,
                    catId: 'trip-stop',
                },
            }]);
        }
        return () => setPushpinsState([]);
    }, [selectedStopState]);

    useEffect(() => {
        if (!tripsState.selectedValue) {
            setSliderValueState(-1);
            setVehiclePositionsState([]);
            setCurrentPositionState(undefined);
            setTripStopsState([]);
            setPolylineState(undefined);
            setPushpinsState([]);
        }
    }, [tripsState]);

    const handleSliderValueChange = (value: number) => {
        setSliderValueState(value);
    };

    const retrieveTripsData = async (_vehicleId: string, _selectedDate: string) => {
        if (props.agencyId && props.shapeId) {
            setFormBlockingState(true);
            try {
                const [vehiclePositions, tripStops, shapePoints] = await Promise.all([
                    getRtVehiclePositionData(props.agencyId, props.tripId, props.vehicleId),
                    getTripStopsData(props.agencyId, props.tripId),
                    getShapeData(props.agencyId, props.serviceDate, props.shapeId),
                ]);
                setVehiclePositionsState(vehiclePositions);
                setTripStopsState(tripStops);
                setPolylineState({
                    locations: shapePoints.map(({ latitude, longitude }) => [latitude, longitude]),
                    options: {
                        strokeColor: 'orangered',
                        strokeThickness: 3,
                    },
                });
                const locations = shapePoints.map(p => new Microsoft.Maps.Location(p.latitude, p.longitude));
                const boundingBox = Microsoft.Maps.LocationRect.fromLocations(locations);
                setMapBoundsState(boundingBox);
                setSliderValueState(0);
            } catch {
                setVehiclePositionsState([]);
                setTripStopsState([]);
                setPolylineState(undefined);
            }
            finally {
                setFormBlockingState(false);
            }
        } else {
            setVehiclePositionsState([]);
            setTripStopsState([]);
            setPolylineState(undefined);
        }
    };

    const updateBusPosition = (sliderValue: number, vehiclePositions: RtVehiclePosition[]): Microsoft.Maps.Infobox | null => {
        const position = vehiclePositions[sliderValue];
        if (!position || !mapRef || !mapRef.current || !mapRef.current.map)
            return null;
        setCurrentPositionState(position);
        const description = `at ${moment.parseZone(position.vehicleTime).format('h:mm:ss A')}`;
        const infobox = new Microsoft.Maps.Infobox(
            new Microsoft.Maps.Location(position.latitude, position.longitude),
            {
                htmlContent: getVehicleInfoboxHtml(props.vehicleId, description),
            },
        );
        infobox.setMap(mapRef.current.map);
        return infobox;
    };

    const clearStates = () => {
        setVehiclePositionsState([]);
        setCurrentPositionState(undefined);
        setTripStopsState([]);
        setSliderValueState(-1);
        setPolylineState(undefined);
        setPushpinsState([]);
    };

    return (
        <div>
            <BlockUi blocking={formBlockingState} style={styles.busy}>
                <div style={styles.sliderSegment}>
                    {polylineState && (!vehiclePositionsState || vehiclePositionsState.length < 1) &&
                        <p>- no history data found -</p>
                    }
                    {vehiclePositionsState && vehiclePositionsState.length > 0 &&
                        <VehicleHistorySlider
                            maxValue={vehiclePositionsState.length - 1}
                            sliderValue={sliderValueState}
                            handleSliderChange={handleSliderValueChange}
                            currentTime={currentPositionState?.vehicleTime}
                            currentDelay={currentPositionState?.delaySec}
                        />
                    }
                </div>
                <div style={styles.block1}>
                    <div style={styles.box1}>
                        {polylineState &&
                            <BingMap
                                ref={mapRef}
                                map={{
                                    center,
                                    options: {
                                        bounds: mapBoundsState,
                                    },
                                }}
                                polylines={polylineState && [polylineState]}
                                pushpins={pushpinsState}
                            />
                        }
                    </div>
                    <div style={styles.box2}>
                        {tripStopsState.length > 0 &&
                            <StopsScheduleTable
                                rows={tripStopsState}
                                selectedRow={selectedStopState}
                                setSelectedRow={setSelectedStopState}
                            />
                        }
                    </div>
                </div>
            </BlockUi>
        </div>
    );
};
