import './GtfsEditorStyles.css';
import BlockUi from '@availity/block-ui';
import * as d3 from 'd3';
import * as React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Header } from 'semantic-ui-react';
import * as actions from '../../../actions/actions';
import { AgencyStateType } from '../../../actions/actionTypes';
import Api from '../../../api/api';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import busStopIcon from '../../../static/bus-stop-20.png';
import { PolylineProps, PushpinProps } from '../../../types/BingMapProps';
import BingMap from '../../shared/BingMap';
import GtfsTable, { GtfsTableHandle } from './GtfsTable';
import ZipLoader, { FileData } from './ZipLoader';

const styles = {
    map: {
        width: '100%',
        height: '522px',
    } as React.CSSProperties,
};

interface Props {
    agency: AgencyStateType | undefined;
    fileNameSource: string | undefined;
}

export interface RowData {
    [key: string]: string;
}

const tableRefs: React.RefObject<GtfsTableHandle>[] = [];
const GtfsEditorForm: React.FC<Props> = ( { agency, fileNameSource } ) => {
    const [filesState, setFilesState] = useState<FileData[]>([]);
    const [isVisibleMapState, setIsVisibleMapState] = useState<boolean>(false);
    const [center, setCenter] = useState<CoordinatePair>();
    const [polylinesState, setPolylinesState] = useState<PolylineProps[]>();
    const [pushpinsState, setPushpinsState] = useState<PushpinProps[]>([]);
    const [formBlockingState, setFormBlockingState] = useState(false);
    const tripIdRef = useRef<string | undefined>(undefined);

    useEffect(() => {
        if (agency === undefined) return;
        (async (agencyId: string) => {
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
        })(agency.id);
    }, [agency?.id]);

    useEffect(() => {
        if (filesState === undefined) return;
        setFormBlockingState(false);
    }, [filesState]);

    useEffect(() => {
        setFormBlockingState(fileNameSource !== undefined);
    }, [fileNameSource]);

    const handleFilesChange = (files: FileData[]) => {
        processData(files);
        setFilesState(prevState => prevState.concat(files).sort((a, b) => a.sort > b.sort ? -1 : 1));
    };

    async function addDateTime(files: FileData[]) {
        if (fileNameSource !== undefined) {
            const tripTimes = await actions.getTripTimes(fileNameSource);
            if (tripTimes && files) {
                const tripsFileData = files.find(s => s.fileName === 'trips.txt')?.data;
                files.find(s => s.fileName === 'trips.txt')?.data;
                tripTimes?.forEach(tripTime => {
                    const trip = tripsFileData?.find(t => t.trip_id === tripTime.tripId);
                    if (trip) {
                        trip.times = `${tripTime.startTime} - ${tripTime.endTime}`;
                    }
                });
                const targetTable = tableRefs.find(s => s.current?.fileName === 'trips.txt')?.current;
                targetTable?.reRender();
            }
        }
    }

    const processData = (files: FileData[]) => {
        for (const file of files) {
            switch (file.fileName) {
                case 'stops.txt':
                    addStopBayName(files.find(s => s.fileName === 'trips_bays.txt'), file);
                    break;
                case 'trips.txt':
                    addDateTime(files);
                    break;
            }
        }
    };

    const groupByToMap = <T, Q>(array: T[] | undefined, predicate: (value: T, index: number, array: T[]) => Q) =>
        array?.reduce((map, value, index, array) => {
            const key = predicate(value, index, array);
            map.get(key)?.push(value) ?? map.set(key, [value]);
            return map;
        }, new Map<Q, T[]>());

    const addStopBayName = (tripsBayTable?: FileData, stopsTable?: FileData) => {
        if(tripsBayTable === null || stopsTable === null) return;
        const tripsBayTableData = tripsBayTable?.data;
        // const tripsBayTableDataMap = new Map(tripsBayTableData?.map(s => [s.stop_id, s]));

        const tripsBayTableDataMap2 = groupByToMap(tripsBayTableData, s => s.STOP_ID as string);
        console.log(tripsBayTableDataMap2);

        const stopsTableData = stopsTable?.data;
        const stopsTableDataMap = new Map(stopsTableData?.map(s => [s.stop_id, s]));

        for (const [key, values] of tripsBayTableDataMap2?.entries() ?? []) {
            const stop = stopsTableDataMap.get(key);
            if (stop) {
                // remove duplicates STOP_BAY from values and copy stop for each STOP_BAY
                const stop_bays = new Set(values.map(s => s.STOP_BAY));
                stop_bays.forEach(stopBay => {
                    const copyStop = JSON.parse(JSON.stringify(stop));
                    copyStop.stop_bays = stopBay;
                    stopsTable?.data.push(copyStop);
                });
            }
        }
    };

    const stopProcessed = (rowData: RowData, value: string) => {
        drawAllRoutesForStop(rowData, value);
    };

    const drawAllRoutesForStop = (rowData: RowData, value: string) => {
        const stopTimesFileData = filesState.find(s => s.fileName === 'stop_times.txt')?.data;
        const stopTimes = stopTimesFileData?.filter(s => s.stop_id === value) ?? [];
        const tripIds = Array.from(d3.group(stopTimes, s => s.trip_id), ([key, _value]) => key);
        const tripsFileData = filesState.find(s => s.fileName === 'trips.txt')?.data;
        const trips = tripsFileData?.filter(x => tripIds.includes(x.trip_id)) ?? [];
        const shapesFileData = filesState.find(s => s.fileName === 'shapes.txt')?.data;
        const groups = d3.group(trips, s => s.trip_id);
        const polylines: PolylineProps[] = [];
        for (const group of groups) {
            const shapeIds = group[1].map(x => x.shape_id);
            const shapes = shapesFileData?.filter(s => shapeIds.includes(s.shape_id)) ?? [];
            const coordPairs: CoordinatePair[] = shapes.map(({ shape_pt_lat, shape_pt_lon }) => [shape_pt_lat as unknown as Latitude, shape_pt_lon as unknown as Longitude]);
            polylines.push({
                locations: coordPairs,
                options: {
                    strokeColor: 'blue',
                    strokeThickness: 4,
                },
            });
        }
        setPolylinesState(polylines);
        setPushpinsState([{
            location: [rowData.stop_lat as unknown as Latitude, rowData.stop_lon as unknown as Longitude],
            options: {
                icon: busStopIcon,
                catId: 'bus-stop',
            },
        }]);
        setIsVisibleMapState(true);
        setCenter([rowData.stop_lat as unknown as Latitude, rowData.stop_lon as unknown as Longitude]);

        const tripsForFilters = tripIds
            .filter((x): x is string => x !== undefined)
            .filter((value, index, self) => self.indexOf(value) === index);
        filterTrips(tripsForFilters);

        const routesForFilters = trips.map(x => x.route_id)
                .filter((x): x is string => x !== undefined)
                .filter((value, index, self) => self.indexOf(value) === index)
            ?? [];
        filterRoutes(routesForFilters);
    };

    const tripProcessed = (rowData: RowData, value: string) => {
        const shapesFileData = filesState.find(s => s.fileName === 'shapes.txt')?.data;
        const shape_id = rowData['shape_id'];
        if (!shape_id) {
            return;
        }

        const shapes = shapesFileData?.filter(s => s.shape_id === shape_id) ?? [];
        const coordPairs: CoordinatePair[] = shapes.map(({ shape_pt_lat, shape_pt_lon }) => [shape_pt_lat as unknown as Latitude, shape_pt_lon as unknown as Longitude]);
        setPolylinesState([{
            locations: coordPairs,
            options: {
                strokeColor: 'red',
                strokeThickness: 4,
            },
        }]);
        const stopTimesFileData = filesState.find(s => s.fileName === 'stop_times.txt')?.data;
        const stopTimes = stopTimesFileData?.filter(s => s.trip_id === value) ?? [];
        const stopIds = stopTimes.map(s => s.stop_id);
        const stopsFileData = filesState.find(s => s.fileName === 'stops.txt')?.data;
        const stops = stopsFileData?.filter(s => stopIds.includes(s.stop_id)) ?? [];
        setPushpinsState(
            stops.map(({ stop_lat, stop_lon }) => ({
                location: [stop_lat as unknown as Latitude, stop_lon as unknown as Longitude],
                options: {
                    icon: busStopIcon,
                    catId: 'bus-stop',
                },
            })),
        );
        const centerShape = shapes[Number((shapes.length / 2).toFixed())];
        setIsVisibleMapState(true);
        setCenter([centerShape.shape_pt_lat as unknown as Latitude, centerShape.shape_pt_lon as unknown as Longitude]);
    };

    const mapProcessed = (rowData: RowData, fieldName: string, value: string) => {
        switch (fieldName) {
            case 'stop_id':
                stopProcessed(rowData, value);
                break;
            case 'trip_id':
                tripProcessed(rowData, value);
                break;
        }
    };

    const onClickClear = () => {
        tableRefs.map(table => table.current?.clearHeaderFilter());
        setPolylinesState([]);
        setPushpinsState([]);
        tripIdRef.current = undefined;
    };

    const onCellClick = (rowData: RowData, fieldName: string, value: string) => {
        tableRefs.map(table => table.current?.setHeaderFilterValue(fieldName, value));
        mapProcessed(rowData, fieldName, value);
        if (fieldName === 'trip_id') {
            tripIdRef.current = value;
            filterStop('stop_id');
        }
        if(fieldName === 'stop_bays') {
            const tripIds: string[] = getTripIdsByStopBay(rowData.stop_id, rowData.stop_bays);
            filterTrips(tripIds);
        }
    };

    function getTripIdsByStopBay(stopId: string, stopBay: string) : string[] {
        if (!filesState)
            return [];
        const tripsBaysFileData = filesState.find(s => s.fileName === 'trips_bays.txt')?.data;
        const tripsBays = tripsBaysFileData?.filter(s => s.STOP_BAY === stopBay && s.STOP_ID === stopId) ?? [];
        return tripsBays.map(s => s.TRIP_ID ?? '');
    }

    const filterStop = (fieldName: string) => {
        if (!filesState)
            return;
        const tripId = tripIdRef.current;
        const stopTimesFileData = filesState.find(s => s.fileName === 'stop_times.txt')?.data;
        const stopTimes = stopTimesFileData?.filter(s => s.trip_id === tripId) ?? [];
        const stopIds = stopTimes.filter(s => s.stop_id).map(s => s.stop_id ?? '');
        const targetTable = tableRefs.find(s => s.current?.fileName === 'stops.txt')?.current;
        targetTable?.setMultiplyValueFilter(fieldName, stopIds);
    };

    const filterTrips = (tripIds: string[]) => {
        const targetTable = tableRefs.find(s => s.current?.fileName === 'trips.txt')?.current;
        targetTable?.setMultiplyValueFilter('trip_id', tripIds);
    };

    const filterRoutes = (routeIds: string[]) => {
        const targetTable = tableRefs.find(s => s.current?.fileName === 'routes.txt')?.current;
        targetTable?.setMultiplyValueFilter('route_id', routeIds);
    };

    return (
        <Fragment>
            <Header as="h1" className="reportHeader">
                GTFS Viewer.
            </Header>
            {fileNameSource && <h4>File name: {fileNameSource}</h4>}
            <BlockUi tag="div" blocking={formBlockingState}>
                <Form className="gtfsEditor">
                    <Form.Group widths="equal" inline className="inputGroup">
                        <Form.Field>
                            <ZipLoader onFilesChange={handleFilesChange}
                                       displayLoader={setFormBlockingState}
                                       fileNameSource={fileNameSource}/>
                        </Form.Field>
                    </Form.Group>
                    {isVisibleMapState && <Form.Group>
                        <Form.Field style={{ width: '100%' }}>
                            <BingMap
                                div={{ style: styles.map }}
                                map={{
                                    center,
                                }}
                                polylines={polylinesState}
                                pushpins={pushpinsState}
                            />
                        </Form.Field>
                    </Form.Group>}
                    {filesState.length > 0 && <Form.Group className="bottomGroup">
                        <Form.Field style={{ width: '100%' }}>
                            <label className="categoryHeader">
                                <div>Tables</div>
                            </label>
                            <Button
                                content="Clear filters"
                                onClick={onClickClear}
                            />
                            <div>
                                {filesState.filter(f => f.visible > 0)
                                    .map((file, index) => {
                                            const tableRef = React.createRef<GtfsTableHandle>();
                                            tableRefs[index] = tableRef;
                                            return <GtfsTable key={index}
                                                              {...file}
                                                              ref={tableRef}
                                                              onCellClick={onCellClick}
                                            />;
                                        },
                                    )}
                            </div>
                        </Form.Field>
                    </Form.Group>}
                </Form>
            </BlockUi>
        </Fragment>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(GtfsEditorForm);