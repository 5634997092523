import 'chartjs-adapter-moment';
import { TooltipItem } from 'chart.js';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { TripDelayHistory } from '../../../types/otpTypes';
import MapUtils from '../../../utilities/map-utils';
import Utils from '../../../utilities/utils';

const styles = {
    chartContainer: {
        width: '630px',
    } as React.CSSProperties,
};

interface Data {
    x: string;
    y: number;
}

interface ChartData {
    labels: string[];
    datasets: {
        label: string;
        data: Data[];
        backgroundColor: string;
        borderColor: string;
    }[];
}

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Delay (minutes)',
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(66, 220, 198, 0.1)',
            pointBackgroundColor: '#42DCC6',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(66, 220, 198, 0.1)',
            pointHoverBorderColor: 'rgba(66, 220, 198, 0.1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as Data[],
        },
    ],
};

const options = {
    responsive: true,
    plugins: {
        title: { display: false },
        legend: { display: false },
        crosshair: {
            line: {
                color: '#F66',  // crosshair line color
                width: 1,       // crosshair line width
            },
            sync: {
                enabled: false,
            },
            zoom: {
                enabled: false,                                      // enable zooming
            },
            snap: {
                enabled: true,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                title: (tooltipItems: TooltipItem<'line'>[]) => {
                    const tooltipItem = tooltipItems[0];
                    return tooltipItem ? moment(tooltipItem.label as string).format('hh:mm A') : '';
                },
                label: (context: TooltipItem<'line'>) => {
                    const tooltipItem = context.parsed.y;
                    return `Delay: ${Utils.convertSecondsToMinutes(tooltipItem, 'm', 's')}`;
                },
            },
        },
    },
    scales: {
        x: {
            type: 'time' as const,
            adapters: {
                date: {
                    unit: 'minute',
                    tooltipFormat: 'h:mm a', // format for the tooltip values
                    displayFormats: {
                        minute: 'h:mm a',
                    },
                    parser: (value: unknown): number => {
                        if (typeof value !== 'string') {
                            throw new Error('Invalid date string');
                        }
                        return moment(value, 'HH:mm').valueOf();
                    },
                },
            },
            ticks: {
                stepSize: 10,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            beginAtZero: false,
            ticks: {
                callback: function (tickValue: number | string) {
                    return Utils.roundNumber(tickValue as unknown as number / 60, 0);
                },
            },
            grid: {
                drawOnChartArea: false,
            },
        },
    },
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
};

interface Props {
    chartData: TripDelayHistory[],
    sliderValue?: number
}

const TripDelaysLineChart: React.FC<Props> = ({
      chartData,
      sliderValue,
    }) => {

    const ref = useRef(null);
    const [dataState, setDataState] = useState<ChartData>(chartSettings);

    useEffect(() => {
        if (sliderValue != null) {
            MapUtils.updateCrossHairLineChart(ref.current, sliderValue);
        }
    }, [sliderValue]);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: chartData.map(d => {
                    const localTime = moment.parseZone(d.localTime);
                    const text = localTime.format('YYYY-MM-DD hh:mm A');
                    return {
                        x: text,
                        y: d.delaySeconds,
                    };
                }),
            }],
        }));
    }, [chartData]);

    return (
        <div style={styles.chartContainer}>
            <Line
                data={dataState}
                options={options}
                height={245}
                plugins={[CrosshairPlugin]}
                ref={ref}
            />
        </div>
    );
};

export default TripDelaysLineChart;
