import ReactDOMServer from 'react-dom/server';
import { TransportTypeEnum } from '../../types/tripPlannelTypes';
import { MapPointKind } from './MapPointKind';

export const colorInput = '#173fb5'; // #38a8ff
export const colorOrigin = '#16ab39'; // "#0f7134"; // #149d49
export const colorTarget = '#b21e1e'; //"#bc1910"; // #ee4036


export function toCoords(location: Microsoft.Maps.Location): CoordinatePair {
    return [location.latitude as Latitude, location.longitude as Longitude];
}

export function renderInfoboxContent(cid: number, pid: string, kind: MapPointKind, address: string | null) {
    const skind = kind === MapPointKind.from ? 'origin' : kind === MapPointKind.to ? 'target' : 'unknown';
    return ReactDOMServer
        .renderToStaticMarkup(<InfoboxContent cid={cid} pid={pid} kind={skind} address={address} />)
        .replace(/tiq-/g, '');
}

export function renderInfoboxContent2(
    cid: number,
    pid: string,
    type: TransportTypeEnum,
    color: string,
    name: string | null,
    text: string[] | undefined,
    frequency: string | null,
    address: string | null,
) {
    return ReactDOMServer
        .renderToStaticMarkup(<InfoboxContent2
            cid={cid}
            pid={pid}
            type={type}
            color={color}
            name={name}
            text={text}
            frequency={frequency}
            address={address}
        />)
        .replace(/tiq-/g, '');
}

export function InfoboxContent2(props: {
    cid: number,
    pid: string,
    type: TransportTypeEnum,
    color: string,
    name: string | null,
    text?: string[],
    frequency: string | null,
    address: string | null,
},
) {
    return (
        <div className={`planner-pushpin leg ${props.type}`}
            id={props.pid}
            tiq-onclick="$tiq_TripPlannerHelper.show(this, event)"
            tiq-style={`border-color: ${props.color}`}>
            <div className="planner-pushpin-positioner">
                <div className="planner-pushpin-textbox">
                    <div className="planner-pushpin-textbox-close"
                        tiq-onclick="$tiq_TripPlannerHelper.hide(this, event)">
                        <i className="icon close"></i>
                    </div>
                    <div className="planner-pushpin-address">{props.name || 'Walk'}</div>
                    <div className="planner-pushpin-details">
                        {!props.text ? null : props.text.join(' / ')}
                        {!props.frequency
                            ? null :
                            <div className="planner-pushpin-frequency">{props.frequency}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export function InfoboxContent(props: { cid: number, pid: string, kind: 'unknown' | 'origin' | 'target', address: string | null }) {
    return (
        <div className={`planner-pushpin endpoint ${props.kind}`} id={props.pid}
            tiq-onclick="$tiq_TripPlannerHelper.show(this, event)">
            <div className="planner-pushpin-positioner ">
                <div className="planner-pushpin-textbox">
                    <div className="planner-pushpin-textbox-close"
                        tiq-onclick="$tiq_TripPlannerHelper.hide(this, event)">
                        <i className="icon close"></i>
                    </div>
                    <div className="planner-pushpin-address">{props.address}</div>
                    {props.kind !== 'unknown'
                        ? null
                        : (<>
                            <div className="planner-pushpin-choose-direction from"
                                tiq-onclick={`$tiq_TripPlannerHelper.click(event, ${props.cid}, '${props.pid}', 'origin')`}>from</div>
                            <div className="planner-pushpin-choose-direction to"
                                tiq-onclick={`$tiq_TripPlannerHelper.click(event, ${props.cid}, '${props.pid}', 'target')`}>to</div>
                        </>)}
                </div>
            </div>
        </div>
    );
}