import { ChartOptions, TooltipItem } from 'chart.js';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { crosshairOption } from '../../../types/chartjs-plugin-crosshair/CrosshairOption';
import { LineDatePoint } from '../../../types/chartTypes';
import { HourlyReportData, ModelType, modelTypeColors } from './_data';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Adaptive',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.HISTORY].fill,
            borderColor: modelTypeColors[ModelType.HISTORY].base,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: modelTypeColors[ModelType.HISTORY].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.HISTORY].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.HISTORY].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
        {
            label: 'Linear',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.LINEAR].fill,
            borderColor: modelTypeColors[ModelType.LINEAR].base,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: modelTypeColors[ModelType.LINEAR].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.LINEAR].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.LINEAR].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
        {
            label: 'Legacy',
            fill: false,
            lineTension: 0.1,
            backgroundColor: modelTypeColors[ModelType.SIRI].fill,
            borderColor: modelTypeColors[ModelType.SIRI].base,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: modelTypeColors[ModelType.SIRI].base,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: modelTypeColors[ModelType.SIRI].dark,
            pointHoverBorderColor: modelTypeColors[ModelType.SIRI].base,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'minute',
                displayFormats: {
                    minute: 'h:mm a',
                },
            },
            title: {
                display: true,
                text: 'Time',
            },
            ticks: {
                stepSize: 10,
            },
        },
        y: {
            type: 'linear' as const,
            title: {
                display: true,
                text: 'Accuracy, %',
            },
            beginAtZero: true,
        },
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    plugins: {
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            callbacks: {
                title: function () {
                    return '';
                },
                labelColor: function (tooltipItem: TooltipItem<'line'>) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            borderColor: 'rgba(65,105,225,1)',
                            backgroundColor: 'rgba(65,105,225,1)',
                        };
                    } else if (tooltipItem.datasetIndex === 1) {
                        return {
                            borderColor: 'rgb(255, 165, 0)',
                            backgroundColor: 'rgb(255, 165, 0)',
                        };
                    } else {
                        return {
                            borderColor: 'rgb(60, 179, 113)',
                            backgroundColor: 'rgb(60, 179, 113)',
                        };
                    }
                },
            },
        },
    },
};

type DataPoint = { dateTime: Moment } & { [key in ModelType]?: number | undefined };

type AccuracyByTimeProps = {
    data: HourlyReportData
};

function processModelData(
    dataPoints: DataPoint[],
    labelMap: { [hour: string]: DataPoint },
    reportData: HourlyReportData,
    model: ModelType,
): void {
    const data = reportData[model];
    if (!data) return;

    for (const n in data) {
        const value = data[n];
        let dataPoint: DataPoint = labelMap[n];
        if (!dataPoint) {
            const hours = parseInt(n);
            dataPoint = { dateTime: moment().add(hours, 'h') };
            labelMap[n] = dataPoint;
            dataPoints.push(dataPoint);
        }
        dataPoint[model] = value || undefined;
    }
}

function mapDataPoints(dataPoints: DataPoint[], model: ModelType): LineDatePoint[] {
    return dataPoints.map(p => ({
        x: p.dateTime,
        y: p[model] || null,
    }));
}

const AccuracyByTime: React.FC<AccuracyByTimeProps> = (props) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        const labelMap: { [hour: string]: DataPoint } = {};
        const dataPoints: DataPoint[] = [];

        processModelData(dataPoints, labelMap, props.data, ModelType.HISTORY);
        processModelData(dataPoints, labelMap, props.data, ModelType.LINEAR);
        processModelData(dataPoints, labelMap, props.data, ModelType.SIRI);

        dataPoints.sort((a, b) => a.dateTime.valueOf() - b.dateTime.valueOf());
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: mapDataPoints(dataPoints, ModelType.HISTORY),
            }, {
                ...prevState.datasets[1],
                data: mapDataPoints(dataPoints, ModelType.LINEAR),
            }, {
                ...prevState.datasets[2],
                data: mapDataPoints(dataPoints, ModelType.SIRI),
            }],
        }));
    }, [props]);

    return (
        <div className="chart-wrapper">
            <Line
                data={dataState}
                options={options}
                height={70}
                plugins={[ { id: 'crosshair', ...crosshairOption } ]}
            />
        </div>
    );
};

export default AccuracyByTime;
