import './PowerBIStyles.css';
import BlockUi from '@availity/block-ui';
import { Embed, models, Report, service } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Message, Segment, Tab } from 'semantic-ui-react';
import * as actions from '../../actions/actions';
import { getListOfReports } from '../../actions/actions';
import { AppState } from '../../reducers';
import { getSelectedOrDefaultAgency } from '../../selectors';
import { PBIReport } from '../../types/pbiTypes';
import { AgencyModel } from './_models';

interface PropsR {
    reportList: PBIReport[] | undefined;
}

interface Props {
    app: AppState;
    agency: AgencyModel;
}

interface State {
    preparing: boolean,
    error?: {
        header?: string,
        message?: string,
    }
}

function PowerBIForm(props: Props) {
    //: React.FC = () => 
    const agencyReady = !!props.agency;
    const agencyId= props.agency;
    const [report, setReport] = useState<Report>();
    const [reportList, setReportList] = useState<PBIReport[]>();
    const [reportName, setReportName] = useState<String>();
    const [state, setState] = useState<State>({ preparing: false });
    const [show, toggleShow] = useState(false);
    const [reportListVisibility, toggleReportListVisibility] = useState(true);
    const [sampleReportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: undefined,
    });

    const changeMode = (mode: string | models.ViewMode) => report?.switchMode(mode);
    const setFullscreen = () => report?.fullscreen();
    const loadList = () => ((loadListOfReports()).then(() => console.log('List of Reports was loaded')));
    const checkCapacity = async () => {
        const newState: State = { preparing: false };
        const c = await IsCapacityPaused();
        newState.preparing = c;
        setState(newState);
    };
   
    const tabPanes = [
        {
           /* menuItem: () => reportName,*/
            render: () =>
                <div>
                    <PowerBIEmbed
                        embedConfig={sampleReportConfig}
                        eventHandlers={eventHandlersMap}
                        cssClassName={'report-style-class'}
                        getEmbeddedComponent={(embedObject: Embed) => {
                            setReport(embedObject as Report);
                            console.log('The current report is ' + reportName);
                        }}
                    />
                </div>,            
        },
    ];
    const tabReport = () => {
        return <Tab panes={tabPanes} />;
    };

    const IsCapacityPaused = async () => {
        const statusCapasity = await actions.powerBIStatusCapacity();
        const statusResponce = JSON.parse(statusCapasity);
        console.log(statusResponce);
        return statusResponce == 'Paused' || statusResponce == 'Undefined';
    };

    const loadReport = async (reportId: string | undefined, mode: string | undefined) => {
        let md = models.ViewMode.View;
        if (mode != undefined && mode == 'edit') {
            md = models.ViewMode.Edit;
        }

        let repId = '';
        if (reportId != undefined) {
            repId = reportId;
        }
        let agencyId = '';
        if (props.agency != undefined) {
            agencyId = props.agency.id;
        }
        const statusCapasity = await actions.powerBIStatusCapacity();
        const statusResponce = JSON.parse(statusCapasity);
        if (statusResponce == 'Paused') {
            const startCapasity = await actions.powerBIStartCapacity();
            const responce = JSON.parse(startCapasity);
            console.log(responce);
        }
        const reportConfigResponse = await actions.powerBIToken(repId, agencyId);
        const rep = JSON.parse(reportConfigResponse);
        setReportName(rep.ReportName);
        setReportConfig({
            ...sampleReportConfig,
            embedUrl: rep.EmbedUrl,
            accessToken: rep.EmbedToken.token,
            permissions: models.Permissions.All,
            viewMode: md,
        });
        reportList?.sort();

        const lastTimeLoadRequest = report?.lastLoadRequest;
        if (report && lastTimeLoadRequest) {          
            report?.configChanged(true);
            await report?.setAccessToken(rep.EmbedToken.token);
            await report?.refresh();    
        }       
    };

    useEffect(() => {
        (async () => {
            if (!agencyReady) return;
            const newState: State = { preparing: false };
            if (!state.preparing) {
                await loadReport(undefined, undefined);
                const c = await IsCapacityPaused();
                newState.preparing = c;
            }
            setState(newState);
        })();
        const timer = setTimeout(() => {
            checkCapacity();
        }, 50000);
        return () => clearTimeout(timer);
    }, [agencyReady, agencyId],
    );

    const eventHandlersMap = new Map([
        ['loaded', function () {
            console.log('Report has loaded');
        }],
        ['rendered', function () {
            console.log('Report has rendered');
        }],
        ['error', function (event?: service.ICustomEvent<unknown>) {
            if (event) {
                console.error(event.detail);
            }
        }],
    ]);

    const refreshReport = async () => {
        if (!report) {
            console.log('Report is not available');
            return;
        }
        report.refresh();
    };

    const reportSelected = (event: React.MouseEvent<HTMLUListElement>) => {
        const tr = event.target as HTMLLIElement;
        console.log(tr.dataset.id);
        loadReport(tr.dataset.id, 'edit');
        event.preventDefault();
    };

    function RList(props: PropsR) {
        const reportList = props.reportList;
        const items = reportList?.map((word, idx) => {
            return <li style={{ listStyleType: 'circle' }} data-id={word.id} key={idx}>{word.name}</li>;
        });
        return (
            <ul onClick={reportSelected}> {items}</ul>);
    }

    async function loadListOfReports(): Promise<void> {
        const listreports = await getListOfReports();
        setReportList(listreports);
    }

    const editReport = () => {
        if (show) {
            changeMode(models.ViewMode.View);
        }
        else {
            changeMode(models.ViewMode.Edit);
            loadList();
        }
        toggleShow(!show);
        toggleReportListVisibility(!reportListVisibility);
    };

    const controlButtons =
        <Form.Field>
            <Button onClick={editReport}>
                {show ? 'View Report' : 'Edit Report'}
            </Button>
            <Button onClick={refreshReport}>
                Refresh Report</Button>
            <Button onClick={setFullscreen}>
                Full Screen</Button>
        </Form.Field>;
    const reportsForm = tabReport();
    if (state.error)
        return (
            <Message warning style={{ textAlign: 'center' }}>
                <Message.Header>{state.error.header}</Message.Header>
                <Message.Content><p>{state.error.message}</p>
                    <p>Please try refresh this page later.</p>
                </Message.Content>
            </Message>
        );

        return (
            <>
                {state.preparing
                    ? <BlockUi blocking={true} message="preparing..." style={{ height: '100%' }} />
                    : <div className="main">
                        {controlButtons}
                        <Segment.Group className="pbi">
                            <Segment>
                                <Form>
                                    <Form.Group>
                                        {/* Will be uncomment after demo*/}
                                        {RList} {/* Will be removed after demo*/}
                                        {/*<Form.Field width={4} hidden={reportListVisibility}>*/}
                                        {/*    <label className="categorySubtitle">*/}
                                        {/*        <div> List of Reports: </div>*/}
                                        {/*    </label>*/}

                                        {/*    <RList reportList={reportList} />*/}
                                        {/*</Form.Field>*/}
                                        

                                        <Form.Field width={16}>
                                            {reportsForm}
                                        </Form.Field>
                                    </Form.Group>
                                </Form>

                            </Segment>
                        </Segment.Group>
                    </div>
                }
            </>
        );
 
}

function mapStateToProps(state: AppState): Props {
    const agency = getSelectedOrDefaultAgency(state);
    return {
        app: state,
        agency: agency,
    };
}

export default connect(
    mapStateToProps,
) (PowerBIForm);