import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../../actions/actionTypes';
import { getDelayStatistics } from '../../../../actions/otpActions';
import { AppState } from '../../../../reducers/index';
import { getSelectedOrDefaultAgency } from '../../../../selectors/index';
import useTableSorting from '../../../hooks/useTableSorting';
import StartAndEndDatesField from '../../../shared/StartAndEndDatesField';
import DelaysByTimeChart from './DelaysByTimeChart';
import DelaysDistributionChart from './DelaysDistributionChart';
import delayStatisticsReducer, { delayStatisticsInitialState } from './delayStatisticsReducer';
import DeviationByDayPartChart from './DeviationByDayPartChart';
import DeviationByWeekDayChart from './DeviationByWeekDayChart';
import DeviationHistoryChart from './DeviationHistoryChart';
import RoutesStatisticsTable from './RoutesStatisticsTable';
import tableSortReducer from './tableSortReducer';

interface Props {
    agency: AgencyStateType | undefined;
}

const styles = {
    bottomContainer: {
        paddingBottom: '14px',
    } as React.CSSProperties,
};

const DelayStatisticsForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [fromDateState, setFromDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-8, 'days').format('YYYY-MM-DD'));
    const [toDateState, setToDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [delayStatistics, delayStatisticsDispatch] = useReducer(delayStatisticsReducer, delayStatisticsInitialState);
    const { tableDataState, handleTableColumnSort } = useTableSorting(tableSortReducer, delayStatistics.routesDelaysStatisticsForTable);

    useEffect(() => {
        (async () => await retrieveDelayStatistics())();
    }, [agency?.id]);

    const retrieveDelayStatistics = async () => {
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const delayStatisticsData = await getDelayStatistics(agency.id, fromDateState, toDateState);
                delayStatisticsDispatch({ type: 'SET_OVERALL_STATISTICS', overallStatistics: delayStatisticsData });
            } catch {
                delayStatisticsDispatch({ type: 'SET_OVERALL_STATISTICS', overallStatistics: undefined });
            } finally {
                setFormBlockingState(false);
            }
        } else {
            delayStatisticsDispatch({ type: 'SET_OVERALL_STATISTICS', overallStatistics: undefined });
        }
    };
    const handleApplyButtonClick = async () => {
        await retrieveDelayStatistics();
    };

    const handleRouteClick = async (routeName: string) => {
        delayStatisticsDispatch({ type: 'SET_SELECTED_ROUTE', selectedRouteName: routeName });
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const delayStatistics = await getDelayStatistics(agency.id, fromDateState, toDateState, routeName);
                delayStatisticsDispatch({ type: 'SET_ROUTE_STATISTICS', routeStatistics: delayStatistics });
            } catch {
                delayStatisticsDispatch({ type: 'SET_ROUTE_STATISTICS', routeStatistics: undefined });
            } finally {
                setFormBlockingState(false);
            }
        } else {
            delayStatisticsDispatch({ type: 'SET_ROUTE_STATISTICS', routeStatistics: undefined });
        }
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Schedule Delay Statistics
                </Header>
                <div className="reportDescription">
                    Delays Classification: Early (&#60; -1 min); On Time (-1 - 4 min); Late (4 - 10 min); Very Late (&#62; 10 min)
                </div>
                <Form.Group inline className="inputGroup">
                    <StartAndEndDatesField {...{
                        startDate: fromDateState,
                        setStartDate: setFromDateState,
                        endDate: toDateState,
                        setEndDate: setToDateState,
                    }} />
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={handleApplyButtonClick}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group className="rowGroup">
                    <Form.Field width={6}>
                        <label className="categoryHeader">Deviation History</label>
                        <DeviationHistoryChart chartData={delayStatistics.delaysByDays} />
                    </Form.Field>
                    <Form.Field width={6}>
                        <label className="categoryHeader">Deviation by Time of the Day</label>
                        <DeviationByDayPartChart chartData={delayStatistics.delaysByDayPart} />
                    </Form.Field>
                    <Form.Field width={6}>
                        <label className="categoryHeader">Deviation by Day of the Week</label>
                        <DeviationByWeekDayChart chartData={delayStatistics.delaysByWeekDay} />
                    </Form.Field>
                </Form.Group>
                {tableDataState.data.length > 0 && <Form.Group className="rowGroup" style={styles.bottomContainer}>
                    <Form.Field width={16}>
                        <label className="categoryHeader">
                            <div>Delays by Route</div>
                            <div className="categorySubtitle">
                                List of routes showing statistics on Early (more than 1 min earlier than scheduled), On time (no more that 5 min late), Late (from 5 to 10 min late), and Very Late (more than 10 minutes)  arrivals for the selected period of time
                            </div>
                            <div className="categorySubtitle">Click on a row to display details</div>
                        </label>
                        <RoutesStatisticsTable tableDataState={tableDataState} selectedRouteName={delayStatistics.selectedRouteName} selectedRouteHandler={handleRouteClick} columnSortHandler={handleTableColumnSort} />
                    </Form.Field>
                </Form.Group>}
                {delayStatistics.selectedRouteName && <Form.Group className="rowGroup" style={styles.bottomContainer}>
                    {delayStatistics.delaysDistributionForRoute && <Form.Field width={6}>
                        <label className="categoryHeader">Delays Distribution for route {delayStatistics.selectedRouteName}</label>
                        <DelaysDistributionChart chartData={delayStatistics.delaysDistributionForRoute} />
                    </Form.Field>}
                    {delayStatistics.delaysByTimeForRoute.length > 0 && <Form.Field width={6}>
                        <label className="categoryHeader">Delays by Time for route {delayStatistics.selectedRouteName}</label>
                        <DelaysByTimeChart chartData={delayStatistics.delaysByTimeForRoute} />
                    </Form.Field>}
                    {delayStatistics.delaysByWeekDayForRoute.length > 0 && <Form.Field width={6}>
                        <label className="categoryHeader">Delays by Day of Week for route {delayStatistics.selectedRouteName}</label>
                        <DeviationByWeekDayChart chartData={delayStatistics.delaysByWeekDayForRoute} />
                    </Form.Field>}
                </Form.Group>}
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(DelayStatisticsForm);