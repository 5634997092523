import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Divider, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getDashboardRealTimeData, getTripDelayHistory } from '../../../actions/otpActions';
import Api from '../../../api/api';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { PolylineProps } from '../../../types/BingMapProps';
import { CurrentTrip, TripDelayHistory, TripDelaysDistribution } from '../../../types/otpTypes';
import BingMapQuerier from '../../../utilities/BingMapQuerier';
import Utils from '../../../utilities/utils';
import { getVehicleInfoboxHtml } from '../../bushistory/VehicleInfobox';
import useTableSorting from '../../hooks/useTableSorting';
import BingMap from '../../shared/BingMap';
import PrettoSliderControl from '../../shared/PrettoSliderControl';
import DelayedTripsTable from './DelayedTripsTable';
import DelaysStatusChart from './DelaysStatusChart';
import tableSortReducer from './tableSortReducer';
import TripDelaysLineChart from './TripDelaysLineChart';

interface Props {
    agency: AgencyStateType | undefined;
}

const styles = {
    delaysStatusChartLabel: {
        marginLeft: '53px',
    } as React.CSSProperties,
    tableName: {
        fontSize: '18px',
        fontWeight: 600,
    } as React.CSSProperties,
    tableDescription: {
        fontWeight: 600,
    } as React.CSSProperties,
    tableContainer: {
        position: 'absolute',
    } as React.CSSProperties,
    delaysStatusChartDescription: {
        fontSize: '12px',
        textAlign: 'left',
        marginLeft: '51px',
        marginTop: '22px',
        color: '#6D6D6D',
    } as React.CSSProperties,
    dashboardForm: {
        overflow: 'hidden',
    } as React.CSSProperties,
    map: {
        width: '100%',
        height: '522px',
    } as React.CSSProperties,
    sliderContainer: {
        marginTop: '40px',
        marginLeft: '20px',
        marginRight: '20px',
    } as React.CSSProperties,
    tripDelaysLineChartLabel: {
        marginTop: 0,
        lineHeight: '10px',
    } as React.CSSProperties,
    vehicleAnalysis: {
        display: 'flex',
        flexDirection: 'row',
    } as React.CSSProperties,
    vehicleAnalysisChart: {
        flexShrink: 0,
        flexBasis: 'fit-content',
    } as React.CSSProperties,
    vehicleAnalysisMap: {
        width: '100%',
    } as React.CSSProperties,
};

const DashboardForm: React.FC<Props> = ({ agency }) => {
    const [tripDelaysState, setTripDelaysState] = useState<TripDelaysDistribution[]>([]);
    const [tripDelayHistoryState, setTripDelayHistoryState] = useState<TripDelayHistory[]>([]);
    const [tripsState, setTripsState] = useState<CurrentTrip[]>([]);
    const [selectedTripState, setSelectedTripState] = useState<CurrentTrip | undefined>(undefined);
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [tripDelaysLoadingState, setTripDelaysLoadingState] = useState(false);
    const [center, setCenter] = useState<CoordinatePair>();
    const [mapBoundsState, setMapBoundsState] = useState<Microsoft.Maps.LocationRect | undefined>();
    const [polylineState, setPolylineState] = useState<PolylineProps>();
    const [sliderValueState, setSliderValueState] = useState<number>(0);
    const { tableDataState, handleTableColumnSort } = useTableSorting(tableSortReducer, tripsState, {
        column: 'delaySeconds',
        data: tripsState,
        direction: 'descending',
    });
    const mapRef = createRef<BingMapQuerier>();

    useEffect(() => {
        if (agency === undefined) return;
        updateReports();
        (async (agencyId: string) => {
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
        })(agency.id);
    }, [agency?.id]);

    useEffect(() => {
        const infobox = updateBusPosition(sliderValueState, tripDelayHistoryState);
        return () => {
            if (infobox) {
                infobox.setMap(null as unknown as Microsoft.Maps.Map);
            }
        };
    }, [sliderValueState]);

    const handleSliderChange = (_: Event, value: number | number[], __: number) => {
        setSliderValueState(value as number);
    };
    const handleValueLabelFormat = (value: number): React.ReactNode => {
        const position = tripDelayHistoryState[value];
        const formattedDate = position ?
            moment.parseZone(position.localTime).format('h:mm a') :
            '';
        return <div style={{ textAlign: 'center', fontSize: '9px' }}>{formattedDate}</div>;
    };
    const delayedTripRowHandler = async (selectedTrip: CurrentTrip) => {
        setSelectedTripState(selectedTrip);
        setSliderValueState(-1);
        if (agency) {
            setTripDelaysLoadingState(true);
            try {
                const tripDelayHistory = await getTripDelayHistory(agency.id, selectedTrip.tripInternalId, selectedTrip.vehicleId);
                setTripDelayHistoryState(tripDelayHistory);
                setPolylineState({
                    locations: tripDelayHistory.map(({ latitude, longitude }) => [latitude, longitude]),
                    options: {
                        strokeColor: 'orangered',
                        strokeThickness: 3,
                    },
                });
                const locations = tripDelayHistory.map(p => new Microsoft.Maps.Location(p.latitude, p.longitude));
                const boundingBox = Microsoft.Maps.LocationRect.fromLocations(locations);
                setMapBoundsState(boundingBox);
                setSliderValueState(0);
            } catch {
                setSliderValueState(-1);
                setTripDelayHistoryState([]);
                setPolylineState(undefined);
            } finally {
                setTripDelaysLoadingState(false);
            }
        } else {
            setSliderValueState(-1);
            setTripDelayHistoryState([]);
            setPolylineState(undefined);
        }
    };
    const updateReports = async () => {
        if (agency) {
            setFormBlockingState(true);
            try {
                const { tripDelaysDistribution, currentTrips } = await getDashboardRealTimeData(agency.id);
                setTripDelaysState(tripDelaysDistribution);
                const currentTripsNullDelay = currentTrips.filter(a => a.delaySeconds === null);
                const currentTripsNullDelayOrdered = currentTripsNullDelay.sort((a, b) => a.tripStartTime <= b.tripStartTime ? 1 : -1);
                const currentTripsDelay = currentTrips.filter(a => a.delaySeconds !== null);
                const currentTripsDelayOrdered = currentTripsDelay.sort((a, b) => a.delaySeconds !== null && b.delaySeconds !== null ? (a.delaySeconds <= b.delaySeconds ? 1 : -1) : -1);
                const currentTripsOrdered = currentTripsDelayOrdered.concat(currentTripsNullDelayOrdered);

                setTripsState(currentTripsOrdered);
                if (currentTripsOrdered.length > 0) {
                    const firstCurrentTrip = currentTripsOrdered[0];
                    await delayedTripRowHandler(firstCurrentTrip);
                }
            } catch {
                setTripDelaysState([]);
                setTripsState([]);
                setSliderValueState(-1);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setTripDelaysState([]);
            setTripsState([]);
        }
    };
    const updateBusPosition = (sliderValue: number, vehiclePositions: TripDelayHistory[]): Microsoft.Maps.Infobox | null => {
        const position = vehiclePositions[sliderValue];
        if (!position || !mapRef || !mapRef.current || !mapRef.current.map)
            return null;
        const description = `at ${moment.parseZone(position.localTime).format('h:mm:ss A')}`;
        const vehicleId = selectedTripState ? `${selectedTripState.vehicleId} (${Utils.convertSecondsToMinutes(position.delaySeconds, 'm', 's')})` : '';
        const infobox = new Microsoft.Maps.Infobox(
            new Microsoft.Maps.Location(position.latitude, position.longitude),
            {
                htmlContent: getVehicleInfoboxHtml(vehicleId, description),
            },
        );
        infobox.setMap(mapRef.current.map);
        return infobox;
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form style={styles.dashboardForm}>
                <Header as="h1" className="reportHeader">
                    <div>Dashboard</div>
                    <div className="reportSubtitle">at this moment</div>
                </Header>
                <Form.Group className="rowGroup">
                    <Form.Field width={11}>
                        <label className="categoryHeader">
                            <div>List of Most Delayed Routes Now</div>
                            <div className="categorySubtitle">Sorted by the most recent delay</div>
                        </label>
                        <DelayedTripsTable tableDataState={tableDataState} selectedTrip={selectedTripState} delayedTripRowHandler={delayedTripRowHandler} columnSortHandler={handleTableColumnSort} />
                    </Form.Field>
                    <Form.Field width={5}>
                        <label className="categoryHeader" style={styles.delaysStatusChartLabel}>Delay Distribution, %</label>
                        <DelaysStatusChart chartData={tripDelaysState} />
                        <div style={styles.delaysStatusChartDescription}>
                            <Divider fitted />
                            Delays Classification: Early (&#60; -1 min); On Time (-1 - 4 min); Late (4 - 10 min); Very Late (&#62; 10 min)
                            <br></br>
                            * "Not Reporting" means no real-time info for vehicles planned to be on the route at this time
                        </div>
                    </Form.Field>
                </Form.Group>
                {selectedTripState &&
                    <>
                        <Form.Group>
                            <Form.Field width={13}>
                                <label className="categoryHeader">
                                    <div>Route: {selectedTripState.routeName}, Bus Id: {selectedTripState.vehicleId}, Trip: {selectedTripState.tripId} ({moment.parseZone(selectedTripState.tripStartTime).format('h:mm A')} - {moment.parseZone(selectedTripState.tripEndTime).format('h:mm A')})</div>
                                </label>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group style={styles.vehicleAnalysis}>
                            <Form.Field width={8} style={styles.vehicleAnalysisChart}>
                                <label className="categoryHeader">
                                    <div className="categorySubtitle" style={styles.tripDelaysLineChartLabel}>Trip Delay (minutes)</div>
                                </label>
                                    <TripDelaysLineChart chartData={tripDelayHistoryState} sliderValue={sliderValueState} />
                            </Form.Field>
                            <Form.Field style={styles.vehicleAnalysisMap}>
                                <BlockUi tag="div" blocking={tripDelaysLoadingState} className="inner">
                                    <BingMap
                                        ref={mapRef}
                                        div={{ style: styles.map }}
                                        map={{
                                            center,
                                            options: {
                                                bounds: mapBoundsState,
                                            },
                                        }}
                                        polylines={polylineState && [polylineState]}
                                    />
                                </BlockUi>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={16} style={styles.sliderContainer} >
                                <PrettoSliderControl
                                    valueLabelDisplay="on"
                                    name="dashboardSlider"
                                    aria-label="pretto slider"
                                    min={0}
                                    max={tripDelayHistoryState.length > 0 ? tripDelayHistoryState.length - 1 : 0}
                                    onChange={handleSliderChange}
                                    step={1}
                                    value={sliderValueState}
                                    valueLabelFormat={handleValueLabelFormat}
                                />
                            </Form.Field>
                        </Form.Group>
                    </>
                }
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(DashboardForm);