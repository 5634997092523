import { Slider, SliderProps } from '@mui/material';
import { styled } from '@mui/system';

interface PrettoSliderProps extends SliderProps {
    noBalloon?: boolean;
}

const PrettoSliderControl = styled((props: PrettoSliderProps) => <Slider {...props} />)(({ noBalloon }) => ({
        color: '#52af77',
        height: 8,
        '& .MuiSlider-track': {
            height: 8,
            borderRadius: 4,
        },
        '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&::before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            // left: 'calc(-50% + 4px)',
            background: 'unset',
            padding: 0,
            width: 34,
            height: 34,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: '#52af77',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&::before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
                transform: 'rotate(45deg)',
            },
            '& *': noBalloon
                ? {
                    background: 'transparent',
                    color: '#000',
                }
                : {},
        },
    }),
);

export default PrettoSliderControl;