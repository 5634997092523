import moment from 'moment';
import * as React from 'react';
import { PushpinProps } from '../../../../types/BingMapProps';
import { RtDelaysNow } from '../../../../types/delaysNowTypes';
import BingMap from '../../../shared/BingMap';
import PrettoSliderControl from '../../../shared/PrettoSliderControl';
import VehicleDelaysTable from './VechicleDelaysTable';

type Styles = {
    sliderContainer: React.CSSProperties,
    sliderTitle: React.CSSProperties,
    block1: React.CSSProperties,
    box1: React.CSSProperties,
    box2: React.CSSProperties,
};

const styles: Styles = {
    sliderContainer: {
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    sliderTitle: {
        marginBottom: '40px',
    },
    block1: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 14,
        marginBottom: '4rem',
    },
    box1: {
        flexGrow: 1,
        minWidth: '50%',
        height: 500,
    },
    box2: {
        minWidth: '25%',
        maxHeight: 500,
        justifyContent: 'space-around',
    },
};

interface DelaysTimePoint {
    time: string;
    data: RtDelaysNow[];
}

type SummaryPaneContentProps = {
    serviceDate: string;
    filteredDetailsState: DelaysTimePoint[];
    center: CoordinatePair | undefined;
    pushpins: PushpinProps[];
    sliderValue: number;
    onSliderChange: (value: number) => void;
    selectedVehicle: RtDelaysNow | null | undefined;
    onSelectVehicle: (value: RtDelaysNow | undefined) => void;
};

export const SummaryPaneContent: React.FC<SummaryPaneContentProps> = (props) => {
    const handleSliderChange = (_: Event, value: number | number[], __: number) => {
        const sliderIndex = value as number;
        props.onSliderChange(sliderIndex);
    };

    const handleValueLabelFormat = (value: number, _: number): React.ReactNode => {
        const t = props.filteredDetailsState[value]?.time;
        const formattedDate = t ? moment(t).format('HH:mm') : '--:--';
        return <div style={{ textAlign: 'center', fontSize: '9px' }}>{formattedDate}</div>;
    };

    return (<>
        <div style={styles.sliderContainer}>
            <div className="categorySubtitle" style={styles.sliderTitle}>
                Move slider below to pick the time
            </div>
            <PrettoSliderControl
                valueLabelDisplay="on"
                name="delaysSlider"
                aria-label="pretto slider"
                min={0}
                max={props.filteredDetailsState.length - 1}
                onChange={handleSliderChange}
                step={1}
                value={props.sliderValue}
                valueLabelFormat={handleValueLabelFormat}
            />
        </div>
        <div style={styles.block1}>
            <div style={styles.box1}>
                <BingMap
                    map={{ center: props.center }}
                    pushpins={props.pushpins}
                />
            </div>
            <div style={styles.box2}>
                <VehicleDelaysTable
                    serviceDate={props.serviceDate}
                    data={props.filteredDetailsState[props.sliderValue]?.data}
                    selectedRow={props.selectedVehicle}
                    onSelectVehicle={props.onSelectVehicle}
                />
            </div>
        </div>
    </>);
};
