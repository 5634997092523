import './BunchingAnalysisStyles.css';
import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react-17';
import { Button, Form, Header, Statistic, Tab } from 'semantic-ui-react';
import { AgencyStateType } from '../../../../actions/actionTypes';
import { getFullRouteInfoByRouteName, getShapeData } from '../../../../actions/gtfsStaticActions';
import { getBunchingReportForDate, getBusBunchingArrivalsChart, getBusBunchingPositions } from '../../../../actions/otpActions';
import Api from '../../../../api/api';
import { AppState } from '../../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../../selectors';
import { InfoboxProps, PolylineProps, PushpinProps } from '../../../../types/BingMapProps';
import { BunchingVehicleData, BusBunchingArrival, BusBunchingOccuracy } from '../../../../types/busBunchingTypes';
import { VehiclePositionDto } from '../../../../types/busHistoryTypes';
import { ChartDataElement, DatasetMeta, LineChartRefType } from '../../../../types/chartTypes';
import { FullRouteInfo } from '../../../../types/gtfsTypes';
import BingMapQuerier from '../../../../utilities/BingMapQuerier';
import Utils from '../../../../utilities/utils';
import { getVehicleInfoboxHtml } from '../../../bushistory/VehicleInfobox';
import BingMap from '../../../shared/BingMap';
import PrettoSliderControl from '../../../shared/PrettoSliderControl';
import BunchingArrivalTimes from './BunchingArrivalTimes';
import BusArrivalsTable from './BusArrivalsTable';
import BusBunchingTable from './BusBunchingTable';

interface Props {
    agency: AgencyStateType | undefined;
}

interface VehiclePosition {
    vehicleId: string;
    positions: VehiclePositionDto[],
}

const styles = {
    map: {
        width: '100%',
        height: '600px',
        marginTop: '40px',
    } as React.CSSProperties,
    sliderContainer: {
        marginTop: '40px',
    } as React.CSSProperties,
    busHistoryLinkContainer: {
        textAlign: 'right',
    } as React.CSSProperties,
    chartDescription: {
        marginBottom: '30px',
    } as React.CSSProperties,
    tabContainer: {
        paddingBottom: '10px',
        marginBottom: '10px',
    } as React.CSSProperties,
};

const BusBunchingFormNew: React.FC<Props> = ({ agency }) => {
    const [selectedDateState, setSelectedDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [bunchingOccuracyState, setBunchingOccuracyState] = useState<BusBunchingOccuracy | undefined>(undefined);
    const [vehiclesPositionsState, setVehiclesPositionsState] = useState<VehiclePosition[]>([]);
    const [bunchingArrivalsState, setBunchingArrivalsState] = useState<BusBunchingArrival[]>([]);
    const [selectedVehicleDataState, setSelectedVehicleDataState] = useState<BunchingVehicleData | undefined>(undefined);
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [center, setCenter] = useState<CoordinatePair>();
    const [mapBoundsState, setMapBoundsState] = React.useState<Microsoft.Maps.LocationRect | undefined>();
    const [polylineState, setPolylineState] = useState<PolylineProps>();
    const [pushpinsState, setPushpinsState] = useState<PushpinProps[]>([]);
    const [sliderValueState, setSliderValueState] = useState<number>(-1);
    const chartRef = useRef<LineChartRefType>(null);
    const [infoboxesState, setInfoboxesState] = useState<InfoboxProps[]>([]);
    const [routeInfoState, setRouteInfoState] = useState<FullRouteInfo | undefined>(undefined);
    const mapRef = React.createRef<BingMapQuerier>();

    useEffect(() => {
        clearArrivalsData();
        if (agency === undefined) return;
        (async (agencyId: string) => {
            await updateTableData();
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
        })(agency.id);
    }, [agency?.id]);

    useEffect(() => {
        const infoboxes = updateBusPosition(sliderValueState, vehiclesPositionsState);
        updateCrossHair(sliderValueState);
        return () => {
            if (infoboxes && infoboxes.length > 0) {
                for (const infobox of infoboxes)
                    infobox.setMap(null as unknown as Microsoft.Maps.Map);
            }
        };
    }, [sliderValueState]);

    const selectedRowHandler = async (selectedRow: BunchingVehicleData) => {
        const { tripId1, tripId2, vehicleId1, vehicleId2, shapeId } = selectedRow;
        setSelectedVehicleDataState(selectedRow);
        setPushpinsState([]);
        if (!agency) {
            clearArrivalsData();
            return;
        }
        setFormBlockingState(true);
        try {
            const [arrivalsData, vehiclesPositions1, vehiclesPositions2, shapePoints] = await Promise.all(
                [getBusBunchingArrivalsChart(agency.id, selectedDateState, tripId1, tripId2, vehicleId1, vehicleId2),
                getBusBunchingPositions(agency.id, selectedDateState, tripId1, vehicleId1),
                getBusBunchingPositions(agency.id, selectedDateState, tripId2, vehicleId2),
                getShapeData(agency.id, selectedDateState, shapeId)]);

            if (!arrivalsData || arrivalsData.length === 0) {
                clearArrivalsData();
                return;
            }
            setBunchingArrivalsState(arrivalsData);
            const routeShortName = vehiclesPositions1[0].routeShortName || vehiclesPositions2[0].routeShortName;
            if (routeShortName) {

                if (!agency || !agency.id)
                    return;

                try {
                    setFormBlockingState(true);
                    const routeInfo = await getFullRouteInfoByRouteName(agency.id, routeShortName);
                    setRouteInfoState(routeInfo);
                } catch {
                    setRouteInfoState(undefined);
                    setInfoboxesState([]);

                } finally {
                    setFormBlockingState(false);
                }

            }

            const minArrivalTime1 = Math.min(...arrivalsData.map(d => new Date(d.actualArrivalTime1).getTime()));
            const minArrivalTime2 = Math.min(...arrivalsData.map(d => new Date(d.actualArrivalTime2).getTime()));
            const vehiclesPositionsFiltered1 = vehiclesPositions1.filter(p => new Date(p.vehicleTime).getTime() >= minArrivalTime1);
            const vehiclesPositionsFiltered2 = vehiclesPositions2.filter(p => new Date(p.vehicleTime).getTime() >= minArrivalTime2);
            const vehiclePositionsGroup1 = Object.entries(Utils.groupBy(vehiclesPositionsFiltered1, position => moment.parseZone(position.vehicleTime).format('YYYY-MM-DD HH:mm')))
                .map(([_key, positions]) => positions[0])
                .sort(({ vehicleTime: a }, { vehicleTime: b }) => moment(a).valueOf() - moment(b).valueOf());
            const vehiclePositionsGroup2 = Object.entries(Utils.groupBy(vehiclesPositionsFiltered2, position => moment.parseZone(position.vehicleTime).format('YYYY-MM-DD HH:mm')))
                .map(([_key, positions]) => positions[0])
                .sort(({ vehicleTime: a }, { vehicleTime: b }) => moment(a).valueOf() - moment(b).valueOf());
            const vehiclePositions: VehiclePosition[] = [{
                vehicleId: vehicleId1,
                positions: vehiclePositionsGroup1,
            }, {
                vehicleId: vehicleId2,
                positions: vehiclePositionsGroup2,
            }];
            setVehiclesPositionsState(vehiclePositions);
            setPolylineState({
                locations: shapePoints.map(({ latitude, longitude }) => [latitude, longitude]),
                options: {
                    strokeColor: 'red',
                    strokeThickness: 3,
                },
            });
            const locations = shapePoints.map(p => new Microsoft.Maps.Location(p.latitude, p.longitude));
            const boundingBox = Microsoft.Maps.LocationRect.fromLocations(locations);
            setMapBoundsState(boundingBox);
            setSliderValueState(0);
        } catch {
            setBunchingArrivalsState([]);
            setVehiclesPositionsState([]);
            setSliderValueState(-1);
        } finally {
            setFormBlockingState(false);
        }
    };
    const updateTableData = async () => {
        clearArrivalsData();
        if (!agency)
            return;
        setFormBlockingState(true);
        try {
            const busBunching = await getBunchingReportForDate(agency.id, selectedDateState);
            setBunchingOccuracyState(busBunching);
        } catch (e) {
            setBunchingOccuracyState(undefined);
        } finally {
            setFormBlockingState(false);
        }
    };
    const handleSelectedDateChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        setSelectedDateState(value);
    };

    const getPrimaryAndSecondaryPositions = (sliderValue: number, vehiclePositions: VehiclePosition[]): {
        primaryPosition: VehiclePositionDto;
        secondaryPosition: VehiclePositionDto | undefined;
    } | null => {
        const [{ positions: positions1 }, { positions: positions2 }] = vehiclePositions;
        const position1 = positions1[sliderValue];
        const position2 = positions2[sliderValue];
        if (!position1 && !position2)
            return null;
        if (position1 && !position2)
            return {
                primaryPosition: position1,
                secondaryPosition: position2,
            };
        if (position2 && !position1)
            return {
                primaryPosition: position2,
                secondaryPosition: position1,
            };
        let primaryPosition: VehiclePositionDto;
        let secondaryPosition: VehiclePositionDto | undefined;
        if (moment(position1.vehicleTime).valueOf() < moment(position2.vehicleTime).valueOf()) {
            primaryPosition = position1;
            secondaryPosition = positions2.find(p => moment(p.vehicleTime).seconds(0).isSame(moment(primaryPosition.vehicleTime).seconds(0)));
        } else {
            primaryPosition = position2;
            secondaryPosition = positions1.find(p => moment(p.vehicleTime).seconds(0).isSame(moment(primaryPosition.vehicleTime).seconds(0)));
        }
        return { primaryPosition, secondaryPosition };

    };
    const handleValueLabelFormat = (value: number): React.ReactNode => {
        if (vehiclesPositionsState.length === 0)
            return;
        const positions = getPrimaryAndSecondaryPositions(value, vehiclesPositionsState);
        const formattedDate = positions && positions.primaryPosition ?
            moment(Utils.utcToLocal(positions.primaryPosition.vehicleTime, positions.primaryPosition.minutesToAtz)).format('hh:mm a') :
            '';
        return <div style={{ textAlign: 'center', fontSize: '9px' }}>{formattedDate}</div>;
    };
    const updateBusPosition = (sliderValue: number, vehiclePositions: VehiclePosition[]) => {
        if (!mapRef || !mapRef.current || !mapRef.current.map)
            return null;
        const infoboxes: Microsoft.Maps.Infobox[] = [];
        const positions = getPrimaryAndSecondaryPositions(sliderValue, vehiclePositions);
        if (!positions)
            return null;
        const syncPositions = [positions.primaryPosition, positions.secondaryPosition];
        for (const position of syncPositions) {
            if (!position)
                continue;
            const description = `at ${moment(Utils.utcToLocal(position.vehicleTime, position.minutesToAtz)).format('h:mm:ss A')}`;
            const infobox = new Microsoft.Maps.Infobox(
                new Microsoft.Maps.Location(position.latitude, position.longitude),
                {
                    htmlContent: getVehicleInfoboxHtml(position.vehicleId, description),
                },
            );
            infobox.setMap(mapRef.current.map);
            infoboxes.push(infobox);
        }
        return infoboxes;
    };
    const clearArrivalsData = () => {
        setBunchingArrivalsState([]);
        setVehiclesPositionsState([]);
        setSliderValueState(-1);
    };
    const getSliderMaxValue = () => {
        const maxValue = Math.max(...vehiclesPositionsState.map(v => v.positions.length));
        return maxValue > 0 ? maxValue - 1 : 0;
    };
    const updateCrossHair = (sliderValue: number): void => {
        if (!chartRef.current)
            return;
        const chartInstance = chartRef.current.chartInstance;
        if (!chartInstance)
            return;
        const positions = getPrimaryAndSecondaryPositions(sliderValue, vehiclesPositionsState);
        if (!positions)
            return;
        const localVehicleTime = Utils.utcToLocal(positions.primaryPosition.vehicleTime, positions.primaryPosition.minutesToAtz);
        const selectedElements: ChartDataElement[] = [];
        let firstFoundElement = null;
        const datasets = chartInstance.config.data.datasets;
        for (let i = 0; i < datasets.data.length; i++) {
            const datasetMeta = chartInstance.getDatasetMeta(i) as unknown as DatasetMeta;
            if (datasetMeta) {
                firstFoundElement = datasetMeta.data.find(d => d._xScale && d._yScale && moment(d._xScale.getLabelForIndex(d._index, i)).isSame(moment(localVehicleTime).seconds(0)));
                if (firstFoundElement)
                    break;
            }
        }
        if (firstFoundElement) {
            selectedElements.push(firstFoundElement);
            for (let i = 0; i < datasets.data.length; i++) {
                if (i === firstFoundElement._datasetIndex)
                    continue;
                const relatedElement = chartInstance.getDatasetMeta(i).data[firstFoundElement._index] as unknown as ChartDataElement;
                if (relatedElement && relatedElement._xScale && relatedElement._yScale)
                    selectedElements.push(relatedElement);
            }
            chartInstance.crosshair.x = firstFoundElement._view.x;
            chartInstance.crosshair.enabled = true;
            chartInstance.tooltip._active = selectedElements;
            chartInstance.tooltip.update(true);
            chartInstance.draw();
        } else if (chartInstance.tooltip._active && chartInstance.tooltip._active.length > 0) {
            chartInstance.tooltip._active = [];
            chartInstance.tooltip.update(true);
            chartInstance.crosshair.enabled = false;
            chartInstance.draw();
        }
    };
    const tabPanes = [
        {
            menuItem: 'Chart',
            pane: (
                <Tab.Pane key="tab1" style={styles.tabContainer}>
                    <Form.Group>
                        {selectedVehicleDataState &&
                            <Form.Field width={16}>
                                <label className="categoryHeader">Arrival times for trips: Trip 1: #{selectedVehicleDataState.tripId1} (Vehicle 1: #{selectedVehicleDataState.vehicleId1}) and Trip 2: #{selectedVehicleDataState.tripId2} (Vehicle 2: #{selectedVehicleDataState.vehicleId2}) for Road: #{routeInfoState?.routeName}</label>
                                <BunchingArrivalTimes arrivalData={bunchingArrivalsState} vehiclesData={selectedVehicleDataState} sliderValue={sliderValueState} />
                            </Form.Field>
                        }
                    </Form.Group >
                </Tab.Pane >
            ),
        },
        {
            menuItem: 'Table',
            pane: (
                <Tab.Pane key="tab2" style={styles.tabContainer}>
                    <Form.Group>
                        <Form.Field width={16} >
                            <BusArrivalsTable rows={bunchingArrivalsState} />
                        </Form.Field>
                    </Form.Group>
                </Tab.Pane>
            ),
        },
    ];

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Bunching Analysis
                </Header>
                <div style={styles.chartDescription}>
                    Bunching is a situation when two buses following the same route in the same direction arrive at the stop almost simultaneously (25% of anticipated headway) instead of arriving at the scheduled interval. Click row to see details.
                </div>
                <Form.Group widths="equal" inline className="inputGroup">
                    <Form.Field className="calendarField">
                        <label className="calendarInputLabel">Report Date:</label>
                        <DateInput
                            name="fromDateCalendar"
                            fluid
                            dateFormat="YYYY-MM-DD"
                            placeholder="Select date"
                            value={selectedDateState}
                            iconPosition="left"
                            popupPosition="bottom center"
                            closable={true}
                            animation="fade"
                            onChange={handleSelectedDateChange}
                            className="calendarInput"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={updateTableData}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                    {bunchingOccuracyState && bunchingOccuracyState.busBunchingReports.length > 0 &&
                        <Form.Field style={styles.busHistoryLinkContainer}>
                            <Statistic
                                size="small"
                                label="Overall bunching percent"
                                value={Math.round(bunchingOccuracyState.busBunchingReports[0].bunchingPcnt) + '%'}
                                className="bunchingAnalysisStatistic"
                            />
                        </Form.Field>
                    }
                </Form.Group>
                {bunchingOccuracyState && bunchingOccuracyState.busBunchingReports.length > 0 && bunchingOccuracyState.busBunchingReports[0].bunchings.length > 0 &&
                    <>
                        <Form.Group>
                            <Form.Field width={16}>
                                <label className="categoryHeader">
                                    <div>Bunched Vehicles</div>
                                    <div className="categorySubtitle">Trip1 - trip that started first, Trip2 - trip that started second, Headway Decrease % - Percentage of decrease in headway between trips compared to scheduled, Stops - Total number of stops, %Bunched - Percentage of stops at which bunching occurred</div>
                                </label>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group className="rowGroup">
                            <Form.Field width={16}>
                                <BusBunchingTable rows={bunchingOccuracyState.busBunchingReports[0].bunchings} selectedRow={selectedVehicleDataState} selectedRowHandler={selectedRowHandler} minutesToAtz={bunchingOccuracyState.minutesToAtz} />
                            </Form.Field>
                        </Form.Group>
                        {bunchingArrivalsState.length > 0 && selectedVehicleDataState &&
                            <>
                                <Form.Group>
                                    <Form.Field width={8}>
                                        <Tab panes={tabPanes} renderActiveOnly={false} />
                                    </Form.Field>

                                    <Form.Field width={8}>
                                        <BingMap
                                            ref={mapRef}
                                            div={{ style: styles.map }}
                                            map={{
                                                center,
                                                options: {
                                                    bounds: mapBoundsState,
                                                },
                                            }}
                                            polylines={polylineState && [polylineState]}
                                            pushpins={pushpinsState}
                                            infoboxes={infoboxesState}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={16} style={styles.sliderContainer} >
                                        <PrettoSliderControl
                                            valueLabelDisplay="on"
                                            name="busBunchingSlider"
                                            aria-label="pretto slider"
                                            min={0}
                                            max={getSliderMaxValue()}
                                            onChange={(_, value: number | number[]) => {
                                                setSliderValueState(value as number);
                                            }}
                                            step={1}
                                            value={sliderValueState}
                                            valueLabelFormat={handleValueLabelFormat}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </>
                        }
                    </>
                }
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(BusBunchingFormNew);
