import { ChartDataElement, LineChartRefType } from '../types/chartTypes';

export default class MapUtils {
    static updateCrossHair(chartRef: LineChartRefType | null, sliderValue: number): void {
        if (!chartRef)
            return;
        const chartInstance = chartRef.chartInstance;
        if (!chartInstance)
            return;
        const selectedElements: ChartDataElement[] = [];
        let firstFoundElement = null;
        const datasets = chartInstance.config.data.datasets;
        for (let i = 0; i < datasets.data.length; i++) {
            firstFoundElement = chartInstance.getDatasetMeta(i).data[sliderValue] as unknown as ChartDataElement;
            if (firstFoundElement)
                break;
        }
        if (firstFoundElement) {
            selectedElements.push(firstFoundElement);
            for (let i = 0; i < datasets.data.length; i++) {
                if (i === firstFoundElement._datasetIndex)
                    continue;
                const relatedElement = chartInstance.getDatasetMeta(i).data[firstFoundElement._index] as unknown as ChartDataElement;
                if (relatedElement && relatedElement._xScale && relatedElement._yScale)
                    selectedElements.push(relatedElement);
            }
            chartInstance.crosshair.x = firstFoundElement._view.x;
            chartInstance.crosshair.enabled = true;
            chartInstance.tooltip._active = selectedElements;
            chartInstance.tooltip.update(true);
            chartInstance.draw();
        } else if (chartInstance.tooltip._active && chartInstance.tooltip._active.length > 0) {
            chartInstance.tooltip._active = [];
            chartInstance.tooltip.update(true);
            chartInstance.crosshair.enabled = false;
            chartInstance.draw();
        }
    }

    static updateCrossHairLineChart(chartRef: any | null, sliderValue: number): void {
        if (!chartRef || sliderValue < 1)
            return;
        const chartInstance = chartRef;
        if (!chartInstance)
            return;
        const selectedElements = [];
        let firstFoundElement = null;
        const datasets = chartInstance.config.data.datasets;
        if (datasets.length > 0 && datasets[0].data.length > 0) {
            firstFoundElement = chartInstance.getDatasetMeta(0).data[sliderValue];
        }
        if (firstFoundElement) {
            selectedElements.push(firstFoundElement);
            chartInstance.crosshair.x = firstFoundElement.x;
            chartInstance.crosshair.enabled = true;
            chartInstance.tooltip.active = true;
            chartInstance.tooltip.setActiveElements([
                {
                    datasetIndex: firstFoundElement.$context.datasetIndex,
                    index: firstFoundElement.$context.index,
                }]
            );
            chartInstance.update();
            chartInstance.draw();
        } else if (chartInstance.tooltip._active && chartInstance.tooltip._active.length > 0) {
            chartInstance.tooltip._active = [];
            chartInstance.tooltip.update(true);
            chartInstance.crosshair.enabled = false;
            chartInstance.draw();
        }
    }
}