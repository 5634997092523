import BlockUi from '@availity/block-ui';
import * as React from 'react';
import { Button, CheckboxProps, Divider, Header, Loader, Radio, Segment } from 'semantic-ui-react';
import { IDirectionModel, IRouteModel } from '../../types/view-models-interfaces';
import RouteVariantsSelector from '../shared/RouteVariantsSelector';
import { AgencyModel } from './_models';
import { SpeedMapContext, SpeedMapContextType } from './SpeedMapContext';
import SpeedMapInputs from './SpeedMapInputs';
import SpeedMapLegend from './SpeedMapLegend';

const markCssColors = ['#e0e0e0', '#fdd835', '#7cb342'];

function CompareTreckSelector() {
    const context = React.useContext(SpeedMapContext) as SpeedMapContextType;
    const handlePeriodChange = (_e: React.SyntheticEvent<HTMLElement, Event>, data: CheckboxProps) => {
        context.setSelectedPeriod(data.value as string);
    };
    return (<>
        {context.periodsState.options.map((o, i) => (
            <Radio
                key={i}
                label={o.text}
                name="trecksGroup"
                value={o.value as number | string}
                checked={context.periodsState.selectedValue === o.value}
                onChange={handlePeriodChange}
            />))
        }
    </>);
}


interface SpeedMapPaneProps {
    agency: AgencyModel;
}

export default function SpeedMapPane({ agency }: SpeedMapPaneProps) {
    const context = React.useContext(SpeedMapContext) as SpeedMapContextType;
    const [showSidePane, setShowSidePane] = React.useState(true);

    React.useEffect(() => {
        context.setAgency(agency);
    }, [agency]);

    const mapDiv = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (mapDiv.current === null) return;
        context.onMapReady(mapDiv.current);
    }, [mapDiv.current]);

    const handleRouteVariantChecked = (route: IRouteModel, direction: IDirectionModel) => {
        if (context.routesList) {
            context.routesList.forEach(r => {
                r.directions.forEach(d => {
                    d.selected = r.routeId === route.routeId && r.routeName === route.routeName && d.directionVariantId === direction.directionVariantId;
                });
            });
        }
        context.selectRouteDirection(route, direction);
    };
    const handleGoClick = () => {
        context.handleGoClick();
    };

    if (!context.agency)
        return null;

    return (
        <BlockUi tag="div" blocking={context.isBusy} className="speedmap-container">
            <div className="speedmap map">
                <Segment.Group className="content">
                    <Segment className="ctl">
                        <div>
                            <Button basic circular size="small" icon={showSidePane ? 'chevron right' : 'chevron left'} onClick={() => setShowSidePane(!showSidePane)} />
                        </div>
                        <div>
                            <SpeedMapInputs onClickGo={handleGoClick} />
                        </div>
                        {context.showComparePeriods && context.dataPeriodsNumber > 1 &&
                            <>
                                <Divider horizontal>
                                    <Header as="h5">Show period:</Header>
                                </Divider>
                                <CompareTreckSelector />
                            </>
                        }
                    </Segment>
                    <Segment className="map">
                        <div ref={mapDiv} className="wrapper-map"></div>
                        <Segment className="map-legend">
                            <SpeedMapLegend />
                        </Segment>
                    </Segment>
                </Segment.Group>
            </div>
            {!showSidePane
                ? null :
                <div className="speedmap side">
                    <Segment id="routeSelectionPanel">
                        {!context.routesList ? <Loader active>Loading active routes...</Loader> : null}
                        {context.routesList?.map((r, i) => (
                            <RouteVariantsSelector key={i}
                                route={r}
                                onChecked={handleRouteVariantChecked}
                                markCssColor={(dirData) => {
                                    const colorIndex = context.directionHasData(r.routeName, r.routeId, dirData.cardinalDirection);
                                    return markCssColors[colorIndex];
                                }}
                            />
                        ))}
                    </Segment>
                </div>
            }
        </BlockUi>
    );
}
