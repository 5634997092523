import './AnalysisByPercentilesStyles.css';
import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, CheckboxProps, DropdownProps, Form, Header, Icon, Loader, Popup, Radio } from 'semantic-ui-react';
import NumberInput from 'semantic-ui-react-numberinput';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getGtfsDirectionVariants } from '../../../actions/gtfsStaticActions';
import { getAnalysisByPercentiles } from '../../../actions/runningTimeAnalyticsActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { GtfsDirectionVariantModel } from '../../../types/gtfsTypes';
import { AllDays, AnalysisByPercentiles, BusinessDays, DaysFilterType, Weekend } from '../../../types/runningTimeAnalyticsTypes';
import { DropDownStateType } from '../../../types/types';
import Utils from '../../../utilities/utils';
import StartAndEndDatesField from '../../shared/StartAndEndDatesField';
import AnalysisByPercentilesChart from './AnalysisByPercentilesChart';
import DistributionChart from './DistributionChart';
import TripsListTable from './TripsListTable';

export interface AggregatedTripsItem {
    tripId: string;
    internalTripId: string;
    directionVariantName: string
    scheduledStartTime: string;
    scheduledEndTime: string;
    scheduledDelayToNextTrip: number;
    scheduledRunTime: number;
    delaysMinutes: number[];
}

export interface AnalysisByPercentilesTripStats {
    tripInfo: AggregatedTripsItem;
    percentileMin: number;
    percentileMax: number;
    percentileMedian: number;
    q1: number;
    q3: number;
}

const initialDropDownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};

const percentitlesChartType = 'percentilesChart';
const distributionChartType = 'distributionChart';

interface Props {
    agency: AgencyStateType | undefined;
}

const styles = {
    bottomContainer: {
        paddingBottom: '14px',
    } as React.CSSProperties,
    chartsContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '500px',
    } as React.CSSProperties,
    monthFilterLabel: {
        display: 'block',
    } as React.CSSProperties,
    popupWindow: {
        left: '-70px',
    } as React.CSSProperties,
    closePopupLink: {
        float: 'right',
    } as React.CSSProperties,
    formSelects: {
        width: '205px',
    } as React.CSSProperties,
    applyButton: {
        marginLeft: '25px',
    },
    routesLoader: {
        marginLeft: '10px',
    },
};

const AnalysisByPercentilesForm: React.FC<Props> = ({ agency }) => {

    const [startDateState, setStartDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'month').format('YYYY-MM-DD'));
    const [endDateState, setEndDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).format('YYYY-MM-DD'));
    const [routesState, setRoutesState] = useState(initialDropDownState);
    const [directionsState, setDirectionsState] = useState(initialDropDownState);
    const [aggregatedTripsState, setAggregatedTripsState] = useState<AggregatedTripsItem[]>([]);
    const [daysFilterState, setDaysFilterState] = useState<DaysFilterType>(AllDays);
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [percentileMinState, setPercentileMinState] = useState<string>('10');
    const [percentileMaxState, setPercentileMaxState] = useState<string>('90');
    const [chartTypeState, setChartTypeState] = useState<typeof percentitlesChartType | typeof distributionChartType>(percentitlesChartType);
    const [tripsTableState, setTripsTableState] = useState<AnalysisByPercentiles[]>([]);
    const [routesLoadingState, setRoutesLoadingState] = useState(false);
    const [monthsFilterState, setMonthsFilterState] = useState<string[]>([]);
    const [popupOpenState, setPopupOpenState] = useState<boolean>(false);
    const allDirectionsRef = useRef<GtfsDirectionVariantModel[]>([]);
    const tripsDataOriginalRef = useRef<AnalysisByPercentiles[]>([]);
    const tripsDataFilteredRef = useRef<AnalysisByPercentiles[]>([]);
    const popupRef = useRef<any>(null);

    useEffect(() => {
        clearTripsData();
        setRoutesState(initialDropDownState);
        allDirectionsRef.current = [];
        setDirectionsState(initialDropDownState);
        updateRoutesAndDirections(startDateState, endDateState);
    }, [agency?.id]);

    useEffect(() => {
        setMonthsFilter();
        clearTripsData();
        updateRoutesAndDirections(startDateState, endDateState);
    }, [startDateState, endDateState]);

    const clearTripsData = () => {
        setPopupOpenState(false);
        setAggregatedTripsState([]);
        setTripsTableState([]);
        tripsDataOriginalRef.current = [];
        tripsDataFilteredRef.current = [];
    };

    const updateRoutesAndDirections = async (startDate: string, endDate: string) => {
        if (agency) {
            try {
                setRoutesLoadingState(true);
                const directions = await getGtfsDirectionVariants(agency.id, startDate, endDate);
                const routes = [...new Set(directions.map(d => d.routeShortName))];
                setRoutesState(prevState => ({
                    options: routes.map(r => ({ value: r, text: r })),
                    selectedValue: prevState.selectedValue && routes.includes(prevState.selectedValue) ? prevState.selectedValue : '',
                }));
                if (routesState.selectedValue) {
                    const directionsForRoute = Utils.distinctValuesByKey(directions.filter(d => d.routeShortName === routesState.selectedValue), 'directionVariantName');
                    setDirectionsState({
                        options: directionsForRoute.map(d => ({ value: d.directionVariantName, text: d.directionVariantName })),
                        selectedValue: '',
                    });
                }
                allDirectionsRef.current = directions;
            } catch {
                setRoutesState(initialDropDownState);
                allDirectionsRef.current = [];
            } finally {
                setRoutesLoadingState(false);
            }
        } else {
            setRoutesState(initialDropDownState);
            allDirectionsRef.current = [];
        }
    };

    const handleRouteChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: routeName }: DropdownProps) => {
        setRoutesState(prevState => ({
            ...prevState,
            selectedValue: routeName as string,
        }));
        clearTripsData();
        if (allDirectionsRef && allDirectionsRef.current.length > 0) {
            const directionsForRoute = Utils.distinctValuesByKey(allDirectionsRef.current.filter(d => d.routeShortName === routeName), 'directionVariantName');
            setDirectionsState({
                options: directionsForRoute.map(d => ({ value: d.directionVariantName, text: d.directionVariantName })),
                selectedValue: '',
            });
        } else {
            setDirectionsState(initialDropDownState);
        }
    };
    const handleDirectionChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: directionVariantName }: DropdownProps) => {
        setDirectionsState(prevState => ({
            ...prevState,
            selectedValue: directionVariantName as string,
        }));
        setPopupOpenState(false);
        updateAggregatedTripsData(directionVariantName as string, daysFilterState);
    };
    const daysFilterChange = (_event: React.FormEvent<HTMLInputElement>, { value }: CheckboxProps) => {
        setDaysFilterState(value as DaysFilterType);
        setPopupOpenState(false);
        updateAggregatedTripsData(directionsState.selectedValue, value as DaysFilterType);
    };
    const percentileMinChange = (newValue: string) => {
        setPercentileMinState(newValue);
    };
    const percentileMaxChange = (newValue: string) => {
        setPercentileMaxState(newValue);
    };
    const handleApplyClick = async () => {
        setPopupOpenState(false);
        const routeName = routesState.selectedValue;
        if (agency && agency.id && routeName) {
            setFormBlockingState(true);
            try {
                tripsDataOriginalRef.current = await getAnalysisByPercentiles({
                    agencyId: agency.id,
                    routeName,
                    startDate: startDateState,
                    endDate: endDateState,
                });
                updateAggregatedTripsData(directionsState.selectedValue, daysFilterState);
                setFormBlockingState(false);
            } catch {
                tripsDataOriginalRef.current = [];
                setAggregatedTripsState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            tripsDataOriginalRef.current = [];
            setAggregatedTripsState([]);
        }
    };
    const handleChartTimeAxisClick = (timeLabel: string) => {
        if (tripsDataFilteredRef && tripsDataFilteredRef.current) {
            const tripsForSelectedTime = tripsDataFilteredRef.current.filter(t => moment.parseZone(t.scheduledStartTime).format('hh.mm A') === timeLabel);
            setTripsTableState(tripsForSelectedTime);
            setPopupOpenState(true);
        }
    };
    const handlePercentileButtonClick = () => {
        setChartTypeState(percentitlesChartType);
        setPopupOpenState(false);
    };
    const handleDistributionButtonClick = () => {
        setChartTypeState(distributionChartType);
        setPopupOpenState(false);
    };

    const updateAggregatedTripsData = (directionVariantName: string | undefined, daysFilter: DaysFilterType) => {
        if (tripsDataOriginalRef && tripsDataOriginalRef.current && directionVariantName) {
            const valuesForDaysFilter =
                daysFilter === BusinessDays ?
                    [1, 2, 3, 4, 5] :
                    daysFilter == Weekend ?
                        [6, 0] :
                        [];
            const tripsDataFiltered = tripsDataOriginalRef.current.filter(d => d.directionVariantName === directionVariantName &&
                (daysFilter === AllDays || valuesForDaysFilter.includes(moment(d.serviceDate).weekday())));
            tripsDataFilteredRef.current = tripsDataFiltered;
            const aggregatedTrips = Object.entries(Utils.groupBy(tripsDataFiltered, d => d.internalTripId)).map<AggregatedTripsItem>(([internalTripId, trips]) => ({
                internalTripId,
                tripId: trips[0].tripId,
                directionVariantName: trips[0].directionVariantName,
                scheduledStartTime: moment.parseZone(trips[0].scheduledStartTime).format('hh.mm A'),
                scheduledEndTime: moment.parseZone(trips[0].scheduledEndTime).format('hh.mm A'),
                scheduledDelayToNextTrip: trips[0].scheduledDelayToNextTrip,
                scheduledRunTime: Utils.roundNumber(trips[0].scheduledRunTime / 60, 0),
                delaysMinutes: trips.sort((a, b) => a.tripDelaySec - b.tripDelaySec).map(t => Utils.roundNumber(t.tripDelaySec / 60, 0)),
            })).sort((a, b) => moment(a.scheduledStartTime, 'hh.mm A').valueOf() - moment(b.scheduledStartTime, 'hh.mm A').valueOf());
            setAggregatedTripsState(aggregatedTrips);
        } else {
            setAggregatedTripsState([]);
        }
    };
    const handleMonthFilterClick = async (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        const monthName = (e.target as HTMLElement).innerText;
        const startDate = moment().month(monthName).startOf('month').format('YYYY-MM-DD');
        const endDate = moment().month(monthName).endOf('month').format('YYYY-MM-DD');
        setStartDateState(startDate);
        setEndDateState(endDate);
    };
    const setMonthsFilter = () => {
        const months: string[] = [];
        const endDate = moment().startOf('day');
        let startDate = moment().add(-5, 'months').startOf('day');
        while (startDate.isSameOrBefore(endDate)) {
            const month = startDate.format('MMM').toUpperCase();
            months.push(month);
            startDate = startDate.add(1, 'months');
        }
        setMonthsFilterState(months);
    };
    const handlePopupClose = () => {
        setPopupOpenState(false);
    };
    const percentilesChart = <AnalysisByPercentilesChart analysisByPercentilesData={aggregatedTripsState}
        percentileMin={Number(percentileMinState)}
        percentileMax={Number(percentileMaxState)}
        handleChartTimeAxisClick={handleChartTimeAxisClick} />;
    const distributionChart = <DistributionChart distributionChartData={aggregatedTripsState} handleChartTimeAxisClick={handleChartTimeAxisClick} />;
    const chart = chartTypeState === percentitlesChartType ? percentilesChart : distributionChart;

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Analysis by Percentiles
                </Header>
                <Form.Group widths="equal" inline className="inputGroup">
                    <StartAndEndDatesField {...{
                        startDate: startDateState,
                        setStartDate: setStartDateState,
                        endDate: endDateState,
                        setEndDate: setEndDateState,
                        className: 'calendarField',
                    }} />
                    <Form.Select
                        placeholder="Select route"
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={routesState.options}
                        value={routesState.selectedValue}
                        onChange={handleRouteChange}
                        width={3}
                        className="fieldControl"
                        style={styles.formSelects}
                    />
                    <Loader active={routesLoadingState} inline className="fieldControl" style={styles.routesLoader} />
                    <Form.Field width={5}>
                        <Button
                            primary
                            content="Apply"
                            onClick={handleApplyClick}
                            className="primaryButton fieldControl"
                            style={styles.applyButton}
                        />
                    </Form.Field>
                    {monthsFilterState.length > 0 &&
                        <>
                            <Form.Field inline={false} className="fieldControl">
                                <label style={styles.monthFilterLabel}> Or choose month:</label>
                                {monthsFilterState.map((month, index) => (
                                    <Link key={index} to="/" onClick={handleMonthFilterClick}>{month} </Link>
                                ))}
                            </Form.Field>
                        </>
                    }
                </Form.Group>
                {tripsDataOriginalRef && tripsDataOriginalRef.current.length > 0 &&
                    <>
                        <Form.Group inline className="inputGroup">
                            <Form.Select
                                placeholder="Direction"
                                search
                                selection
                                openOnFocus={false}
                                selectOnBlur={false}
                                options={directionsState.options}
                                value={directionsState.selectedValue}
                                onChange={handleDirectionChange}
                                width={3}
                                style={styles.formSelects}
                                className="percentilesDirectionsInput"
                            />
                            {chartTypeState === percentitlesChartType &&
                                <>
                                    <Form.Field>
                                        <NumberInput value={percentileMinState}
                                            onChange={percentileMinChange}
                                            stepAmount={10}
                                            minValue={0}
                                            maxValue={40}
                                            className="percentileInput"
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <NumberInput
                                            value={percentileMaxState}
                                            onChange={percentileMaxChange}
                                            stepAmount={10}
                                            minValue={60}
                                            maxValue={100}
                                            className="percentileInput"
                                        />
                                    </Form.Field>
                                </>
                            }
                            <div className="daysFiltersGroup">
                                <Radio
                                    label="All days of the week"
                                    name="radioGroup"
                                    value="all"
                                    checked={daysFilterState === 'all'}
                                    onChange={daysFilterChange}
                                />
                                <Radio
                                    label="Mon-Fri"
                                    name="radioGroup"
                                    value="businessDays"
                                    checked={daysFilterState === 'businessDays'}
                                    onChange={daysFilterChange}
                                />
                                <Radio
                                    label="Sat-Sun"
                                    name="radioGroup"
                                    value="weekend"
                                    checked={daysFilterState === 'weekend'}
                                    onChange={daysFilterChange}
                                />
                            </div>
                            <Form.Field className="buttonGroup">
                                <Button.Group floated="right">
                                    <Button onClick={handlePercentileButtonClick} active={chartTypeState === percentitlesChartType}>Percentile</Button>
                                    <Button.Or />
                                    <Button onClick={handleDistributionButtonClick} active={chartTypeState === distributionChartType}>Distribution</Button>
                                </Button.Group>
                            </Form.Field>
                        </Form.Group>
                        {directionsState.selectedValue &&
                            <>
                                <Form.Group className="rowGroup" style={styles.bottomContainer}>
                                    <Form.Field width={16}>
                                        <label className="categoryHeader">
                                            <div className="categorySubtitle">
                                                {chartTypeState === percentitlesChartType ?
                                                    <>
                                                        The chart below shows the historical arrival distribution through their <a href="https://en.wikipedia.org/wiki/Quartile">quartiles</a>. Green dots represent the scheduled arrival and upcoming departure times. Outliers indicate variability outside the upper and lower quartiles.
                                                        This box plot helps to assess whether the actual arrival is consistent with the scheduled arrival and scheduled departure of the trip.<br />
                                                        Point of 0 minutes is designated as a scheduled arrival time.
                                                    </> :
                                                    <>
                                                        This dot plot chart shows problem areas with scheduled run-times by understanding if some clusters of trips in a schedule are always behind or ahead of schedule, or if there are one or two vehicles that tend to skew the average scheduled run-time.
                                                    </>}
                                                <br />
                                                <br /> Click to the start time on the chart below to open trips list table.
                                            </div>
                                        </label>
                                        <div ref={popupRef} style={styles.chartsContainer}>
                                            {chart}
                                        </div>
                                    </Form.Field>
                                </Form.Group>
                                {tripsTableState.length > 0 &&
                                    <Popup
                                        context={popupRef}
                                        position="top center"
                                        style={styles.popupWindow}
                                        pinned
                                        open={popupOpenState}>
                                        <Form>
                                            <Form.Group className="rowGroup">
                                                <Form.Field width={16}>
                                                    <label className="categoryHeader">
                                                        <span className="categorySubtitle">Click Bus ID to see its travel history</span>
                                                        <Icon link name="close" onClick={handlePopupClose} style={styles.closePopupLink} />
                                                    </label>
                                                    <TripsListTable rows={tripsTableState} />
                                                </Form.Field>
                                            </Form.Group>
                                        </Form>
                                    </Popup>
                                }
                            </>
                        }
                    </>}
            </Form>
        </BlockUi >
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(AnalysisByPercentilesForm);