import './BusHistoryPageStyles.css';
import BlockUi from '@availity/block-ui';
import React from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { getAgencyLocation } from '../../actions/gtfsStaticActions';
import { AppState } from '../../reducers';
import { getSelectedOrDefaultAgency } from '../../selectors';
import { BusPositionHistoryState } from './BusHistoryContext';
import BusHistoryPane from './BusHistoryPane';
import { AgencyModel } from './types/agencyModel';

interface Props {
    app: AppState;
    agency: AgencyModel;
}

interface State {
    preparing: boolean,
    error?: {
        header?: string,
        message?: string,
    }
}

const defaultErrorHeader = 'An error occured';
const defaultErrorMessage = 'Something went wrong.';

function BusHistoryPage(props: Props) {
    const mapApiReady = !!props.app.bingMaps;
    const agencyReady = !!props.agency;
    const agencyLocationReady = !!(props.agency?.location);
    const [state, setState] = React.useState<State>({ preparing: !mapApiReady || !agencyReady || !agencyLocationReady });
    React.useEffect(() => {
        if (!state.preparing) return;
        (async () => {
            if (!state.preparing || !agencyReady) return;

            const res = await getAgencyLocation(props.agency.id);
            const newState: State = { preparing: false };
            if (res.isSuccess) {
                props.agency.location = new Microsoft.Maps.Location(res.data[0], res.data[1]);
            }
            else {
                newState.error = {
                    header: res.error ? `ERROR ${res.error.statusCode}` : defaultErrorHeader,
                    message: (res.error && res.error.message) || defaultErrorMessage,
                };
            }
            setState(newState);
        })();
    }, [mapApiReady, agencyReady, agencyLocationReady]);

    if (state.preparing)
        return <BlockUi blocking={true} message="preparing..." style={{ height: '100%' }} />;

    if (state.error)
        return (
            <Message warning style={{ textAlign: 'center' }}>
                <Message.Header>{state.error.header}</Message.Header>
                <Message.Content><p>{state.error.message}</p>
                    <p>Please try refresh this page later.</p>
                </Message.Content>
            </Message>
        );
    
    if (!props.agency)
        return (
            <Message warning style={{ textAlign: 'center' }}>
                <Message.Header>Agency not selected</Message.Header>
            </Message>
        );

    return (
        <BusPositionHistoryState>
            <BusHistoryPane agency={props.agency} />
        </BusPositionHistoryState>
    );
}


function mapStateToProps(state: AppState): Props {
    const agency = getSelectedOrDefaultAgency(state);
    return {
        app: state,
        agency: agency,
    };
}

export default connect(
    mapStateToProps,
)(BusHistoryPage);