import BlockUi from '@availity/block-ui';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ColumnDefinition, ReactTabulator } from 'react-tabulator';
import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';
import { Button, Form, Header, Input } from 'semantic-ui-react';
import { getTestRecipients, saveTestRecipients } from '../../actions/pushNotificationActions';
import { PushNotificationRecipient } from '../../types/pushNotificationTypes';

const ManageTestRecipientsForm: React.FC = () => {
    const [formBlockingState, setFormBlockingState] = useState(true);
    const [recipientsState, setRecipientsState] = useState<PushNotificationRecipient[]>([]);
    const [newRecipientIdState, setNewRecipientIdState] = useState<string | undefined>('');
    const [newRecipientNameState, setNewRecipientNameState] = useState<string | undefined>('');

    function ColumnsDefinition() {

        const linkStyle = function (cell: Tabulator.CellComponent) {
            cell.getElement().classList.add('custom-class');
            return cell.getValue();
        };

        const columns: ColumnDefinition[] = [
            {
                title: 'id',
                field: 'Id',
                headerFilter: true,
                formatter: linkStyle,
            }, {
                title: 'name',
                field: 'Name',
                headerFilter: true,
                formatter: linkStyle,
            }, {
                title: '',
                formatter: 'buttonCross',
                width: 30,
                hozAlign: 'center',
                cellClick: async function (_e: UIEvent, cell: Tabulator.CellComponent) {
                    await cell.getRow().delete();
                    const data = cell.getTable().getData();
                    setRecipientsState(data);
                    await onSaveHandler(data);
                },
            },
        ];

        return columns;
    }

    const columns = ColumnsDefinition();

    const onSaveHandler = async (data: PushNotificationRecipient[]) => await saveTestRecipients(data);

    const onAddRowHandler = async () => {
        if (newRecipientIdState && newRecipientNameState) {
            recipientsState.push({
                Id: newRecipientIdState,
                Name: newRecipientNameState,
            });
            setRecipientsState([...recipientsState, ...[]]);
            setNewRecipientIdState('');
            setNewRecipientNameState('');
            await onSaveHandler(recipientsState);
        }
    };

    const onNameChangeHandle = (value: string) => {
        setNewRecipientNameState(value);
    };

    const onIdChangeHandle = (value: string) => {
        setNewRecipientIdState(value);
    };

    useEffect(() => {
        getTestRecipients().then(result => {
            if (result) {
                setRecipientsState(result);
                setFormBlockingState(false);
            }
        });
    }, []);

    return (
        <Fragment>
            <Header as="h1" className="reportHeader">
                Manage test recipients.
            </Header>
            <p>In order to obtain ID, triple tap on the version number in the About screen of the app. The ID will be copied to the phone's clipboard.</p>
            <BlockUi tag="div" message="preparing..." blocking={formBlockingState}>
                <Form key="form" sx={{
                    m: 1,
                    width: '25ch',
                }}>
                    <Form.Group inline>
                        <Form.Field width={4}>
                            <Input
                                labelPosition="left"
                                placeholder="Id"
                                value={newRecipientIdState}
                                onChange={e => onIdChangeHandle(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field width={4}>
                            <Input
                                labelPosition="left"
                                placeholder="Name"
                                value={newRecipientNameState}
                                onChange={e => onNameChangeHandle(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Button key="addButton"
                                    primary
                                    content="Add"
                                    onClick={onAddRowHandler}
                                    className="primaryButton"
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={8}>
                            <ReactTabulator key="recipients_table"
                                            data={recipientsState}
                                            columns={columns}
                                            height={300}
                                            layout={'fitColumns'}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </BlockUi>
        </Fragment>
    );
};


export default connect()(ManageTestRecipientsForm);
