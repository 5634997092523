import { ChartOptions, TooltipItem } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { crosshairOption } from '../../../types/chartjs-plugin-crosshair/CrosshairOption';
import { LineDatePoint } from '../../../types/chartTypes';
import { PredictionArrivalChartData } from '../../../types/delaysAnalysisTypes';
import Utils from '../../../utilities/utils';

const baseDatasetSettings = {
    fill: false,
    lineTension: 0.1,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 5,
    pointHitRadius: 2,
    data: [] as LineDatePoint[],
};

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            ...baseDatasetSettings,
            label: 'Actual',
            backgroundColor: '#ba3021',
            borderColor: '#ba3021',
            pointBorderColor: '#ba3021',
            pointHoverBackgroundColor: '#ba3021',
        },
        {
            ...baseDatasetSettings,
            label: 'Predicted',
            backgroundColor: '#2185d0',
            borderColor: '#2185d0',
            pointBorderColor: '#2185d0',
            pointHoverBackgroundColor: '#2185d0',
        },
        {
            ...baseDatasetSettings,
            label: 'Scheduled',
            backgroundColor: '#696463',
            borderColor: '#696463',
            pointBorderColor: '#696463',
            pointHoverBackgroundColor: '#696463',
        },
    ],
};

const options: ChartOptions<'line'> = {
    plugins: {
        title: {
            display: true,
            text: '',
            font: { size: 16 },
            color: '#ba3021',
        },
        tooltip: {
            mode: 'nearest' as const,
            intersect: false,
            callbacks: {
                title: function () {
                    return '';
                },
                label: function (context: TooltipItem<'line'>) {
                    const {
                        datasetIndex,
                        parsed,
                    } = context;
                    let lineType = 'actual';
                    if (datasetIndex === 1) lineType = 'predicted';
                    if (datasetIndex === 2) lineType = 'scheduled';
                    const time = moment(parsed.y).format('hh:mm A');
                    const runTimesLabel = `${lineType} arrival time: ${time}, ${parsed.x} mil`;
                    return runTimesLabel;
                },
            },
        },
    },
    interaction: {
        mode: 'x' as const,
        intersect: false,
    },
    scales: {
        x: {
            type: 'linear' as const,
            title: {
                display: true,
                text: 'Distance (miles)',
            },
        },
        y: {
            type: 'linear' as const,
            // type: 'time' as const,
            // time: {
            //     parser: 'HH:mm',
            //     tooltipFormat: 'HH:mm',
            // },
            title: {
                display: true,
                text: 'Agency time',
            },
            ticks: {
                stepSize: 600 * 1000,
                callback: (value) => {
                    const date = moment(value);
                    return date.format('hh:mm A');
                },
            },
        },
    },
};

const PredictedArrivalReportChart: React.FC<{ chartData: PredictionArrivalChartData }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const arrivalData = chartData.arrivalData;
        const orderedChartData = arrivalData.sort((a, b) => a.traveledKm > b.traveledKm ? 1 : -1).map(a => {
            return {
                traveledMiles: Utils.kmToMiles(a.traveledKm),
                actualTime: moment.parseZone(a.actualTime).format('YYYY-MM-DD hh:mm A'),
                predictedTime: moment.parseZone(a.predictedTime).format('YYYY-MM-DD hh:mm A'),
                scheduledTime: moment.parseZone(a.scheduledTime).format('YYYY-MM-DD hh:mm A'),
            };
        });
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: orderedChartData.map(d => {
                    return {
                        x: d.traveledMiles,
                        y: moment(d.actualTime).valueOf(),
                    };
                }),
            }, {
                ...prevState.datasets[1],
                data: orderedChartData.map(d => {
                    return {
                        x: d.traveledMiles,
                        y: moment(d.predictedTime).valueOf(),
                    };
                }),
            }, {
                ...prevState.datasets[2],
                data: orderedChartData.map(d => {
                    return {
                        x: d.traveledMiles,
                        y: moment(d.scheduledTime).valueOf(),
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                title: {
                    ...prevState.plugins?.title,
                    text: `30 min prediction accuracy (as of ${chartData.predictionTime}) ${chartData.accuracy}%`,
                },
            },
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState}
            height={150}
            options={optionsState}
            plugins={[{ id: 'crosshair', ...crosshairOption }]}
        />
    );
};

export default PredictedArrivalReportChart;