import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Utils from '../../../../utilities/utils';
import { HeadwaysChartDataType } from './StopHeadways';

const styles = {
    chartContainer: {
        width: '470px',
    } as React.CSSProperties,
};

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Headway Minutes',
            data: [] as number[],
            barPercentage: 0.5,
            backgroundColor: [],
        },
    ],
};

const options: ChartOptions<'bar'> = {
    scales: {
        x: {
            ticks: {
                maxTicksLimit: 10,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                callback: function (tickValue: string | number) {
                    return `${Utils.roundNumber(tickValue as number / 60, 1)} min`;
                },
            },
            title: {
                display: false,
            },
        },
    },
    responsive: true,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                label: function (tooltipItem) {
                    return [`Local Arrival Time: ${tooltipItem.parsed.x}`, `Headway (min): ${tooltipItem.parsed.y < 3600 ? Utils.convertSecondsToMinutes(tooltipItem.parsed.y) : Utils.convertSecondsToHours({ seconds: tooltipItem.parsed.y })}`];
                },
            },
        },
    },
};

const StopHeadwaysChart: React.FC<{ chartData: HeadwaysChartDataType[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const labels = chartData.map(d => d.actualTime);
        const values = chartData.map(d => d.headwaySec);

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0],
                data: values,
                backgroundColor: chartData.map<never>(({ sequence }) => {
                    switch (sequence) {
                        case 1:
                            return '#FFC061' as never;
                        case 2:
                            return '#FF8368' as never;
                        case 3:
                            return '#31A6DC' as never;
                        default:
                            return '#42DCC6' as never;
                    }
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        title: function (tooltipItem) {
                            const arrivalTime = tooltipItem[0].label;
                            const stateData = chartData.find(d => d.actualTime === arrivalTime);
                            return stateData ? `Vehicle ID: ${stateData.vehicleId}` : '';
                        },
                    },
                },
            },
        }));
    }, [chartData]);

    return (
        <div style={styles.chartContainer}>
            <Bar
                data={dataState}
                height={405}
                options={optionsState}
            />
        </div>
    );
};

export default StopHeadwaysChart;