import { ChartOptions, TooltipItem } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineDatePoint } from '../../../types/chartTypes';
import { TravelTimeBetweenStops } from '../../../types/runningTimeAnalyticsTypes';
import Utils from '../../../utilities/utils';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Scheduled time',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#FF8368',
            borderColor: '#FF8368',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: '#FF8368',
            pointBackgroundColor: '#FF8368',
            pointBorderWidth: 1,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#FF8368',
            pointHoverBorderColor: '#FF8368',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
        {
            label: 'Average Actual time',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#31A6DC',
            borderColor: '#31A6DC',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: '#31A6DC',
            pointBackgroundColor: '#31A6DC',
            pointBorderWidth: 1,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#31A6DC',
            pointHoverBorderColor: '#31A6DC',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'day' as const,
            },
            ticks: {
                stepSize: 1,
                autoSkip: false,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            beginAtZero: false,
            ticks: {
                callback: function (tickValue: number | string) {
                    return `${Utils.roundNumber((tickValue as number) / 60, 1)} min`;
                },
                stepSize: 60,
            },
        },
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                title: function (tooltipItem: TooltipItem<'line'>[]) {
                    return moment(tooltipItem[0].parsed.x).format('YYYY-MM-DD');
                },
            },
        },
    },
};

const TravelTimeBetweenStopsChart: React.FC<{ chartData: TravelTimeBetweenStops[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: chartData.map(p => {
                    return {
                        x: moment(p.serviceDate).format('YYYY-MM-DD'),
                        y: p.scheduledSec,
                    };
                }),
            },
                {
                    ...prevState.datasets[1],
                    data: chartData.map(p => {
                        return {
                            x: moment(p.serviceDate).format('YYYY-MM-DD'),
                            y: p.actualSec,
                        };
                    }),
                }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        label: function (tooltipItem: TooltipItem<'line'>) {
                            let lineType = 'Scheduled';
                            let value = chartData.map(d => d.scheduledSec)[tooltipItem.dataIndex];
                            if (tooltipItem.datasetIndex === 1) {
                                lineType = 'Average Actual';
                                value = chartData.map(d => d.actualSec)[tooltipItem.dataIndex];
                            }
                            return `${lineType}: ${Utils.convertSecondsToMinutes(value, 'm', 's')}`;
                        },
                    },
                },
            },
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState}
            options={optionsState}
            height={70}
        />
    );
};

export default TravelTimeBetweenStopsChart;