import BlockUi from '@availity/block-ui';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Menu } from 'semantic-ui-react';
import { hideAlert, loadGtfsFiles, postGtfsFile, updateActivationDateValue } from '../../actions/actions';
import { getSelectedOrDefaultAgency, isLogged } from '../../selectors';
import AlertModal from '../shared/AlertModal';
import { GtfsPageType } from './constants';
import GtfsDetails from './GtfsDetails';
import GtfsEditorForm from './gtfsEditor/GtfsEditorForm';
import GtfsTable from './GtfsTable';
import GtfsUpload from './GtfsUpload';

const styles = {
    menu: {
        border: 'none',
        boxShadow: 'none',
    },
};

class GtfsContainerPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectFileId: null,
            gtfsViewState: GtfsPageType.Loader,
        };
    }

    componentDidMount = () => {
        this.props.getGtfsFiles(this.props.agencyId);
    };

    onBackHandle = () => {
        this.setState({
            gtfsViewState: GtfsPageType.Loader,
        });
    };

    onDetailsButtonClick = (value) => {
        this.setState({
            gtfsViewState: GtfsPageType.Details,
            selectFileId: value,
        });
    };

    onGtfsViewButtonClick = (value) => {
        this.setState({
            gtfsViewState: GtfsPageType.Viewer,
            selectFileId: value,
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.agencyId !== this.props.agencyId) {
            this.props.getGtfsFiles(nextProps.agencyId);
        }
    }

    render() {
        return (<div>
            <BlockUi tag="div" blocking={this.props.blocking} color="#337ab7">
                {this.state.gtfsViewState !== GtfsPageType.Loader &&
                    <Button style={{ margin: '25px' }} onClick={() => this.onBackHandle()}> Back</Button>}
                {this.state.gtfsViewState === GtfsPageType.Loader && <Fragment>
                    <AlertModal show={this.props.alertVisible} title={this.props.alertTitle}
                                text={this.props.alertText} onHideClick={this.props.hideAlert}/>
                    <Menu style={styles.menu}>
                        <GtfsUpload postGtfs={this.props.postGtfs}/>
                    </Menu>
                    <GtfsTable
                        gtfsFiles={this.props.gtfsFiles}
                        role={this.props.role}
                        updateActivationDate={this.props.updateActivationDate}
                        onDetailsClick={this.onDetailsButtonClick}
                        onGtfsViewClick={this.onGtfsViewButtonClick}
                    >
                    </GtfsTable>
                </Fragment>}
                {this.state.gtfsViewState === GtfsPageType.Details &&
                    <GtfsDetails
                        text="GTFS View page"
                        gtfsFiles={this.props.gtfsFiles}
                        fileId={this.state.selectFileId}/>}
                {this.state.gtfsViewState === GtfsPageType.Viewer &&
                    <GtfsEditorForm fileNameSource={this.state.selectFileId}/>}
            </BlockUi>
        </div>);
    }
}

const mapStateToGtfsContainerPageProps = (state) => ({
    isLogged: isLogged(state),
    gtfsFiles: state.gtfsFiles,
    alertTitle: state.alert.title,
    alertText: state.alert.text,
    alertVisible: state.alert.show,
    alertSuccess: state.alert.success,
    blocking: state.blocking,
    agencyId: getSelectedOrDefaultAgency(state)?.id || '',
    role: state.user.profile.roles,
});

const mapDispatchToGtfsContainerPageProps = (dispatch) => ({
    postGtfs: (file) => (dispatch(postGtfsFile(file))),
    hideAlert: () => (dispatch(hideAlert())),
    getGtfsFiles: (agencyId) => (dispatch(loadGtfsFiles(agencyId))),
    updateActivationDate: (gtfsId, activationDate) => (dispatch(updateActivationDateValue(gtfsId, activationDate))),
    dispatch: dispatch,
});

export default connect(mapStateToGtfsContainerPageProps, mapDispatchToGtfsContainerPageProps)(GtfsContainerPage);