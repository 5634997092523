import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';

export interface UploadFile {
    file: File;
}
export interface KeyValuePair<TKey, TValue> {
    key: TKey;
    value: TValue;
}
export interface AgencyInfo {
    utcOffset: number;
    serviceDate: string;
}
export interface OrgLocation {
    Latitude: Latitude;
    Longitude: Longitude;
}
export type DropDownStateType = {
    options: DropdownItemProps[];
    selectedValue: string | undefined;
};
export interface SliderMark {
    value: number;
    label: string;
}
export interface DropDownInfo {
    state: DropDownStateType;
    changeHandler: (_e: React.SyntheticEvent<HTMLElement, Event>, props: DropdownProps) => void;
}
export interface TableSortProperties<T> {
    column: string;
    data: T[];
    direction: 'ascending' | 'descending' | undefined;
}
export interface TableDispatchAction<T> {
    type: 'CHANGE_SORT' | 'UPDATE_DATA';
    column: string;
    data?: T[];
}
export interface SliderOptions {
    currentValue: number;
    minValue: number;
    maxValue: number;
}
export enum VehiclePositionDataSource {
    Taip = 0,
    GtfsRt = 1
}

export interface IErrorMessage {
    message?: string;
}

export interface IErrorData {
    response?: {
        data?: string;
    } & IErrorMessage;
}

export type ErrorType = IErrorMessage & IErrorData;