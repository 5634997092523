import { ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Utils from '../../../utilities/utils';
import { getPercentageValue, KpiBunch, ModelType, modelTypeColors } from './_data';

const chartSettings = {
    labels: ['0-3 minutes', '3-6 minutes', '6-12 minutes', '12-30 minutes'],
    datasets: [
        {
            label: 'Adaptive',
            data: [] as number[],
            borderWidth: 1,
            backgroundColor: modelTypeColors[ModelType.HISTORY].fill,
            borderColor: modelTypeColors[ModelType.HISTORY].base,
            hoverBackgroundColor: modelTypeColors[ModelType.HISTORY].lite,
            hoverBorderColor: modelTypeColors[ModelType.HISTORY].dark,
        },
        {
            label: 'Linear',
            data: [] as number[],
            borderWidth: 1,
            backgroundColor: modelTypeColors[ModelType.LINEAR].fill,
            borderColor: modelTypeColors[ModelType.LINEAR].base,
            hoverBackgroundColor: modelTypeColors[ModelType.LINEAR].lite,
            hoverBorderColor: modelTypeColors[ModelType.LINEAR].dark,
        },
        {
            label: 'Legacy',
            data: [] as number[],
            borderWidth: 1,
            backgroundColor: modelTypeColors[ModelType.SIRI].fill,
            borderColor: modelTypeColors[ModelType.SIRI].base,
            hoverBackgroundColor: modelTypeColors[ModelType.SIRI].lite,
            hoverBorderColor: modelTypeColors[ModelType.SIRI].dark,
        },
    ],
};

const options: ChartOptions<'bar'> = {
    scales: {
        x: {
            title: {
                display: true,
                text: 'Range',
            },
        },
        y: {
            title: {
                display: true,
                text: 'Accuracy, %',
            },
            min: 0,
        },
    },
    plugins: {
        datalabels: {
            color: 'white',
            textShadowColor: 'red',
            textShadowBlur: 5,
            textStrokeColor: 'red',
            textStrokeWidth: 1,
            font: {
                weight: 'bold',
                size: 14,
            },
            formatter: function (value: number) {
                return `${Utils.roundNumber(value, 0)}%`;
            },
        },
    },
};

type AccuracyBarChartProps = {
    data: KpiBunch;
};

const emptyData = [null, null, null, null];

function makeDataset(bunch: KpiBunch, model: ModelType) {
    const src = bunch[model];
    if (!src) return emptyData as unknown as number[];
    return [
        getPercentageValue(src.shortAccurate, src.shortTotal),
        getPercentageValue(src.mid1Accurate, src.mid1Total),
        getPercentageValue(src.mid2Accurate, src.mid2Total),
        getPercentageValue(src.longAccurate, src.longTotal),
    ] as unknown as number[];
}

const AccuracyBarChart: React.FC<AccuracyBarChartProps> = (props) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: makeDataset(props.data, ModelType.HISTORY),
            }, {
                ...prevState.datasets[1],
                data: makeDataset(props.data, ModelType.LINEAR),
            }, {
                ...prevState.datasets[2],
                data: makeDataset(props.data, ModelType.SIRI),
            }],
        }));
    }, [props.data]);

    return (
        <Bar
            data={dataState}
            height={70}
            options={options}
            plugins={[ChartDataLabels]}
        />
    );
};

export default AccuracyBarChart;