import { Box, Slider } from '@mui/material';

type SliderValue = number | number[];

interface TimeSliderProps {
    value: SliderValue;
    onChange: (event: Event, newValue: SliderValue) => void;
}

function valuetext(value: number) {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

const RangeSlider = ({ value, onChange }: TimeSliderProps) => (
    <Box sx={{ width: 300, padding: '20px', margin: 'auto', textAlign: 'center' }} width="3" >
        <Slider
            value={value}
            onChange={onChange}
            valueLabelDisplay="off"
            min={0}
            max={1440}
            step={30}
            sx={{ color: '#1976d2' }}
            getAriaValueText={valuetext}
            valueLabelFormat={valuetext}
        />
    </Box>
);

export default RangeSlider;