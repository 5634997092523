import { ChartOptions, TooltipItem } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { DeviationByDayPart } from '../../../../types/delayStatisticsTypes';
import Utils from '../../../../utilities/utils';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Average Deviation (min)',
            data: [] as number[],
            barPercentage: 0.5,
            backgroundColor: '#42DCC6',
        },
    ],
};

const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                boxWidth: 20,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                label: function (tooltipItem: TooltipItem<'bar'>) {
                    const value = tooltipItem.parsed.y;
                    return `Avg Deviation: ${Utils.convertSecondsToMinutes(value, 'm', 's')}`;
                },
            },
        },
    },
    scales: {
        x: {
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            min: 0,
            ticks: {
                callback: function (value: number | string): string | null {
                    if (typeof value === 'number') {
                        return `${Math.round((value as unknown as number) / 60 * 10) / 10} min`;
                    }
                    return null;
                },
                stepSize: 60,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
    },
};

const DeviationByDayPartChart: React.FC<{ chartData: DeviationByDayPart[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const labels = chartData.map(d => d.dayPart);
        const values = chartData.map(d => d.deviation);

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0],
                data: values,
                state: chartData,
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        title: function (tooltipItems: TooltipItem<'bar'>[]) {
                            const toolTipData = tooltipItems[0];
                            let dayPart = toolTipData.label;
                            const stateData = chartData.find(d => d.dayPart === dayPart);
                            if (stateData)
                                dayPart += `\n${stateData.dayPartDesc}`;
                            return dayPart;
                        },
                    },
                },
            },
        }));
    }, [chartData]);

    return (
        <Bar
            data={dataState}
            height={200}
            options={optionsState}
        />
    );
};

export default DeviationByDayPartChart;