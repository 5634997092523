import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadBingMapsActionType } from '../../actions/actionTypes';

const key = 'AnNjOQUEpx1-gkTx541SoEv8HE0LhTTaXW4EJJ0hng97oRQGSMsm7VVlfDwFs5SI' as const;
const callbackName = '__bing_maps_callback__' as const;

declare global {
    interface Window {
        __bing_maps_callback__?: () => void;
    }
}

const BingMapsLoader = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.__bing_maps_callback__ = () => {
            dispatch(loadBingMapsActionType);
        };

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = `https://www.bing.com/api/maps/mapcontrol?callback=${callbackName}&key=${key}`;
        document.body.appendChild(script);

        return () => {
            delete window.__bing_maps_callback__;
            document.body.removeChild(script);
        };
    }, [dispatch]);

    return null;
};

export default BingMapsLoader;