import { OperationResult } from '../types/apiTypes';
import { FullRouteInfo, GtfsRoute } from '../types/gtfsTypes';
import { AutocompleteResult, ItineraryDto } from '../types/tripPlannelTypes';
import api from './api';
import { buildUrlString } from './helpers';

async function apiGet<T>(url: string): Promise<T> {
    return await api.doAuthGetRequestAsync<T>(url);
}

class TripPlannerApi {

    static async getActiveRoutes(agencyId: string, serviceDate: string): Promise<OperationResult<GtfsRoute[]>> {
        const queryParams = {
            agencyId: agencyId,
            serviceDate: serviceDate,
            // lat: latitude,
            // lon: longitude
            // radiusMiles: 0.5
        };
        const url = buildUrlString(queryParams, 'OnTimePerformance', 'ActiveRoutes');
        return await apiGet<OperationResult<GtfsRoute[]>>(url);
    }

    static async getFullRouteInfo(routeId: string, serviceDate: string): Promise<OperationResult<FullRouteInfo>> {
        const queryParams = {
            routeId: routeId,
            serviceDate: serviceDate,
        };
        const url = buildUrlString(queryParams, 'ScheduleReport', 'FullRouteInfo');
        return await apiGet<OperationResult<FullRouteInfo>>(url);
    }

    static async findAddress(latitude: number, longitude: number): Promise<string> {
        const queryParams = {
            lat: latitude.toString(),
            lon: longitude.toString(),
        };
        const url = buildUrlString(queryParams, 'TripPlannerReport', 'FindAddress');
        const result = await apiGet<OperationResult<string>>(url);
        return result.data;
    }

    static async autoCompleteAddress(agencyId: string, searchStr: string): Promise<AutocompleteResult[]> {
        const queryParams = {
            agencyId,
            address: searchStr,
        };
        const url = buildUrlString(queryParams, 'TripPlannerReport', 'AutocompleteAddress');
        const result = await apiGet<OperationResult<AutocompleteResult[]>>(url);
        return result.data || [];
    }

    static async buildTripStartingAt(
        lat1: number,
        lon1: number,
        lat2: number,
        lon2: number,
        startTime: string,
        includeShape = true,
        useTrains = true,
        useBuses = true,
        useBicycles = false,
    ): Promise<ItineraryDto[] | null> {
        let time = new Date(new Date().toLocaleDateString('en-us') + ' ' + startTime).toISOString();
        time = time.substr(0, time.length - 5);

        const queryParams = {
            lat1: lat1.toString(),
            lon1: lon1.toString(),
            lat2: lat2.toString(),
            lon2: lon2.toString(),
            startTime: time,
            useTrains: useTrains.toString(),
            useBuses: useBuses.toString(),
            useBicycles: useBicycles.toString(),
            includeShape: includeShape.toString(),
        };
        const url = buildUrlString(queryParams, 'TripPlannerReport', 'BuildTripStartingAt');
        const result = await apiGet<OperationResult<ItineraryDto[]>>(url);
        return result.data || [];
    }
}

export default TripPlannerApi;
