import { ChartOptions } from 'chart.js';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { BunchingVehicleData, BusBunchingArrival } from '../../../../types/busBunchingTypes';
import { ChartRef, LineChartDataPoint } from '../../../../types/chartTypes';
import MapUtils from '../../../../utilities/map-utils';
import Utils from '../../../../utilities/utils';

const styles = {
    chartContainer: {
        maxHeight: '560px',
        maxWidth: '597px',
        display: 'flex',
    } as React.CSSProperties,
};

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Trip 1',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#ba3021',
            borderColor: '#ba3021',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: '#ba3021',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#ba3021',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineChartDataPoint[],
        },
        {
            label: 'Trip 2',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#2185d0',
            borderColor: '#2185d0',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: '#2185d0',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#2185d0',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 2,
            data: [] as LineChartDataPoint[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'linear',
            title: {
                display: true,
                text: 'Local time',
            },
            beginAtZero: false,
            ticks: {
                stepSize: 600 * 1000,
                autoSkip: false,
                callback: (tickValue: string | number) => {
                    const date = moment(tickValue);
                    return date.format('hh:mm A');
                },
            },
        },
        y: {
            type: 'linear',
            ticks: {
                stepSize: 5,
                autoSkip: false,
            },
            title: {
                display: true,
                text: 'Distance (miles)',
            },
        },
    },
    plugins: {
        title: {
            display: true,
            text: '',
            color: '#ba3021',
            font: {
                size: 16,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 110,
            callbacks: {
                title: function () {
                    return '';
                },
                labelColor: function (tooltipItem) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            borderColor: '#ba3021',
                            backgroundColor: '#ba3021',
                        };
                    } else {
                        return {
                            borderColor: '#2185d0',
                            backgroundColor: '#2185d0',
                        };
                    }
                },
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    maintainAspectRatio: false,
};

interface Props {
    arrivalData: BusBunchingArrival[],
    vehiclesData: BunchingVehicleData,
    sliderValue?: number
}


export type BunchingArrivalTimeRef = typeof Line & ChartRef;

const BunchingArrivalTimes = React.forwardRef<BunchingArrivalTimeRef, Props>(({
                                                                                  arrivalData,
                                                                                  vehiclesData,
                                                                                  sliderValue,
                                                                              }, _) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);
    const ref = useRef(null);

    useEffect(() => {
        if (sliderValue != null) {
            MapUtils.updateCrossHairLineChart(ref.current, sliderValue);
        }
    }, [sliderValue]);

    useEffect(() => {
        const orderedChartData = arrivalData.sort((a, b) => a.traveledKm > b.traveledKm ? 1 : -1);
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: orderedChartData.map(d => {
                    return {
                        x: moment(moment.parseZone(d.actualArrivalTime1).format('YYYY-MM-DD hh:mm A')).valueOf() as unknown as number,
                        y: Utils.kmToMiles(d.traveledKm),
                    };
                }),
            }, {
                ...prevState.datasets[1],
                data: orderedChartData.map(d => {
                    return {
                        x: moment(moment.parseZone(d.actualArrivalTime2).format('YYYY-MM-DD hh:mm A')).valueOf() as unknown as number,
                        y: Utils.kmToMiles(d.traveledKm),
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        label: function (tooltipItem) {
                            const arrival = orderedChartData[tooltipItem.dataIndex];
                            const delay = tooltipItem.datasetIndex === 0 ? arrival.delaySec1 : arrival.delaySec2;
                            const time = moment.parseZone(tooltipItem.parsed.x).format('hh:mm A');
                            const runTimesLabel = `arrival time: ${time} @ ${tooltipItem.parsed.y.toFixed(2)} mil @ delay: ${Utils.convertSecondsToMinutes(delay, 'm', 's')}`;
                            return runTimesLabel;
                        },
                    },
                },
            },
        }));
    }, [arrivalData, vehiclesData]);

    return (
        <div style={styles.chartContainer}>
            <Line
                data={dataState}
                height={250}
                options={optionsState}
                ref={ref}
                plugins={[CrosshairPlugin]}
            />
        </div>
    );
});

export default BunchingArrivalTimes;