import { VehiclePositionState } from '../../types/busHistoryTypes';
import { ActionType, BusPositionHistoryAction } from './types/ActionType';
import { AgencyModel } from './types/agencyModel';
import { BphDirectionsState, BphRoutesState, BphSliderState, DateTimeState, StopPredictionsState } from './types/bphStates';
import { BphDirection, BphStopsStateType, BusPositionHistoryStateType, StopsSearchStateType } from './types/BphTypes';
import { DateTime } from './types/dateTime';
import { SliderInfoProps as BphSliderInfoProps } from './types/SliderInfoProps';

/*-- reducer --*/

export function bphReducer(state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType {
    const handler = (handlers as any)[action.type] || handlers[ActionType.DEFAULT];
    return handler(state, action);
}

export type ActionMap = {
    [actionType in ActionType]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction) => BusPositionHistoryStateType
};

export const handlers: ActionMap = {
    [ActionType.DEFAULT]: (state: BusPositionHistoryStateType) => state,
    [ActionType.SET_MAP_READY]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        return {
            ...state,
            mapReady: !!action.payload,
        };
    },
    [ActionType.SET_AGENCY]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as (AgencyModel | null);
        if (value === state.agency) {
            return state;
        }
        return {
            ...state,
            agency: value,
        };
    },
    [ActionType.SET_DATE_TIME]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as DateTimeState;
        return {
            ...state,
            dateTime: value,
        };
    },
    [ActionType.SET_ROUTES_LOADING]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload === true;
        return {
            ...state,
            routesLoading: value,
        };
    },
    [ActionType.SET_ROUTES_STATE]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as BphRoutesState;
        return {
            ...state,
            routesState: value,
        };
    },
    [ActionType.SET_STOPS_PREDICTIONS]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as Nullable<StopPredictionsState>;
        return {
            ...state,
            stopsPredictions: value,
        };
    },
    [ActionType.SET_DIRECTIONS_STATE]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as BphDirectionsState;
        return {
            ...state,
            directionsState: value,
        };
    },
    [ActionType.SET_STOPS_LOADING]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload === true;
        return {
            ...state,
            stopsLoading: value,
        };
    },
    [ActionType.SET_STOPS_STATE]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as BphStopsStateType;
        return {
            ...state,
            stopsState: value,
        };
    },
    [ActionType.SET_STOP_SEARCH_STATE]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as StopsSearchStateType;
        return {
            ...state,
            stopsSearchState: value,
        };
    },
    [ActionType.SET_NO_STOPS_MESSSAGE]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as string;
        return {
            ...state,
            stopsNoResultMessageState: value,
        };
    },
    [ActionType.SET_DIRECTIONS_LOADING]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload === true;
        return {
            ...state,
            directionsLoading: value,
        };
    },
    [ActionType.SET_DIRECTIONS_INFO]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as BphDirection[];
        return {
            ...state,
            directionsInfo: value,
        };
    },
    [ActionType.SET_HISTORY_LOADING]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload === true;
        return {
            ...state,
            historyLoading: value,
        };
    },
    [ActionType.SET_BUS_POSITIONS]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as VehiclePositionState[];
        return {
            ...state,
            busPositionsState: value,
        };
    },
    [ActionType.SET_SCHEDULE_DATES]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as Nullable<DateTime[]>;
        return {
            ...state,
            scheduleDates: value,
        };
    },
    [ActionType.SET_SLIDER_OPTIONS]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as Nullable<BphSliderState>;
        return {
            ...state,
            sliderOptions: value,
        };
    },
    [ActionType.SET_SLIDER_INFO]: (state: BusPositionHistoryStateType, action: BusPositionHistoryAction): BusPositionHistoryStateType => {
        const value = action.payload as Nullable<BphSliderInfoProps>;
        return {
            ...state,
            sliderInfo: value,
        };
    },
};