import './PredictionAccuracy.css';
import BlockUi from '@availity/block-ui';
import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, DropdownItemProps, DropdownProps, Form, Header, Message } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { DropDownStateType } from '../../../types/types';
import Utils from '../../../utilities/utils';
import StartAndEndDatesField from '../../shared/StartAndEndDatesField';
import { BaseKpiSet, collectOverallData, getPredictionAccuracyCustomPeriodReportData, getPredictionAccuracyReportData, HourlyReportData, KpiBunch, ModelType, OverallDataPoint, ReportKpiData } from './_data';
import AccuracyBarChart from './AccuracyBarChart';
import AccuracyByTime from './AccuracyByTime';
import AccuracyLineChart from './AccuracyLineChart';

Chart.register(...registerables);

interface Props {
    agency: AgencyStateType | undefined;
}

const styles = {
    chartDescription: {
        marginBottom: '30px',
    } as React.CSSProperties,
};

function makeDateRangeString(date1: string, date2: string): string {
    const mt1 = moment(date1);
    const dt1 = mt1.toDate();
    const mt2 = moment(date2);
    const dt2 = mt2.toDate();
    if (dt1.valueOf() === dt2.valueOf())
        return mt1.format('D MMM');
    if (dt1.getFullYear() !== dt2.getFullYear())
        return `${mt1.format('MM/DD/YYYY')} - ${mt2.format('MM/DD/YYYY')}`;
    if (dt1.getMonth() !== dt2.getMonth())
        return `${mt1.format('D MMM')} - ${mt2.format('D MMM')}`;
    else
        return `${mt1.format('D')} - ${mt2.format('D MMM')}`;
}

const modeDropdownState: DropDownStateType = {
    options: [
        {
            value: 'Day',
            text: 'Days',
        },
        {
            value: 'Week',
            text: 'Weeks',
        },
        {
            value: 'Month',
            text: 'Months',
        },
        {
            value: 'Custom',
            text: 'Custom Range',
        },
    ],
    selectedValue: 'Day',
};

const emptyDropdownState: DropDownStateType = {
    options: [],
    selectedValue: '',
};

function getRoute(sourceData: ReportKpiData | undefined, mode: string | undefined, routeId: string | undefined) {
    if (sourceData && mode && routeId)
        return sourceData[mode]?.routes[routeId];
    return undefined;
}

type PredictionAccuracyState = {
    ditribution: KpiBunch;
    overallDate: string;
    overallData: OverallDataPoint[];
    hourlyDate: string;
    hourlyData?: HourlyReportData;
};

const noReportDataState = {} as PredictionAccuracyState;

const PredictionAccuracyForm: React.FC<Props> = ({ agency }) => {
    const [modeState, setModeState] = useState(modeDropdownState);
    const [routeState, setRouteState] = useState(emptyDropdownState);
    const [directionState, setDirectionState] = useState(emptyDropdownState);
    const [sourceData, setSourceData] = useState<ReportKpiData | undefined>();
    const [reportState, setReportState] = useState<PredictionAccuracyState | undefined>();
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [fromDateState, setFromDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-8, 'days').format('YYYY-MM-DD'));
    const [toDateState, setToDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));

    useEffect(() => {
        if (!agency || !agency.id)
            return;

        const fetchChartData = async () => {
            setFormBlockingState(true);
            try {
                const rawReportData = await getPredictionAccuracyReportData(agency.id);
                setSourceData(rawReportData);
                updateRouteState(rawReportData, modeState.selectedValue);
            } catch {
                setReportState(undefined);
            }
            finally {
                setFormBlockingState(false);
            }
        };
        fetchChartData();
    }, [agency?.id]);

    const buildReportState = (
        data: ReportKpiData | undefined,
        mode: string | undefined,
        routeId: string | undefined,
        directionId: string | undefined,
    ) => {
        const modeEntry = data && mode && data[mode];
        if (!modeEntry)
            return noReportDataState;

        const dateRange1 = {
            date1: '',
            date2: '',
        };
        const dateRange2 = {
            date1: '',
            date2: '',
        };
        if (modeEntry.pointData.length > 0) {
            const first = modeEntry.pointData[0];
            const last = modeEntry.pointData[modeEntry.pointData.length - 1];
            dateRange1.date1 = first.date1;
            dateRange1.date2 = last.date2;
            dateRange2.date1 = last.date1;
            dateRange2.date2 = last.date2;
        }
        if (dateRange1.date1 === '') {
            return noReportDataState;
        }

        let kpiSet: BaseKpiSet | undefined = modeEntry;
        if (routeId) {
            const route = modeEntry.routes[routeId];
            kpiSet = route;
            if (route && directionId) {
                kpiSet = route.directions[directionId];
            }
        }

        if (!kpiSet) {
            return noReportDataState;
        }
        kpiSet.pointData.sort((a, b) => a.date1.localeCompare(b.date1));

        const overallData = (kpiSet && collectOverallData(kpiSet.pointData)) || [];
        const reportState: PredictionAccuracyState = {
            ditribution: kpiSet.bunchData,
            overallDate: makeDateRangeString(dateRange1.date1, dateRange1.date2),
            overallData,
            hourlyDate: makeDateRangeString(dateRange2.date1, dateRange2.date2),
        };
        const lastHourly = kpiSet?.pointData[kpiSet?.pointData.length - 1];
        if (lastHourly) {
            reportState.hourlyData = {
                [ModelType.HISTORY]: lastHourly[ModelType.HISTORY]?.overallHourly,
                [ModelType.LINEAR]: lastHourly[ModelType.LINEAR]?.overallHourly,
                [ModelType.SIRI]: lastHourly[ModelType.SIRI]?.overallHourly,
            };
        }
        return reportState;
    };

    useEffect(() => {
        setReportState(undefined);

        const update = () => {
            const reportState = !sourceData
                ? noReportDataState
                : buildReportState(sourceData, modeState.selectedValue,
                    routeState.selectedValue, directionState.selectedValue);
            setReportState(reportState);
        };
        update();
    }, [sourceData, modeState.selectedValue, routeState.selectedValue, directionState.selectedValue]);

    const handleModeChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        const mode = value as string;
        setModeState(prevState => ({
            ...prevState,
            selectedValue: mode,
        }));
        updateRouteState(sourceData, mode);
    };

    const updateRouteState = (sourceData: ReportKpiData | undefined, mode: string | undefined) => {
        const options: DropdownItemProps[] = [];
        if (sourceData && mode && sourceData[mode]) {
            for (const r in sourceData[mode].routes) {
                options.push({ value: r, text: r });
            }
            options.sort((a, b) => {
                const sa = (a.text as string);
                const sb = (b.text as string);
                return sa.localeCompare(sb);
            });
        }
        setRouteState(_prevState => ({
            options: options,
            selectedValue: emptyDropdownState.selectedValue,
        }));
        setDirectionState(emptyDropdownState);
    };

    const updateDirectionState = (sourceData: ReportKpiData | undefined, mode: string | undefined, routeId: string | undefined) => {
        const options: DropdownItemProps[] = [];
        const route = getRoute(sourceData, mode, routeId);
        if (route) {
            const directions = Object.values(route.directions);
            for (const { id, otsTripShortName, tripHeadsign } of directions) {
                options.push({
                    value: id,
                    text: Utils.getDirectionVariantName(otsTripShortName, tripHeadsign),
                });
            }
            options.sort((a, b) => {
                const sa = (a.text as string);
                const sb = (b.text as string);
                return sa.localeCompare(sb);
            });
        }
        setDirectionState({
            options: options,
            selectedValue: emptyDropdownState.selectedValue,
        });
    };

    const handleRouteChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        const routeId = value as string;
        setRouteState(prevState => ({
            ...prevState,
            selectedValue: routeId,
        }));
        updateDirectionState(sourceData, modeState.selectedValue, routeId);
    };

    const handleDirectionChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        const directionId = value as string;
        setDirectionState(prevState => ({
            ...prevState,
            selectedValue: directionId,
        }));
    };

    const handleApplyButtonClick = async () => {
        if (!agency || !agency.id)
            return;
        try {
            setFormBlockingState(true);
            const rawReportData = await getPredictionAccuracyCustomPeriodReportData(agency.id, fromDateState, toDateState);
            setSourceData(prevState => ({
                Day: prevState!.Day,
                Week: prevState!.Week,
                Month: prevState!.Month,
                Custom: rawReportData.Custom,
            }));
            updateRouteState(rawReportData, modeState.selectedValue);
        } catch {
            if (sourceData?.Custom) {
                setSourceData(prevState => ({
                    Day: prevState!.Day,
                    Week: prevState!.Week,
                    Month: prevState!.Month,
                }));
            }
        }
        finally {
            setFormBlockingState(false);
        }
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form className="predictionAccuracy">
                <Header as="h1" className="reportHeader">
                    Prediction Accuracy
                </Header>
                <div style={styles.chartDescription}>
                    The accuracy indicates the proportion of correct predictions.  Accuracy is split between 4 ranges (buckets) representing actual stop arrival wait times. The chart compares 3 different models with "Adaptive" currently used in production. The other two are baselines used for troubleshooting purposes. Each range has its own margin of error: (+/-1), (+2/-1.5), (+3/-2), (+4/-2.5) for each bucket, respectively, in minutes.
                </div>
                {modeState.selectedValue === 'Custom' && <Form.Group className="inputGroup">
                    <StartAndEndDatesField {...{
                        startDate: fromDateState,
                        setStartDate: setFromDateState,
                        endDate: toDateState,
                        setEndDate: setToDateState,
                        className: 'calendarField',
                    }} />
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={handleApplyButtonClick}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                </Form.Group>}
                <Form.Group className="inputGroup">
                    <Form.Select
                        placeholder="Choose display mode"
                        fluid
                        search
                        selection
                        selectOnNavigation={false}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={modeState.options}
                        value={modeState.selectedValue}
                        onChange={handleModeChange}
                        width={3}
                    />
                    {routeState.options.length > 0 && <Form.Select
                        placeholder="Select route"
                        fluid
                        search
                        selection
                        selectOnNavigation={false}
                        clearable
                        icon={routeState.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={routeState.options}
                        value={routeState.selectedValue}
                        onChange={handleRouteChange}
                        width={3}
                    />}
                    {routeState.selectedValue && <Form.Select
                        placeholder="Direction"
                        fluid
                        search
                        selection
                        selectOnNavigation={false}
                        clearable
                        icon={directionState.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={directionState.options}
                        value={directionState.selectedValue}
                        onChange={handleDirectionChange}
                        width={4}
                    />}
                </Form.Group>
                {!sourceData
                    ? null
                    : reportState === noReportDataState
                        ? <Message info>
                            <Message.Header>No report data</Message.Header>
                        </Message>
                        : reportState && reportState?.hourlyData && <>
                            <Form.Group className="rowGroup">
                                <Form.Field width={16}>
                                    <label className="categoryHeader">Average Accuracy by Range and Prediction Model</label>
                                    <AccuracyBarChart
                                        data={reportState.ditribution}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={16}>
                                    <label className="categoryHeader">Accuracy History by Prediction Model for {reportState.overallDate}</label>
                                    <AccuracyLineChart
                                        mode={modeState.selectedValue}
                                        data={reportState.overallData}
                                    />
                                </Form.Field>
                            </Form.Group>
                            {reportState.hourlyData &&
                                <Form.Group> {/* className="rowGroup"> */}
                                    <Form.Field width={16}>
                                        <label className="categoryHeader">Accuracy by Hour for {reportState.hourlyDate}</label>
                                        <AccuracyByTime
                                            data={reportState.hourlyData}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            }
                        </>
                }
            </Form>
        </BlockUi >
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(PredictionAccuracyForm);