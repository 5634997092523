import { VehiclePositionState } from '../../../types/busHistoryTypes';
import { AgencyModel } from './agencyModel';
import { BphDirectionsState, BphRoutesState, BphSliderState, DateTimeState, StopPredictionsState } from './bphStates';
import { BphDirection, BphRouteModel, BphStopModel, BphStopsStateType, StopsSearchStateType } from './BphTypes';
import { DateTime } from './dateTime';
import { SliderInfoProps as BphSliderInfoProps } from './SliderInfoProps';

export enum ActionType {
    DEFAULT,

    SET_MAP_READY = 'SET_MAP_READY',
    SET_AGENCY = 'SET_AGENCY',

    SET_DATE_TIME = 'SET_DATE_TIME',
    SET_ROUTES_LOADING = 'SET_ROUTES_LOADING',
    SET_ROUTES_STATE = 'SET_ROUTES_STATE',
    SET_DIRECTIONS_LOADING = 'SET_DIRECTIONS_LOADING',
    SET_DIRECTIONS_STATE = 'SET_DIRECTIONS_STATE',
    SET_STOPS_LOADING = 'SET_STOPS_LOADING',
    SET_STOPS_STATE = 'SET_STOPS_STATE',
    SET_STOP_SEARCH_STATE = 'SET_STOP_SEARCH_STATE',
    SET_NO_STOPS_MESSSAGE = 'SET_NO_STOPS_MESSSAGE',
    SET_STOPS_PREDICTIONS = 'SET_STOPS_PREDICTIONS',
    SET_DIRECTIONS_INFO = 'SET_DIRECTIONS_INFO',
    SET_HISTORY_LOADING = 'SET_HISTORY_LOADING',
    SET_BUS_POSITIONS = 'SET_BUS_POSITIONS',
    SET_SCHEDULE_DATES = 'SET_SCHEDULE_DATES',
    SET_SLIDER_OPTIONS = 'SET_SLIDER_OPTIONS',
    SET_SLIDER_INFO = 'SET_SLIDER_INFO',
}


export type BusPositionHistoryAction = {
    type: ActionType,
    payload?: null | boolean | string | Microsoft.Maps.LocationRect
        | DateTimeState
        | AgencyModel | BphRoutesState | BphRouteModel
        | BphDirectionsState | BphDirection[]
        | BphStopsStateType | BphStopModel | StopsSearchStateType
        | VehiclePositionState[] | DateTime[] | BphSliderInfoProps | BphSliderState
        | StopPredictionsState
};