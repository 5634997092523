import { ChartOptions, TooltipItem } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineDatePoint } from '../../../types/chartTypes';
import { TwelveHoursStatusItem } from '../../../types/otpTypes';

const chartSettings = {
    labels: [] as Date[],
    datasets: [
        {
            label: 'Not Reporting',
            lineTension: 0.1,
            backgroundColor: 'rgba(255, 131, 104, 0.8)',
            borderColor: 'rgba(255, 131, 104, 0.8)',
            fill: true,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(255, 131, 104, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(255, 131, 104, 0.8)',
            pointHoverBorderColor: 'rgba(255, 131, 104, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
        {
            label: 'Reporting',
            lineTension: 0.1,
            backgroundColor: 'rgba(130, 225, 128, 0.8)',
            borderColor: 'rgba(130, 225, 128, 0.8)',
            fill: true,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(130, 225, 128, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(130, 225, 128, 0.8)',
            pointHoverBorderColor: 'rgba(130, 225, 128, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'time' as const,
            time: {
                parser: 'HH:mm a',
                unit: 'hour',
            },
        },
        y: {
            beginAtZero: true,
            stacked: true,
        },
    },
    spanGaps: false,
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                boxWidth: 10,
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 110,
            callbacks: {
                title: function (tooltipItem: TooltipItem<'line'>[]) {
                    return moment(tooltipItem[0].parsed.x).format('hh:mm A');
                },
                labelColor: function (tooltipItem: TooltipItem<'line'>) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            borderColor: 'rgba(255, 131, 104, 0.8)',
                            backgroundColor: 'rgba(255, 131, 104, 0.8)',
                        };
                    } else {
                        return {
                            borderColor: 'rgba(130, 225, 128, 0.8)',
                            backgroundColor: 'rgba(130, 225, 128, 0.8)',
                        };
                    }
                },
            },
        },
    },
};

interface Props {
    statusItems: TwelveHoursStatusItem[]
}

const HistoricalDataStatusChart: React.FC<Props> = ({ statusItems }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const orderedChartData = statusItems.sort((a, b) => new Date(a.reportTime).getTime() - new Date(b.reportTime).getTime());
        const labels = orderedChartData.map(d => new Date(d.reportTime));

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0],
                data: orderedChartData.map(d => {
                    return {
                        x: new Date(d.reportTime),
                        y: d.notReportedCount,
                    };
                }),
            },
                {
                ...prevState.datasets[1],
                data: orderedChartData.map(d => {
                    return {
                        x: new Date(d.reportTime),
                        y: d.reportedCount,
                    };
                }),
            },
            ],
        }));

        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        label: function (tooltip: TooltipItem<'line'>) {
                            const total = statusItems[tooltip.dataIndex]?.notReportedCount + statusItems[tooltip.dataIndex]?.reportedCount;
                            const lineType = tooltip.datasetIndex === 0 ? 'Not Reporting' : 'Reporting';
                            return `${lineType}: ${tooltip.parsed.y} (${Math.round(tooltip.parsed.y * 100 / total)}%)`;
                        },
                    },
                },
            },
        }));
    }, [statusItems]);

    return (
        <Line
            data={dataState}
            height={80}
            options={optionsState}
        />
    );
};

export default HistoricalDataStatusChart;
