
import { InstanceHelper } from '../InstanceHelper';
import { BphStopModel } from './BphTypes';

export class StopsMapDisplayPool {
    private _selected: BphStopModel | null = null;
    private _directionStops: BphStopModel[] = [];
    public isDisplayDirectionStops = false;

    constructor(private owner: InstanceHelper) {
    }

    public empty() {
        this.select(null);
    }

    public select(stop: Nullable<BphStopModel>): void {
        if (this._selected === stop) return;
        if (this._selected) {
            this.owner._map?.entities.remove(this._selected.pushpin);
        }
        this._selected = stop;
        if (this._selected) {
            this.owner._map?.entities.add(this._selected.pushpin);
            if (this.isDisplayDirectionStops) {
                this.removeDirectionStops();
                this.displayDirectionStops();
            }
        }
    }

    public setDirectionStops(stops: BphStopModel[]) {
        this._directionStops = stops;
    }

    public switchDirectionStops(display: boolean) {
        this.isDisplayDirectionStops = display;
        if (display)
            this.displayDirectionStops();
        else
            this.removeDirectionStops();
    }

    private displayDirectionStops() {
        this._directionStops.forEach(stop => {
            if (!this._selected || this._selected.stopId !== stop.stopId) {
                this.owner._map?.entities.add(stop.pushpin);
                if (this.owner._map)
                    stop.infobox.setMap(this.owner._map);
            }
        });
    }

    private removeDirectionStops() {
        this._directionStops.forEach(stop => {
            this.owner._map?.entities.remove(stop.pushpin);
            stop.infobox.setMap(null as any as Microsoft.Maps.Map);
            stop.infobox.setOptions({ visible: false });
        });
    }
}