import { TooltipItem } from 'chart.js';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { BusAnalysisReport } from '../../../types/anomaliesTypes';
import { LineDatePoint } from '../../../types/chartTypes';

const color1 = 'rgb(229, 92, 85)';
const color2 = 'rgb(147, 192, 32)';
const color2b = 'rgba(147, 192, 32, 0.2)';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Actual Travel Time (min)',
            unit: '',
            backgroundColor: color1,
            borderColor: color1,
            fill: false,
            borderWidth: 2,
            data: [] as LineDatePoint[],
        },
        {
            label: 'Schedule Travel Time (min)',
            unit: '',
            fill: false,
            backgroundColor: color2b,
            borderColor: color2,
            borderWidth: 1,
            lineTension: 0,
            data: [] as LineDatePoint[],
        },
    ],
};

const options = {
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'day' as const,
                displayFormats: {
                    day: 'M/D/YYYY',
                },
            },
            ticks: {
                autoSkip: false,
                stepSize: 1,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            beginAtZero: false,
            ticks: {},
            position: 'left' as const,
        },
    },

    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                boxWidth: 10,
                usePointStyle: true,
            },
        },
        crosshair: {
            line: {
                color: '#F66',  // crosshair line color
                width: 1,       // crosshair line width
            },
            sync: {
                enabled: false,
            },
            zoom: {
                enabled: false,                                      // enable zooming
            },
            snap: {
                enabled: true,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            callbacks: {
                label: function (tooltipItem: TooltipItem<'line'>) {
                    const dataset = chartSettings.datasets[tooltipItem.datasetIndex];
                    return `${dataset.label}: ${tooltipItem.parsed.y} ${dataset.unit}`;
                },
            },
        },
    },
    maintainAspectRatio: false,
    interaction: {
        mode: 'x' as const,
        intersect: false,
    },
};

const BusAnalysisScheduledActualTime: React.FC<{ chartData: BusAnalysisReport[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: chartData.map(d => {
                    return {
                        x: d.reportDate,
                        y: Math.round(d.actualSec / 60 * 10) / 10,
                    };
                }),
            }, {
                ...prevState.datasets[1],
                data: chartData.map(d => {
                    return {
                        x: d.reportDate,
                        y: Math.round(d.scheduledSec / 60 * 10) / 10,
                    };
                }),
            }],
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState}
            options={options}
            plugins={[CrosshairPlugin]}
        />
    );
};

export default BusAnalysisScheduledActualTime;