import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import BusHistorySliderControl from '../../../bushistory/BusHistorySliderControl';
import { secondsIntervalToString } from '../../../bushistory/types/externalFunctions';

type Styles = {
    form: React.CSSProperties,
    timeSpan: React.CSSProperties,
};
const styles: Styles = {
    form: {
        paddingRight: 3,
    },
    timeSpan: {
        minWidth: 120,
        paddingLeft: 10,
        paddingRight: 10,
    },
};

interface Props {
    maxValue: number;
    sliderValue: number;
    currentTime?: string | null;
    currentDelay?: number | null;
    handleSliderChange: (value: number) => void;
}

export const VehicleHistorySlider = ({ maxValue, sliderValue, currentTime, currentDelay, handleSliderChange }: Props) => {

    const [sliderValueState, setSliderValueState] = useState<number>(sliderValue);

    useEffect(() => {
        setSliderValueState(sliderValue);
    }, [sliderValue]);

    const handleChange = (_: Event, value: number | number[], __: number) => {
        if (Array.isArray(value))
            console.error('BusHistory: array value is not supported');
        else {
            setSliderValueState(value);
            if (handleSliderChange)
                handleSliderChange(value);
        }
    };

    const handleLeft = () => {
        setSliderValueState(prevState => {
            if (prevState > 0) {
                const sliderValue = prevState - 1;
                if (handleSliderChange)
                    handleSliderChange(sliderValue);
                return sliderValue;
            } else {
                return prevState;
            }
        });
    };

    const handleRight = () => {
        setSliderValueState(prevState => {
            if (prevState < maxValue) {
                const sliderValue = prevState + 1;
                if (handleSliderChange)
                    handleSliderChange(sliderValue);
                return sliderValue;
            } else {
                return prevState;
            }
        });
    };

    const timeValue = currentTime
        ? moment.parseZone(currentTime).format('h:mm A')
        :null;
    const delayValue = typeof currentDelay !== 'number'
        ? null
        : secondsIntervalToString(currentDelay);

    return (
        <Form>
            <BusHistorySliderControl
                valueLabelDisplay="off"
                aria-label="pretto slider"
                min={0}
                max={maxValue || 0}
                onChange={handleChange}
                step={1}
                value={sliderValueState || 0}
            />
            <Form.Group inline style={styles.form} >
                <Button icon="caret square left" onClick={handleLeft} />
                <Button icon="caret square right" onClick={handleRight} />
                {timeValue !== null &&
                    <span style={styles.timeSpan}>Time: <b>{timeValue}</b></span>
                }
                {delayValue !== null &&
                    <span>Delay from schedule: <b>{delayValue}</b></span>
                }
            </Form.Group>
        </Form>
    );
};
