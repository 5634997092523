import { SecondsIntervalOptions } from './BphTypes';

export function twoDigitString(value: number) {
    return ('0' + value).substr(-2);
}

/** Converts time interval in seconds to diaplay string:
 *
 * no options:
 * * `"-2.03:59:58"` - 2 days 3 hours 59 minutes and 58 seconds ago
 * * `"+7:02:03"` - after 7 hours 2 minutes and 3 seconds
 * * `"-05:46"` - 5 minutes and 46 seconds ago
 * * `"+00:15"` - after 15 seconds
 *
 * using options `{ negativeSign: " behind", positiveSign: " ahead", position: "after" }`:
 * * `"2.03:59:58 bihind"`
 * * `"7:02:03 ahead"`
 * * `"05:46 behind"`
 * * `"00:15 ahead"`
 */
export function secondsIntervalToString(interval: number, options?: SecondsIntervalOptions) {
    if (interval === 0) return '00:00';

    let sign: string;
    if (interval > 0) {
        sign = (options && options?.positiveSign) || '+';
    } else {
        sign = (options && options?.negativeSign) || '-';
        interval = -interval;
    }

    const seconds = twoDigitString(interval % 60);
    interval = Math.floor(interval / 60);
    const minutes = twoDigitString(interval % 60);
    interval = Math.floor(interval / 60);
    const hours = (interval % 24);
    const days = Math.floor(interval / 24);

    let result = '';
    if (days > 0) {
        result += days.toFixed() + '.';
        result += twoDigitString(hours) + ':';
    } else if (hours > 0) {
        result += hours.toFixed() + ':';
    }
    result += minutes + ':' + seconds;
    if (options && options?.position === 'after')
        return result + sign;
    else
        return sign + result;
}