import { ChartOptions, TooltipItem } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarChartDataPoint } from '../../../types/chartTypes';
import { KeyValuePair } from '../../../types/types';

interface ChartDataSet {
    data: BarChartDataPoint[],
    backgroundColor: string;
    borderWidth: number;
    label: string;
}

interface ChartSettings {
    datasets: ChartDataSet[],
}

const chartSettings: ChartSettings = {
    datasets: [],
};

const options: ChartOptions<'bar'> = {
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'day',
            },
            ticks: {
                stepSize: 1,
                autoSkip: false,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            beginAtZero: false,
            ticks: {
                autoSkip: false,
                maxTicksLimit: 5,
                callback: function (tickValue: string | number) {
                    return `${tickValue}%`;
                },
            },
            suggestedMin: 100,
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                title: function (tooltipItems: TooltipItem<'bar'>[]) {
                    return moment(tooltipItems[0].parsed.x).format('YYYY-MM-DD');
                },
                label: function (tooltipItem) {
                    return `Performance: ${tooltipItem.parsed.y}%`;
                },
            },
        },
    },
};

const PerformanceByDatesChart: React.FC<{
    performanceByDates: Array<KeyValuePair<Date, number>>
}> = ({ performanceByDates }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const orderedData = performanceByDates.sort((a, b) => new Date(a.key).getTime() - new Date(b.key).getTime());
        const weekdayValues: BarChartDataPoint[] = [], weekendValues: BarChartDataPoint[] = [];
        for (const data of orderedData) {
            if ([0, 6].includes(moment(data.key).weekday())) {
                weekendValues.push({
                    x: moment(data.key).format('YYYY-MM-DD'),
                    y: data.value,
                });
            } else {
                weekdayValues.push({
                    x: moment(data.key).format('YYYY-MM-DD'),
                    y: data.value,
                });
            }
        }
        setDataState(prevState => {
            const datasets = [];
            if (weekdayValues.length > 0) {
                datasets.push({
                    data: weekdayValues,
                    backgroundColor: 'rgba(130, 225, 128, 0.5)',
                    borderWidth: 0,
                    label: 'weekdays',
                });
            }
            if (weekendValues.length > 0) {
                datasets.push({
                    data: weekendValues,
                    backgroundColor: 'rgba(255, 131, 104, 0.5)',
                    borderWidth: 0,
                    label: 'weekends',
                });
            }
            return {
                ...prevState,
                datasets,
            };
        });
        let minValue = 100;
        if (weekdayValues.length > 0 && weekendValues.length > 0) {
            minValue = Math.min(...[...weekdayValues, ...weekendValues].map(v => v.y));
        } else if (weekdayValues.length > 0) {
            minValue = Math.min(...weekdayValues.map(v => v.y));
        } else {
            minValue = Math.min(...weekendValues.map(v => v.y));
        }
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                y: {
                    ...prevState.scales?.y,
                    suggestedMin: minValue - 1,
                },
            },
        }));
    }, [performanceByDates]);

    return (
        <Bar
            data={dataState}
            options={optionsState}
            height={70}
        />
    );
};

export default PerformanceByDatesChart;