import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { CurrentTrip } from '../../../types/otpTypes';
import { TableSortProperties } from '../../../types/types';
import DelayedTripsTableRow from './DelayedTripsTableRow';

const styles = {
    statisticsTable: {
        display: 'block',
        maxHeight: '451px',
        overflowY: 'scroll',
    } as React.CSSProperties,
};

interface Props {
    tableDataState: TableSortProperties<CurrentTrip>;
    selectedTrip: CurrentTrip | undefined;
    delayedTripRowHandler: (selectedTrip: CurrentTrip) => Promise<void>;
    columnSortHandler: (column: string) => void;
}

const DelayedTripsTable: React.FC<Props> = ({ tableDataState, selectedTrip, delayedTripRowHandler, columnSortHandler }) => {
    const { column, data, direction } = tableDataState;
    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.statisticsTable} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'delaySeconds' ? direction : undefined}
                            onClick={() => columnSortHandler('delaySeconds')}>
                            Most Recent Delay
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'routeNumber' ? direction : undefined}
                            onClick={() => columnSortHandler('routeNumber')}>
                            Route
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'tripStartTime' ? direction : undefined}
                            onClick={() => columnSortHandler('tripStartTime')}>
                            Trip
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'vehicleId' ? direction : undefined}
                            onClick={() => columnSortHandler('vehicleId')}>
                            Bus Id
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}
                            sorted={column === 'directionVariantName' ? direction : undefined}
                            onClick={() => columnSortHandler('directionVariantName')}>
                            Direction
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((row, index) => (
                        <DelayedTripsTableRow key={index} row={row} selectedTrip={selectedTrip} delayedTripRowHandler={delayedTripRowHandler} />
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default DelayedTripsTable;