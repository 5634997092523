import moment from 'moment';
import React from 'react';
import { Icon, List, Message, Modal, SemanticCOLORS, SemanticICONS, Table } from 'semantic-ui-react';
import { ErrorContext } from '../../types/apiTypes';
import { BusPositionHistoryContext } from './BusHistoryContext';
import { BusPositionHistoryContextType } from './types/BphTypes';
import { StopPredictionEntry } from './types/stopPredictionEntry';

function getCurrentTimeString(context: BusPositionHistoryContextType): string | undefined {
    if (!context.sliderInfo) return;
    return moment.parseZone(context.sliderInfo.vehiclePosition.reportTimeAtz).format('yyyy-MM-DDTHH:mm:ss');
}

export function StopPredictionsDetailedView() {
    const context = React.useContext(BusPositionHistoryContext);

    if (!context.stopsPredictions) return null;
    if (context.stopsPredictions.error)
        return (<Message warning>{context.stopsPredictions.error.message || 'unknnown error occured'}</Message>);

    if (!context.stopsPredictions.plist || context.stopsPredictions.plist.length === 0)
        return (<Message info>no predictions found</Message>);

    const currentTime = getCurrentTimeString(context) || '9999';

    const activePredictions = context.stopsPredictions.onTime(currentTime);

    return (
        <div style={{ overflowY: 'auto' }}>
            <Table size="small" celled striped>
                <Table.Header>
                    <Table.Row>
                        <th colSpan={4}>Predictions ({context.stopsPredictions.plist?.length}) at {currentTime}</th>
                    </Table.Row>
                    <Table.Row>
                        <td colSpan={4}>
                            <List horizontal>
                                {activePredictions.map((e: StopPredictionEntry, i: number) => {
                                    return (
                                        <List.Item key={'pa' + i}>
                                            <List.Content>
                                                <List.Header>{e.vehicleId}</List.Header>
                                                {e.active?.predictedTime.substr(-8)}
                                            </List.Content>
                                        </List.Item>
                                    );
                                })}
                            </List>
                        </td>
                    </Table.Row>
                    <Table.Row>
                        <th>at</th>
                        <th>scheduled</th>
                        <th>predicted</th>
                        <th>actual</th>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {context.stopsPredictions.plist.map((e: StopPredictionEntry, i: number) => {
                        return (
                            <React.Fragment key={'pg' + i}>
                                <Table.Row>
                                    <td colSpan={4}>{e.tripId} : {e.vehicleId}</td>
                                </Table.Row>
                                {e.predictions.map((p, i) => {
                                    let rowClass: string;
                                    if (p === e.active)
                                        rowClass = 'positive';
                                    else if (p.predictionTime <= currentTime)
                                        rowClass = 'disabled';
                                    else
                                        rowClass = 'negative';
                                    return (
                                        <Table.Row key={'pe' + i} className={rowClass}>
                                            <td>{p.predictionTime}</td>
                                            <td>{p.scheduledTime}</td>
                                            <td>{p.predictedTime}</td>
                                            <td>{p.actualTime}</td>
                                        </Table.Row>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </Table.Body>
            </Table>
        </div>
    );
}

interface ModalInfoProps {
    icon: SemanticICONS;
    iconColor: SemanticCOLORS;
    header?: string;
    content?: string;
}

function ModalInfo(props: ModalInfoProps) {
    return (
        <Modal
            trigger={<Icon name={props.icon} circular color={props.iconColor} inverted />}
            size="tiny"
            dimmer="inverted"
            centered={false}
            header={props.header}
            content={props.content}
            actions={['OK']}
            style={{ textAlign: 'center' }}
        />
    );
}

function ErrorView(props: { error?: ErrorContext }) {
    const header = props.error?.statusCode
        ? `Error ${props.error.statusCode}`
        : `Error`;
    return (
        <div className="body message">
            <ModalInfo
                icon="exclamation" iconColor="red" header={header}
                content={props.error?.message || 'unknnown error occured'}
            />
        </div>
    );
}

function NotFoundView() {
    return (
        <div className="body message">
            <ModalInfo icon="info" iconColor="blue" header={'No predictions found'} />
        </div>
    );
}

function PredictionsList(props: { active?: StopPredictionEntry[] }) {
    if (!props.active || props.active.length === 0)
        return null;
    return (
        <div className="body" style={{ flexShrink: 0 }}>
            {props.active.map((e, i) => {
                const text = (moment(e.active?.predictedTime).format('LTS'));
                return (
                    <div className="prediction" key={i}>
                        <div>{e.vehicleId}</div>
                        {text}
                    </div>
                );
            })}
        </div>
    );
}

export default function StopPredictionsView() {
    const context = React.useContext(BusPositionHistoryContext);
    if (!context.stopsPredictions) {
        return null;
    }
    if (context.stopsPredictions.error) {
        return (<ErrorView error={context.stopsPredictions?.error} />);
    }
    if (!context.stopsPredictions.plist || context.stopsPredictions.plist.length === 0) {
        return (<NotFoundView />);
    }
    const currentTime = getCurrentTimeString(context);
    const activePredictions = currentTime ? context.stopsPredictions?.onTime(currentTime) : undefined;
    return (<PredictionsList active={activePredictions} />);
}
