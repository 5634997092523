import { KeyValuePair } from './types';

export const AllDays = 'all';
export const BusinessDays = 'businessDays';
export const Weekend = 'weekend';
export const routeLevelTableType = 'routeLevelTable';
export const aggregatedTripsLevelType = 'aggregatedTripsLevelType';
export const tripsDetailsLevelType = 'tripsDetailsLevelType';

export type DaysFilterType = typeof AllDays | typeof BusinessDays | typeof Weekend;

export interface TripLevelPerformanceRequest extends BasePerformanceRequest {
    routeName: string;
    directionVariantInternalId: string;
}
export type TripDetailsPerformance = {
    serviceDate: Date;
    tripInternalId: string;
    tripId: string;
    vehicleId: string;
    tripActualTravelSec: number;
    tripScheduledTravelSec: number;
    performance: number;
    averageStopSec: number;
    totalInMotionSec: number;
    startDelaySec: number;
    totalStopSec: number;
    startDetected: number;
    finishDetected: number;
    tripScheduledStart: Date;
    tripScheduledFinish: Date;
    tripActualStart: Date;
    tripActualFinish: Date;
    [key: string]: unknown;
};
export interface BasePerformanceRequest {
    agencyId: string;
    startDate: string;
    endDate: string;
    daysFilter: typeof AllDays | typeof BusinessDays | typeof Weekend;
}
export type RouteLevelPerformance = {
    routeName: string;
    cardinalDirection: string;
    directionVariantInternalId: string;
    performance: number;
    fastestTripTime: number;
    dwellTime: number;
    inMotion: number;
    scheduledTripTime: number;
};
export type OverallPerformance = {
    lastAvailableDatePerformance: number | null;
    lastAvailableDate: Date | null;
    performanceByDates: Array<KeyValuePair<Date, number>>;
};
export interface AnalysisByPercentilesRequest {
    agencyId: string;
    routeName: string;
    startDate: string;
    endDate: string;
}
export type AnalysisByPercentiles = {
    serviceDate: Date;
    tripId: string;
    internalTripId: string;
    directionVariantName: string;
    vehicleId: string;
    scheduledStartTime: Date;
    scheduledEndTime: Date;
    scheduledDelayToNextTrip: number;
    scheduledRunTime: number;
    tripDelaySec: number;
};
export interface RunningTimesComparisonRequest {
    agencyId: string;
    directionVariantInternalId: string;
    startDate1: string;
    endDate1: string;
    startDate2: string;
    endDate2: string;
}
export type RunningTimesComparison = {
    tripsData1: ArrivalsComparison[];
    tripsData2: ArrivalsComparison[]
};
export type ArrivalsComparison = {
    stopId: string;
    stopSequence: number;
    stopName: string;
    traveledKm: number;
    travelSec: number;
};
export type RunningTimesComparisonChartType = {
    runningTimesComparisonData: RunningTimesComparison | undefined;
    line1Label: string;
    line2Label: string;
};
export interface BaseItemType {
    min: number;
    median: number;
    max: number;
    /**
     * values of the raw items used for rendering jittered background points
     */
    items?: number[];
}
export interface BoxPlotItemType extends BaseItemType {
    q1?: number;
    q3?: number;
    whiskerMin?: number;
    whiskerMax?: number;
    /**
     * list of box plot outlier values
     */
    outliers?: number[];
}
export interface TransitMeansOverall {
    agencyId: string;
    stopSequence: number;
    traveledKm: number;
    avgTimeCoeffPcnt: number;
}
export interface TravelTimeBetweenStops {
    agencyId: string;
    serviceDate: Date;
    routeShortName: string;
    scheduledSec: number;
    actualSec: number;
}
