import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { crosshairOption } from '../../../types/chartjs-plugin-crosshair/CrosshairOption';

const chartSettings = {
    labels: [] as string[][],
    datasets: [
        {
            label: 'GTFS',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgb(66, 220, 198)',
            pointBackgroundColor: 'rgb(66, 220, 198)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(66, 220, 198)',
            pointHoverBorderColor: 'rgba(66, 220, 198)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: 'TAIP',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#C0E139',
            borderColor: '#C0E139',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: '#C0E139',
            pointBackgroundColor: '#C0E139',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#C0E139',
            pointHoverBorderColor: '#C0E139',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            display: false,
            labels: [],
            grid: {
                drawOnChartArea: false,
            },
            ticks: {
                maxRotation: 0,
                autoSkip: false,
            },
        },
        x2: {
            grid: {
                display: false,
            },
            border: {
                display: false,
            },
            ticks: {
                autoSkip: false,
                maxRotation: 0,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                autoSkip: false,
                stepSize: 1,
                maxTicksLimit: 10,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                usePointStyle: true,
                boxWidth: 10,
            },
        },
        tooltip: {
            intersect: false,
            callbacks: {
                title: function () {
                    return '';
                },
                label: function (tooltipItem) {
                    let lineType = 'GTFS';
                    if (tooltipItem.datasetIndex === 1) {
                        lineType = 'TAIP';
                    }
                    return `${lineType}: ${tooltipItem.parsed.y} %`;
                },
                labelColor: function (tooltipItem) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            backgroundColor: '#42DCC6',
                            borderColor: '#42DCC6',
                        };
                    } else {
                        return {
                            backgroundColor: '#C0E139',
                            borderColor: '#C0E139',
                        };
                    }
                },
            },
        },
    },
};

interface Props {
    chartData: {
        gtfsValue: number;
        taipValue: number;
        period: string
    }[];
    axesLabelSign?: string;
}

const TrendsReportingBusesChart: React.FC<Props> = ({
        chartData,
        axesLabelSign,
    }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);
    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period.split(/(?=-)/g)),
            datasets: [{
                ...prevState.datasets[0],
                data: chartData.map(d => {
                    return {
                        x: d.period,
                        y: d.gtfsValue,
                    };
                }),
            },
                {
                    ...prevState.datasets[1],
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.taipValue,
                        };
                    }),
                }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                y: {
                    ...prevState.scales?.y,
                    ticks: {
                        ...prevState.scales?.y?.ticks,
                        callback: function (tickValue: number | string) {
                            return axesLabelSign ? `${tickValue} ${axesLabelSign}` : `${tickValue}`;
                        },
                    },
                },
            },

        }));
    }, [chartData]);

    return (
        <Line
            data={dataState}
            height={150}
            options={optionsState}
            plugins={[{ id: 'crosshair', ...crosshairOption }]}
        />
    );
};

export default TrendsReportingBusesChart;
