import React from 'react';
import { bphReducer } from './bphReducer';
import { cast, initializeState, initialState } from './InstanceHelper';
import { AgencyModel } from './types/agencyModel';
import { BphDirectionModel, BphRouteModel, BphStopModel, BusPositionHistoryContextType } from './types/BphTypes';
import { Optional } from './types/Optional';


export const BusPositionHistoryContext = (React as any).createContext() as React.Context<BusPositionHistoryContextType>;

export const BusPositionHistoryState = (props: React.Props<{}>) => {
    const [state, dispatch] = React.useReducer(bphReducer, initialState, initializeState);
    cast(state).dispatch = dispatch;

    const onMapReady = (div: HTMLDivElement): void => {
        cast(state).onMapReady(div);
    };
    const setAgency = (agency: AgencyModel | null) => {
        cast(state).setAgency(state, agency);
    };
    const updateDateTime = (date: Optional<string>, time: Optional<string>): boolean => {
        return cast(state).updateDateTime(state, date, time);
    };
    const selectRoute = (value?: Nullable<BphRouteModel>): boolean => {
        return cast(state).selectRoute(state, value);
    };
    const selectDirection = (value?: Nullable<BphDirectionModel>): boolean => {
        return cast(state).selectDirection(state, value);
    };
    const selectStop = (value?: Nullable<BphStopModel>): boolean => {
        return cast(state).selectStop(state, value);
    };
    const setStopsSearchString = (value?: Nullable<string>): void => {
        return cast(state).setStopsSearchString(state, value);
    };
    const onSliderTimeChanged = (value: number): void => {
        cast(state).onSliderTimeChanged(state, value);
    };
    const onDisplayDirectionStopsChanged = (value: boolean): void => {
        cast(state).onDisplayDirectionStopsChanged(value);
    };

    React.useEffect(() => {
        cast(state).onSelectedDateChanged(state);
    }, [state.agency, state.dateTime.selectedDate, state.mapReady]);

    React.useEffect(() => {
        cast(state).onSelectedRouteChanged(state);
    }, [state.routesState.selectedRoute]);

    React.useEffect(() => {
        cast(state).onSelectedStopChanged(state);
    }, [state.stopsState.selectedStop]);

    React.useEffect(() => {
        cast(state).onSelectedDirectionChanged(state);
    }, [state.directionsState.selectedDirection]);

    React.useEffect(() => {
        cast(state).onStopAndDirectionChanged(state);
    }, [state.stopsState.selectedStop, state.directionsState.selectedDirection]);

    React.useEffect(() => {
        if (!state.agency) return;
        cast(state).onStopSearchParamsChanged(state);
    }, [state.stopsSearchState]);

    React.useEffect(() => {
        cast(state).onBusPositionsChanged(state);
    }, [state.busPositionsState]);

    return (
        <BusPositionHistoryContext.Provider value={{
            ...state,
            onMapReady,
            setAgency,
            updateDateTime,
            selectRoute,
            selectDirection,
            selectStop,
            setStopsSearchString,
            onSliderTimeChanged,
            onDisplayDirectionStopsChanged,
        }}
        >
            {props.children}
        </BusPositionHistoryContext.Provider>
    );
};
