import BlockUi from '@availity/block-ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dropdown, DropdownOnSearchChangeData, DropdownProps, Form, Header, Loader, Message } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getStops, getTravelTimeBetweenStops } from '../../../actions/runningTimeAnalyticsActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { TravelTimeBetweenStops } from '../../../types/runningTimeAnalyticsTypes';
import { DropDownStateType } from '../../../types/types';
import Utils from '../../../utilities/utils';
import TravelTimeBetweenStopsChart from './TravelTimeBetweenStopsChart';

const initialStopsState: DropDownStateType = {
    options: [],
    selectedValue: '',
};

const styles = {
    stopsDropdown1: {
        marginLeft: '8px',
    } as React.CSSProperties,
    stopsDropdown2: {
        marginLeft: '20px',
    } as React.CSSProperties,
    statistic: {
        paddingTop: '32px',
    } as React.CSSProperties,
    chartContainer: {
        width: '88%',
        display: 'inline-block',
    } as React.CSSProperties,
    performanceMessageContainer: {
        paddingLeft: '0px',
    } as React.CSSProperties,
    stopsLoader: {
        marginLeft: '10px',
    } as React.CSSProperties,
};

interface MatchParams {
    stopId1: string;
    stopId2: string;
}
interface StopsSearchCriteria {
    searchQuery: string;
    searchTime: number;
    setStopsState: React.Dispatch<React.SetStateAction<typeof initialStopsState>>;
    onSelectStopCode: (stopCode: string) => void,
    stopLoading: React.Dispatch<React.SetStateAction<boolean>>,
    exactMatch?: boolean,
}
interface Props extends RouteComponentProps<MatchParams> {
    agency: AgencyStateType | undefined;
    stopId1Property: string;
    stopId2Property: string;
}
interface TravelTimeForRoute {
    routeName: string;
    travelTimeData: TravelTimeBetweenStops[];
}

const TravelTimeBetweenStopsForm: React.FC<Props> = ({ agency, match }) => {
    const stopId1Property = match.params.stopId1;
    const stopId2Property = match.params.stopId2;
    const [stopsSearchCriteriaState, setStopsSearchCriteriaState] = useState<StopsSearchCriteria>();
    const [stopsState1, setStopsState1] = useState(initialStopsState);
    const [stopsState2, setStopsState2] = useState(initialStopsState);
    const [stopsNoResultMessageState, setStopsNoResultMessageState] = useState<string>();
    const [travelTimeForRoutesState, setTravelTimeForRoutesState] = useState<TravelTimeForRoute[]>([]);
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [stopLoadingState1, setStopLoadingState1] = useState(false);
    const [stopLoadingState2, setStopLoadingState2] = useState(false);

    useEffect(() => {
        if (stopId1Property && stopId2Property) {
            (async () => {
                await updateStop({
                    searchQuery: stopId1Property,
                    searchTime: new Date().getTime(),
                    setStopsState: setStopsState1,
                    onSelectStopCode: (stopCode: string) => updatePerformanceValue(stopCode, stopsState2.selectedValue || ''),
                    stopLoading: setStopLoadingState1,
                    exactMatch: true,
                });
                await updateStop({
                    searchQuery: stopId2Property,
                    searchTime: new Date().getTime(),
                    setStopsState: setStopsState2,
                    onSelectStopCode: (stopCode: string) => updatePerformanceValue(stopsState1.selectedValue || '', stopCode),
                    stopLoading: setStopLoadingState2,
                    exactMatch: true,
                });
                updatePerformanceValue(stopId1Property, stopId2Property);
            })();
        }
    }, [agency?.id]);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (stopsSearchCriteriaState && stopsSearchCriteriaState.searchTime) {
                const currentTime = new Date().getTime();
                if ((currentTime - stopsSearchCriteriaState.searchTime) / 1000 > 1) {
                    await updateStop(stopsSearchCriteriaState);
                }
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [stopsSearchCriteriaState]);

    const updateStop = async ({ stopLoading, setStopsState, onSelectStopCode, searchQuery, exactMatch }: StopsSearchCriteria) => {
        if (agency && agency.id) {
            try {
                stopLoading(true);
                const response = await getStops(agency.id, searchQuery, Boolean(exactMatch));
                const stops = response && response.length > 0 ? response : [];
                if (stops.length === 1 && !stops[0].key) {
                    setStopsState(initialStopsState);
                    setStopsNoResultMessageState(stops[0].value);
                } else {
                    const selectedStopKey = stops.length === 1 ? stops[0].key : stops.find(s => s.key === searchQuery)?.key;
                    setStopsState({
                        options: stops.map(s => { return { value: s.key, text: s.value }; }),
                        selectedValue: selectedStopKey,
                    });
                    if (selectedStopKey)
                        onSelectStopCode(selectedStopKey);
                }
            } catch {
                setStopsState(initialStopsState);
            } finally {
                stopLoading(false);
            }
        } else {
            setStopsState(initialStopsState);
        }
    };

    const handleStopChange1 = (_event: React.SyntheticEvent<HTMLElement, Event>, { value: stopId }: DropdownProps) => {
        setStopsState1(prevState => ({
            ...prevState,
            selectedValue: stopId as string,
        }));
        if (stopsState2.selectedValue)
            updatePerformanceValue(stopId as string, stopsState2.selectedValue);
    };

    const handleStopChange2 = (_event: React.SyntheticEvent<HTMLElement, Event>, { value: stopId }: DropdownProps) => {
        setStopsState2(prevState => ({
            ...prevState,
            selectedValue: stopId as string,
        }));
        if (stopsState1.selectedValue)
            updatePerformanceValue(stopsState1.selectedValue, stopId as string);
    };

    const updatePerformanceValue = async (stopId1: string, stopId2: string) => {
        if (!agency || !agency.id || !stopId1 || !stopId2) {
            setTravelTimeForRoutesState([]);
            return;
        }
        setFormBlockingState(true);
        try {
            const travelTimeData = await getTravelTimeBetweenStops(agency.id, stopId1, stopId2);
            const travelTimeForRoutes = Object.entries(Utils.groupBy(travelTimeData.sort((a, b) => new Date(a.serviceDate).getTime() - new Date(b.serviceDate).getTime()), d => d.routeShortName))
                .map<TravelTimeForRoute>(([routeName, travelTimeData]) => ({ routeName, travelTimeData })).sort((a, b) => a.routeName > b.routeName ? 1 : -1);
            setTravelTimeForRoutesState(travelTimeForRoutes);

        } catch {
            setFormBlockingState(false);
            setTravelTimeForRoutesState([]);
        } finally {
            setFormBlockingState(false);
        }
    };

    const handleSearchChange1 = (_event: React.SyntheticEvent<HTMLElement, Event>, { searchQuery }: DropdownOnSearchChangeData) => {
        const searchTime = new Date().getTime();
        setStopsSearchCriteriaState({
            searchQuery,
            searchTime,
            setStopsState: setStopsState1,
            onSelectStopCode: (stopCode: string) => updatePerformanceValue(stopCode, stopsState2.selectedValue || ''),
            stopLoading: setStopLoadingState1,
        });
    };

    const handleSearchChange2 = (_event: React.SyntheticEvent<HTMLElement, Event>, { searchQuery }: DropdownOnSearchChangeData) => {
        const searchTime = new Date().getTime();
        setStopsSearchCriteriaState({
            searchQuery,
            searchTime,
            setStopsState: setStopsState2,
            onSelectStopCode: (stopCode: string) => updatePerformanceValue(stopsState1.selectedValue || '', stopCode),
            stopLoading: setStopLoadingState2,
        });
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Travel Time Between Stops
                </Header>
                <Form.Group className="inputGroup">
                    <Dropdown
                        placeholder="Start typing name or ID of a stop"
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={stopsState1.options}
                        value={stopsState1.selectedValue}
                        noResultsMessage={stopsNoResultMessageState}
                        style={styles.stopsDropdown1}
                        onChange={handleStopChange1}
                        onSearchChange={handleSearchChange1}
                    />
                    <Loader active={stopLoadingState1} inline style={styles.stopsLoader} />
                    <Dropdown
                        placeholder="Start typing name or ID of a stop"
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={stopsState2.options}
                        value={stopsState2.selectedValue}
                        noResultsMessage={stopsNoResultMessageState}
                        style={styles.stopsDropdown2}
                        onChange={handleStopChange2}
                        onSearchChange={handleSearchChange2}
                    />
                    <Loader active={stopLoadingState2} inline style={styles.stopsLoader} />
                </Form.Group>
                {travelTimeForRoutesState.length > 0 ?
                    travelTimeForRoutesState.map((data) => (
                    <Form.Group key={data.routeName} className="rowGroup">
                        <Form.Field width={16}>
                            <label className="categoryHeader">Route {data.routeName}</label>
                            <TravelTimeBetweenStopsChart chartData={data.travelTimeData} />
                        </Form.Field>
                    </Form.Group>
                )) :
                    <Form.Group>
                        <Form.Field width={16}>
                            {!formBlockingState && stopsState1.selectedValue && stopsState2.selectedValue && <Message> No data found between two stops</Message>}
                        </Form.Field>
                    </Form.Group>}
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(TravelTimeBetweenStopsForm);