import './SegmentTransitPerformanceStyles.css';
import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DateInput } from 'semantic-ui-calendar-react-17';
import { Button, Form, Header, Loader, Segment } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getGtfsTripsAndStops, getShapeData } from '../../../actions/gtfsStaticActions';
import { getTransitMeansOverall } from '../../../actions/runningTimeAnalyticsActions';
import Api from '../../../api/api';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import busStopIcon from '../../../static/bus-stop-20.png';
import { InfoboxProps, PolylineProps, PushpinProps } from '../../../types/BingMapProps';
import { GtfsTripAndStops, ShapePoint } from '../../../types/gtfsTypes';
import { TransitMeansOverall } from '../../../types/runningTimeAnalyticsTypes';
import { IDirectionModel, IRouteModel } from '../../../types/view-models-interfaces';
import Utils from '../../../utilities/utils';
import BingMap from '../../shared/BingMap';
import RouteVariantsSelector from '../../shared/RouteVariantsSelector';
import { buildPolylines } from './polylinesAlgorithm';
import SegmentTransitPerformanceLegend from './SegmentTransitPerformanceLegend';


interface Props {
    agency: AgencyStateType | undefined;
}

export interface ScheduleTableData {
    tripId: string;
    tripScheduledStart: Date;
    tripScheduledFinish: Date;
    scheduledTime?: Date;
}
interface SelectedStopPair {
    from?: GtfsTripAndStops;
    to?: GtfsTripAndStops;
}
interface ExtendedInfoboxProps extends InfoboxProps {
    fromStopId?: string;
    toStopId?: string;
}

const styles = {
    mapContainer: {
        height: '674',
    } as React.CSSProperties,
    routeSelection: {
        height: '674px',
    } as React.CSSProperties,
    tab: {
        border: 'none',
        padding: 0,
        marginBottom: 0,
    } as React.CSSProperties,
    speedMap: {
        paddingBottom: 0,
        maxWidth: '100%',
    } as React.CSSProperties,
    stopsDropdown: {
        width: '100%',
        marginBottom: '10px',
    } as React.CSSProperties,
};

const SegmentTransitPerformanceForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [center, setCenter] = useState<CoordinatePair>();
    const [mapBoundsState, setMapBoundsState] = useState<Microsoft.Maps.LocationRect | undefined>();
    const [selectedDateState, setSelectedDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [polylinesState, setPolylinesState] = useState<PolylineProps[]>([]);
    const [pushpinsState, setPushpinsState] = useState<PushpinProps[]>([]);
    const [infoboxesState, setInfoboxesState] = useState<ExtendedInfoboxProps[]>([]);
    const [stopsState, setStopsState] = useState<GtfsTripAndStops[]>([]);
    const [selectedStopPairState, setSelectedStopPairState] = useState<SelectedStopPair | undefined>(undefined);
    const [routesListState, setRoutesListState] = useState<IRouteModel[]>([]);
    const [selectedStopState, setSelectedStopState] = useState<GtfsTripAndStops | undefined>(undefined);
    const history = useHistory();

    useEffect(() => {
        clearStates();
        if (agency === undefined) return;
        (async (agencyId: string) => {
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
            await retrieveTripsData();
        })(agency.id);
    }, [agency?.id]);

    useEffect(() => {
        if (!selectedStopPairState)
            return;
        if (selectedStopPairState.from) {
            setInfoboxesState(prevState => (
                prevState.map(i => (i.location[0] === selectedStopPairState.from?.latitude && i.location[1] === selectedStopPairState.from?.longitude) ?
                    { ...i, fromStopId: selectedStopPairState.from.stopId, options: { ...i.options, htmlContent: getInfoboxContent(`${selectedStopPairState.from?.stopName} (${selectedStopPairState.from?.stopId})`, true, false) } } :
                    { ...i, fromStopId: undefined, options: { ...i.options, visible: false, htmlContent: getInfoboxContent(`${selectedStopPairState.from?.stopName} (${selectedStopPairState.from?.stopId})`, false, false) } })
            ));
        }
        if (selectedStopPairState.to) {
            setInfoboxesState(prevState => (
                prevState.map(i => (i.location[0] === selectedStopPairState.to?.latitude && i.location[1] === selectedStopPairState.to?.longitude) ?
                    { ...i, toStopId: selectedStopPairState.to.stopId, options: { ...i.options, htmlContent: getInfoboxContent(`${selectedStopPairState.to?.stopName} (${selectedStopPairState.to?.stopId})`, false, true) } } :
                    { ...i, toStopId: undefined, options: { ...i.options, visible: false, htmlContent: getInfoboxContent(`${selectedStopPairState.to?.stopName} (${selectedStopPairState.to?.stopId})`, false, false) } })
            ));
        }
        if (selectedStopPairState.from && selectedStopPairState.to) {
            const orderedStops = [selectedStopPairState.from, selectedStopPairState.to].sort((a, b) => a.stopSequence - b.stopSequence);
            const [firstStop, secondStop] = orderedStops;
            history.push(`/running-time/segments-travel-time-stops/${firstStop.stopId}/${secondStop.stopId}`);
        }
    }, [selectedStopPairState]);

    const handleSelectedDateChange = async (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        setSelectedDateState(value);
    };

    const handleRouteVariantChecked = async (route: IRouteModel, direction: IDirectionModel) => {
        routesListState.forEach(r => {
            r.directions.forEach(d => {
                d.selected = r.routeId === route.routeId && r.routeName === route.routeName && d.directionVariantId === direction.directionVariantId;
            });
        });
        setPolylinesState([]);
        setSelectedStopPairState(undefined);
        setSelectedStopState(undefined);
        if (agency && agency.id && direction.shapeId) {
            setFormBlockingState(true);
            try {
                const [transitMeansOverall, shapePoints] = await Promise.all(
                    [getTransitMeansOverall(agency.id, selectedDateState, direction.directionVariantId),
                    getShapeData(agency.id, selectedDateState, direction.shapeId)]);
                updatePolylines(transitMeansOverall, shapePoints);
                const locations = shapePoints.map(p => new Microsoft.Maps.Location(p.latitude, p.longitude));
                const boundingBox = Microsoft.Maps.LocationRect.fromLocations(locations);
                setMapBoundsState(boundingBox);
                const stopsForSelectedDirection = stopsState.filter(s => s.directionVariantInternalId === direction.directionVariantId);
                drawPushpins(stopsForSelectedDirection);
            } catch {
                setPolylinesState([]);
                setPushpinsState([]);
                setInfoboxesState([]);
            } finally {
                setFormBlockingState(false);
            }
        }
    };

    const updatePolylines = (transitMeansOverall: TransitMeansOverall[], shapePoints: ShapePoint[]) => {
        const polylines = buildPolylines(transitMeansOverall, shapePoints);
        setPolylinesState(polylines);
    };

    const drawPushpins = async (stopPositions: GtfsTripAndStops[]) => {
        if (stopPositions.length > 0) {
            setPushpinsState(
                stopPositions.map(stop => ({
                    location: [stop.latitude, stop.longitude],
                    options: {
                        icon: busStopIcon,
                        catId: 'bus-stop',
                    },
                    eventHandlers: [{
                        event: 'mouseover',
                        callback: async () => {
                            setInfoboxesState(prevState => (
                                prevState.map(i => (i.location[0] === stop.latitude && i.location[1] === stop.longitude) || i.fromStopId || i.toStopId ?
                                    { ...i, options: { ...i.options, visible: true, htmlContent: getHtmlContentForSelectedStop(i, stop) } } :
                                    { ...i, options: { ...i.options, visible: false } })
                            ));
                            setSelectedStopState(stop);
                        },
                    }],
                })));
            setInfoboxesState(
                stopPositions.map(stop => ({
                    location: [stop.latitude, stop.longitude],
                    options: {
                        htmlContent: getInfoboxContent(`${stop.stopName} (${stop.stopId})`, false, false),
                        visible: false,
                        offset: new Microsoft.Maps.Point(0, 115),
                    },
                })));
        } else {
            setPushpinsState([]);
            setInfoboxesState([]);
        }
    };

    const getHtmlContentForSelectedStop = (i: ExtendedInfoboxProps, stop: GtfsTripAndStops) => {
        return i.fromStopId || i.toStopId ?
            i.options?.htmlContent :
            getInfoboxContent(`${stop.stopName} (${stop.stopId})`, false, false);
    };

    const retrieveTripsData = async () => {
        clearStates();
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const stopsData = await getGtfsTripsAndStops(agency.id, selectedDateState);
                setStopsState(stopsData);
                updateRoutesList(stopsData);
            } catch {
                setStopsState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setStopsState([]);
        }
    };
    const updateRoutesList = (stopsData: GtfsTripAndStops[]) => {
        const routesList = Object.entries(Utils.groupBy(stopsData, t => t.routeShortName))
            .map<IRouteModel>(([routeName, routeData]) => ({
                routeId: routeName,
                routeName: routeName,
                cssColor: 'rgb(0, 64, 128)',
                directions: Object.entries(Utils.groupBy(routeData, r => r.directionVariantInternalId)).map<IDirectionModel>(([directionVariantId, variantData]) => ({
                    directionVariantId,
                    distance: 0,
                    selected: false,
                    cardinalDirection: variantData[0].otsTripShortName,
                    cardinalDirectionShort: variantData[0].otsTripShortName,
                    fromStopName: variantData.reduce((prev: GtfsTripAndStops, curr: GtfsTripAndStops) => prev.stopSequence < curr.stopSequence ? prev : curr).stopName,
                    toStopName: variantData.reduce((prev: GtfsTripAndStops, curr: GtfsTripAndStops) => prev.stopSequence > curr.stopSequence ? prev : curr).stopName,
                    isMain: true,
                    shapeId: variantData[0].shapeId,
                })),
            }))
            .sort((a, b) => (a.routeName > b.routeName) as unknown as number - ((a.routeName < b.routeName) as unknown as number));
        setRoutesListState(routesList);
    };

    const clearStates = () => {
        setStopsState([]);
        setPushpinsState([]);
        setInfoboxesState([]);
        setPolylinesState([]);
        setRoutesListState([]);
        setSelectedStopPairState(undefined);
        setSelectedStopState(undefined);
    };

    (window as any).handleFromClicked = () => {
        if (selectedStopState) {
            setSelectedStopPairState(prevState => ({ ...prevState, from: selectedStopState }));
        }
    };
    (window as any).handleToClick = () => {
        if (selectedStopState) {
            setSelectedStopPairState(prevState => ({ ...prevState, to: selectedStopState }));
        }
    };

    function getInfoboxContent(description: string, isFromActive: boolean, isToActive: boolean) {
        return ReactDOMServer.renderToStaticMarkup(
            <div className="planner-pushpin endpoint">
                <div className="planner-pushpin-positioner">
                    <div className="planner-pushpin-textbox">
                        <div className="planner-pushpin-address">{description}</div>
                        <div className={`planner-pushpin-choose-direction from ${isFromActive && 'active'}`}
                            tiq-onclick={`handleFromClicked()`}>From</div>
                        <div className={`planner-pushpin-choose-direction to ${isToActive && 'active'}`}
                            tiq-onclick={`handleToClick()`}>To</div>
                    </div>
                </div>
            </div>,
        ).replace(/tiq-/g, '');
    }

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form className="segment-transit">
                <Header as="h1" className="reportHeader">
                    Segment Transit Performance
                </Header>
                <Form.Group widths="equal" inline className="inputGroup">
                    <Form.Field className="calendarField">
                        <label className="calendarInputLabel">Report Date:</label>
                        <DateInput
                            name="fromDateCalendar"
                            fluid
                            dateFormat="YYYY-MM-DD"
                            placeholder="Select date"
                            value={selectedDateState}
                            iconPosition="left"
                            popupPosition="bottom center"
                            closable={true}
                            animation="fade"
                            onChange={handleSelectedDateChange}
                            className="calendarInput"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={retrieveTripsData}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={16}>
                        <label className="categoryHeader">
                            <div className="categorySubtitle">
                                Average vehicles' travel time duration between stops compared to the anticipated (according to schedule). Select the route direction and click on the stop signs to view a detailed history of the average travel time.
                            </div>
                        </label>
                    </Form.Field>
                </Form.Group>
                <Form.Group className="bottomGroup">
                    <Form.Field width={11} style={styles.mapContainer}>
                        <SegmentTransitPerformanceLegend />
                        <BingMap
                            map={{
                                center,
                                options: {
                                    bounds: mapBoundsState,
                                },
                            }}
                            polylines={polylinesState}
                            pushpins={pushpinsState}
                            infoboxes={infoboxesState}
                        />
                    </Form.Field>
                    <Form.Field width={5}>
                        <div className="speedmap side" style={styles.speedMap}>
                            <Segment id="routeSelectionPanel" style={styles.routeSelection}>
                                {routesListState.length === 0 ? <Loader active>Loading active routes...</Loader> : null}
                                {routesListState.map((r, i) => (
                                    <RouteVariantsSelector key={i}
                                        route={r}
                                        onChecked={handleRouteVariantChecked}
                                    />
                                ))}
                            </Segment>
                        </div>
                    </Form.Field>
                </Form.Group>
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({ agency: getSelectedOrDefaultAgency(state) }),
)(SegmentTransitPerformanceForm);