import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BunchesByDayPart } from '../../../../types/busBunchingTypes';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Bunches Count',
            data: [] as number[],
            barPercentage: 0.5,
            backgroundColor: '#42DCC6',
        },
    ],
};

const options: ChartOptions<'bar'> = {
    scales: {
        x: {
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            beginAtZero: true,
            grid: {
                drawOnChartArea: false,
            },
            title: {
                display: true,
                text: 'Number of buched vehicles',
            },
        },
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (tooltipItem) {
                    return `Bunches: ${tooltipItem.parsed.y}`;
                },
            },
        },
    },
};

const BunchesByDayPartChart: React.FC<{ chartData: BunchesByDayPart[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const labels = chartData.map(d => d.dayPart);
        const values = chartData.map(d => d.bunchesCount);

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0],
                data: values,
                state: chartData,
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        title: function (tooltipItem) {
                            const toolTipData = tooltipItem[0];
                            let dayPart = toolTipData.label;
                            const stateData = chartData.find(d => d.dayPart === dayPart);
                            if (stateData)
                                dayPart += `\n${stateData.dayPartDesc}`;
                            return dayPart;
                        },
                    },
                },
            },
        }));
    }, [chartData]);

    return (
        <Bar
            data={dataState}
            height={150}
            options={optionsState}
        />
    );
};

export default BunchesByDayPartChart;