export const crosshairOption = {
    line: {
        color: '#F66',  // crosshair line color
        width: 1,       // crosshair line width
    },
    sync: {
        enabled: false,
    },
    zoom: {
        enabled: true,                                      // enable zooming
        zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box
        zoomboxBorderColor: '#48F',                         // border color of zoom box
        zoomButtonText: 'RESET ZOOM',                       // reset zoom button text
        zoomButtonClass: 'ui button grey mini reset-zoom',      // reset zoom button class
    },
    snap: {
        enabled: true,
    },
};