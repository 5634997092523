import { ChartOptions } from 'chart.js';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDataPoint } from '../../../../types/chartTypes';
import { DeviationForHour } from '../../../../types/delayStatisticsTypes';
import Utils from '../../../../utilities/utils';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Average Deviation (min)',
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',
            fill: true,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(66, 220, 198, 0.1)',
            pointBackgroundColor: '#42DCC6',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(66, 220, 198, 0.1)',
            pointHoverBorderColor: 'rgba(66, 220, 198, 0.1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDataPoint[],
        },
    ],
};

const options: ChartOptions<'line'>  = {
    scales: {
        x: {
            type: 'linear' as const,
            ticks: {
                stepSize: 2,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            ticks: {
                callback: function (tickValue) {
                    return `${Utils.roundNumber((tickValue as unknown as number) / 60, 1)} min`;
                },
                stepSize: 60,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'x',
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                title: function (tooltipItem) {
                    return `Hour: ${tooltipItem[0].parsed.x}`;
                },
                label: function (tooltipItem) {
                    return `Avg Deviation: ${Utils.convertSecondsToMinutes(tooltipItem.parsed.y, 'm', 's')}`;
                },
                labelColor: function () {
                    return {
                        borderColor: '#42DCC6',
                        backgroundColor: '#42DCC6',
                    };
                },
            },
        },
    },
};

const DelaysByTimeChart: React.FC<{ chartData: DeviationForHour[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0], data: chartData.sort((a, b) => a.hour - b.hour).map(d => {
                    return {
                        x: d.hour,
                        y: d.deviation,
                    };
                }),
            }],
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState}
            height={190}
            options={options}
            plugins={[CrosshairPlugin]}
        />
    );
};

export default DelaysByTimeChart;
