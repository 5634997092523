import './DelaysAnalysisStyles.css';
import { TooltipItem } from 'chart.js';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineChartDataPoint } from '../../../../types/chartTypes';
import { DelaysAnalysisCompareData } from '../../../../types/delaysAnalysisTypes';
import MapUtils from '../../../../utilities/map-utils';

const styles = {
    chartContainer: {
        width: '630px',
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'end',
    } as React.CSSProperties,
};

const data = {
    labels: [],
    datasets: [
        {
            label: 'TransitIQ Delay',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(34,139,34, 0.4)',
            borderColor: 'rgba(34,139,34, 1)',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(34,139,34, 1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: 'rgba(34,139,34, 1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 4,
            data: [] as LineChartDataPoint[],
        },
        {
            label: 'Actual Delay',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(255,25,25,0.4)',
            borderColor: 'rgba(255,25,25,1)',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(255,25,25,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 4,
            pointHoverBackgroundColor: 'rgba(255,25,25,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 4,
            data: [] as LineChartDataPoint[],
        },
    ],
};

const options= {
    scales: {
        x: {
            type: 'linear' as const,
            title: {
                display: true,
                text: 'Local time',
            },
            beginAtZero: false,
            ticks: {
                autoSkip: false,
                stepSize: 600 * 1000,
                callback: (tickValue: string | number) => {
                    const date = moment(tickValue);
                    return date.format('hh:mm A');
                },
            },
        },
        y: {
            type: 'linear' as const,
            ticks: {
                stepSize: 5,
                autoSkip: false,
            },
            title: {
                display: true,
                text: 'Delay (minutes)',
            },
        },
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    plugins: {
        crosshair: {
            line: {
                color: '#F66',  // crosshair line color
                width: 1,       // crosshair line width
            },
            sync: {
                enabled: false,
            },
            zoom: {
                enabled: false,                                      // enable zooming
            },
            snap: {
                enabled: true,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 110,
            callbacks: {
                title: function () {
                    return '';
                },
                label: function (tooltipItem: TooltipItem<'line'>) {
                    const delaysSource = tooltipItem.datasetIndex === 0 ? 'TransitIQ Delay' : 'Actual Delay';
                    tooltipItem.datasetIndex === 0 ? 'trip 1' : 'trip 2';
                    const time = moment.parseZone(tooltipItem.parsed.x).format('hh:mm A');
                    const runTimesLabel = `${delaysSource} for time ${time}: ${tooltipItem.parsed.y} min`;
                    return runTimesLabel;
                },
                labelColor: function (tooltipItem: TooltipItem<'line'>) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            borderColor: 'rgba(34,139,34,1)',
                            backgroundColor: 'rgba(34,139,34,1)',
                        };
                    } else {
                        return {
                            borderColor: 'rgba(255,25,25,1)',
                            backgroundColor: 'rgba(255,25,25,1)',
                        };
                    }
                },
            },
        },
    },
    maintainAspectRatio: false,
};

interface Props {
    delaysData: DelaysAnalysisCompareData[],
    sliderValue?: number
}

const DelaysAnalysisChart : React.FC<Props> = ({
                                                   delaysData,
                                                   sliderValue,
                                               }) => {

    const ref = useRef(null);
    const [dataState, setDataState] = useState(data);

    useEffect(() => {
        if (sliderValue != null) {
            console.log(sliderValue);
            MapUtils.updateCrossHairLineChart(ref.current, sliderValue);
        }
    }, [sliderValue]);

    useEffect(() => {
        const chartData = delaysData.map(d => {
            return {
                ...d,
                vehicleTime: moment.parseZone(d.vehicleTime).format('YYYY-MM-DD hh:mm A'),
            };
        });

        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: chartData.filter(d => d.transitIqDelaySec !== null && d.transitIqDelaySec !== undefined).map(d => {
                    return {
                        x: moment(moment.parseZone(d.vehicleTime).format('YYYY-MM-DD hh:mm A')).valueOf() as unknown as number,
                        y: Math.round(d.transitIqDelaySec as number / 60 * 10) / 10,
                    };
                }),
            }, {
                ...prevState.datasets[1],
                data: chartData.filter(d => d.realDelaySec !== null && d.realDelaySec !== undefined).map(d => {
                    return {
                        x: moment(moment.parseZone(d.vehicleTime).format('YYYY-MM-DD hh:mm A')).valueOf() as unknown as number,
                        y: Math.round(d.realDelaySec as number / 60 * 10) / 10,
                    };
                }),
            }],
        }));
    }, [delaysData]);

    return (
        <div style={styles.chartContainer}>
            <Line
                data={dataState}
                options={options}
                height={450}
                plugins={[CrosshairPlugin]}
                ref={ref}
            />
        </div>
    );
};

export default DelaysAnalysisChart;
