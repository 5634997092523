import { ChartOptions, TooltipItem } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Utils from '../../../../utilities/utils';

const chartSettings = {
    labels: [] as string[][],
    datasets: [
        {
            label: 'Scheduled Waiting Time',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: '#42DCC6',
            pointBackgroundColor: '#42DCC6',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#42DCC6',
            pointHoverBorderColor: '#42DCC6',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: 'Observed Waiting Time',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#C0E139',
            borderColor: '#C0E139',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: '#C0E139',
            pointBackgroundColor: '#C0E139',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#C0E139',
            pointHoverBorderColor: '#C0E139',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
        {
            label: 'Estimated Waiting Time',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#FFC061',
            borderColor: '#FFC061',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: '#FFC061',
            pointBackgroundColor: '#FFC061',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#FFC061',
            pointHoverBorderColor: '#FFC061',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            display: false,
            labels: [],
            ticks: {
                autoSkip: false,
                maxRotation: 0,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        x2: {
            grid: {
                display: false,
            },
            border: {
                display: false,
            },
            ticks: {
                autoSkip: false,
                maxRotation: 0,
            },
        },
        y: {
            beginAtZero: false,
            ticks: {
                callback: function (tickValue: string | number) {
                    return `${Utils.roundNumber(tickValue as number / 60, 1)} min`;
                },
                stepSize: 120,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                usePointStyle: true,
            },
        },
        tooltip: {
            intersect: false,
            callbacks: {
                title: function () {
                    return '';
                },
                label: function (tooltipItem: TooltipItem<'line'>) {
                    let lineType = 'Scheduled Waiting Time';
                    if (tooltipItem.datasetIndex === 1) {
                        lineType = 'Observed Waiting Time';
                    } else if (tooltipItem.datasetIndex === 2) {
                        lineType = 'Estimated Waiting Time';
                    }
                    return `${lineType}: ${Utils.convertSecondsToMinutes(tooltipItem.parsed.y, 'm', 's')}`;
                },
                labelColor: function (tooltipItem: TooltipItem<'line'>) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            backgroundColor: '#42DCC6',
                            borderColor: '#42DCC6',
                        };
                    } else if (tooltipItem.datasetIndex === 1) {
                        return {
                            backgroundColor: '#C0E139',
                            borderColor: '#C0E139',
                        };
                    } else {
                        return {
                            backgroundColor: '#FFC061',
                            borderColor: '#FFC061',
                        };
                    }
                },
            },
        },
    },
};

interface Props {
    chartData: {
        scheduledWaitSec: number;
        observedWaitSec: number;
        inferredWaitSec: number;
        period: string;
    }[];
}

const AverageWaitTimeChart: React.FC<Props> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period.split(/(?=-)/g)),
            datasets: [{
                ...prevState.datasets[0],
                data: chartData.map(d => {
                    return {
                        x: d.period,
                        y: d.scheduledWaitSec,
                    };
                }),
            },
                {
                    ...prevState.datasets[1],
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.observedWaitSec,
                        };
                    }),
                },
                {
                    ...prevState.datasets[2],
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.inferredWaitSec,
                        };
                    }),
                }],
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState}
            height={150}
            options={options}
        />
    );
};

export default AverageWaitTimeChart;
