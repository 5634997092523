import { ChartOptions, TooltipItem } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { DeviationDistribution } from './TrendsForm';

type ChartData = { x: string | number; y: number }[];

interface DatasetSettings {
    label: string;
    borderColor: string;
    backgroundColor: string;
}

const settings: DatasetSettings[] = [
    {
        label: 'Early',
        borderColor: '#82E180',
        backgroundColor: 'rgba(130, 225, 128, 0.8)',
    },
    {
        label: 'On Time',
        borderColor: '#31DCC3',
        backgroundColor: 'rgba(49, 220, 195, 0.8)',
    },
    {
        label: '4 to 10 minutes',
        borderColor: '#FFC061',
        backgroundColor: 'rgba(255, 192, 97, 0.8)',
    },
    {
        label: 'More then 10 minutes',
        borderColor: '#FF8368',
        backgroundColor: 'rgba(255, 131, 104, 0.8)',
    },
];

const commonProperties = {
    fill: 'origin',
    lineTension: 0.1,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 5,
    pointHitRadius: 10,
    data: [] as ChartData,
};

const chartSettings = {
    labels: [] as string[][],
    datasets: settings.map(({
                                label,
                                borderColor,
                                backgroundColor,
                            }) => ({
        ...commonProperties,
        label,
        backgroundColor,
        borderColor,
        pointBorderColor: borderColor,
        pointBackgroundColor: borderColor,
        pointHoverBackgroundColor: borderColor,
        pointHoverBorderColor: borderColor,
    })),
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            display: false,
            labels: [],
            ticks: {
                autoSkip: false,
                maxRotation: 0,
            },
        },
        x2: {
            grid: {
                display: false,
            },
            border: {
                display: false,
            },
            ticks: {
                autoSkip: false,
                maxRotation: 0,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                autoSkip: false,
                callback: function (tickValue) {
                    return tickValue + ' %';
                },
                stepSize: 1,
                maxTicksLimit: 10,
            },
            stacked: true,
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    elements: {
        line: {
            tension: 0.000001,
        },
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                boxWidth: 10,
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            callbacks: {
                title: function (tooltipItems) {
                    return `${tooltipItems[0].parsed.x}`;
                },
                label: function (tooltipItem: TooltipItem<'line'>) {
                    let lineType = 'Early';
                    if (tooltipItem.datasetIndex === 1)
                        lineType = 'On Time';
                    else if (tooltipItem.datasetIndex === 2)
                        lineType = '4 to 10 minutes';
                    else if (tooltipItem.datasetIndex === 3)
                        lineType = 'More then 10 minutes';
                    return `${lineType}: ${Math.round(tooltipItem.parsed.y * 10) / 10} %`;
                },
                labelColor: function (tooltipItem: TooltipItem<'line'>) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            borderColor: '#82E180',
                            backgroundColor: '#82E180',
                        };
                    } else if (tooltipItem.datasetIndex === 1) {
                        return {
                            borderColor: '#31DCC3',
                            backgroundColor: '#31DCC3',
                        };
                    } else if (tooltipItem.datasetIndex === 2) {
                        return {
                            borderColor: '#FFC061',
                            backgroundColor: '#FFC061',
                        };
                    } else {
                        return {
                            borderColor: '#FF8368',
                            backgroundColor: '#FF8368',
                        };
                    }
                },
            },
        },
        filler: {
            propagate: true,
        },
    },
};

const PercentageOfArrivalsChart: React.FC<{ chartData: DeviationDistribution[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period.split(/(?=-)/g)),
            datasets: [
                {
                    ...prevState.datasets[0],
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.early,
                        };
                    }),
                },
                {
                    ...prevState.datasets[1],
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.onTime,
                        };
                    }),
                },
                {
                    ...prevState.datasets[2],
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.late,
                        };
                    }),
                },
                {
                    ...prevState.datasets[3],
                    data: chartData.map(d => {
                        return {
                            x: d.period,
                            y: d.veryLate,
                        };
                    }),
                },
            ],
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState}
            height={150}
            options={options}
        />
    );
};

export default PercentageOfArrivalsChart;
