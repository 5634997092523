import { ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { crosshairOption } from '../../../types/chartjs-plugin-crosshair/CrosshairOption';
import { LineDatePoint } from '../../../types/chartTypes';
import { PredictionAccuracyValue } from '../../../types/predictionAccuracyTypes';
import { KeyValuePair } from '../../../types/types';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Adaptive',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(65,105,225,1)',
            borderColor: 'rgba(65,105,225,1)',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(65,105,225,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(65,105,225,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'minute',
                displayFormats: {
                    minute: 'h:mm a',
                },
            },
            ticks: {
                stepSize: 1,
                autoSkip: true,
            },
            title: {
                display: true,
                text: 'Start',
            },
        },
        y: {
            title: {
                display: true,
                text: 'Accuracy',
            },
            beginAtZero: true,
        },
    },
    plugins: {
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            callbacks: {
                label: function (tooltipItem) {
                    return `Accuracy ${tooltipItem.parsed.y} %`;
                },
            },
        },
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
};

function getChartColor(modelName: string): string {
    switch (modelName.toLowerCase()) {
        case 'adaptive':
            return 'rgba(65,105,225,1)';
        case 'linear':
            return 'rgb(255, 165, 0)';
        case 'legacy':
            return 'rgb(60, 179, 113)';
        default:
            return 'rgba(65,105,225,1)';
    }
}

const AccuracyForRouteChart: React.FC<{
    chartData: KeyValuePair<string, PredictionAccuracyValue>[],
    modelName: string
}> = ({
          chartData,
          modelName,
      }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const accuracyDataByTime = chartData.sort((a, b) => moment(a.key).isSameOrAfter(b.key) ? 1 : -1);
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: accuracyDataByTime.map(s => {
                    return {
                        x: moment(s.key),
                        y: s.value.percentage,
                    };
                }),
                label: modelName,
                backgroundColor: getChartColor(modelName),
                borderColor: getChartColor(modelName),
                pointBorderColor: getChartColor(modelName),
                pointHoverBackgroundColor: getChartColor(modelName),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        labelColor: function () {
                            const chartColor = getChartColor(modelName);
                            return {
                                borderColor: chartColor,
                                backgroundColor: chartColor,
                            };
                        },
                    },
                },
            },
        }));
    }, [chartData]);


    return (
        <Line
            data={dataState}
            options={optionsState}
            height={150}
            plugins={[{ id: 'crosshair', ...crosshairOption }]}
        />
    );
};

export default AccuracyForRouteChart;
