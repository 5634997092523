import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react-17';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import { timeApiToUi, timeUiToApi } from './_models';
import { SpeedMapContext, SpeedMapContextType } from './SpeedMapContext';

interface Props { onClickGo: () => void; }

const SpeedMapInputs: React.FC<Props> = (props: Props) => {
    const context = React.useContext(SpeedMapContext) as SpeedMapContextType;

    const upperBoundDate1 = context.inputDates.find(d => d.id === 1)?.upperBoundDate ?? '';
    const lowerBoundDate1 = context.inputDates.find(d => d.id === 1)?.lowerBoundDate ?? '';
    const upperBoundTime1 = context.inputDates.find(d => d.id === 1)?.upperBoundTime ?? '';
    const lowerBoundTime1 = context.inputDates.find(d => d.id === 1)?.lowerBoundTime ?? '';
    const upperBoundDate2 = context.inputDates.find(d => d.id === 2)?.upperBoundDate ?? '';
    const lowerBoundDate2 = context.inputDates.find(d => d.id === 2)?.lowerBoundDate ?? '';
    const upperBoundTime2 = context.inputDates.find(d => d.id === 2)?.upperBoundTime ?? '';
    const lowerBoundTime2 = context.inputDates.find(d => d.id === 2)?.lowerBoundTime ?? '';

    const handleLowerBoundDate1Changed = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string }) => {
        const lowerBound = moment(value).toDate();
        context.setLowerBoundDate(value, 1);
        if (upperBoundDate1) {
            const upperBound = moment(upperBoundDate1).toDate();
            if (lowerBound > upperBound) {
                context.setUpperBoundDate(value, 1);
            }
        }
    };
    const handleUpperBoundDate1Changed = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        const upperBound = moment(value).toDate();
        context.setUpperBoundDate(value, 1);
        if (lowerBoundDate1) {
            const lowerBound = moment(lowerBoundDate1).toDate();
            if (lowerBound > upperBound) {
                context.setLowerBoundDate(value, 1);
            }
        }
    };
    const handleTimeFrom1Changed = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        const timeApi = timeUiToApi(value);
        context.setLowerBoundTime(timeApi, 1);
    };
    const handleTimeTo1Changed = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        const timeApi = timeUiToApi(value);
        context.setUpperBoundTime(timeApi, 1);
    };

    const handleLowerBoundDate2Changed = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string }) => {
        const lowerBound = moment(value).toDate();
        context.setLowerBoundDate(value, 2);
        if (upperBoundDate2) {
            const upperBound = moment(upperBoundDate2).toDate();
            if (lowerBound > upperBound) {
                context.setUpperBoundDate(value, 2);
            }
        }
    };
    const handleUpperBoundDate2Changed = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        const upperBound = moment(value).toDate();
        context.setUpperBoundDate(value, 2);
        if (lowerBoundDate2) {
            const lowerBound = moment(lowerBoundDate2).toDate();
            if (lowerBound > upperBound) {
                context.setLowerBoundDate(value, 2);
            }
        }
    };
    const handleTimeFrom2Changed = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        const timeApi = timeUiToApi(value);
        context.setLowerBoundTime(timeApi, 2);
    };
    const handleTimeTo2Changed = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        const timeApi = timeUiToApi(value);
        context.setUpperBoundTime(timeApi, 2);
    };
    const comparePeriodsClick = async (e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        context.setShowComparePeriods(!context.showComparePeriods);
    };


    return (<Form.Group inline>
        <Form.Field width={4} className="ui left labeled input date-input">
            <div className="ui basic label label">From</div>
            <DateInput
                width={3}
                name="dateFromCalendar1"
                fluid
                dateFormat="YYYY-MM-DD"
                placeholder="Select date"
                value={lowerBoundDate1}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                onChange={handleLowerBoundDate1Changed}
                style={{ width: '10em' }}
            />
            <div className="ui basic label label">to</div>
            <DateInput
                name="dateToCalendar1"
                fluid
                dateFormat="YYYY-MM-DD"
                placeholder="Select date"
                value={upperBoundDate1}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                onChange={handleUpperBoundDate1Changed}
                style={{ width: '10em' }}
            />
        </Form.Field>
        <Form.Field width={4} className="ui left labeled input date-input">
            <div className="ui basic label label">Time of day</div>
            <TimeInput
                name="timeFromInput1"
                timeFormat="AMPM"
                placeholder="Select time"
                value={timeApiToUi(lowerBoundTime1)}
                onChange={handleTimeFrom1Changed}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                style={{ width: '9em' }}
            />
            <div className="ui basic label label">-</div>
            <TimeInput
                name="timeToInput1"
                timeFormat="AMPM"
                placeholder="Select time"
                value={timeApiToUi(upperBoundTime1)}
                onChange={handleTimeTo1Changed}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                style={{ width: '9em' }}
            />
        </Form.Field>
        {context.showComparePeriods && <Form.Field width={4} className="ui left labeled input date-input">
            <div className="ui basic label label">From</div>
            <DateInput
                width={3}
                name="dateFromCalendar2"
                fluid
                dateFormat="YYYY-MM-DD"
                placeholder="Select date"
                value={lowerBoundDate2}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                onChange={handleLowerBoundDate2Changed}
                style={{ width: '10em' }}
            />
            <div className="ui basic label label">to</div>
            <DateInput
                name="dateToCalendar2"
                fluid
                dateFormat="YYYY-MM-DD"
                placeholder="Select date"
                value={upperBoundDate2}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                onChange={handleUpperBoundDate2Changed}
                style={{ width: '10em' }}
            />
        </Form.Field>}
        {context.showComparePeriods && <Form.Field width={4} className="ui left labeled input date-input">
            <div className="ui basic label label">Time of day</div>
            <TimeInput
                name="timeFromInput2"
                timeFormat="AMPM"
                placeholder="Select time"
                value={timeApiToUi(lowerBoundTime2)}
                onChange={handleTimeFrom2Changed}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                style={{ width: '9em' }}
            />
            <div className="ui basic label label">-</div>
            <TimeInput
                name="timeToInput2"
                timeFormat="AMPM"
                placeholder="Select time"
                value={timeApiToUi(upperBoundTime2)}
                onChange={handleTimeTo2Changed}
                iconPosition="left"
                popupPosition="bottom center"
                closable={true}
                animation="fade"
                style={{ width: '9em' }}
            />
        </Form.Field>}
        <Form.Field>
            <Link to="/" onClick={comparePeriodsClick}>{context.showComparePeriods ? 'Switch to Single Period' : 'Compare with Period'}</Link>
        </Form.Field>
        <Form.Field width={1} className="ui left input">
            <Checkbox label="Exclude Weekends" checked={context.excludeWeekends} onChange={(_e, { checked }) => context.setExcludeWekends(checked === true) } />
        </Form.Field>
        <Form.Field width={1} className="ui left input">
            <Checkbox label="Exclude Stops" checked={context.excludeStops} onChange={(_e, { checked }) => context.setExcludeStops(checked === true) } />
        </Form.Field>
        <Button primary disabled={!context.canLoadNewData()} onClick={props.onClickGo}>GO</Button>
    </Form.Group>
    );
};

export default SpeedMapInputs;
