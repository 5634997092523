import { DSVRowString } from 'd3';
import { ColumnDefinition } from 'react-tabulator';
import { Tabulator } from 'react-tabulator/lib/types/TabulatorTypes';
import { RowData } from './GtfsEditorForm';

export default function ColumnsDefinition(fileName: string, searchAnywhere: (rowData: RowData, fieldName: string, value: string) => void, data: DSVRowString<string>) {

    const linkStyle = function (cell: Tabulator.CellComponent) {
        cell.getElement().classList.add('formatter-link');
        return cell.getValue();
    };

    const filteredColumns: ColumnDefinition[] = [
        {
            title: 'trip_id', field: 'trip_id', headerFilter: true,
            headerFilterFunc: '=',
            formatter: linkStyle,
            cellClick: function (_e: UIEvent, cell: Tabulator.CellComponent) {
                searchAnywhere(cell.getRow().getData(), 'trip_id', cell.getValue());
            },
        }, {
            title: 'service_id', field: 'service_id', headerFilter: true,
            headerFilterFunc: '=',
            formatter: linkStyle,
            cellClick: function (_e: UIEvent, cell: Tabulator.CellComponent) {
                searchAnywhere(cell.getRow().getData(), 'service_id', cell.getValue());
            },
        }, {
            title: 'route_id', field: 'route_id', headerFilter: true,
            headerFilterFunc: '=',
            formatter: linkStyle,
            cellClick: function (_e: UIEvent, cell: Tabulator.CellComponent) {
                searchAnywhere(cell.getRow().getData(), 'route_id', cell.getValue());
            },
        }, {
            title: 'stop_id', field: 'stop_id', headerFilter: true,
            headerFilterFunc: '=',
            formatter: linkStyle,
            cellClick: function (_e: UIEvent, cell: Tabulator.CellComponent) {
                searchAnywhere(cell.getRow().getData(), 'stop_id', cell.getValue());
            },
        },
        {
            title: 'stop_bays', field: 'stop_bays', headerFilter: true,
            headerFilterFunc: '=',
            formatter: linkStyle,
            cellClick: function (_e: UIEvent, cell: Tabulator.CellComponent) {
                searchAnywhere(cell.getRow().getData(), 'stop_bays', cell.getValue());
            },
        },
    ];

    const hiddenTableColumns = [{
        table: 'trips',
        columns: ['wheelchair_accessible', 'bikes_allowed', 'end_time', 'start_time', 'times'],
    }];

    const additionalColumns = [{
        table: 'stops.txt',
        columns: ['stop_bays'],
        isFilter: true,
        headerFilterFunc: 'like',
        formatter: linkStyle,
        cellClick: function (_e: UIEvent, cell: Tabulator.CellComponent) {
            searchAnywhere(cell.getRow().getData(), 'stop_bays', cell.getValue());
        },
    }, {
        table: 'trips.txt',
        columns: ['times'],
        isFilter: false,
    }];

    const columns: ColumnDefinition[] = [];
    if (data) {
        Object.keys(data).forEach((value) => {
            if (hiddenTableColumns.some((table) => table.columns.includes(value))) {
                return;
            }
            const foundColumn = filteredColumns.find(col => col.field === value);
            if (foundColumn) {
                columns.push(foundColumn);
            } else {
                columns.push({
                    title: value as string, field: value as string, headerFilter: false as Tabulator.Editor,
                });
            }
        });
    }

    const additionalColumnsForTable = additionalColumns.find((table) => table.table === fileName);
    if (additionalColumnsForTable) {
        additionalColumnsForTable.columns.forEach((value) => {
            columns.splice(1, 0, {
                title: value as string,
                field: value as string,
                formatter: additionalColumnsForTable.formatter,
                headerFilter: additionalColumnsForTable.isFilter as Tabulator.Editor,
                headerFilterFunc: additionalColumnsForTable.headerFilterFunc as Tabulator.FilterType,
                cellClick: additionalColumnsForTable.cellClick,
            });
        });
    }
    return columns;
}