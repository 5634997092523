import { TableDispatchAction, TableSortProperties } from '../../../types/types';
import { VehicleStatisticsData } from '../../../types/vehicleStatisticsTypes';

export default function tableSortReducer(state: TableSortProperties<VehicleStatisticsData>, action: TableDispatchAction<VehicleStatisticsData>): TableSortProperties<VehicleStatisticsData> {
    switch (action.type) {
        case 'CHANGE_SORT': {
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    direction: state.direction === 'ascending' ? 'descending' : 'ascending',
                };
            }
            let data = [];
            switch (action.column) {
                case 'vehicleId':
                case 'tripsCount':
                case 'nonServiceTimeIndicators.notReportingSec':
                case 'nonServiceTimeIndicators.inGarageSec':
                case 'nonServiceTimeIndicators.moveSec':
                case 'nonServiceTimeIndicators.staySec':
                case 'serviceTimeIndicators.notReportingSec':
                case 'serviceTimeIndicators.moveSec':
                case 'serviceTimeIndicators.stayOnStopSec':
                case 'serviceTimeIndicators.stayOnRoadSec':
                case 'serviceTimeIndicators.travelKm':
                case 'nonServiceTimeIndicators.travelKm': {
                    const propertySplit = action.column.split('.');
                    const propertyGroup: string = propertySplit.length === 2 ? propertySplit[0] : '';
                    const property: string = propertySplit.length === 2 ? propertySplit[1] : propertySplit[0];
                    data = state.data.sort((a: VehicleStatisticsData, b: VehicleStatisticsData) => {
                        const aVal = propertyGroup ? a[propertyGroup ][property] as number | null : a[property] as number | null;
                        const bVal = propertyGroup ? b[propertyGroup][property] as number | null : b[property] as number | null;
                        if (aVal === null)
                            return -1;
                        if (bVal === null)
                            return 1;
                        return aVal - bVal;
                    });
                    break;
                }
                default:
                    data = state.data;
            }
            return {
                column: action.column,
                data: data,
                direction: 'ascending',
            };
        }
        case 'UPDATE_DATA': {
            return {
                ...state,
                data: action.data || [],
            };
        }
    }
}