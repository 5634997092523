import './BusAnalysisStyles.css';
import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react-17';
import { DropdownProps, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getBusAnalysisReports, getVehicles } from '../../../actions/anomaliesActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { BusAnalysisReport } from '../../../types/anomaliesTypes';
import BusAnalysisFrequencyChart from './BusAnalysisFrequencyChart';
import BusAnalysisJumpsOfflineChart from './BusAnalysisJumpsOfflineChart';
import BusAnalysisScheduledActualTimeChart from './BusAnalysisScheduledActualTimeChart';

type Props = {
    agency: AgencyStateType | undefined;
};

interface VechileItem {
    /** ,ust be an index of the item */
    value: number,
    /** display text */
    text: string,
}

const styles = {
    bottomContainer: {
        paddingBottom: '30px',
    } as React.CSSProperties,
};

const BusAnalysisForm: React.FC<Props> = ({ agency }) => {
    const [lowerBoundDate, setLowerBoundDate] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-30, 'days').format('YYYY-MM-DD'));
    const [upperBoundDate, setUpperBoundDate] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [vehicleList, setVehicleList] = useState<VechileItem[]>([]);
    const [vehicleListLoading, setVehicleListLoading] = useState(false);
    const [busAnalysisReportsState, setBusAnalysisReportsState] = useState<BusAnalysisReport[]>([]);
    const [selection, setSelection] = useState<{ item: VechileItem | null }>({ item: null });
    const [chartDataLoadingState, setChartDataLoadingState] = useState(false);

    const handleLowerBoundDateChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        const lowerBound = moment(value).toDate();
        const upperBound = moment(upperBoundDate).toDate();
        setLowerBoundDate(value);
        if (lowerBound > upperBound) {
            setUpperBoundDate(value);
        }
    };
    const handleUpperBoundDateChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        const lowerBound = moment(lowerBoundDate).toDate();
        const upperBound = moment(value).toDate();
        setUpperBoundDate(value);
        if (lowerBound > upperBound) {
            setLowerBoundDate(value);
        }
    };
    const updateVehicleList = async () => {
        if (!agency)
            return;

        setBusAnalysisReportsState([]);
        setChartDataLoadingState(false);
        setSelection({ item: null });

        setVehicleListLoading(true);
        setVehicleList([]);
        try {
            const result: VechileItem[] = (await getVehicles(agency.id))
                .map<VechileItem>((v, i) => ({ value: i, text: v }));
            setVehicleList(result);
        } catch {
            setVehicleList([]);
        } finally {
            setVehicleListLoading(false);
        }
    };
    const loadChartData = async (item?: VechileItem | null) => {
        if (agency && agency.id && item && item.text) {
            setChartDataLoadingState(true);
            try {
                const reports = await getBusAnalysisReports(agency.id, lowerBoundDate, upperBoundDate, item.text);
                setBusAnalysisReportsState(reports);
            }
            catch {
                setBusAnalysisReportsState([]);
            }
            finally {
                setChartDataLoadingState(false);
            }
        } else {
            setBusAnalysisReportsState([]);
        }
    };
    const handleVechicleChanged = async (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (!agency?.id) return;
        const newValue = vehicleList[data.value as number];
        if (newValue !== selection?.item) {
            setSelection({ item: newValue });
            setBusAnalysisReportsState([]);
        }
    };

    useEffect(() => {
        if (agency !== undefined && !vehicleListLoading)
            updateVehicleList();
    }, [agency?.id]);

    useEffect(() => {
        loadChartData(selection?.item);
    }, [selection?.item, lowerBoundDate, upperBoundDate]);

    return (
        <BlockUi tag="div" blocking={vehicleListLoading} className="outer">
            <Form>
                <Header as="h1" className="reportHeader">
                    Bus Analysis
                </Header>
                <Form.Group className="inputGroup">
                    <Form.Field>
                        <Form.Select
                            placeholder="Select a Bus"
                            search
                            selection
                            openOnFocus={false}
                            selectOnBlur={false}
                            options={vehicleList}
                            onChange={handleVechicleChanged}
                        />
                    </Form.Field>
                    <Form.Field width={3}>
                        <DateInput
                            name="fromDateCalendar"
                            fluid
                            dateFormat="YYYY-MM-DD"
                            placeholder="From date"
                            value={lowerBoundDate}
                            iconPosition="left"
                            popupPosition="bottom center"
                            closable={true}
                            animation="fade"
                            onChange={handleLowerBoundDateChange}
                        />
                    </Form.Field>
                    <Form.Field width={3}>
                        <DateInput
                            name="fromDateCalendar"
                            fluid
                            dateFormat="YYYY-MM-DD"
                            placeholder="To date"
                            value={upperBoundDate}
                            iconPosition="left"
                            popupPosition="bottom center"
                            closable={true}
                            animation="fade"
                            onChange={handleUpperBoundDateChange}
                        />
                    </Form.Field>
                </Form.Group>
                {selection.item &&
                    <BlockUi tag="div" blocking={chartDataLoadingState} className="inner">
                        {busAnalysisReportsState.length > 0 &&
                            <>
                                <Form.Group className="rowGroup">
                                    <Form.Field width={16}>
                                        <label className="categoryHeader">
                                            <div>Bus {selection.item?.text} Jumps & Offline Times</div>
                                            <div className="categorySubtitle">Bus "Jumps" and accumulated offline times</div>
                                        </label>
                                        <div className="chart-container" style={{ height: '30vh' }}>
                                            <BusAnalysisJumpsOfflineChart chartData={busAnalysisReportsState} />
                                        </div>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group className="rowGroup">
                                    <Form.Field width={16}>
                                        <label className="categoryHeader">Bus {selection.item?.text} TAIP/GTFS Reporting Frequency</label>
                                        <div className="chart-container" style={{ height: '30vh' }}>
                                            <BusAnalysisFrequencyChart chartData={busAnalysisReportsState} />
                                        </div>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group className="rowGroup" style={styles.bottomContainer}>
                                    <Form.Field width={16}>
                                        <label className="categoryHeader">
                                            <div>Scheduled vs Actual Travel Time</div>
                                            <div className="categorySubtitle">For the bus detected driving on a particular trip, this chart showing the difference between the actual time this bus spent on these trips vs scheduled time for these trips.</div>
                                        </label>
                                        <div className="chart-container" style={{ height: '30vh' }}>
                                            <BusAnalysisScheduledActualTimeChart chartData={busAnalysisReportsState} />
                                        </div>
                                    </Form.Field>
                                </Form.Group>
                            </>
                        }
                    </BlockUi>
                }
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(BusAnalysisForm);
