import moment from 'moment/moment';
import { DateTimeKind } from './dateTimeKind';

export class DateTime {
    private constructor(public readonly kind: DateTimeKind, public readonly value: number) {
    }

    public static fromAgencyDateAndTime(dateTime: Date): DateTime {
        return new DateTime(DateTimeKind.AGENCY, dateTime.valueOf());
    }

    public static fromStringUtcAsAgency(str: string): DateTime {
        const value = moment(str.substr(0, str.length - 1)).toDate().valueOf();
        return new DateTime(DateTimeKind.AGENCY, value);
    }

    public static fromStringAgencyDateTime(strDateTime: string): DateTime {
        const value = moment(strDateTime).toDate().valueOf();
        return new DateTime(DateTimeKind.AGENCY, value);
    }

    public static fromStringAgencyDateAndTime(strDate: string, strTime: string): DateTime {
        const value = moment(`${strDate} ${strTime}`).toDate().valueOf();
        return new DateTime(DateTimeKind.AGENCY, value);
    }

    public toDate(): Date {
        return new Date(this.value);
    }

    public format(fmt: string): string {
        return moment(this.value).format(fmt);
    }

    public compareTo(that: DateTime): number {
        if (this.kind !== that.kind) throw new Error('DateTime: can not compare different kinds');
        return this.value - that.value;
    }

    public static compare(a: DateTime, b: DateTime): number {
        if (a.kind !== b.kind) throw new Error('DateTime object must have the same kind');
        return a.value - b.value;
    }

    public toString() {
        return `${moment(this.value).format('YYYY-MM-DDTHH:mm:ss')} (${this.kind})}`;
    }
}