import { BoxAndWiskers, BoxPlotController, ViolinController } from '@sgratzl/chartjs-chart-boxplot';
import { Chart as ChartJS, ChartOptions, registerables } from 'chart.js';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { BoxPlotItemType } from '../../../types/runningTimeAnalyticsTypes';
import { AggregatedTripsItem } from './AnalysisByPercentilesForm';

const heightPerRow = 30;

ChartJS.register(
    ...registerables,
    BoxPlotController,
    BoxAndWiskers,
    ViolinController
);

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            backgroundColor: 'rgba(255,0,0,0.5)',
            borderColor: 'red',
            borderWidth: 1,
            outlierColor: 'lightgreen',
            outlierLineWidth: 2,
            padding: 20,
            outlierBorderColor: 'grey',
            lowerColor: 'lightblue',
            medianColor: 'green',
            itemRadius: 10,
            itemStyle: 'circle' as const,
            outlierRadius: 5,
            lowerBackgroundColor: 'lightblue',
            outlierBackgroundColor: 'rgba(65,255,125,0.5)',
            data: [] as BoxPlotItemType[],
        },
    ],
};

const options: ChartOptions<'boxplot'> = {
    responsive: true,
    indexAxis: 'y',
    scales: {
        x: {
            position: 'top',
            ticks: {
                color: '#4183c4',
            },
            title: {
                display: true,
                text: 'Minutes',
            },
        },
        y: {
            ticks: {
                color: '#4183c4',
            },
        },
    },
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    const { tripInfo, outliers }: any = context.raw;
                    const hoveredOutlierIndex = context.dataIndex;
                    if (hoveredOutlierIndex < 0 || outliers.length <= hoveredOutlierIndex) return;

                    const { tripId, scheduledDelayToNextTrip, scheduledRunTime } = tripInfo;
                    const actualDelay = outliers[hoveredOutlierIndex];
                    const observedRunTime = scheduledRunTime + actualDelay;
                    return [
                        'Trip ' + tripId,
                        'Scheduled Run-Time, min: ' + scheduledRunTime,
                        'Next trip start in, min: ' + scheduledDelayToNextTrip,
                        'Observed Run-Time, min: ' + observedRunTime.toFixed(2),
                        `${actualDelay < 0 ? 'Shorter' : 'Longer'} than scheduled by, min: ` + Math.abs(actualDelay).toFixed(2),
                    ].join('; ');
                },
            },
        },
    },
};

const DistributionChart: React.FC<{
    distributionChartData: AggregatedTripsItem[],
    handleChartTimeAxisClick: (timeLabel: string) => void
}> = ({
          distributionChartData,
          handleChartTimeAxisClick,
      }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);
    const [chartStyleState, setChartStyleState] = useState({
        height: distributionChartData.length * heightPerRow + 'px',
    });

    const chartRef = useRef(null);

    useEffect(() => {
        const chartData = distributionChartData.map(d => {
            const min: number = Math.min(0, d.delaysMinutes[0]);
            const max: number = Math.max(d.scheduledDelayToNextTrip, d.delaysMinutes[d.delaysMinutes.length - 1]);
            return {
                ...d,
                min,
                max,
            };
        }).filter(d => d.delaysMinutes.length > 0);
        const chartHeight: number = chartData.length * heightPerRow;
        setChartStyleState({ height: chartHeight + 'px' });
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(p => p.scheduledStartTime),
              datasets: [{
                  ...prevState.datasets[0],
                  data: chartData.map(p => ({
                      min: p.min - 1,
                      max: p.max + 1,
                      q1: 0,
                      median: 0,
                      q3: 0,
                      whiskerMin: 0,
                      whiskerMax: p.scheduledDelayToNextTrip,
                      outliers: p.delaysMinutes,
                  })),
              }],
        }));

        const relativePosition = (event: MouseEvent, chart: Chart): { y: number } => {
            const rect = chart.canvas?.getBoundingClientRect();
            const top = rect ? rect.top : 0;
            return {
                y: event.clientY - top,
            };
        };

        const handleClick = (e: MouseEvent) => {
            if (!chartRef || !chartRef.current) return;
            const chartInstance = chartRef.current as Chart;
            const { y } = relativePosition(e, chartInstance as Chart);
            const { scales } = chartInstance.options as any;
            const yAxis = scales['y-axis-0'];
            if (!yAxis)
                return;
            const index = yAxis.getValueForPixel(y);
            const timeLabel = yAxis.getLabelForValue(index as number);
            if (timeLabel) {
                handleChartTimeAxisClick(timeLabel);
            }
        };

        setOptionsState(prevState => ({
            ...prevState,
            onClick: (e) => handleClick(e.native as MouseEvent),
        }));

    }, [distributionChartData]);

    return (
        <div style={chartStyleState}>
            <Chart ref={chartRef} type="boxplot" data={dataState} options={optionsState} />
        </div>
    );
};

export default DistributionChart;