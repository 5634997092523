import { ChartOptions } from 'chart.js';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { DelaysDistibutionChartData } from './StopDelaysForm';

const chartSettings = {
    labels: [] as string[],
    datasets: [
        {
            label: 'Number of arrivals',
            data: [] as number[],
            barPercentage: 0.5,
            backgroundColor: '#42DCC6',
            datalabels: {
                align: 'end' as const,
                anchor: 'end' as const,
            },
        },
    ],
};

const options: ChartOptions<'bar'> = {
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
            },
        },
        datalabels: {
            align: 'end',
            anchor: 'end',
            color: 'red',
            font: {
                weight: 'bold',
            },
        },
    },
    scales: {
        x: {
            grid: {
                drawOnChartArea: false,
            },
            ticks: {
                maxTicksLimit: 6,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                callback: function (tickValue: number | string) {
                    const valueNum = Number(tickValue);
                    return valueNum !== 0 && valueNum > 1000 ? `${Math.round(valueNum / 1000)}k` : valueNum;
                },
                maxTicksLimit: 6,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
    },
    responsive: true,
    layout: {
        padding: {
            top: 25,
        },
    },
};

const DelaysDistributionChart: React.FC<{ delayDistribution: DelaysDistibutionChartData }> = ({ delayDistribution }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const labels = ['Early (< -1 min)', 'On Time (-1 - 4 min)', 'Late (4 - 10 min)', 'Very Late (> 10 min)'];
        const values = [delayDistribution.early, delayDistribution.onTime, delayDistribution.late, delayDistribution.veryLate];

        setDataState(prevState => ({
            ...prevState,
            labels: labels,
            datasets: [{
                ...prevState.datasets[0], data: values,
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                datalabels: {
                    ...prevState.plugins?.datalabels,
                    formatter: function (_value: number, context: Context) {
                        const labelText = context.chart.data.labels?.[context.dataIndex];
                        let percentage: number;
                        switch (labelText) {
                            case 'Early (< -1 min)':
                                percentage = delayDistribution.earlyPercentage;
                                break;
                            case 'On Time (-1 - 4 min)':
                                percentage = delayDistribution.onTimePercentage;
                                break;
                            case 'Late (4 - 10 min)':
                                percentage = delayDistribution.latePercentage;
                                break;
                            case 'Very Late (> 10 min)':
                                percentage = delayDistribution.veryLatePercentage;
                                break;
                            default:
                                percentage = 0;
                                break;
                        }
                        return percentage ?`${percentage}%`: '';
                    },
                },
            },
        }));
    }, [delayDistribution]);

    return (
        <Bar
            data={dataState}
            height={150}
            options={optionsState}
            plugins={[ChartDataLabels]}
        />
    );
};

export default DelaysDistributionChart;