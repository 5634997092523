import { Dictionary } from '../../../types/view-models-interfaces';
import { InstanceHelper } from '../InstanceHelper';
import { BphVehicleModel } from './BphTypes';

export class VehicleMapDisplayPool {

    private readonly _cached: Dictionary<BphVehicleModel> = {};
    private _listed: Dictionary<BphVehicleModel> = {};

    constructor(private owner: InstanceHelper) {
    }

    public empty() {
        this._clear(this._listed, true);
        this._clear(this._cached, false);
    }

    public has(id: string): boolean {
        return (id in this._cached);
    }

    public get(id: string): BphVehicleModel {
        const result = this._cached[id];
        if (!result)
            throw new Error(`BHP.VehiclePool: no cached object (${id})`);
        return result;
    }

    public add(vehicle: BphVehicleModel): void {
        this._cached[vehicle.id] = vehicle;
    }

    private _highlighted: BphVehicleModel | null = null;

    public highlight(vehicleId?: string | null): boolean {
        const vehicle = (vehicleId && this.get(vehicleId)) || null;
        if (vehicle === this._highlighted) return false;

        if (this._highlighted)
            this._highlighted.highlighted = false;

        this._highlighted = vehicle;

        if (this._highlighted)
            this._highlighted.highlighted = true;

        return true;
    }

    public set(vehicles: Dictionary<BphVehicleModel>) {
        const toRemove = this._listed;
        for (const id in vehicles) {
            if (this._cached[id] !== vehicles[id]) {
                throw new Error(`BHP.VehiclePool: vehicle (${id}) is not cached!`);
            }
            if (id in toRemove) {
                // already displayed
                delete toRemove[id];
            } else {
                // new item to display
                const item = vehicles[id];
                this._showInfobox(item);
            }
        }
        for (const id in toRemove) {
            const item = toRemove[id];
            this._hideInfobox(item);
        }
        this._listed = vehicles;
    }

    private _showInfobox(item: BphVehicleModel) {
        if (this.owner._map)
            item.infobox.setMap(this.owner._map);
    }

    private _hideInfobox(item: BphVehicleModel): void {
        if (this.owner._map)
            item.infobox.setMap(null as any as Microsoft.Maps.Map);
    }

    private _clear(list: Dictionary<BphVehicleModel>, remove?: boolean) {
        for (const id in list) {
            const item = list[id];
            delete list[id];
            if (remove) {
                this._hideInfobox(item);
            }
        }
    }
}