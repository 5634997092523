import { ChartOptions, TooltipItem } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

const chartSettings = {
    labels: [] as string[][],
    datasets: [
        {
            label: '',
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            fill: 'origin',
            borderColor: '#42DCC6',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgb(66, 220, 198)',
            pointBackgroundColor: 'rgb(66, 220, 198)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(66, 220, 198)',
            pointHoverBorderColor: 'rgba(66, 220, 198)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as { x: string; y: number }[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            display: false,
            labels: [],
            ticks: {
                autoSkip: false,
                maxRotation: 0,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        x2: {
            grid: {
              display: false,
            },
            border: {
                display: false,
            },
            ticks: {
                autoSkip: false,
                maxRotation: 0,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                autoSkip: false,
                stepSize: 1,
                maxTicksLimit: 10,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
            },
        },
        tooltip: {
            intersect: false,
            callbacks: {
                title: function () {
                    return '';
                },
                labelColor: function () {
                    return {
                        backgroundColor: '#42DCC6',
                        borderColor: '#42DCC6',
                    };
                },
            },
        },
    },
};

interface Props {
    chartData: { value: number; period: string }[];
    axesLabelSign?: string;
    dataLabel: string;
}

const TrendsSimpleLineChart: React.FC<Props> = ({
        chartData,
        axesLabelSign,
        dataLabel,
    }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period.split(/(?=-)/g)),
            datasets: [{
                ...prevState.datasets[0],
                label: dataLabel,
                data: chartData.map(d => {
                    return {
                        x: d.period,
                        y: d.value,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                y: {
                    ...prevState.scales?.y,
                    ticks: {
                        ...prevState.scales?.y?.ticks,
                        callback: function (value: string | number) {
                            return axesLabelSign ? `${value} ${axesLabelSign}` : `${value}`;
                        },
                    },
                },
            },
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        label: function (tooltipItem: TooltipItem<'line'>) {
                            return `${chartData.map(v => v.value)[tooltipItem.dataIndex]} ${axesLabelSign}`;
                        },
                    },
                },
            },

        }));
    }, [chartData]);


    return (
        <Line
            data={dataState}
            height={150}
            options={optionsState}
        />
    );
};

export default TrendsSimpleLineChart;
