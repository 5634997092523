import './ReportingGapsStyles.css';
import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react-17';
import { Button, Form, Header, Icon, Tab } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getPositionsReportingGaps, getTripsReportingGaps, getVehiclesReportingGaps } from '../../../actions/anomaliesActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { PositionReportingGap, TripReportingGap, VehicleReportingGap } from '../../../types/anomaliesTypes';
import { VehiclePositionDataSource } from '../../../types/types';
import PositionsReportingGapsTable from './PositionsReportingGapsTable';
import TripsReportingGapsTable from './TripsReportingGapsTable';
import VehiclesReportingGapsTable from './VehiclesReportingGapsTable';

type Props = {
    agency: AgencyStateType | undefined;
};

const ReportingGapsForm: React.FC<Props> = ({ agency }) => {
    const [serviceDate, setServiceDate] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [mainDataLoading, setMainDataLoading] = useState(false);
    const [vehiclesState, setVehiclesState] = useState<VehicleReportingGap[]>([]);
    const [tripsState, setTripsState] = useState<TripReportingGap[]>([]);
    const [selectedVehicleState, setSelectedVehicleState] = useState<VehicleReportingGap | undefined>(undefined);
    const [selectedTripState, setSelectedTripState] = useState<TripReportingGap | undefined>(undefined);
    const [positionsState, setPositionsState] = useState<PositionReportingGap[]>([]);
    const [detailsLoading, setDetailsLoading] = useState(true);

    const tabPanes = [
        {
            menuItem: 'GTFS-Realtime',
            render: () => <PositionsReportingGapsTable {...{ items: positionsState.filter(p => p.dataSource == VehiclePositionDataSource.GtfsRt) }} />,
        },
        {
            menuItem: 'TAIP',
            render: () => <PositionsReportingGapsTable {...{ items: positionsState.filter(p => p.dataSource == VehiclePositionDataSource.Taip) }} />,
        },
    ];

    const handleVehicleRowClick = async (rowData: VehicleReportingGap) => {
        if (!agency || (selectedVehicleState && selectedVehicleState.vehicleId === rowData.vehicleId))
            return;
        setSelectedVehicleState(rowData);
        setPositionsState([]);
        try {
            setMainDataLoading(true);
            const tripsReportingGaps = await getTripsReportingGaps(agency.id, serviceDate, rowData.vehicleId);
            setTripsState(tripsReportingGaps);
        } catch {
            setTripsState([]);
        } finally {
            setMainDataLoading(false);
        }
    };
    const handleTripRowClick = async (rowData: TripReportingGap) => {
        if (!agency)
            return;
        if (selectedTripState && selectedTripState.tripInternalId === rowData.tripInternalId)
            return;
        setSelectedTripState(rowData);
        try {
            setDetailsLoading(true);
            const positionsReportingGaps = await getPositionsReportingGaps(agency.id, serviceDate, rowData.tripInternalId, rowData.vehicleId);
            setPositionsState(positionsReportingGaps);
        }
        finally {
            setDetailsLoading(false);
        }
    };
    const updateMainTableData = async () => {
        if (!agency)
            return;
        setVehiclesState([]);
        setSelectedVehicleState(undefined);
        setSelectedTripState(undefined);
        try {
            setMainDataLoading(true);
            const vehiclesReportingGaps = await getVehiclesReportingGaps(agency.id, serviceDate);
            setVehiclesState(vehiclesReportingGaps);
        } finally {
            setMainDataLoading(false);
        }
    };

    const handleServiceDateChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: { value: string; }) => {
        setServiceDate(value);
    };

    const getReportingHistoryHeader = (selectedTrip: TripReportingGap) => {
        const { vehicleId, tripScheduledStart, tripScheduledFinish, routeName, cardinalDirection } = selectedTrip;
        const tripSchedule = `${moment.parseZone(tripScheduledStart).format('h:mm A')} - ${moment.parseZone(tripScheduledFinish).format('h:mm A')}`;
        return `Bus ${vehicleId} reporting history for trip with schedule ${tripSchedule} (${routeName} - ${cardinalDirection})`;
    };

    useEffect(() => {
        if (agency !== undefined && !mainDataLoading)
            updateMainTableData();
    }, [agency?.id, serviceDate]);

    return (
        <BlockUi tag="div" blocking={mainDataLoading} className="outer">
            <Form>
                <Header as="h1" className="reportHeader">
                    Buses with pauses during reporting
                </Header>
                <Form.Group className="inputGroup">
                    <Form.Field width={3}>
                        <DateInput
                            name="serviceDateCalendar"
                            fluid
                            dateFormat="YYYY-MM-DD"
                            placeholder="Date"
                            value={serviceDate}
                            iconPosition="left"
                            popupPosition="bottom center"
                            closable={true}
                            animation="fade"
                            onChange={handleServiceDateChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            content="Refresh"
                            onClick={updateMainTableData}>
                        </Button>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <p><Icon color="grey" name="info circle" size="large" /> Click on a row to see details.</p>
                </Form.Group>
                <Form.Group className="rowGroup">
                    <Form.Field width={16}>
                        <VehiclesReportingGapsTable items={vehiclesState} selectedItem={selectedVehicleState} onRowClick={handleVehicleRowClick} />
                    </Form.Field>
                </Form.Group>
                {selectedVehicleState &&
                    <Form.Group className="rowGroup">
                        <Form.Field width={16}>
                            <label className="categoryHeader">
                                <div>Bus {selectedVehicleState.vehicleId} by trips offline times</div>
                            </label>
                            <TripsReportingGapsTable items={tripsState} selectedItem={selectedTripState} onRowClick={handleTripRowClick} />
                        </Form.Field>
                    </Form.Group>
                }
                {selectedTripState &&
                    <BlockUi tag="div" blocking={detailsLoading} className="inner">
                        <Form.Group>
                            <Form.Field width={16}>
                            <label className="categoryHeader">
                                <div>{getReportingHistoryHeader(selectedTripState)}</div>
                                </label>
                                {positionsState.length &&
                                    <Tab panes={tabPanes} />
                                }
                            </Form.Field>
                        </Form.Group>
                    </BlockUi>
                }
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(ReportingGapsForm);
