import { Dictionary } from '../../../types/view-models-interfaces';
import { InstanceHelper } from '../InstanceHelper';
import { BphDirectionModel } from './BphTypes';

export class DirectionsMapDisplayPool {
    private readonly _cached: Dictionary<BphDirectionModel> = {};
    private _listed: Dictionary<BphDirectionModel> = {};
    private _selected: BphDirectionModel | null = null;

    constructor(private owner: InstanceHelper) {
    }

    public empty() {
        this.select(null);
        this._clear(this._listed, true);
        this._clear(this._cached, false);
    }

    public select(direction: Nullable<BphDirectionModel>): void {
        if (this._selected === direction) {
            return;
        }
        if (this._selected) {
            this._selected.select(false);
        }
        this._selected = direction;
        if (this._selected) {
            this._selected.select(true);
            this._hidePoyline(this._selected);
            this._showPoyline(this._selected);
        }
    }

    public add(direction: BphDirectionModel): void {
        this._cached[direction.directionVariantId] = direction;
    }

    public set(directions: Dictionary<BphDirectionModel>) {
        const toRemove = this._listed;
        for (const id in directions) {
            if (this._cached[id] !== directions[id]) {
                throw new Error(`BHP.TrackPool: direction (${id}) is not cached!`);
            }
            if (id in toRemove) {
                // already displayed
                delete toRemove[id];
            } else {
                // new item to display
                const item = directions[id];
                this._showPoyline(item);
            }
        }
        for (const id in toRemove) {
            const item = toRemove[id];
            if (this._selected === item) {
                item.select(false);
            }
            this._hidePoyline(item);
        }
        this._listed = directions;
    }

    public forceClear() {
        if (this.owner._map)
            this.owner._map?.entities.clear();
    }

    private _showPoyline(item: BphDirectionModel) {
        if (this.owner._map)
            this.owner._map?.entities.push(item.polyline);
    }

    private _hidePoyline(item: BphDirectionModel) {
        if (this.owner._map)
            this.owner._map?.entities.remove(item.polyline);
    }

    private _clear(list: Dictionary<BphDirectionModel>, remove?: boolean) {
        for (const id in list) {
            const item = list[id];
            delete list[id];
            if (remove) {
                this._hidePoyline(item);
            }
        }
    }
}