import { ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { BunchesByKey } from '../../../../types/busBunchingTypes';
import { LineChartDatePoint } from '../../../../types/chartTypes';


const chartSettings = {
    labels: [],
    datasets: [
        {
            label: '',
            lineTension: 0.1,
            backgroundColor: 'rgba(66, 220, 198, 0.1)',
            borderColor: '#42DCC6',

            borderDash: [],
            borderDashOffset: 0.0,

            pointBorderColor: '#42DCC6',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#42DCC6',
            pointHoverBorderColor: '#42DCC6',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: [] as LineChartDatePoint[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'hour',

                displayFormats: {
                    minute: 'h:mm a',
                },
            },
            ticks: {
                stepSize: 1,
                autoSkip: true,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {},
            title: {
                display: true,
                text: 'Number of buched vehicles',
            },
        },
    },
    hover: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 50,
            callbacks: {
                title: function () {
                    return '';
                },
            },
        },
    },
    maintainAspectRatio: false,
};

interface Props {
    bunchesByDates: BunchesByKey[],
    daysDiff: number;
}

const BunchesByDatesChart: React.FC<Props> = ({
         bunchesByDates,
         daysDiff,
     }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const chartData = bunchesByDates
            .sort((a, b) => moment(a.key).isSameOrAfter(b.key) ? 1 : -1);

        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: chartData.map(d => {
                    return {
                        t: moment(d.key).toDate(),
                        y: d.bunchesCount,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                x: {
                    ...prevState.scales?.x,
                    time: {
                        unit: daysDiff > 1 ? 'day' : 'hour',
                        unitStepSize: 1,
                        displayFormats: {
                            minute: 'h:mm a',
                            day: 'ddd MM-DD',
                        },
                    },
                },
            },
        }));
    }, [bunchesByDates, daysDiff]);

    return (
        <div>
            <Line
                data={dataState}
                height={100}
                options={optionsState}
            />
        </div>
    );
};

export default BunchesByDatesChart;
