import { NavLink, useLocation } from 'react-router-dom';
import { AccordionPanelProps, IconProps, Image, Menu, SemanticShorthandCollection, SemanticShorthandItem } from 'semantic-ui-react';
import Utils from '../../utilities/utils';
import { NavigationSecondLayerElement } from './navigation-second-layer';
import { toRoutePaths } from './routes';
import { isRootRouteForPathOver, TransitIqRootRouteProps } from './TransitIqRootRoute';

/**
 * React Hook to get the index of the navigation group that is
 * currently active.
 * @param firstLayer The navigation groups being used in this Accordion.
 */
export function useActiveNavigationFirstLayerIndex(
    firstLayer: readonly TransitIqRootRouteProps[],
): number {
    const { pathname } = useLocation();
    return firstLayer.findIndex(isRootRouteForPathOver(pathname));
}

interface NavigationIcon {
    readonly data: DataUri;
    readonly width: Pixels;
    readonly height: Pixels;
}

/**
 * Everything in the navbar needs an icon,
 * nothing not in the navigation uses one.
 */
export type TransitIqRootRouteInNavigationProps =
    & TransitIqRootRouteProps
    & {
        readonly icon: NavigationIcon;
    }
    ;

function toNavigationGroupIconShorthand(
    { data, width, height }: NavigationIcon,
): SemanticShorthandItem<IconProps> {
    return {
        as: Image,
        src: data,
        width, height,
        verticalAlign: 'middle',
        spaced: true,
        alt: '', // accessibility feature
    };
}

/**
 * Creates the shorthand object used to populate an `Accordion`'s
 * `panels` property such that it creates `NavigationGroup` components.
 * We use shorthand because it causes `Accordion` to handle all the
 * open/close stuff for us.
 * @param props The React properties for the `NavigationGroup`
 */
export function toNavigationGroupAccordionPanelShorthand(
    firstLayer: TransitIqRootRouteInNavigationProps[],
): SemanticShorthandCollection<AccordionPanelProps> {
    return firstLayer.map(props => {
        if ('subroutes' in props) {
            const { label, icon, path, subroutes } = props;
            return {
                key: label,
                title: {
                    className: 'navigation-first-layer navigation-group',
                    icon: toNavigationGroupIconShorthand(icon),
                    content: label,
                },
                content: {
                    content: (
                        <Menu fluid vertical borderless inverted color="blue">
                            {subroutes.map(subroute => (
                                !subroute.hidden &&
                                <NavigationSecondLayerElement
                                    key={subroute.label}
                                    label={subroute.label}
                                    path={toRoutePaths(subroute, path)}
                                />
                            ))}
                        </Menu>
                    ),
                },
            };
        }
        else {
            const { label, icon, path } = props;
            return {
                key: label,
                title: {
                    as: NavLink,
                    className: 'navigation-first-layer navigation-item',
                    icon: toNavigationGroupIconShorthand(icon),
                    content: label,
                    to: Utils.ensureIsArray(path)[0],
                },
            };
        }
    });
}
