import { ChartOptions } from 'chart.js';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { DelayDataForPeriod } from './models';

const chartSettings = {
    datasets: [{
        label: 'Average Delay',
        lineTension: 0.1,
        backgroundColor: 'transparent',
        borderColor: '#bee1cc',
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: '#52af77',
        pointBorderColor: 'rgb(61, 133, 90)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#bee1cc',
        pointHoverBorderColor: 'rgb(61, 133, 90)',
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        data: [] as { x: string; y: number }[],
    }],
};

const options: ChartOptions<'line'> = {
    spanGaps: false,
    scales: {
        x: {
            ticks: {
                autoSkip: false,
            },
        },
        y: {
            type: 'linear' as const,
            beginAtZero: true,
            ticks: {
                autoSkip: false,
                stepSize: 1,
                maxTicksLimit: 10,
            },
        },
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    elements: {
        line: {
            fill: false,
            tension: 0.4,
        },
    },
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'index',
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            footerColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                title: function (tooltipItem) {
                    return `${tooltipItem[0].label}`;
                },
                label: function tooltipsLabel(tooltipItem) {
                    const lineType = chartSettings.datasets[tooltipItem.datasetIndex].label;
                    return `${lineType}: ${tooltipItem.parsed.y} min`;
                },
                footer(tooltipItems) {
                    const source = tooltipItems[0].raw as {
                        x: string,
                        y: number
                    };
                    return [
                        '',
                        `Minimum: ${source.x} min`,
                        `Maximum: ${source.y}`];
                },
            },
        },
    },
};

interface Props {
    chartData: DelayDataForPeriod[];
}

const DelaysSimpleLineChart: React.FC<Props> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: chartData.map(d => d.period),
            datasets: [{
                ...prevState.datasets[0],
                spanGaps: false,
                data: chartData.map(d => {
                    return {
                        x: d.period,
                        y: d.avg as number,
                    };
                }),
            }],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            scales: {
                ...prevState.scales,
                y: {
                    ...prevState.scales?.y,
                    ticks: {
                        ...prevState.scales?.y?.ticks,
                        callback: function (tickValue: number | string) {
                            return `${tickValue} min`;
                        },
                    },
                },
            },
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState}
            height={150}
            options={optionsState}
            plugins={[CrosshairPlugin]}
        />
    );
};

export default DelaysSimpleLineChart;
