import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { KpiRoutesDelayStatistics } from '../../../../types/delayStatisticsTypes';

const settingsBase = {
    backgroundColor: '',
    borderColor: '',
    borderWidth: 1,
    hoverBackgroundColor: '',
    hoverBorderColor: '',
};
const colors = [
    '#82E180',
    '#31DCC3',
    '#FFC061',
    '#FF8368'];

const labels = [
    'Early Arrivals',
    'On Time Arrivals',
    'Late Arrivals',
    'Very Late Arrivals'];

const chartSettings = {
    labels: [] as string[],
    datasets: labels.map((label, index) => {
        const color = colors[index];
        return {
            ...settingsBase,
            label: label,
            data: [] as number[],
            backgroundColor: color,
            borderColor: color,
            hoverBackgroundColor: color,
            hoverBorderColor: color,
        };
    }),
};

const options: ChartOptions<'bar'> = {
    scales: {
        x: {
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            ticks: {
                callback: function (value: number | string): string | null {
                    if (typeof value === 'number') {
                        const valueNum = value as unknown as number;
                        return valueNum !== 0 ? `${Math.round((valueNum) / 1000)}k` : `${valueNum}`;
                    }
                    return null;
                },
                maxTicksLimit: 10,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
    },

    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                boxWidth: 20,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                title: function (tooltipItem) {
                    let lineType = 'Early Arrivals';
                    if (tooltipItem[0].datasetIndex === 1)
                        lineType = 'On Time Arrivals';
                    else if (tooltipItem[0].datasetIndex === 2)
                        lineType = 'Late Arrivals';
                    else if (tooltipItem[0].datasetIndex === 3)
                        lineType = 'Very Late Arrivals';
                    return lineType;
                },
                label: function (tooltipItem) {
                    return `Number of Arrivals: ${tooltipItem.parsed.y.toLocaleString('en-US')}`;
                },
                labelColor: function (tooltipItem) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            borderColor: '#82E180',
                            backgroundColor: '#82E180',
                        };
                    } else if (tooltipItem.datasetIndex === 1) {
                        return {
                            borderColor: '#31DCC3',
                            backgroundColor: '#31DCC3',
                        };
                    } else if (tooltipItem.datasetIndex === 2) {
                        return {
                            borderColor: '#FFC061',
                            backgroundColor: '#FFC061',
                        };
                    } else {
                        return {
                            borderColor: '#FF8368',
                            backgroundColor: '#FF8368',
                        };
                    }
                },
            },
        },
    },
};

const DelaysDistributionChart: React.FC<{ chartData: KpiRoutesDelayStatistics }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            labels: [''],
            datasets: [{
                ...prevState.datasets[0],
                data: [chartData.earlyArrivals],
            }, {
                ...prevState.datasets[1],
                data: [chartData.onTimeArrivals],
            }, {
                ...prevState.datasets[2],
                data: [chartData.lateArrivals],
            }, {
                ...prevState.datasets[3],
                data: [chartData.veryLateArrivals],
            }],
        }));
    }, [chartData]);

    return (
        <Bar
            data={dataState}
            height={160}
            options={options}
        />
    );
};

export default DelaysDistributionChart;