import moment from 'moment/moment';
import React from 'react';
import { getDirectionVariantsByRoute, getDirectionVariantsForStop, getRoutesPairs } from '../../actions/gtfsStaticActions';
import { getPositions, getSchedule, getStopPredictions, getStops } from '../../actions/vehiclesHistoryActions';
import { VehiclePositionState } from '../../types/busHistoryTypes';
import { DirectionVariant, GtfsStop2 } from '../../types/gtfsTypes';
import { Dictionary } from '../../types/view-models-interfaces';
import { ActionType, BusPositionHistoryAction } from './types/ActionType';
import { AgencyModel } from './types/agencyModel';
import { BphDirectionsState, BphRoutesState, BphSliderState, DateTimeState, StopPredictionsState } from './types/bphStates';
import { BphDirection, BphDirectionModel, BphRouteModel, BphStopModel, BphStopsStateType, BphVehicleModel, BusPositionHistoryStateType } from './types/BphTypes';
import { DateTime } from './types/dateTime';
import { DirectionsMapDisplayPool } from './types/DirectionsMapDisplayPool';
import { InternalInstanceHelper, PublicInstanceHelper } from './types/InternalInstanceHelper';
import { Optional } from './types/Optional';
import { SliderInfoProps as BphSliderInfoProps } from './types/SliderInfoProps';
import { StopsMapDisplayPool } from './types/StopsMapDisplayPool';
import { VehicleMapDisplayPool } from './types/VehicleMapDisplayPool';


export const initialState: BusPositionHistoryStateType = {
    helper: (undefined as unknown) as PublicInstanceHelper,

    mapReady: false,
    agency: null,
    dateTime: {} as DateTimeState,

    routesLoading: false,
    routesState: {
        routes: null,
        selectedRoute: null,
        initialRoutesList: [],
    },
    directionsLoading: false,
    directionsState: {
        directions: null,
        selectedDirection: null,
    },
    directionsInfo: [],
    stopsLoading: false,
    stopsState: {
        stops: null,
        selectedStop: null,
        loadingSchedule: false,
    },
    stopsSearchState: {
    },
    stopsNoResultMessageState: undefined,
    stopsPredictions: null,

    historyLoading: false,
    busPositionsState: [],

    scheduleDates: null,
    sliderOptions: null,
    sliderInfo: null,
};

export function composeForRoute(directionVariant: DirectionVariant) {
    return `${directionVariant.directionVariantName}`;
}

export function composeForStop(directionVariant: DirectionVariant) {
    return `${directionVariant.routeName} ${directionVariant.directionVariantName}`;
}

function getFormattedDateTime(date: string, time: string): string {
    return `${date} ${time}`;
}

function getInitialDateTime(): DateTimeState {
    const dt = moment(new Date().setMinutes(0, 0, 0));
    const date = dt.format('YYYY-MM-DD');
    const time = dt.format('h:mm A');
    return {
        selectedDate: date,
        selectedTime: time,
        selectedDateTime: getFormattedDateTime(date, time),
    };
}

export function initializeState(src: BusPositionHistoryStateType): BusPositionHistoryStateType {
    if (!src.helper) {
        const result: BusPositionHistoryStateType = {
            ...src,
            helper: new InstanceHelper(),
            dateTime: getInitialDateTime(),
        };
        return result;
    }
    return src;
}

export function cast(state: BusPositionHistoryStateType): InternalInstanceHelper {
    if (state.helper instanceof InstanceHelper)
        return state.helper;

    console.error('BusPositionHistoryContext: invalid state.internals');
    return (state.helper as unknown) as InternalInstanceHelper;
}

export class InstanceHelper implements PublicInstanceHelper, InternalInstanceHelper {
    static nextUid = 0;

    public readonly uid: number;

    public dispatch: React.Dispatch<BusPositionHistoryAction>;

    constructor() {
        this.uid = ++InstanceHelper.nextUid;

        this.dispatch = (undefined as unknown) as React.Dispatch<BusPositionHistoryAction>;
        this._spool = new StopsMapDisplayPool(this);
        this._vpool = new VehicleMapDisplayPool(this);
        this._tpool = new DirectionsMapDisplayPool(this);
    }

    private _setMapBounds(value: Microsoft.Maps.LocationRect) {
        this._map?.setView({ bounds: value });
    }

    private _mapReady = initialState.mapReady;
    public _mapDiv?: HTMLDivElement;
    public _map?: Microsoft.Maps.Map;

    public onMapReady(div: HTMLDivElement): void {
        this._mapReady = !!div;
        this._mapDiv = div;
        this.dispatch({ type: ActionType.SET_MAP_READY, payload: this._mapReady });
    }

    public async setAgency(state: BusPositionHistoryStateType, agency: AgencyModel | null) {
        if (state.agency === agency) return;
        this.dispatch({ type: ActionType.SET_AGENCY, payload: agency });
        return true;
    }

    public updateDateTime(state: BusPositionHistoryStateType, date: Optional<string>, time: Optional<string>): boolean {
        date = date || state.dateTime.selectedDate;
        time = time || state.dateTime.selectedTime;

        const dateChanged = date !== state.dateTime.selectedDate;
        const timeChanged = time !== state.dateTime.selectedTime;

        if (!dateChanged && !timeChanged) {
            return false;
        }
        const value: DateTimeState = {
            selectedDate: date,
            selectedTime: time,
            selectedDateTime: getFormattedDateTime(date, time),
        };
        this.dispatch({ type: ActionType.SET_DATE_TIME, payload: value });
        if (!dateChanged && timeChanged) {
            if (!state.routesState?.selectedRoute) {
                this._setStopsState(null);
            }
            this.selectRoute(state, null);
        }
        return true;
    }

    private _setRoutesState(value: Optional<BphRoutesState>): void {
        this.dispatch({ type: ActionType.SET_ROUTES_STATE, payload: value || initialState.routesState });
    }

    public selectRoute(state: BusPositionHistoryStateType, value: Optional<BphRouteModel>): boolean {
        this._tpool.forceClear();
        this._spool.empty();
        this._setStopsState(null);
        value = value || null;
        if (state.routesState.selectedRoute === value) return false;
        // if (!value) {
        //     this._setStopsState(null);
        // }
        // else {
        //     this.selectStop(state, state.stopsState.selectedStop);
        // }
        this.dispatch({
            type: ActionType.SET_ROUTES_STATE, payload: {
                ...state.routesState,
                selectedRoute: value,
            },
        });
        return true;
    }

    private _setDirections(value?: Optional<BphDirectionModel[]>): void {
        const v: BphDirectionsState = {
            directions: value || null,
            selectedDirection: null,
        };
        this.dispatch({ type: ActionType.SET_DIRECTIONS_STATE, payload: v });
        if (!value) {
            this._tpool.empty();
            this._vpool.empty();
        }
        else {
            const dirs: Dictionary<BphDirectionModel> = {};
            for (const d of value) {
                this._tpool.add(d);
                dirs[d.directionVariantId] = d;
            }
            this._tpool.set(dirs);
        }
    }

    selectDirection(state: BusPositionHistoryStateType, value: Optional<BphDirectionModel>): boolean {
        value = value || null;
        if (state.directionsState.selectedDirection === value) return false;
        if (state.directionsState.selectedDirection) {
            state.directionsState.selectedDirection.select(false);
        }
        this._tpool.select(value || null);
        this.dispatch({
            type: ActionType.SET_DIRECTIONS_STATE, payload: {
                directions: state.directionsState.directions,
                selectedDirection: value,
            },
        });
        return true;
    }

    private _setStopsLoading(value: boolean): void {
        this.dispatch({ type: ActionType.SET_STOPS_LOADING, payload: !!value });
    }

    private _setStopsState(value: Optional<BphStopsStateType>): void {
        const v = value || initialState.stopsState;
        this.dispatch({ type: ActionType.SET_STOPS_STATE, payload: v });
        this._spool.select(v.selectedStop);
    }

    public selectStop(state: BusPositionHistoryStateType, value: Optional<BphStopModel>): boolean {
        value = value || null;
        if (state.stopsState.selectedStop === value) return false;
        this._spool.select(value);
        this._setStopsState({
            stops: state.stopsState.stops,
            selectedStop: value,
            loadingSchedule: false,
        });
        if (value) {
            this._map?.setView({
                center: value.pushpin.getLocation(),
            });
        }
        return true;
    }

    public setStopsSearchString(state: BusPositionHistoryStateType, value: Optional<string>): void {
        if (state.routesState.selectedRoute)
            return;
        if (!value) {
            this._setStopsNoResutMessageState('');
        }
        this.dispatch({
            type: ActionType.SET_STOP_SEARCH_STATE,
            payload: { searchQuery: value },
        });
    }

    private _setStopsNoResutMessageState(value: Optional<string>): void {
        this.dispatch({ type: ActionType.SET_NO_STOPS_MESSSAGE, payload: value || null });
    }
    private _setDirectionsInfo(value: Optional<BphDirection[]>): void {
        this.dispatch({ type: ActionType.SET_DIRECTIONS_INFO, payload: value || initialState.directionsInfo });
    }
    private _setStopPredictions(value: Optional<StopPredictionsState>): void {
        this.dispatch({ type: ActionType.SET_STOPS_PREDICTIONS, payload: value || initialState.stopsPredictions });
    }
    private _setBusPositionsState(value: Optional<VehiclePositionState[]>): void {
        this.dispatch({ type: ActionType.SET_BUS_POSITIONS, payload: value || initialState.busPositionsState });
    }
    private _setScheduleDates(value: Optional<DateTime[]>): void {
        this.dispatch({ type: ActionType.SET_SCHEDULE_DATES, payload: value || null });
    }
    private _setSliderOptions(value: Optional<BphSliderState>): void {
        this.dispatch({ type: ActionType.SET_SLIDER_OPTIONS, payload: value || null });
    }
    private _setSliderInfo(value: Optional<BphSliderInfoProps>): void {
        this._vpool.highlight(value?.vehiclePosition.vehicleId);
        this.dispatch({ type: ActionType.SET_SLIDER_INFO, payload: value || null });
    }

    //---

    private _setRoutesLoading(value: boolean) {
        this.dispatch({ type: ActionType.SET_ROUTES_LOADING, payload: value });
    }

    public async onSelectedDateChanged(state: BusPositionHistoryStateType) {
        this._setRoutesState(null);
        this._setStopsState(null);
        this._setDirections(null);
        this._setDirectionsInfo(null);
        this._setBusPositionsState(null);

        this._tpool.empty();
        this._vpool.empty();

        if (!state.agency || !state.dateTime.selectedDate || !state.mapReady) return;

        this._setRoutesLoading(true);

        const serviceDate = moment(state.dateTime.selectedDate).format('YYYY-MM-DD');
        try {
            const resRoutes = await getRoutesPairs(state.agency.id, serviceDate);
            const agencyLocation = state.agency?.location || new Microsoft.Maps.Location(38.87757365244203, -77.10571938437442);
            if (this._map) {
                this._map.setView({
                    center: agencyLocation,
                });
            }
            else {
                if (!this._mapDiv) throw new Error('BPH: map div is not ready');
                this._map = new Microsoft.Maps.Map(this._mapDiv, {
                    center: agencyLocation,
                    zoom: 12,
                });
            }
            this._setRoutesState({
                routes: resRoutes.map(item => new BphRouteModel(item.key, item.value)),
                selectedRoute: null,
                initialRoutesList: resRoutes.map(item => new BphRouteModel(item.key, item.value)),
            });
        }
        finally {
            this._setRoutesLoading(false);
        }
    }

    private _setDirectionsLoading(value: boolean) {
        this.dispatch({ type: ActionType.SET_DIRECTIONS_LOADING, payload: value });
    }

    private _setHistoryLoading(value: boolean) {
        this.dispatch({ type: ActionType.SET_HISTORY_LOADING, payload: value });
    }

    private async _updateSliderData(agencyId: string, selectedDateState: string, direction: Nullable<BphDirectionModel>) {
        if (!agencyId || !direction) return;

        this._vpool.empty();
        this._setHistoryLoading(true);
        try {
            const time = moment(selectedDateState).format('YYYY-MM-DDTHH:mm:ss');
            const vehiclePositions = await getPositions(agencyId, direction.directionVariantId, time);
            this._setBusPositionsState(vehiclePositions);
        }
        finally {
            this._setHistoryLoading(false);
        }
    }

    public async onSelectedRouteChanged(state: BusPositionHistoryStateType) {
        this._tpool.empty();
        if (!state.stopsState.selectedStop) {
            this._setDirections(null);
            this._setDirectionsInfo(null);
            this._setBusPositionsState(null);
            this._setScheduleDates(null);
        }

        if (!state.agency)
            return;

        if (state.routesState.selectedRoute) {
            // use directions by route

            try {
                this._setDirectionsLoading(true);
                const directionVariants = state.stopsState.selectedStop?.stopId ?
                    await getDirectionVariantsForStop(state.agency.id, state.stopsState.selectedStop.stopId, state.routesState.selectedRoute.routeId, state.dateTime.selectedTime) :
                    await getDirectionVariantsByRoute(state.routesState.selectedRoute.routeId, state.dateTime.selectedTime);
                if (directionVariants) {
                    this._showDirections(directionVariants, composeForRoute);
                    if (!state.stopsState.selectedStop) {
                        const directionStops = directionVariants.reduce<GtfsStop2[]>((accumulator, direction) => accumulator.concat(direction.stops), []);
                        this._loadStopsOptions(directionStops);
                    }
                }
            }
            finally {
                this._setDirectionsLoading(false);
            }
        }
    }

    public async onSelectedStopChanged(state: BusPositionHistoryStateType) {
        if (!state.routesState.selectedRoute) {
            this._setDirections(null);
            this._setDirectionsInfo(null);
            this._setBusPositionsState(null);
            this._setScheduleDates(null);
        }
        if (!state.agency)
            return;

        this._spool.select(state.stopsState.selectedStop);

        if (!state.stopsState.selectedStop) {
            this._setRoutesState({
                ...state.routesState,
                routes: state.routesState.initialRoutesList,
                selectedRoute: state.routesState.initialRoutesList.find(r => r.routeId === state?.routesState?.selectedRoute?.routeId) || null,
            });
            this.onSelectedRouteChanged(state);
        }
        if (state.routesState.selectedRoute && state.directionsState.selectedDirection) {
            return;
        }
        if (!state.stopsState.selectedStop && !state.routesState.selectedRoute) {
            this._tpool.empty();
            this._setDirectionsInfo(null);
        }
        if (state.stopsState.selectedStop) {
            // use directions by stop

            this._tpool.empty();
            this._setDirections(null);
            let directionVarintId: Nullable<string> = null;
            try {
                this._setDirectionsLoading(true);
                const directionVariants = await getDirectionVariantsForStop(state.agency.id, state.stopsState.selectedStop.stopId, state.routesState.selectedRoute?.routeId, state.dateTime.selectedTime);
                if (directionVariants) {
                    const directionsForRoutes = state?.routesState?.selectedRoute?.displayName ?
                        directionVariants.filter(d => d.routeName === state?.routesState?.selectedRoute?.displayName) :
                        directionVariants;
                    this._showDirections(directionsForRoutes, composeForStop);
                    directionVarintId = directionsForRoutes[0].directionVariantId;
                    if (state && state.routesState && state.routesState.routes && state.routesState.routes.length > 0 && !state.routesState.selectedRoute) {
                        const routes = state.routesState.initialRoutesList.filter(r => directionVariants.map(v => v.routeName).includes(r.displayName));
                        this._setRoutesState({
                            ...state.routesState,
                            routes,
                            selectedRoute: routes.find(r => r.routeId === state?.routesState?.selectedRoute?.routeId) || null,
                        });
                    }
                }
            }
            finally {
                this._setDirectionsLoading(false);
                if(state.directionsState.directions && directionVarintId)
                    this.selectDirection(state, state.directionsState.directions.find(d => d.directionVariantId === directionVarintId));
            }
        }
    }

    public async onSelectedDirectionChanged(state: BusPositionHistoryStateType) {
        if (!state.agency) return;

        this._setBusPositionsState(null);
        this._setSliderInfo(null);

        const isDisplayDirectionStops = this._spool.isDisplayDirectionStops;
        if (isDisplayDirectionStops)
            this._spool.switchDirectionStops(false);
        if (state.directionsState.selectedDirection) {
            this._setMapBounds(state.directionsState.selectedDirection.bounds);
            this._spool.setDirectionStops(state.directionsState.selectedDirection.getStops());
            if (isDisplayDirectionStops)
                this._spool.switchDirectionStops(true);
        } else {
            this._spool.setDirectionStops([]);
        }

        await this._updateSliderData(state.agency.id, state.dateTime.selectedDateTime, state.directionsState.selectedDirection);
    }

    public async onStopSearchParamsChanged(state: BusPositionHistoryStateType) {
        if (state.agency &&
            state.stopsSearchState &&
            state.stopsSearchState.searchQuery
        ) {
            this._setStopsLoading(true);
            try {
                const result = await getStops(state.agency.id,
                    state.stopsSearchState.searchQuery,
                    state.directionsState.selectedDirection?.directionVariantId,
                    state.routesState.selectedRoute?.routeId);
                if (result) {
                    this._loadStopsOptions(result);
                }
            }
            finally {
                this._setStopsLoading(false);
            }
        }
    }

    public async onStopAndDirectionChanged(state: BusPositionHistoryStateType): Promise<void> {
        this._setStopPredictions(null);
        this._setScheduleDates(null);

        const stopId = state.stopsState.selectedStop?.stopId;
        const selectedDirection = state.directionsState.selectedDirection;

        if (!state.agency || !selectedDirection || !stopId) return;

        this._setStopsState({ ...state.stopsState, loadingSchedule: true });

        const serviceDate = DateTime.fromStringAgencyDateTime(state.dateTime.selectedDateTime);
        const directionVariantId = selectedDirection.directionVariantId;
        try {
            const [resSchedule, resPredictions] = await Promise.all([
                getSchedule(serviceDate.format('YYYY-MM-DD'), stopId, directionVariantId),
                getStopPredictions(state.agency.id, stopId, directionVariantId, serviceDate.format('YYYY-MM-DDTHH:mm:ss')),
            ]);
            if (resSchedule) {
                const scheduleRange = (resSchedule as any as string[])
                    .map(s => DateTime.fromStringUtcAsAgency(s));
                this._setScheduleDates(scheduleRange);
            } else {
                this._setScheduleDates(null);
            }
            this._setStopPredictions(new StopPredictionsState(resPredictions));
        }
        catch (_error) {
            console.error('BusHistory: getSchedule failed', _error);
        } finally {
            this._setStopsState({ ...state.stopsState, loadingSchedule: false });
        }
    }

    public onSliderTimeChanged(state: BusPositionHistoryStateType, sliderValue: number): void {
        if (state.busPositionsState.length === 0) {
            this._setSliderInfo(null);
            return;
        }

        const aheadPosition = this._onTimeSliderChange(state.busPositionsState, sliderValue);

        const position = state.busPositionsState[sliderValue];

        if (state.directionsState.selectedDirection && position) {
            const selectedDirection = state.directionsState.selectedDirection;
            this._setSliderInfo({
                routeName: selectedDirection.routeName,
                stopName: selectedDirection.lastStop,
                vehiclePosition: position,
                aheadPosition: aheadPosition,
            });
        }
    }

    public onDisplayDirectionStopsChanged(value: boolean): void {
        this._spool.switchDirectionStops(value);
    }

    public onBusPositionsChanged(state: BusPositionHistoryStateType): void {
        this._vpool.empty();

        if (state.busPositionsState.length > 0) {
            const sliderValue = Math.floor(state.busPositionsState.length / 2);
            this._setSliderOptions({
                maxValue: state.busPositionsState.length - 1,
                sliderValue: sliderValue,
            });
            this.onSliderTimeChanged(state, sliderValue);
        } else {
            this._setSliderOptions(null);
        }
    }

    private _showDirections(directionVariants: DirectionVariant[], nameComposer: (v: DirectionVariant) => string) {
        let bounds: Microsoft.Maps.LocationRect | null = null;
        const infos: BphDirection[] = [];
        const directions: BphDirectionModel[] = [];
        for (const variant of directionVariants) {
            const direction = new BphDirectionModel(
                nameComposer(variant),
                variant,
            );
            directions.push(direction);
            infos.push({
                id: variant.directionVariantId,
                route: variant.routeName,
                stop: variant.lastStop,
            });
            bounds = bounds === null
                ? direction.bounds.clone()
                : Microsoft.Maps.LocationRect.merge(bounds, direction.bounds);
        }
        if (bounds) {
            this._setMapBounds(bounds);
        }
        this._setDirectionsInfo(infos);
        this._setDirections(directions);
    }

    private _loadStopsOptions(stops: GtfsStop2[]) {
        if (stops.length === 1 && !stops[0].stopId) { // no stops found
            this._setStopsState(null);
            this._setStopsNoResutMessageState(stops[0].stopName);
        } else {
            const directionStops = Array.from(new Set(stops.map(s => {
                const r = JSON.stringify({
                    stopId: s.stopId,
                    displayName: s.stopName,
                    lat: s.lat,
                    lon: s.lon,
                });
                return r;
            }))).map(s => JSON.parse(s));
            this._setStopsState({
                stops: directionStops.map(s => new BphStopModel(s.displayName, s.stopId, s.lat, s.lon)),
                selectedStop: null,
                loadingSchedule: false,
            });
        }
    }

    private readonly _spool: StopsMapDisplayPool;
    private readonly _tpool: DirectionsMapDisplayPool;
    private readonly _vpool: VehicleMapDisplayPool;

    private _onTimeSliderChange(
        busPositionsState: VehiclePositionState[],
        sliderValue: number,
    ): VehiclePositionState | null {
        const vehicles: Dictionary<BphVehicleModel> = {}; // key is `position.vehicleId`

        const position = busPositionsState[sliderValue];

        this._addVehicle(vehicles, position);

        const ahead: VehiclePositionState | null = null;

        const maxTripDuration = 5 * 60000; // 5 min

        const lowerBoundTime = moment.parseZone(position.reportTimeAtz).valueOf() - maxTripDuration / 2;
        const upperBoundTime = moment.parseZone(position.reportTimeAtz).valueOf() + maxTripDuration / 2;

        // scan array from 'present'+1 to the 'future'
        //
        for (let ix = sliderValue + 1; ix < busPositionsState.length; ++ix) {
            const sibling = busPositionsState[ix];

            if (moment.parseZone(sibling.reportTimeAtz).valueOf() > upperBoundTime)
                break;

            if (!(sibling.vehicleId in vehicles)) {
                this._addVehicle(vehicles, sibling);
            }
        }

        // scan array from 'present'-1 to the 'past'
        //
        for (let ix = sliderValue - 1; ix >= 0; --ix) {
            const sibling = busPositionsState[ix];

            if (moment.parseZone(sibling.reportTimeAtz).valueOf() < lowerBoundTime)
                break;

            if (!(sibling.vehicleId in vehicles)) {
                this._addVehicle(vehicles, sibling);
            }
        }

        this._vpool.set(vehicles);

        return ahead;
    }

    private _addVehicle(
        vehicles: Dictionary<BphVehicleModel>,
        position: VehiclePositionState,
    ) {
        let vehicle: BphVehicleModel;
        if (this._vpool.has(position.vehicleId)) {
            vehicle = this._vpool.get(position.vehicleId);
            vehicle.update(position);
        }
        else {
            vehicle = new BphVehicleModel(position);
            this._vpool.add(vehicle);
        }
        vehicles[vehicle.id] = vehicle;
    }
}
