import BlockUi from '@availity/block-ui';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import { approveNotificationSending } from '../../actions/pushNotificationActions';
import { ErrorType } from '../../types/types';

const ConfirmedPushNotificationForm: React.FC = () => {
    const [formBlockingState, setFormBlockingState] = useState(true);
    const [errorMessageState, setErrorMessageState] = useState<string | null>(null);

    const location = useLocation();

    const isErrorType = (err: any | unknown): err is ErrorType => {
        return (err && typeof err === 'object' && ('message' in err || ('response' in err && 'data' in err.response)));
    };
    
    useEffect(() => {
        const queryParameters = new URLSearchParams(location.search);
        const code = queryParameters.get('code') ?? '';
        const email = queryParameters.get('email') ?? '';
        (async (code: string, email: string) => {
            try {
                await approveNotificationSending(code, email);
            }
            catch (error) {
                if (isErrorType(error)) {
                    const castedError = error as ErrorType;
                    const errorMessage = castedError?.response?.data || castedError.message || null;
                    setErrorMessageState(errorMessage);
                }
                else{
                    console.log(error);
                }
            }
            finally {
                setFormBlockingState(false);
            }
        })(code, email);
    }, []);

    return (
        <Fragment>
            <BlockUi tag="div" message="preparing..." blocking={formBlockingState}>
                {errorMessageState === null && <Header as="h2" className="reportHeader">
                    Message Sent.
                </Header>
                }
                <h2>{errorMessageState}</h2>
            </BlockUi>
        </Fragment>
    );
};


export default connect()(ConfirmedPushNotificationForm);
