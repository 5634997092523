import * as React from 'react';
import { DateInput, DateInputProps } from 'semantic-ui-calendar-react-17';
import { Form } from 'semantic-ui-react';

interface Props {
    className?: string;
    startDate: string;
    setStartDate: (newStartDate: string) => void;
    endDate: string;
    setEndDate: (newEndDate: string) => void;
}

const StartAndEndDatesField: React.FC<Props> = ({ className: classNameProp, startDate, setStartDate, endDate, setEndDate }) => {
    const className = classNameProp ? `start-and-end-dates ${classNameProp}` : 'start-and-end-dates';
    const handleStartDateChange: DateInputProps['onChange'] = (_, { value }) => setStartDate(value);
    const handleEndDateChange: DateInputProps['onChange'] = (_, { value }) => setEndDate(value);
    return (
        <>
            <Form.Field className={className}>
                <label className="calendarInputLabel">Start Date:</label>
                <DateInput
                    name="startDateCalendar"
                    dateFormat="YYYY-MM-DD"
                    placeholder="Select date"
                    value={startDate}
                    iconPosition="left"
                    popupPosition="bottom center"
                    closable={true}
                    animation="fade"
                    pickerWidth="30px"
                    onChange={handleStartDateChange}
                />
            </Form.Field>
            <Form.Field className={className}>
                <label className="calendarInputLabel">End Date:</label>
                <DateInput
                    name="endDateCalendar"
                    dateFormat="YYYY-MM-DD"
                    placeholder="Select date"
                    value={endDate}
                    iconPosition="left"
                    popupPosition="bottom center"
                    closable={true}
                    animation="fade"
                    pickerWidth="30"
                    onChange={handleEndDateChange}
                />
            </Form.Field>
        </>
    );
};

export default StartAndEndDatesField;
