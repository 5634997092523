import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Dropdown, DropdownItemProps, DropdownProps, Form, Header, Tab, TabProps } from 'semantic-ui-react';
import { AgencyStateType } from '../../../../actions/actionTypes';
import { getArrivalsForStop, getStopDelaysData } from '../../../../actions/otpActions';
import Api from '../../../../api/api';
import { AppState } from '../../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../../selectors';
import busStopIcon from '../../../../static/bus-stop-20.png';
import { InfoboxProps, MapEventHandlerProps, PushpinProps } from '../../../../types/BingMapProps';
import { ArrivalForStop, StopDelayData } from '../../../../types/delaysTypes';
import BingMapQuerier from '../../../../utilities/BingMapQuerier';
import Utils from '../../../../utilities/utils';
import BingMapWithOverlay from '../../../shared/BingMapWithOverlay';
import StartAndEndDatesField from '../../../shared/StartAndEndDatesField';
import ArrivalsForStopTable from './ArrivalsForStopTable';
import ColorsScaleTable from './ColorsScaleTable';
import { colorsScaleValuesMeanDelay, colorsScaleValuesPercentage, delaysMetricDropDownState, initialStopsState } from './constants';
import DelaysDistributionChart from './DelaysDistributionChart';
import { drawStops } from './drawing';
import StopTable from './StopTable';
import tableSortReducer from './tableSortReducer';


interface Props {
    agency: AgencyStateType | undefined;
}
interface StopPosition {
    stopData: StopDelayMetric;
    description: string;
}
interface MapData {
    zoom: number;
    bounds: Microsoft.Maps.LocationRect;
}
export interface StopDelayMetric extends StopDelayData {
    metricName: string;
    metricValue: number;
    meanDelay: number;
    earlyPercentage: number;
    onTimePercentage: number;
    latePercentage: number;
    veryLatePercentage: number;
}
export interface DelaysDistibutionChartData {
    totalArrivals: number;
    meanDelay: number;
    early: number;
    onTime: number;
    late: number;
    veryLate: number;
    earlyPercentage: number;
    onTimePercentage: number;
    latePercentage: number;
    veryLatePercentage: number;
}

export interface ColorsScaleRowData {
    color: string;
    description: string;
}

const styles = {
    chartDescription: {
        marginBottom: '30px',
    } as React.CSSProperties,
    mapContainer: {
        height: '670px',
    } as React.CSSProperties,
    loadTripsContainer: {
        marginBottom: '10px',
    } as React.CSSProperties,
    rightGroupContainer: {
        marginTop: '35px',
    } as React.CSSProperties,
    tabContainer: {
        paddingBottom: '10px',
        marginBottom: '10px',
    } as React.CSSProperties,
    stopsTableContainer: {
        marginBottom: 0,
    } as React.CSSProperties,
};

const StopDelaysForm: React.FC<Props> = ({ agency }) => {
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [center, setCenter] = useState<CoordinatePair>();
    const [zoom, setZoom] = useState<number | undefined>(undefined);
    const [startDate, setStartDate] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date().setMinutes(0, 0, 0)).format('YYYY-MM-DD'));
    const [delaysState, setDelaysState] = useState<StopDelayData[]>([]);
    const [metricsState, setMetricsState] = useState<StopDelayMetric[]>([]);
    const [selectedStopDelay, setSelectedStopDelay] = useState<StopDelayMetric | undefined>(undefined);
    const [selectedMetricState, setSelectedMetricState] = useState(delaysMetricDropDownState);
    const [chartDataState, setChartDataState] = useState<DelaysDistibutionChartData>();
    const [pushpinsState, setPushpinsState] = useState<PushpinProps[]>([]);
    const [infoboxesState, setInfoboxesState] = useState<InfoboxProps[]>([]);
    const [mapEventHandlers, setMapEventHandlers] = useState<MapEventHandlerProps[]>([]);
    const [mapDataState, setMapDataState] = useState<MapData | undefined>(undefined);
    const [arrivalsState, setArrivalsState] = useState<ArrivalForStop[]>([]);
    const [selectedArrivalState, setSelectedArrivalState] = useState<ArrivalForStop | undefined>(undefined);
    const [colorsScaleState, setColorsScaleState] = useState<ColorsScaleRowData[]>(colorsScaleValuesMeanDelay);
    const [stopTableState, stopTableDispatch] = React.useReducer(tableSortReducer, {
        column: '',
        data: [],
        direction: undefined,
    });
    const [stopsDropdownState, setStopsDropdownState] = useState(initialStopsState);
    const [tabActiveIndexState, setTabActiveIndexState] = useState<number>(0);

    const selectedRowHandler = (stopData: StopDelayMetric) => {
        updateSelectedStopData(stopData);
    };

    const updateSelectedStopData = (stopData: StopDelayMetric) => {
        setSelectedStopDelay(stopData);
        setChartDataState({
            totalArrivals: stopData.totalArrivals,
            meanDelay: stopData.meanDelay,
            early: stopData.early,
            onTime: stopData.onTime,
            late: stopData.late,
            veryLate: stopData.veryLate,
            earlyPercentage: stopData.earlyPercentage,
            onTimePercentage: stopData.onTimePercentage,
            latePercentage: stopData.latePercentage,
            veryLatePercentage: stopData.veryLatePercentage,
        });
        setArrivalsState([]);
        navigateToStop(stopData);
    };

    const navigateToStop = (stopData: StopDelayMetric) => {
        setZoom(17);
        setCenter([stopData.latitude, stopData.longitude]);
        setInfoboxesState(prevState => (
            prevState.map(i => i.location[0] === stopData.latitude && i.location[1] === stopData.longitude ?
                { ...i, options: { ...i.options, visible: true } } :
                { ...i, options: { ...i.options, visible: false } })
        ));
    };

    const getMapCaption = () => {
        const metricName = selectedMetricState.selectedValue;
        return metricName === 'MeanDelay'
            ? 'Average Delay (in munutes) by Stop'
            : `Percentage of ${delaysMetricDropDownState.options.find(o => o.value === metricName)?.text || metricName} Arrivals by Stop`;
    };

    const updateMapData = (e: Microsoft.Maps.IMouseEventArgs) => {
        const map = e.target as Microsoft.Maps.Map;
        const mapData = map ? { zoom: map.getZoom(), bounds: map.getBounds() } : undefined;
        setMapDataState(mapData);
    };

    const getStopPositions = (): StopPosition[] => {
        if (!mapDataState)
            return [];
        const { zoom, bounds } = mapDataState;
        if (zoom < 16)
            return [];
        const stopPositions: StopPosition[] = [];
        stopPositions.push(...metricsState
            .filter(stop => { return bounds.contains(new Microsoft.Maps.Location(stop.latitude, stop.longitude)); })
            .map((stop) => {
                let desc = `<div>${stop.stopId || ''}</div>`;
                desc += `<div>${stop.stopName}</div>`;
                return { stopData: stop, description: desc };
            }));
        return stopPositions;
    };

    const drawPushpins = () => {
        const stopPositions = getStopPositions();
        if (stopPositions.length > 0) {
            setPushpinsState(
                stopPositions.map(({ stopData }) => ({
                    location: [stopData.latitude, stopData.longitude],
                    options: {
                        icon: busStopIcon,
                        catId: 'bus-stop',
                    },
                    eventHandlers: [{
                        event: 'click',
                        callback: () => {
                            setInfoboxesState(prevState => (
                                prevState.map(i => i.location[0] === stopData.latitude && i.location[1] === stopData.longitude ?
                                    { ...i, options: { ...i.options, visible: true } } :
                                    { ...i, options: { ...i.options, visible: false } })
                            ));
                            setSelectedStopDelay(stopData);
                            setChartDataState({
                                totalArrivals: stopData.totalArrivals,
                                meanDelay: stopData.meanDelay,
                                early: stopData.early,
                                onTime: stopData.onTime,
                                late: stopData.late,
                                veryLate: stopData.veryLate,
                                earlyPercentage: stopData.earlyPercentage,
                                onTimePercentage: stopData.onTimePercentage,
                                latePercentage: stopData.latePercentage,
                                veryLatePercentage: stopData.veryLatePercentage,
                            });
                            setArrivalsState([]);
                        },
                    }],
                })));
            setInfoboxesState(
                stopPositions.map(({ stopData: { latitude, longitude }, description }) => ({
                    location: [latitude, longitude],
                    options: {
                        description: description,
                        visible: Boolean(selectedStopDelay && selectedStopDelay.latitude === latitude && selectedStopDelay.longitude === longitude),
                    },
                })));
        } else {
            setPushpinsState([]);
            setInfoboxesState([]);
        }
    };

    useEffect(
        () => {
            if (delaysState.length > 0) {
                setMapEventHandlers([{
                    event: 'viewchangeend', callback: updateMapData,
                }]);

            }
            return () => {
                setMapEventHandlers([]);
            };
        }, [delaysState]);

    useEffect(
        () => {
            drawPushpins();
        }, [mapDataState]);

    const updateStopDelaysData = async () => {
        setArrivalsState([]);
        setSelectedArrivalState(undefined);
        setSelectedStopDelay(undefined);
        if (agency) {
            try {
                setFormBlockingState(true);
                const delaysData = await getStopDelaysData(agency.id, startDate, endDate);
                setDelaysState(delaysData);
                const metricsData = getMetricsData(selectedMetricState.selectedValue as string, delaysData);
                setMetricsState(metricsData);
                const chartData = getOverallChartData(delaysData);
                setChartDataState(chartData);
            } catch {
                setDelaysState([]);
                setMetricsState([]);
                setChartDataState(undefined);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setDelaysState([]);
            setMetricsState([]);
            setChartDataState(undefined);
        }
    };

    const getOverallChartData = (delaysData: StopDelayData[]): DelaysDistibutionChartData => {
        const early = delaysData.reduce((sum: number, data: StopDelayData) => data.early + sum, 0);
        const onTime = delaysData.reduce((sum: number, data: StopDelayData) => data.onTime + sum, 0);
        const late = delaysData.reduce((sum: number, data: StopDelayData) => data.late + sum, 0);
        const veryLate = delaysData.reduce((sum: number, data: StopDelayData) => data.veryLate + sum, 0);
        const totalArrivals = delaysData.reduce((sum: number, data: StopDelayData) => data.totalArrivals + sum, 0);
        const totalDelay = delaysData.reduce((sum: number, data: StopDelayData) => data.delaySec + sum, 0);
        const meanDelay = totalArrivals !== 0 ? Math.round(totalDelay / totalArrivals) : 0;
        const chartData: DelaysDistibutionChartData = {
            totalArrivals,
            meanDelay,
            early,
            onTime,
            late,
            veryLate,
            earlyPercentage: Math.round((early / totalArrivals) * 100),
            onTimePercentage: Math.round((onTime / totalArrivals) * 100),
            latePercentage: Math.round((late / totalArrivals) * 100),
            veryLatePercentage: Math.round((veryLate / totalArrivals) * 100),
        };
        return chartData;
    };

    const loadTrips = async (e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        if (agency && selectedStopDelay) {
            try {
                setFormBlockingState(true);
                const arrivalsData = await getArrivalsForStop(agency.id, startDate, endDate, selectedStopDelay.stopId);
                setArrivalsState(arrivalsData);
            } catch {
                setArrivalsState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setArrivalsState([]);
        }
    };

    const handleModeChange = (_e: React.SyntheticEvent<HTMLElement, Event>, { value: selectedMetric }: DropdownProps) => {
        const metricName = selectedMetric as string;
        setSelectedMetricState(prevState => ({
            ...prevState,
            selectedValue: metricName,
        }));
        setColorsScaleState(metricName === 'MeanDelay' ? colorsScaleValuesMeanDelay : colorsScaleValuesPercentage);
        const metricsData = getMetricsData(metricName, delaysState);
        setMetricsState(metricsData);
    };

    const handleRemoveSelectedStop = () => {
        setArrivalsState([]);
        setSelectedArrivalState(undefined);
        setSelectedStopDelay(undefined);
        const chartData = getOverallChartData(delaysState);
        setChartDataState(chartData);
    };

    const getMetricsData = (metricName: string, delaysData: StopDelayData[]): StopDelayMetric[] => {
        const mapData: StopDelayMetric[] = delaysData.map(data => {
            let metricValue: number;
            const meanDelay = Math.round(data.delaySec / data.totalArrivals);
            const earlyPercentage = Math.round((data.early / data.totalArrivals) * 100);
            const onTimePercentage = Math.round((data.onTime / data.totalArrivals) * 100);
            const latePercentage = Math.round((data.late / data.totalArrivals) * 100);
            const veryLatePercentage = Math.round((data.veryLate / data.totalArrivals) * 100);
            switch (metricName) {
                case 'MeanDelay':
                    metricValue = Math.round(meanDelay / 60);
                    break;
                case 'Early':
                    metricValue = earlyPercentage;
                    break;
                case 'OnTime':
                    metricValue = onTimePercentage;
                    break;
                case 'Late':
                    metricValue = latePercentage;
                    break;
                case 'VeryLate':
                    metricValue = veryLatePercentage;
                    break;
                default:
                    metricValue = 0;
                    break;
            }
            return {
                ...data,
                metricName: metricName,
                metricValue: metricValue,
                meanDelay: meanDelay,
                earlyPercentage,
                onTimePercentage,
                latePercentage,
                veryLatePercentage,
            };
        }).filter(data => data.metricName === 'MeanDelay' || data.metricValue > 0);
        return mapData;
    };

    const handleStopTableColumnSort = (column: string) => {
        stopTableDispatch({ type: 'CHANGE_SORT', column, data: metricsState });
    };

    const handleTabChange = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) => {
        setTabActiveIndexState(data.activeIndex as number);
        if (data.activeIndex === 0 && selectedStopDelay) {
            navigateToStop(selectedStopDelay);
        }
    };

    let foundOptions: DropdownItemProps[] = stopsDropdownState.options;
    const handleStopChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value: stopId }: DropdownProps) => {
        if (!stopId)
            foundOptions = [];
        setStopsDropdownState({
            options: foundOptions,
            selectedValue: stopId as string,
        });
        const selectedStopData = metricsState.find(s => s.stopId === stopId);
        selectedStopData ? updateSelectedStopData(selectedStopData) : handleRemoveSelectedStop();
    };
    const stopsSearch = (_options: DropdownItemProps, query: string) => {
        foundOptions = metricsState
            .filter(({ stopId, stopName }) => stopId.startsWith(query) || stopName.startsWith(query))
            .map(({ stopId, stopName }) => ({ value: stopId, text: stopName }));
        return foundOptions;
    };

    const drawOverlay = useCallback(
        async (map: BingMapQuerier, canvas: HTMLCanvasElement) =>
            drawStops(
                tabActiveIndexState === 0 ? metricsState : [],
                canvas,
                ({ latitude, longitude }) => map.tryLocationToPixel([latitude, longitude]),
            ),
        [metricsState, tabActiveIndexState],
    );

    const tabPanes = [
        {
            menuItem: 'Map',
            pane: (
                <Tab.Pane key="tab1" style={styles.tabContainer}>
                    <Form.Group>
                        <Form.Field width={15} style={styles.mapContainer}>
                            <label className="categoryHeader">
                                <div className="categorySubtitle">{getMapCaption()}</div>
                            </label>
                            <BingMapWithOverlay {...{ mapProps: { map: { center, options: { zoom }, eventHandlers: mapEventHandlers }, pushpins: pushpinsState, infoboxes: infoboxesState }, drawOverlay }} />
                        </Form.Field>
                        <Form.Field>
                            <ColorsScaleTable rows={colorsScaleState} header={selectedMetricState.selectedValue == 'MeanDelay' ? 'Minutes': '%'} />
                        </Form.Field>
                    </Form.Group >
                </Tab.Pane >
            ),
        },
        {
            menuItem: 'Table',
            pane: (
                <Tab.Pane key="tab2" style={styles.tabContainer}>
                    {stopTableState.data.length > 0 && <Form.Group style={styles.stopsTableContainer}>
                        <Form.Field width={16} >
                            <label className="categoryHeader">
                                <div className="categorySubtitle">Click on a row to display details</div>
                            </label>
                            <StopTable tableDataState={stopTableState} selectedRow={selectedStopDelay} selectedRowHandler={selectedRowHandler} columnSortHandler={handleStopTableColumnSort} />
                        </Form.Field>
                    </Form.Group>}
                </Tab.Pane>
            ),
        },
    ];

    useEffect(() => {
        stopTableDispatch(({ type: 'UPDATE_DATA', data: metricsState.sort((a, b) => b.meanDelay - a.meanDelay), column: '' }));
    }, [metricsState]);

    useEffect(() => {
        if (selectedStopDelay) {
            setStopsDropdownState({
                options: [{ value: selectedStopDelay.stopId, text: selectedStopDelay.stopName }],
                selectedValue: selectedStopDelay.stopId,
            });
        }
    }, [selectedStopDelay]);

    useEffect(() => {
        setDelaysState([]);
        setMetricsState([]);
        setArrivalsState([]);
        setSelectedArrivalState(undefined);
        setSelectedStopDelay(undefined);
        setChartDataState(undefined);
        if (agency === undefined) return;
        (async (agencyId: string) => {
            await updateStopDelaysData();
            const { Latitude, Longitude } = await Api.getOrgLocation(agencyId);
            setCenter([Latitude, Longitude]);
        })(agency.id);
    }, [agency?.id]);

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Delays at Stops
                </Header>
                <div style={styles.chartDescription}>
                    The size of the bubble corresponds to the number of arrivals to the stop, while color corresponds to delay. Choose the metric and period you are interested in. Zoom in to see the stop icons. Click on any stop icon to see detailed arrival statistics.
                </div>
                <Form.Group widths="equal" inline className="inputGroup">
                    <Form.Select
                        placeholder="Select Metric"
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={selectedMetricState.options}
                        value={selectedMetricState.selectedValue}
                        onChange={handleModeChange}
                        width={4}
                        className="fieldControl"
                    />
                    <StartAndEndDatesField {...{ startDate, setStartDate, endDate, setEndDate, className: 'calendarField' }} />
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={updateStopDelaysData}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={10}>
                        <Tab panes={tabPanes} renderActiveOnly={false} onTabChange={handleTabChange} />
                    </Form.Field>
                    {chartDataState &&
                        <Form.Field width={6} style={styles.rightGroupContainer}>
                            <label className="categoryHeader">
                                <div>Delays Distribution</div>
                            </label>
                            <Dropdown
                                placeholder="Start typing name or ID of a stop"
                                search={stopsSearch}
                                selection
                                clearable
                                icon={stopsDropdownState.selectedValue ? 'delete' : undefined}
                                openOnFocus={false}
                                selectOnBlur={false}
                                options={stopsDropdownState.options}
                                value={stopsDropdownState.selectedValue}
                                onChange={handleStopChange}
                            />
                            <label className="categoryHeader">
                                <div className="categorySubtitle">Average Delay: {Utils.convertSecondsToMinutes(chartDataState.meanDelay, 'm', 's')}</div>
                                <div className="categorySubtitle">Total Arrivals: {chartDataState.totalArrivals.toLocaleString('en-US')}</div>
                            </label>
                            <DelaysDistributionChart delayDistribution={chartDataState} />
                            {selectedStopDelay &&
                                <>
                                    <Form.Field style={styles.loadTripsContainer} >
                                        <Link to="/" onClick={loadTrips}>Load Arrivals</Link>
                                    </Form.Field>
                                    {arrivalsState.length > 0 &&
                                        <>
                                            <label className="categoryHeader">
                                                <div className="categorySubtitle">Click Bus ID to see its travel history</div>
                                            </label>
                                            <ArrivalsForStopTable rows={arrivalsState} selectedRow={selectedArrivalState} selectedRowHandler={setSelectedArrivalState} />
                                        </>
                                    }
                                </>
                            }
                        </Form.Field>
                    }
                </Form.Group>
            </Form>
        </BlockUi >
    );
};

export default connect(
    (state: AppState) => ({ agency: getSelectedOrDefaultAgency(state) }),
)(StopDelaysForm);
