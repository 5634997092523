import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DateInput } from 'semantic-ui-calendar-react-17';
import { Button, Form, Header } from 'semantic-ui-react';
import { AgencyStateType } from '../../../actions/actionTypes';
import { getTopWrongPredictingRoutes } from '../../../actions/anomaliesActions';
import { AppState } from '../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../selectors';
import { TopWrongPredictingRoute } from '../../../types/anomaliesTypes';
import TopPredictionRoutesTable from './TopPredictionRoutesTable';

interface Props {
    agency: AgencyStateType | undefined;
}

const TopPredictionRoutesForm: React.FC<Props> = ({ agency }) => {
    const [selectedDateState, setSelectedDateState] = useState(moment(new Date().setMinutes(0, 0, 0)).add(-1, 'days').format('YYYY-MM-DD'));
    const [mainState, setMainState] = useState<TopWrongPredictingRoute[]>([]);
    const [formBlockingState, setFormBlockingState] = useState(false);

    useEffect(() => {
        setMainState([]);
        if (agency === undefined) return;
        (async () => await updateMainTableData())();
    }, [agency?.id]);

    const handleSelectedDateChange = async (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: {
        value: string;
    }) => {
        setSelectedDateState(value);
    };

    const updateMainTableData = async () => {
        setMainState([]);
        if (agency && agency.id) {
            try {
                setFormBlockingState(true);
                const wrongPredictingRoutes = await getTopWrongPredictingRoutes(agency.id, selectedDateState);
                setMainState(wrongPredictingRoutes);
            } catch {
                setMainState([]);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setMainState([]);
        }
    };

    const history = useHistory();
    const rowHandler = (rowData: TopWrongPredictingRoute) => {
        const url = '/prediction-analysis/prediction-arrival?'
            + 'routeId=' + rowData.routeId
            + '&routeName=' + rowData.routeName
            + '&directionId=' + rowData.directionId
            + '&dateReport=' + selectedDateState;
        history.push(url);
    };

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">
                    Low prediction routes
                </Header>

                <Form.Group widths="equal" inline className="inputGroup">
                    <Form.Field className="calendarField">
                        <label className="calendarInputLabel">Report Date:</label>
                        <DateInput
                            name="fromDateCalendar"
                            fluid
                            dateFormat="YYYY-MM-DD"
                            placeholder="Select date"
                            value={selectedDateState}
                            iconPosition="left"
                            popupPosition="bottom center"
                            closable={true}
                            animation="fade"
                            onChange={handleSelectedDateChange}
                            className="calendarInput"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Button
                            primary
                            content="Apply"
                            onClick={updateMainTableData}
                            className="primaryButton fieldControl"
                        />
                    </Form.Field>
                </Form.Group>
                {mainState.length > 0 &&
                    <Form.Group className="rowGroup">
                        <Form.Field>
                            <label className="calendarInputLabel">Click on route name to see details</label>
                            <TopPredictionRoutesTable rows={mainState} rowHandler={rowHandler}/>
                        </Form.Field>
                    </Form.Group>}
            </Form>
        </BlockUi>
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(TopPredictionRoutesForm);