import { ChartOptions } from 'chart.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { LineDatePoint } from '../../../types/chartTypes';
import { VehiclesInServiceChartData } from '../../../types/vehicleStatisticsTypes';
import Utils from '../../../utilities/utils';

const chartSettings = {
    labels: [],
    datasets: [
        {
            label: 'Not Reporting',
            lineTension: 0.1,
            backgroundColor: 'rgba(255, 192, 97, 0.8)',
            borderColor: 'rgba(255, 192, 97, 0.8)',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(255, 192, 97, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(255, 192, 97, 0.8)',
            pointHoverBorderColor: 'rgba(255, 192, 97, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
        {
            label: 'In Motion',
            lineTension: 0.1,
            backgroundColor: 'rgba(49, 220, 195, 0.8)',
            borderColor: 'rgba(49, 220, 195, 0.8)',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(49, 220, 195, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(49, 220, 195, 0.8)',
            pointHoverBorderColor: 'rgba(49, 220, 195, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
        {
            label: 'Standing at Stops',
            lineTension: 0.1,
            backgroundColor: 'rgba(255, 131, 104, 0.8)',
            borderColor: 'rgba(255, 131, 104, 0.8)',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(255, 131, 104, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(255, 131, 104, 0.8)',
            pointHoverBorderColor: 'rgba(255, 131, 104, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
        {
            label: 'Standing on Road',
            lineTension: 0.1,
            backgroundColor: 'rgba(130, 225, 128, 0.8)',
            borderColor: 'rgba(130, 225, 128, 0.8)',
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: 'rgba(130, 225, 128, 0.8)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(130, 225, 128, 0.8)',
            pointHoverBorderColor: 'rgba(130, 225, 128, 0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [] as LineDatePoint[],
        },
    ],
};

const options: ChartOptions<'line'> = {
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'day',
            },
            ticks: {
                autoSkip: false,
                stepSize: 1,
            },
            title: {
                display: false,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                callback: function (tickValue: number | string) {
                    return `${Math.round((tickValue as number) / 3600)}h`;
                },
            },
            title: {
                display: false,
            },
            stacked: true,
        },
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    elements: {
        line: {
            tension: 0.4,
        },
    },
    plugins: {
        title: {
            display: false,
            position: 'top',
            text: 'Vehicles in service time average breakdown',
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
                boxWidth: 20,
            },
        },

        filler: {
            propagate: true,
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderColor: 'black',
            borderWidth: 1,
            bodyColor: 'black',
            titleColor: 'black',
            caretPadding: 20,
            callbacks: {
                title: function (tooltipItem) {
                    return moment(tooltipItem[0].parsed.x).format('YYYY-MM-DD');
                },
                labelColor: function (tooltipItem) {
                    if (tooltipItem.datasetIndex === 0) {
                        return {
                            borderColor: 'rgba(255, 192, 97, 0.8)',
                            backgroundColor: 'rgba(255, 192, 97, 0.8)',
                        };
                    } else if (tooltipItem.datasetIndex === 1) {
                        return {
                            borderColor: 'rgba(49, 220, 195, 0.8)',
                            backgroundColor: 'rgba(49, 220, 195, 0.8)',
                        };
                    } else if (tooltipItem.datasetIndex === 2) {
                        return {
                            borderColor: 'rgba(255, 131, 104, 0.8)',
                            backgroundColor: 'rgba(255, 131, 104, 0.8)',
                        };
                    } else {
                        return {
                            borderColor: 'rgba(130, 225, 128, 0.8)',
                            backgroundColor: 'rgba(130, 225, 128, 0.8)',
                        };
                    }
                },
            },
        },
    },
    spanGaps: false,
};

interface Props {
    chartData: VehiclesInServiceChartData[],
}

const ServiceTimeBreakdownChart: React.FC<Props> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);
    const [optionsState, setOptionsState] = useState(options);

    useEffect(() => {
        const orderedChartData = chartData.sort((a, b) => new Date(a.serviceDate).getTime() - new Date(b.serviceDate).getTime());
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: orderedChartData.map(d => {
                    return {
                        x: d.serviceDate,
                        y: d.tripNotReportingSec,
                    };
                }),
            },
                {
                    ...prevState.datasets[1],
                    data: orderedChartData.map(d => {
                        return {
                            x: d.serviceDate,
                            y: d.tripMoveSec,
                        };
                    }),
                },
                {
                    ...prevState.datasets[2],
                    data: orderedChartData.map(d => {
                        return {
                            x: d.serviceDate,
                            y: d.tripOnStopStaySec,
                        };
                    }),
                },
                {
                    ...prevState.datasets[3],
                    data: orderedChartData.map(d => {
                        return {
                            x: d.serviceDate,
                            y: d.tripOnRoadStaySec,
                        };
                    }),
                },
            ],
        }));
        setOptionsState(prevState => ({
            ...prevState,
            plugins: {
                ...prevState.plugins,
                tooltip: {
                    ...prevState.plugins?.tooltip,
                    callbacks: {
                        ...prevState.plugins?.tooltip?.callbacks,
                        label: function (tooltipItem) {
                            let lineType = 'Not Reporting';
                            let value = chartData.map(d => d.tripNotReportingSec)[tooltipItem.dataIndex];
                            if (tooltipItem.datasetIndex === 1) {
                                lineType = 'In Motion';
                                value = chartData.map(d => d.tripMoveSec)[tooltipItem.dataIndex];
                            } else if (tooltipItem.datasetIndex === 2) {
                                lineType = 'Standing at Stops';
                                value = chartData.map(d => d.tripOnStopStaySec)[tooltipItem.dataIndex];
                            } else if (tooltipItem.datasetIndex === 3) {
                                lineType = 'Standing on Road';
                                value = chartData.map(d => d.tripOnRoadStaySec)[tooltipItem.dataIndex];
                            }
                            return `${lineType}: ${Utils.convertSecondsToHours({
                                seconds: value,
                                hoursLabel: 'h',
                                minutesLabel: 'm',
                                secondsLabel: 's',
                            })}`;
                        },
                    },
                },
            },
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState}
            height={120}
            options={optionsState}
        />
    );
};

export default ServiceTimeBreakdownChart;