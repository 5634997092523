import { TooltipItem } from 'chart.js';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { BusAnalysisReport } from '../../../types/anomaliesTypes';
import { LineDatePoint } from '../../../types/chartTypes';

const color1 = 'rgb(229, 92, 85)';
const color2 = 'rgb(147, 192, 32)';
const color2b = 'rgba(147, 192, 32, 0.2)';
const color2c = 'rgba(147, 192, 32, 0.5)';

const chartSettings = {
    labels: [],
    datasets: [
        {
            yAxisID: 'y-axis-1',
            label: 'Number of "Jumps"',
            unit: '',
            backgroundColor: color1,
            borderColor: color1,
            fill: false,
            borderWidth: 2,
            data: [] as LineDatePoint[],
        },
        {
            yAxisID: 'y-axis-2',
            label: 'Offline Time TAIP (min)',
            unit: '',
            fill: 'origin',
            backgroundColor: color2b,
            borderColor: color2,
            borderWidth: 1,
            lineTension: 0,
            data: [] as LineDatePoint[],
        },
        {
            yAxisID: 'y-axis-2',
            label: 'Offline Time GTFS (min)',
            unit: '',
            fill: 'origin',
            backgroundColor: color2c,
            borderColor: color2,
            borderWidth: 1,
            lineTension: 0,
            data: [] as LineDatePoint[],
        },
    ],
};

const options = {
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'day' as const,
                displayFormats: {
                    day: 'M/D/YYYY',
                },
            },
            ticks: {
                stepSize: 1,
                autoSkip: false,
            },
            grid: {
                drawOnChartArea: false,
            },
        },
        'y-axis-1': {
            ticks: {
                color: color1,
            },
            beginAtZero: true,
            position: 'left' as const,
        },
        'y-axis-2': {
            ticks: {
                color: color2,
            },
            beginAtZero: true,
            position: 'right' as const,
        },
    },
    hover: {
        mode: 'x' as const,
        intersect: false,
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                boxWidth: 10,
                usePointStyle: true,
            },
        },
        tooltip: {
            mode: 'x' as const,
            intersect: false,
            callbacks: {
                label: function (tooltipItem: TooltipItem<'line'>) {
                    const dataset = chartSettings.datasets[tooltipItem.datasetIndex];
                    return `${dataset.label}: ${tooltipItem.parsed.y} ${dataset.unit}`;
                },
            },
        },
    },
    maintainAspectRatio: false,
    interaction: {
        mode: 'x' as const,
        intersect: false,
    },
};

const BusAnalysisJumpsOfflineChart: React.FC<{ chartData: BusAnalysisReport[] }> = ({ chartData }) => {
    const [dataState, setDataState] = useState(chartSettings);

    useEffect(() => {
        setDataState(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: chartData.map(d => {
                    return {
                        x: d.reportDate,
                        y: d.jumpsCount,
                    };
                }),
            }, {
                ...prevState.datasets[1],
                data: chartData.map(d => {
                    return {
                        x: d.reportDate,
                        y: Math.round(d.offlineSecTaip / 60 / 10) * 10,
                    };
                }),
            }, {
                ...prevState.datasets[2],
                data: chartData.map(d => {
                    return {
                        x: d.reportDate,
                        y: Math.round(d.offlineSecGtfs / 60 / 10) * 10,
                    };
                }),
            }],
        }));
    }, [chartData]);

    return (
        <Line
            data={dataState}
            options={options}
            plugins={[CrosshairPlugin]}
        />
    );
};

export default BusAnalysisJumpsOfflineChart;