import * as COLORS from '@mui/material/colors';

/*-- spectrum palette --*/

const scr = [
    COLORS.blueGrey,
    COLORS.brown,

    COLORS.pink, //
    COLORS.red,
    COLORS.deepOrange, //
    COLORS.orange,
    COLORS.amber, //
    COLORS.yellow,
    COLORS.lime,
    COLORS.lightGreen, //
    COLORS.green,
    COLORS.teal,
    COLORS.cyan,
    COLORS.lightBlue, //
    COLORS.blue,
    COLORS.indigo,
    COLORS.deepPurple, //
    COLORS.purple,

    // COLORS.brown,
    // COLORS.grey,
    // COLORS.blueGrey,
];

const setIndex1 = 200 as const;
const setIndex2 = 500 as const;
const setIndex3 = 'A700' as const;

type SpectrumColors = {
    set1: string[],
    set2: string[],
    set3: string[]
};

export const spectrumColors: SpectrumColors = {
    set1: scr.map(v => v[setIndex1].toUpperCase()),
    set2: scr.map(v => v[setIndex2].toUpperCase()),
    set3: scr.map(v => v[setIndex3].toUpperCase()),
};
