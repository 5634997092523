import BlockUi from '@availity/block-ui';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DropdownProps, Form, Header, Tab, TabProps } from 'semantic-ui-react';
import { AgencyStateType } from '../../../../actions/actionTypes';
import Api from '../../../../api/api';
import GtfsStaticApi from '../../../../api/gtfsStaticApi';
import { AppState } from '../../../../reducers';
import { getSelectedOrDefaultAgency } from '../../../../selectors';
import { PushpinProps } from '../../../../types/BingMapProps';
import { RtDelaysNow, RtDelaysNowInterval } from '../../../../types/delaysNowTypes';
import { GtfsDirectionVariantModel } from '../../../../types/gtfsTypes';
import Utils from '../../../../utilities/utils';
import DelaysSimpleLineChart from './DelaysSimpleLineChart';
import DelaysStackedLineChart from './DelaysStackedLineChart';
import { DelaysTimePoint, DropDownState, getDelaySort, RoutesDropDownStateType } from './models';
import { SummaryPaneContent } from './SummaryPaneContent';
import { calcAverageDelayDataChartData, calcDeviationDistribution, delayIcons, getAverageDelayDescription, getDelaysDistributionDescription, loadReportData, ReportData } from './utils';
import { VehicleHistoryPane } from './VehicleHistoryPane';

type Styles = {
    chartDescription: React.CSSProperties,
    row1: React.CSSProperties,
    box2: React.CSSProperties,
    row2: React.CSSProperties,
};

const styles: Styles = {
    chartDescription: {
        marginBottom: 30,
    },
    row1: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 14,
        marginBottom: '2rem',
    },
    box2: {
        minWidth: '40%',
        flex: '1 1 410px',
    },
    row2: {
        paddingBottom: '2rem',
    },
};

const routesDropDownState: RoutesDropDownStateType = {
    options: [],
    selectedValue: '',
    directions: [],
};

const directionDropDownState: DropDownState<string> = {
    options: [],
    selectedValue: '',
};

const displayModeDropDownState: DropDownState<RtDelaysNowInterval> = {
    options: [
        { value: RtDelaysNowInterval.m05, text: '5 min (last 1 hour)' },
        { value: RtDelaysNowInterval.m10, text: '10 min (last 2 hours)' },
        { value: RtDelaysNowInterval.m30, text: '30 min (last 6 hours)' },
        { value: RtDelaysNowInterval.m60, text: '1 hour (all day)' },
    ],
    selectedValue: RtDelaysNowInterval.m10,
};

interface Props {
    agency: AgencyStateType | undefined;
}

const DelaysNowForm: React.FC<Props> = ({ agency }) => {
    const [serviceDate, setServiceDate] = useState('');
    const [formBlockingState, setFormBlockingState] = useState(false);
    const [routesState, setRoutesState] = useState(routesDropDownState);
    const [directionsState, setDirectionsState] = useState(directionDropDownState);
    const [displayModeState, setDisplayModeState] = useState(displayModeDropDownState);
    const [reportsDataState, setReportsDataState] = useState<ReportData | undefined>();
    const [sourceDataState, setSourceDataState] = useState<DelaysTimePoint[]>([]);
    const [filteredDetailsState, setFilteredDetailsState] = useState<DelaysTimePoint[]>([]);
    const [sliderValueState, setSliderValueState] = useState<number>(0);
    const [center, setCenter] = useState<CoordinatePair>();
    const [pushpinsState, setPushpinsState] = useState<PushpinProps[]>([]);
    const [activeTabIndex, setActiveTabIndex] = useState<string | number | undefined>(0);
    const [selectedVehicle, setSelectedVehicle] = useState<RtDelaysNow | undefined>();

    useEffect(() => {
        if (agency === undefined) return;
        Api.getOrgLocation(agency.id).then(response => {
            setCenter([response.Latitude, response.Longitude]);
        });
        (async () => {
            const agencyInfo = await GtfsStaticApi.getAgencyInfo(agency.id);
            setServiceDate(agencyInfo.data.serviceDate);
            await retrieveReportData();
        }
        )();
    }, [agency?.id]);

    useEffect(() => {
        const r = routesState.selectedValue;
        const d = directionsState.selectedValue;
        let selectionPresented = false;

        const filteredSourceData: DelaysTimePoint[] = sourceDataState.map(ddp => {
            const filteredData = ddp.data.filter(item => {
                if (r && r !== item.routeShortName)
                    return false;

                if (d && d !== item.directionVariantInternalId)
                    return false;

                return true;
            });
            if (!selectionPresented && selectedVehicle) {
                selectionPresented = 0 <= filteredData.findIndex(item => (
                    selectedVehicle.vehicleId == item.vehicleId &&
                    selectedVehicle.tripId == item.tripId
                ));
            }
            return { time: ddp.time, data: filteredData };
        });
        setFilteredDetailsState(filteredSourceData);
        if (selectedVehicle && !selectionPresented) {
            setActiveTabIndex(0);
            setSelectedVehicle(undefined);
        }
    }, [sourceDataState, routesState.selectedValue, directionsState.selectedValue]);

    useEffect(() => {
        const pushpins: PushpinProps[] = [];
        for (let t = 0; t < filteredDetailsState.length; ++t) {
            const tp = filteredDetailsState[t];
            if (t !== sliderValueState) continue;

            for (let i = 0; i < tp.data.length; ++i) {
                const d = tp.data[i];
                if (typeof d.delaySec == 'number' &&
                    typeof d.latitude == 'number' &&
                    typeof d.longitude == 'number') {
                    pushpins.push({
                        location: [d.latitude as Latitude, d.longitude as Longitude],
                        options: {
                            title: d.vehicleId,
                            subTitle: Utils.convertSecondsToMinutes(d.delaySec!, 'm', 's'),
                            //text: d.routeShortName,
                            //color: (delayStatusColors[DelaySort.Early].base, c0),
                            icon: delayIcons[getDelaySort(d.delaySec)],
                            catId: 'vehicle',
                        },
                    });
                }
            }
        }
        setPushpinsState(pushpins);
    }, [filteredDetailsState, sliderValueState]);

    const averageDelayDataChartData = calcAverageDelayDataChartData(filteredDetailsState, routesDropDownState);
    const distributionSummaryChartData = calcDeviationDistribution(filteredDetailsState);
    const averageDelayDescription = getAverageDelayDescription(displayModeState.selectedValue);
    const delaysDistributionDescription = getDelaysDistributionDescription(displayModeState.selectedValue);

    const handleRouteChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: routeName }: DropdownProps) => {
        setRoutesState(prevState => ({
            ...prevState,
            selectedValue: routeName as string,
        }));
        if (routesState.directions.length === 0)
            return;
        const directions = routesState.directions.filter(r => r.routeShortName === routeName);
        if (!directions || directions.length === 0) {
            setDirectionsState(directionDropDownState);
        } else {
            setDirectionsState(prevState => ({
                ...prevState,
                selectedValue: '',
                options: directions
                    .sort((a, b) => a.directionVariantName.localeCompare(b.directionVariantName))
                    .map(d => ({ value: d.directionVariantInternalId, text: d.directionVariantName })),
            }));
        }
    };

    const handleDirectionChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value: directionVariantInternalId }: DropdownProps) => {
        setDirectionsState(prevState => ({
            ...prevState,
            selectedValue: directionVariantInternalId as string,
        }));
    };

    const handleModeChange = async (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        const interval = value as RtDelaysNowInterval;
        setDisplayModeState(prevState => ({
            ...prevState,
            selectedValue: interval,
        }));
        if (reportsDataState !== undefined) {
            const sourceData = reportsDataState[interval];
            updateRoutesAndDirections(sourceData.source);
            setSourceDataState(sourceData.points);
        }
    };

    const retrieveReportData = async () => {
        if (agency && agency.id) {
            setFormBlockingState(true);
            try {
                const reportData = await loadReportData(agency.id);
                setReportsDataState(reportData);
                if (displayModeState.selectedValue !== undefined) {
                    const interval = displayModeState.selectedValue;
                    updateRoutesAndDirections(reportData[interval].source);
                    setSourceDataState(reportData[interval].points);
                }
            } catch {
                setSourceDataState([]);
                setFilteredDetailsState([]);
                setActiveTabIndex(undefined);
                setSelectedVehicle(undefined);
            } finally {
                setFormBlockingState(false);
            }
        } else {
            setSourceDataState([]);
            setFilteredDetailsState([]);
            setActiveTabIndex(undefined);
            setSelectedVehicle(undefined);
        }
    };

    const updateRoutesAndDirections = (reportData: RtDelaysNow[]) => {
        const directions: GtfsDirectionVariantModel[] = Utils.groupByEntries(reportData, d => d.directionVariantInternalId)
            .map(([directionVariantInternalId, reportData]) =>
            ({
                routeShortName: reportData[0].routeShortName,
                directionVariantInternalId,
                directionVariantName: Utils.getDirectionVariantName(reportData[0].otsTripShortName, reportData[0].tripHeadsign),
            }))
            .sort((a, b) => a.directionVariantInternalId.localeCompare(b.directionVariantInternalId));
        const routes = [...new Set(directions.map(d => d.routeShortName))];
        const directionVariantIds = directions.map(d => d.directionVariantInternalId);
        if (routes.length === 0) {
            setRoutesState(routesDropDownState);
            setDirectionsState(directionDropDownState);
            return;
        }
        setRoutesState(prevState => ({
            options: routes.map(r => ({ value: r, text: r })),
            selectedValue: prevState.selectedValue && routes.includes(prevState.selectedValue) ? prevState.selectedValue : '',
            directions,
        }));
        setDirectionsState(prevState => {
            const selectedValue = prevState.selectedValue && directionVariantIds.includes(prevState.selectedValue) ? prevState.selectedValue : '';
            const directionsByRoute = routesState.selectedValue ? directions.filter(r => r.routeShortName === routesState.selectedValue) : [];
            return {
                selectedValue,
                options: directionsByRoute
                    .sort((a, b) => a.directionVariantName.localeCompare(b.directionVariantName))
                    .map(d => ({ value: d.directionVariantInternalId, text: d.directionVariantName })),
            };
        });
    };

    const handleSliderChange = (value: number) => {
        setSliderValueState(value);
    };

    const handleTabChange = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) => {
        setActiveTabIndex(data.activeIndex);
    };

    const tabPanes = [];
    {
        if (agency && sourceDataState.length > 0) {
            tabPanes.push({
                menuItem: 'Vehicle Delays',
                pane: (<Tab.Pane key="summary">
                    <SummaryPaneContent
                        serviceDate={serviceDate}
                        filteredDetailsState={filteredDetailsState}
                        center={center}
                        pushpins={pushpinsState}
                        sliderValue={sliderValueState}
                        onSliderChange={handleSliderChange}
                        selectedVehicle={selectedVehicle}
                        onSelectVehicle={s => {
                            setSelectedVehicle(s);
                            setActiveTabIndex(1);
                        }}
                    />
                </Tab.Pane>),
            });
        }
        if (agency && agency.id && selectedVehicle) {
            tabPanes.push({
                menuItem: `Vehicle ${selectedVehicle.vehicleId} (${selectedVehicle.routeShortName
                    }, ${moment(selectedVehicle.tripScheduledStart.substr(0, 19)).format('h:mm a')
                    }-${moment(selectedVehicle.tripScheduledFinish.substr(0, 19)).format('h:mm a')
                    })`,
                pane: (<Tab.Pane key="vehicle">
                    <VehicleHistoryPane
                        agencyId={agency.id}
                        serviceDate={serviceDate}
                        vehicleId={selectedVehicle.vehicleId}
                        tripId={selectedVehicle.tripId}
                        shapeId={selectedVehicle.shapeId}
                    />
                </Tab.Pane>),
            });
        }
    }

    return (
        <BlockUi tag="div" blocking={formBlockingState}>
            <Form>
                <Header as="h1" className="reportHeader">Delays Now</Header>
                <div style={styles.chartDescription}>
                    Select the aggregation period, route, and direction you are interested in.
                </div>
                <Form.Group>
                    <Form.Select
                        placeholder="Choose display mode"
                        fluid
                        search
                        selection
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={displayModeState.options}
                        value={displayModeState.selectedValue}
                        onChange={handleModeChange}
                        width={3}
                    />
                    <Form.Select
                        placeholder="Select route"
                        fluid
                        search
                        selection
                        clearable
                        icon={routesState.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={routesState.options}
                        value={routesState.selectedValue}
                        onChange={handleRouteChange}
                        width={3}
                    />
                    {routesState.selectedValue && <Form.Select
                        placeholder="Direction"
                        fluid
                        search
                        selection
                        clearable
                        icon={directionsState.selectedValue ? 'delete' : undefined}
                        openOnFocus={false}
                        selectOnBlur={false}
                        options={directionsState.options}
                        value={directionsState.selectedValue}
                        onChange={handleDirectionChange}
                        width={4}
                    />}
                </Form.Group>
                {agency && sourceDataState.length > 0 &&
                    <>
                        <div style={styles.row1}>
                            <div style={styles.box2}>
                                <label className="categoryHeader">{averageDelayDescription}</label>
                                <DelaysSimpleLineChart chartData={averageDelayDataChartData} />
                            </div>
                            <div style={styles.box2}>
                                <label className="categoryHeader">{delaysDistributionDescription}</label>
                                <DelaysStackedLineChart chartData={distributionSummaryChartData} />
                            </div>
                        </div>
                        <div style={styles.row2}>
                            <Tab
                                panes={tabPanes}
                                renderActiveOnly={false}
                                activeIndex={activeTabIndex}
                                onTabChange={handleTabChange}
                            />
                        </div>
                    </>
                }
            </Form>
        </BlockUi >
    );
};

export default connect(
    (state: AppState) => ({
        agency: getSelectedOrDefaultAgency(state),
    }),
)(DelaysNowForm);