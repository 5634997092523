import './TripPlannerStyles.css';
import * as React from 'react';
import { ItineraryDto, LegDto, ShapeItemDto, TransportTypeEnum } from '../../types/tripPlannelTypes';
import Utils from '../../utilities/utils';
import { Color, getLegColor } from './TripPlannerColors';

export function GetNormalTimeString(date: Date): string {
    const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    const minutes = date.getMinutes();
    const ampm = date.getHours() > 11 ? 'PM' : 'AM';
    return hours + ':'
        + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm;
}

function GetDurationString(start: Date, end: Date) {

    const mxFr2 = new Intl.NumberFormat('en', { minimumIntegerDigits: 2 });

    const totalSeconds = (end.getTime() - start.getTime()) / 1000;
    const minutes = totalSeconds % (60 * 60);
    const hours = totalSeconds - minutes;
    return (hours / (60 * 60)) + 'h:' + mxFr2.format(Math.floor(minutes / 60)) + 'm';
}

export function toTitleCase(str: string) {
    return str.split(/\b/).map(word => toSentenceCase(word)).join('');
}

function toSentenceCase(str: string) {
    const match: RegExpMatchArray | null = str.match(/\w/);
    if (!match) return str;
    const firstLetter = match[0];
    const i = str.indexOf(firstLetter);
    return str.slice(0, i) + (firstLetter.toUpperCase() + str.slice(i + 1).toLowerCase()).replace(/\b[NS][EW]\b/gi, match => match.toUpperCase());
}

export class LegData {
    public readonly type: TransportTypeEnum;
    public readonly summary: string;
    public readonly name;
    private direction;
    private distance;
    public readonly time: string;
    public readonly frequency: string | null;
    public readonly from;
    public readonly to;
    public readonly shape: ShapeItemDto[];
    public readonly text?: string[];
    public readonly solid?: boolean;
    public readonly color: Color;

    constructor(data: LegDto, private readonly useAternativeColor: boolean) {
        this.type = TransportTypeEnum[data.transportType];
        if (this.type == TransportTypeEnum.LightTrain) this.type = TransportTypeEnum.Metro;

        this.name = data.routeName;
        this.direction = toTitleCase(data.routeDestinationName || '');

        const miles = Utils.kmToMiles(data.lengthKm, 1);
        this.distance = (miles > 0) ? (miles + ' mi') : (Math.floor(Utils.kmToFeet(data.lengthKm)) + ' ft');

        this.time = GetNormalTimeString(ParseServiceDate(data.startTimeUTC));
        this.frequency = data.frequency;

        this.from = toTitleCase(data.stop1Name || '');
        this.to = toTitleCase(data.stop2Name || '');

        this.shape = data.shape;
        for (let i = 0; i < this.shape.length; ++i) {
            try {
                new Microsoft.Maps.Location(this.shape[i].lat, this.shape[i].lon);
            }
            catch (e) {
                console.error(`TripPlanner.LegData: invalid location? [${this.shape[i].lat}, ${this.shape[i].lon}]`, e);
            }
        }

        this.summary = (this.name && this.name !== 'Bicycle') ? this.name : this.distance;

        let text: string | undefined;
        switch (this.type) {
            case TransportTypeEnum.Walk:
            case TransportTypeEnum.Bicycle: {
                const verb = this.type;
                if (this.from) {
                    text = 'Transfer at ' + this.from + ' and ' + verb.toLowerCase() + ' to ';
                }
                else {
                    text = verb + ' to ';
                }
                if (data.stop2Name) {
                    text += toTitleCase(this.to);
                }
                else {
                    text += 'final destination';
                }
                text += '.';
                this.solid = false;
                break;
            }
            case TransportTypeEnum.Bus:
            case TransportTypeEnum.Metro:
                text = 'Transfer at ' + this.from
                    + ' to the ' + this.name + ' ' + this.type
                    + ' towards ' + this.direction;
                this.solid = true;
                break;
        }
        if (text)
            this.text = text.split('/');
        this.color = getLegColor(this.type, this.name, this.useAternativeColor);
    }
}

function ParseServiceDate(date: string) {
    return new Date(date);
}

export class ItineraryData {
    public readonly guid: string;
    public readonly legs: LegData[];
    public readonly departure: string;
    public readonly duration: string;

    constructor(data: ItineraryDto, public readonly changeColor: boolean) {
        this.guid = data.itineraryId;

        const startTime = ParseServiceDate(data.startTimeUTC);
        const endTime = ParseServiceDate(data.endTimeUTC);

        this.departure = GetNormalTimeString(startTime);
        this.duration = GetDurationString(startTime, endTime);

        this.legs = data.legs.map(data => {
            const leg = new LegData(data, this.changeColor);
            return leg;
        });
    }
}

type ItineraryItemProps = {
    open: boolean,
    data: ItineraryData | null,
    onClick: (data: ItineraryData) => void
};

const Slash = () => (<span className="wbr">|</span>);

const LegSummary: React.FC<{ data: LegData }> = ({ data }) => {
    return (<li className={data.type}>{data.summary}</li>);
};

const LegDetails: React.FC<{ data: LegData, prev: LegData | null }> = ({ data, prev }) => {
    let style: React.CSSProperties | undefined = {};
    if (prev && prev.color) {
        style = {
            borderLeftColor: prev.color.hex3,
            borderLeftStyle: (prev.solid === false ? 'dashed' : 'solid'),
        };
    }
    return (
        <li style={style}>
            <div className={`itinerary-step-summary ${data.type}`}>
                <div>{data.summary}</div>
            </div>
            <div>
                <div className="itinerary-step-time">{data.time}</div>
                {!data.frequency ? null : <div className="itinerary-step-frequency">{data.frequency}</div>}
                {!data.text
                    ? null
                    : (
                        <div className="itinerary-step-description">
                            {data.text.map((s, i) => (<React.Fragment key={i}>{i > 0 ? (<Slash />) : null}{s}</React.Fragment>))}
                        </div>
                    )}
            </div>
        </li>
    );
};

const ItineraryItem: React.FC<ItineraryItemProps> = ({ open, data, onClick }) => {
    let css = data?.changeColor ? 'itinerary-description itinerary-description-alternative' : 'itinerary-description';
    if (open) css += ' open';
    let prevStep: LegData | null = null;
    if (!data) return null;
    return (
        <div onClick={() => onClick(data)}>
            <table className={css}>
                <thead><tr><th>Departure</th><th>Duration</th></tr></thead>
                <tbody><tr><td>{data.departure}</td><td>{data.duration}</td></tr></tbody>
                <tfoot><tr><td colSpan={2}>
                    <div className="itinerary-summary">
                        <ol>{data.legs.map((leg, i) => (<LegSummary key={i} data={leg} />))}</ol>
                    </div>
                    <div className="itinerary-details">
                        <ol>{data.legs.map((leg, i) => {
                            const result = (<LegDetails key={i} data={leg} prev={prevStep} />);
                            prevStep = leg;
                            return result;
                        })}
                        </ol>
                    </div>
                </td></tr></tfoot>
            </table>
        </div>
    );
};

interface ItinerariesProps {
    itinerares: ItineraryData[];
    selected: ItineraryData | null;
    onClick: (itinerary: ItineraryData) => void;
}

const Itineraries: React.FC<ItinerariesProps> = ({ itinerares, selected, onClick }) => {
    return (
        <div className="plans-panel" id="tripPlansPanel">
            {!itinerares
                ? null
                : itinerares.map(item => (<ItineraryItem key={item.guid} open={item === selected} data={item} onClick={onClick} />))
            }
        </div>
    );
};

export default Itineraries;
