import { getFullRouteInfo } from '../../actions/gtfsStaticActions';
import { InfoboxProps, PolylineProps, PushpinProps } from '../../types/BingMapProps';
import { FullRouteDirectionVariant, FullRouteInfo } from '../../types/gtfsTypes';
import { MapLocalion } from '../../types/map-types';
import { IDirectionModel, IRouteModel } from '../../types/view-models-interfaces';
import Utils from '../../utilities/utils';
import { getCurrentRealtimeVehicles, getCurrentVehiclesOnDirections } from './_apiActions';
import { HeadwaySimpleInfoDto, VehicleHeadwaysInfoDto, VehicleNowDto } from './_dto';
import { Headway, IRouteHeadwayEntry, IRouteStop, IRouteVariant,
    IRouteVehicle, IRouteVehicleList, RouteVariantContainer,
    Thresholds } from './_interfaces';
import { parseAsNetDate, toTitleCase } from './_utils';
import { getBusInfoboxLargeHtml, getNonTripVehiclePushpinHtml, getRouteVehiclePushpinHtml } from './LiveMapInfoboxes';

export type RouteVariantEntry = {
    route: IRouteModel,
    direction: IDirectionModel,
    routeVariant: IRouteVariant
};

class Stop implements IRouteStop {
    public location: MapLocalion;
    public pushpin: PushpinProps;
    public infobox: InfoboxProps;
    public selected = false;

    constructor(
        lat: number,
        lon: number,
        public readonly name: string,
        public readonly distance: number,
        public readonly route: IRouteVariant,
    ) {
        this.location = new MapLocalion(lat as Latitude, lon as Longitude);
        this.route = route;

        this.pushpin = this.createPushpin();
        this.infobox = this.createInfobox();
    }

    private getInfoboxHtml(): string {
        return `<div class='route-${this.route.id} pushpin stop-infobox ${this.route.cardinalDirection.join(' ')}' style='border-color: ${this.route.cssColor}'>`
            + "<div class='stop-info'>"
            + "<h4 style='margin: 0'}>" + toTitleCase(this.name) + '</h4>'
            + "<h5 style='margin: 0'}>" + this.route.name + '</h5>'
            + '</div>'
            + '</div>';
    }

    private createPushpin(): PushpinProps {
        const result: PushpinProps = {
            location: this.location.toCoordinatePair(),
            options: {
                color: this.route.cssColor,
            },
        };
        return result;
    }

    private createInfobox(): InfoboxProps {
        const result: InfoboxProps = {
            location: this.location.toCoordinatePair(),
            options: {
                htmlContent: this.getInfoboxHtml(),
                zIndex: 5,
            },
        };
        return result;
    }
}

class HeadwayEntry implements IRouteHeadwayEntry {
    public readonly vehicleId: string;
    public readonly secondsReal: number;
    public readonly secondsScheduled: number;

    constructor(
        public readonly routeVariant: IRouteVariant,
        headway: HeadwaySimpleInfoDto,
    ) {
        this.vehicleId = headway.vehicleId2;
        this.secondsReal = headway.secondsReal;
        this.secondsScheduled = headway.secondsScheduled;
    }

    public get cardinalDirection(): string {
        return this.routeVariant.cardinalDirectionShort;
    }

    public get secondsDiff(): number {
        return Math.abs(this.secondsReal - this.secondsScheduled);
    }

    public getKind(thresholds: Thresholds): 'error' | 'warn' | undefined {
        if (this.secondsReal < thresholds.bunchedError ||
            thresholds.spreadError < this.secondsReal) {
            return 'error';
        }
        else if (this.secondsReal < thresholds.bunchedWarning ||
            thresholds.spreadWarning < this.secondsReal) {
            return 'warn';
        }
        return;
    }
}

class VehicleList implements IRouteVehicleList {
    public dict: { [vechicleId: string]: Vehicle } = {};

    constructor(public readonly routeVariant: IRouteVariant) {
    }

    public updateVehicles(vehicles: VehicleNowDto[]) {
        const constiant = this.routeVariant.constiant;
        const currentVehicles: { [vehicleId: string]: VehicleNowDto } = {};

        vehicles.forEach(vehicleData => {
            if (this.routeVariant.id === 'none' || vehicleData.trip?.directionVariantId === constiant) {
                currentVehicles[vehicleData.vehicleId] = vehicleData;
            }
        });

        if (!this.dict)
            this.dict = {};

        for (const i in this.dict) {
            const vehicle = currentVehicles[i];
            if (vehicle) {
                this.dict[i].update(currentVehicles[i]);
                delete currentVehicles[i];
            }
            else {
                delete this.dict[i];
            }
        }

        for (const n in currentVehicles) {
            const data = currentVehicles[n];
            this.dict[data.vehicleId] = new Vehicle(this.routeVariant, data);
        }
    }

    public updateHeadways(data: HeadwaySimpleInfoDto[]) {
        const result: HeadwayEntry[] = [];

        const items = data
            .filter(d =>
                d.directionVariantId1 === this.routeVariant.constiant ||
                d.directionVariantId2 === this.routeVariant.constiant,
            );

        items.forEach(headway => {
            const item: HeadwayEntry = new HeadwayEntry(this.routeVariant, headway);
            result.push(item);
        });
        return result;
    }
}

class Vehicle implements IRouteVehicle {
    public selected = false;
    public readonly location: MapLocalion;
    public name: string;
    public delaySeconds: number;
    public isNextBus: boolean;
    public heading: number | null;
    public start: Date;
    public finish: Date;
    public scheduled: boolean;
    public headway: Headway;
    public distance: number;

    public tripHeadsign: string;
    public tripLastStopName: string;

    constructor(
        public readonly route: IRouteVariant,
        data: VehicleNowDto,
    ) {
        this.location = new MapLocalion(data.lat as Latitude, data.lon as Longitude);
        this.name = data.vehicleId;
        this.distance = Utils.kmToMiles(data.distanceFromStartKm);
        this.delaySeconds = data.trip?.delaySeconds || 0;
        this.isNextBus = data.isLastUpdateFromDevice === false;
        this.tripHeadsign = data.trip?.headsign || 'none';
        this.tripLastStopName = data.trip?.lastStopName || 'none';
        this.heading = data.angle || null;
        this.start = data.trip ? parseAsNetDate(data.trip.tripStartTimeUtc, data.trip.offset) : new Date();
        this.finish = data.trip ? parseAsNetDate(data.trip.tripEndTimeUtc, data.trip.offset) : this.start;
        this.scheduled = data.trip?.isRealTime === false;

        this.headway = {
            Ahead: data.headwayPrev,
            Behind: data.headwayNext,
        };
    }

    public get isNotTrip(): boolean {
        return this.route.id === 'none';
    }

    private _hasProblems?: boolean;
    private _pushpin?: InfoboxProps;

    public hasProblem(thresholds: Thresholds): boolean {
        const hasProblems = 
            !!(this.headway.Ahead && getProblemKind(thresholds, this.headway.Ahead.secondsReal)) ||
            !!(this.headway.Behind && getProblemKind(thresholds, this.headway.Behind.secondsReal));
        return hasProblems;
    }

    public getVehiclePushpinInfobox(thresholds: Thresholds): InfoboxProps {
        const hasProblems = this.hasProblem(thresholds);
    
        if (!this._pushpin || (this._hasProblems !== hasProblems)) {
            this._hasProblems = hasProblems;
            this._pushpin = {
                location: this.location.toCoordinatePair(),
                options: {
                    htmlContent: this.getPushpinHtml(hasProblems),
                    zIndex: this.selected ? 8 : 4,
                },
            };
        }
        return this._pushpin;
    }

    public update(data: VehicleNowDto) {
        //RF should do the same as constructor!!!
        this.setLocation(data.lat, data.lon);

        this.name = data.vehicleId;
        this.distance = Utils.kmToMiles(data.distanceFromStartKm);
        this.delaySeconds = data.trip?.delaySeconds || 0;
        this.isNextBus = data.isLastUpdateFromDevice === false;
        this.tripHeadsign= data.trip?.headsign || 'none';
        this.tripLastStopName = data.trip?.lastStopName || 'none';
        this.heading = data.angle || null;
        this.start = data.trip ? parseAsNetDate(data.trip.tripStartTimeUtc, data.trip.offset) : new Date();
        this.finish = data.trip ? parseAsNetDate(data.trip.tripEndTimeUtc, data.trip.offset) : this.start;
        this.scheduled = data.trip?.isRealTime === false;

        this.headway = {
            Ahead: data.headwayPrev,
            Behind: data.headwayNext,
        };

        delete this._infobox;
    }

    private setLocation(lat: number, lon: number) {
        if (this.location.latitude !== lat || this.location.longitude !== lon)
        {
            this.location.set(lat as Latitude, lon as Longitude);
            delete this._pushpin;

            // this.infobox && this.infobox.setLocation(this.location);
        }
        return this.location;
    }

    private getPushpinHtml(hasProblems: boolean) {
        if (this.isNotTrip) {
            return getNonTripVehiclePushpinHtml(this);
        }
        else {
            return getRouteVehiclePushpinHtml(this, hasProblems);
        }
    }

    private getHeadwayInfo(headway: VehicleHeadwaysInfoDto | null | undefined, thresholds: Thresholds) {
        if (!headway)
            return undefined;

        return {
            vehicleId: headway.vehicleId,
            secondsReal: headway.secondsReal,
            problem: getProblemKind(thresholds, headway.secondsReal),
        };
    }

    private _infobox?: InfoboxProps;

    public getVehicleFullInfobox(thresholds: Thresholds): InfoboxProps | undefined {
        if (this.isNotTrip) return;

        if (!this._infobox) {
            const htmlContent = getBusInfoboxLargeHtml({
                routeId: this.route.id,
                cssColor: this.route.cssColor,
                routeCardinalDirection: this.route.cardinalDirection,
                scheduled: this.scheduled,
                delay: this.delaySeconds,
                headsign: this.tripHeadsign,
                lastStopName: this.tripLastStopName,
                start: this.start,
                finish: this.finish,
                headway: {
                    Ahead: this.getHeadwayInfo(this.headway.Ahead, thresholds),
                    Behind: this.getHeadwayInfo(this.headway.Behind, thresholds),
                },
            });
            this._infobox = {
                location: this.location.toCoordinatePair(),
                options: {
                    htmlContent,
                    //visible: this.selected,
                    zIndex: 6,
                },
            };
        }
        return this._infobox;
    }
}

class RouteVariant implements IRouteVariant {
    public static readonly dict: RouteVariantContainer = {
        routeVariants: {},
    };

    static readonly NonRoute = new RouteVariant('none', 'none', 'Not On a Trip', '#696969', [], 'none', 0, true);

    public readonly id: string;
    public readonly name: string;
    public readonly cssColor: string;
    public stops: IRouteStop[] = [];
    public readonly vehicles: VehicleList;
    public routeLine?: PolylineProps;

    public loading = false;

    public readonly cardinalDirectionShort: string;

    constructor(
        public readonly constiant: string,
        routeId: string,
        routeName: string,
        color: string | null,
        public readonly cardinalDirection: string[],
        public readonly variant: string,
        public readonly distance: number,
        public readonly isMain: boolean,
    ) {
        this.id = routeId;
        this.name = routeName;
        this.cssColor = (color || '#8f00ff');
        this.cardinalDirectionShort = cardinalDirection.map(dir => dir.slice(0, 1).toUpperCase()).join('');
        this.vehicles = new VehicleList(this);
    }

    private _owner: RouteVariantContainer | null = null;
    public get owner(): RouteVariantContainer | null {
        return this._owner;
    }
    public set owner(value: RouteVariantContainer | null) {
        if (this._owner === value) return;
        if (this._owner) {
            delete this._owner.routeVariants[this.constiant];
            this._owner = null;
        }
        this._owner = value;
        if (this._owner) {
            this._owner.routeVariants[this.constiant] = this;
        }
    }

    public async initialLoad(): Promise<unknown | null> {
        this.loading = true;
        try {
            const data = await getFullRouteInfo(this.id);

            const variantData = this.extractVariantData(data);
            if (variantData) {
                this.routeLine = {
                    options: {
                        strokeColor: this.cssColor,
                        strokeThickness: 3,
                        strokeDashArray: this.isMain ? undefined : '6 3',
                    },
                    locations: variantData.shape.points.map(c => [c.lat as Latitude, c.lon as Longitude]),
                };
                this.stops = variantData.stops.map(data => new Stop(data.stop.lat, data.stop.lon, data.stop.stopName, data.distanceFromStart, this));
            }
            return null;
        }
        catch (error) {
            return error;
        }
    }

    private extractVariantData(data: FullRouteInfo): FullRouteDirectionVariant | null {
        for (const direction in data.directions) {
            const directionData = data.directions[direction];
            if (directionData.directionId === this.constiant.slice(0, -2)) {
                for (const constiant in directionData.directionVariants) {
                    const constiantData = directionData.directionVariants[constiant];
                    if (constiantData.directionVariantId === this.constiant) {
                        return constiantData;
                    }
                }
            }
        }
        return null;
    }
}

export function getProblemKind(thresholds: Thresholds, secondsReal: number): ('error' | 'warn' | null) {
    if (secondsReal < thresholds.bunchedError ||
        thresholds.spreadError < secondsReal) {
        return 'error';
    }
    if (secondsReal < thresholds.bunchedWarning ||
        thresholds.spreadWarning < secondsReal) {
        return 'warn';
    }
    return null;
}

export async function updateNonRouteVehiclesAndHeadways(rvContainer: RouteVariantContainer, agencyId: string | null) {
    if (!agencyId || rvContainer.routeVariants[nonRoute.id] !== nonRoute) return;
    try {
        const data = await getCurrentRealtimeVehicles(agencyId, true);
        if (data !== null) {
            nonRoute.vehicles.updateVehicles(data.vehicles.filter(vn => !vn.trip));
        }
    }
    catch (e) {
        console.error('LiveMap: getCurrentRealtimeVehicles failed', e);
    }
}

export async function updateOnRouteVehiclesAndHeadways(rvContainer: RouteVariantContainer) {
    const directionsIds: string[] = [];
    for (const key in rvContainer.routeVariants) {
        const rv = rvContainer.routeVariants[key];
        if (rv.id !== 'none') {
            directionsIds.push(key.slice(0, -2));
        }
    }
    if (directionsIds.length <= 0)
        return null;
    try {
        const data = await getCurrentVehiclesOnDirections(directionsIds.join(), false);
        if (data !== null) {
            let result: IRouteHeadwayEntry[] | null = null;

            for (const rvid in rvContainer.routeVariants) {
                const vehicles = rvContainer.routeVariants[rvid].vehicles;

                const dirId = rvid.slice(0, -2);

                vehicles.updateVehicles(data.vehicles.filter(vn => vn.trip?.directionId === dirId));

                if (data.headways && data.headways.length > 0) {
                    const hw = vehicles.updateHeadways(data.headways.filter(e => e.directionId === dirId));
                    if (!result)
                        result = hw;
                    else
                        result = result.concat(hw);
                }
            }
            return result;
        }
    }
    catch (e) {
        console.error('LiveMap: getCurrentVehiclesOnDirections failed', e);
    }
    return null;
}

export const nonRoute: IRouteVariant = RouteVariant.NonRoute;

export function createRouteVariantInstance(route: IRouteModel, direction: IDirectionModel): IRouteVariant {
    return new RouteVariant(
        direction.directionVariantId,
        route.routeId, route.routeName,
        route.cssColor,
        direction.cardinalDirection.toLowerCase().split('-'),
        direction.directionVariantId,
        direction.distance,
        direction.isMain);
}
